import { useState, useCallback, useEffect } from "react";
import AvailModal from "./AvailModal";
import PortalPopup from "./PortalPopup";
import styles from "./LocationModal.module.css";
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import Slider from '@mui/material/Slider';
import { auth, db } from "../firebase";
import {Grid} from '@mui/material';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  thumb: {
    width: '40px', // Adjust the width of the thumb
    height: '20px', // Adjust the height of the thumb
    borderRadius: '0', // Set border-radius to 0 for a rectangular shape
    color: 'black',
  },
  track: {
    color: 'black',
  },
  rail: {
    color: 'grey', // Set the color of the empty track to grey
  },
});

const Input = styled(MuiInput)`
  width: 42px;
`;
const mapContainerStyle = {
  width: '100%',
  height: '158px',
};

const center = {
  lat: 0, // Default center latitude
  lng: 0, // Default center longitude
};

const radiusOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
};
const LocationModal = ({ onClose }) => {
  const [isAvailModalPopupOpen, setAvailModalPopupOpen] = useState(false);
  const [loc, setLoc] = useState(false)
  const [address, setAddress] = useState('');
  const [tab, setTab]= useState(0)
  const [name, setName] = useState('')
  const [sub, setSub] = useState(1)
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [done, setDone] = useState(false)
  const [currentLocation, setCurrentLocation] = useState();
  const [radius, setRadius] = useState(0);
  const classes = useStyles();

  useEffect(() => {
     const fetchData = async () => {
     await new Promise(resolve => setTimeout(resolve, 1000));
    if (auth.currentUser) {
      try {
        const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
        if (snapshot.exists) {
          setName(snapshot.data().fname + " "+ snapshot.data().lname)
              setAddress(snapshot.data().addy1)
              setSub(snapshot.data().subType)
              setRadius(snapshot.data().distance)
              setLat(snapshot.data().lat)
              setLng(snapshot.data().lng)
              if (snapshot.data().lat!=null&&snapshot.data().lng!=null) {
                setCurrentLocation({
        lat:snapshot.data().lat,
        lng:snapshot.data().lng
      })
              } else{
                setCurrentLocation(center)
              } 
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    }
  };
  fetchData();
  
  }, [])

  const handleMapClick = (event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
     setCurrentLocation(newLocation);
    setLat(event.latLng.lat())
      setLng(event.latLng.lng())
      getLocationAddress(event.latLng.lat(),event.latLng.lng());
  };

  const handleRadiusChange = (event, value) => {
    setRadius(value);
  };

  const handleGoToCurrentLocation = () => {
     navigator.geolocation.getCurrentPosition(function(position) {
      setCurrentLocation({
        lat:position.coords.latitude,
        lng:position.coords.longitude
      })
       setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      getLocationAddress(position.coords.latitude, position.coords.longitude);
    });
  };

  const updateLocation =()=>{
    db.collection('users').doc(auth.currentUser.uid).update({
      addy1: address,
      lat: lat,
      lng: lng,
      distance: radius,
    }).then(()=>{
 onClose()
    window.location.reload()
    setLoc(false)
    })
  }
const getLocationAddress = async (lat, lng) => {
  try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const fullAddress = data.results[0].formatted_address;
        setAddress(fullAddress)
        console.log(fullAddress);
        // Do something with the full address (e.g., store it in state or display it)
      } else {
        console.log('No address found for the given coordinates.');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadius(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (radius < 0) {
      setRadius(0);
    } else if (radius > 100) {
      setRadius(100);
    }
  };

  const openAvailModalPopup = useCallback(() => {
    setAvailModalPopupOpen(true);
  }, []);

  const closeAvailModalPopup = useCallback(() => {
    setAvailModalPopupOpen(false);
  }, []);

  return (
    <>
    {
      !isAvailModalPopupOpen?
 <div className={styles.locationmodal}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>{`Location & Availabilty`}</div>
            <img className={styles.iconsclose} alt="" src="/iconsclose1.svg" onClick={onClose} />
          </div>
        </div>
        <div className={styles.twoTabSwitchParent}>
          <div className={styles.twoTabSwitch}>
            <div className={styles.tab1Wrapper}>
              <div className={styles.tab1}>Location</div>
            </div>
            <button
              className={styles.tab2Wrapper}
              onClick={openAvailModalPopup}
            >
              <div className={styles.tab2}>Availability</div>
            </button>
          </div>
          <div className={styles.labelContainerParent}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Location</div>
            </div>
            <div className={styles.baseFieldIcon}>
            <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={radius ? Math.floor(14 - Math.log2(radius)) : 2}
        center={currentLocation}
        onClick={handleMapClick}
      >
      <Marker position={currentLocation} animation="BOUNCE"/>
        {radius > 0 && <Circle center={currentLocation} radius={radius * 1000} options={radiusOptions} />}
      </GoogleMap>
            </div>
           
            <div className={styles.iconslocationParent} style={{cursor:'pointer'}} 
            onClick={handleGoToCurrentLocation}>
              <img
                className={styles.iconsclose}
                alt=""
                src="/iconslocation.svg"
              />
              <div className={styles.useMyCurrent}>Use my current location</div>
            </div>
          </div>
        </div>
        <div className={styles.reachParent}>
          <div className={styles.reach}>Reach</div>
          <div className={styles.bgParent}>
          <Grid container spacing={2} alignItems="center">
        <Grid item xs>
        <Slider
        value={radius}
        min={0}
        max={100}
        step={1}
        onChange={handleRadiusChange}
        classes={{ thumb: classes.thumb , track: classes.track, rail: classes.rail}}
      />
        </Grid>
        <Grid item>
          <Input
            value={radius}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
          </div>
        </div>
        <button className={styles.largeButtons} onClick={updateLocation}>
          <img
            className={styles.icons20pxplaceholder}
            alt=""
            src="/icons20pxplaceholder311.svg"
          />
          <b className={styles.label1}>Update Location</b>
          <img
            className={styles.icons20pxplaceholder}
            alt=""
            src="/icons20pxplaceholder311.svg"
          />
        </button>
      </div>
      :
       <AvailModal onClose={onClose} onLocation={closeAvailModalPopup}/>
    }
     
      
    </>
  );
};

export default LocationModal;
