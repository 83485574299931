import styles from "./Tools.module.css";
const Tools = () => {
  return (
    <div className={styles.tools}>
      <div className={styles.quoteCardsParent}>
        <div className={styles.quoteCards}>
          <div className={styles.frameParent}>
            <div className={styles.quoteNumberParent}>
              <div className={styles.quoteNumber}>Electrical insulator</div>
              <div className={styles.x10}>
                <span className={styles.span}>{`10 `}</span>
                <span className={styles.x}>X</span>
                <span className={styles.span}> £10</span>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.kvPinInsulatorsParent}>
                <div className={styles.kvPinInsulators}>
                  11KV Pin Insulators
                </div>
                <div className={styles.div}>£95</div>
              </div>
              <div className={styles.kvPinInsulatorsParent}>
                <div className={styles.kvPinInsulators}>Discount(5%)</div>
                <div className={styles.div1}>-£5</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.quoteCards}>
          <div className={styles.frameParent}>
            <div className={styles.quoteNumberParent}>
              <div className={styles.quoteNumber}>20kva generator</div>
              <div className={styles.x101}>
                <span>1 X £300</span>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.kvPinInsulatorsParent}>
                <div className={styles.kvPinInsulators1}>
                  11KV Pin Insulators
                </div>
                <div className={styles.div}>£285</div>
              </div>
              <div className={styles.kvPinInsulatorsParent}>
                <div className={styles.kvPinInsulators}>Discount(5%)</div>
                <div className={styles.div1}>-£15</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.quoteCards}>
          <div className={styles.frameParent}>
            <div className={styles.quoteNumberParent}>
              <div className={styles.quoteNumber}>Solar panels</div>
              <div className={styles.x10}>
                <span className={styles.span}>{`5 `}</span>
                <span className={styles.x}>X</span>
                <span className={styles.span}> £010</span>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.kvPinInsulatorsParent}>
                <div className={styles.kvPinInsulators}>
                  Waterproof 300W monocrystalline solar panel
                </div>
                <div className={styles.div}>£475</div>
              </div>
              <div className={styles.kvPinInsulatorsParent}>
                <div className={styles.kvPinInsulators}>Discount(5%)</div>
                <div className={styles.div1}>-£25</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.quoteCards3}>
        <div className={styles.quoteNumber3}>Subtotal:</div>
        <div className={styles.qn0001}>£855</div>
        <div className={styles.transactionPills}>
          <div className={styles.success}>DRAFTS</div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
