import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SignIn.module.css";
import app, { firebase, auth, signInWithGoogle, db, signInWithFacebook } from '../firebase';
import { TextField, IconButton, InputAdornment, Link } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Verify from "./Verify";

const SignIn = () => {
  const navigate = useNavigate();
  const [phone,setPhone] = useState("")
  const [password,setPassword] = useState('')
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [final, setfinal] = useState('');
  const [sent, setSent] = useState(false);
  const [tabs,setTab]=useState(0)
  const recaptchaRef = useRef(null);
  const [emailPhoneError, setEmailPhoneError] = useState("");
const [passwordError, setPasswordError] = useState("");


  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
    // Sent OTP
    const signin = (e) => {
      e.preventDefault();
      setEmailPhoneError(""); // Reset email/phone error
      setPasswordError("");   // Reset password error
    
      if (tabs == 0) {
        if (phone === "" || phone.length < 10) {
          setEmailPhoneError("Please enter a valid phone number");
          return;
        }
    
        db.collection('phone_passwords').doc(phone).get().then(function (snap) {
          if (snap.data()) {
          if (snap.data().password!=password) {
            setEmailPhoneError("Your password is incorrect");
            return;
          }
          else{
            setLoading(true)
             // Check if the user exists for phone-based authentication
      const phoneNo = "+" + phone;
      app
        .auth()
        .signInWithPhoneNumber(phoneNo, recaptchaRef.current)
        .then((result) => {
          setfinal(result);
            setSent(true)
            setLoading(false)
        })
            .catch((err) => {
                console.log(err.message)
                setEmailPhoneError('Unable to send OTP please try again later')
                setLoading(false)
        });
          }
          }
          else{
            setEmailPhoneError("Your password is incorrect");
            return;
          }
         
        })
        
      } else {
        signInWithEmailAndPassword();
      }
    };
    
    async function signInWithEmailAndPassword() {
      try {
        setEmailPhoneError(""); // Reset email/phone error
        setPasswordError("");   // Reset password error
        setLoading(true)
        // Check if the user exists for email-based authentication
        const signInMethods = await app.auth().fetchSignInMethodsForEmail(phone);
    
        if (signInMethods.includes("password")) {
          // User with this email exists
          const userCredential = await app.auth().signInWithEmailAndPassword(phone, password);
          const user = userCredential.user;
    
          if (user.uid) {
            let email = phone;
            const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/firecreateUser');
            console.log(response.data.code)
            await fetch('https://loggworks-c15c9e56f169.herokuapp.com/verifyuser', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                to: email,
                name: 'user',
                code: response.data.code,
              }),
            }).then(()=>{
              console.log(response.data.code)
              db.collection('otp').doc(email).set({
                otp: `${response.data.code}`
              })
            // After both axios and fetch are completed, navigate to the confirmation page
            navigate("/confirm-email/" + email);
            });
           
          }
        } else {
          // User does not exist for this email
          if (signInMethods.length === 0) {
            setEmailPhoneError("User does not exist");
          } else {
            // The user exists, but the password is incorrect
            setPasswordError("Wrong login details");
          }
          setLoading(false)
        }
      } catch (error) {
        console.error("Error signing in:", error.message);
        setLoading(false)
        // Handle other sign-in errors if needed
      }
    }
     const [user, setUser] = useState(null);
  useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      // You can provide optional parameters here
      size: "invisible", // "normal" or "compact"
      callback: () => {
        // This callback will be triggered when the reCAPTCHA verification is successful
      },
      "expired-callback": () => {
        // This callback will be triggered when the reCAPTCHA verification expires
      },
    });
    // Assign the RecaptchaVerifier instance to the ref
    recaptchaRef.current = recaptchaVerifier;
    app.auth().onAuthStateChanged(user => {
      if(user)
      {
        db.collection('users').doc(user.uid).get().then(function(snapshot) {
            if(snapshot.data())
            {
              
              navigate('/home')
            }
            else
            {
              navigate('/personalinfo')
            }
          });
      }
    })
  }, [])

  const onLargeInputsContainerClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const onThisIsA2Click =() => {
    if (tabs==0) {
      navigate("/resetpassword-phone");
    }
    else{
      navigate("/resetpassword");
    }
  };

  const onLargeButtonsClick = useCallback(() => {
    navigate("/otp");
  }, [navigate]);

  const onDontHaveAnClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  return (
    <>
    {
      !sent?
       <div className={styles.signIn}>
      <div className={styles.img}>
        <img className={styles.logoIcon} alt="" src="/logo.svg" />
        <div className={styles.testimonial}>
          <div className={styles.greatSessionDaniWasSuperParent}>
            <div className={styles.greatSessionDani}>
              "Great session! Dani was super helpful. She shared some practical
              advice on how can we go about refining our service offerings."
            </div>
            <div className={styles.frameParent}>
              <div className={styles.ellipseParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="/ellipse-249@2x.png"
                />
                <div className={styles.cameronWilliamson}>
                  Cameron Williamson
                </div>
              </div>
              <div className={styles.starRating}>
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar11.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.loginformWrapper}>
        <div className={styles.loginform}>
          <b className={styles.logIn}>{`Log in `}</b>
          {emailPhoneError && (
  <div style={{ color: "red",fontSize:'15px' }}>{emailPhoneError}</div>
)}

{passwordError && (
  <div style={{ color: "red",fontSize:'15px' }}>{passwordError}</div>
)}

          <div className={styles.login}>
            <div className={styles.greatSessionDaniWasSuperParent}>
              <div className={styles.verifyForm}>
                <div className={styles.inputs}>
                  <div
                    className={styles.largeInputs}
                  >
                    <div className={styles.label}><a>{tabs==0?'Phone number':'Email Address'}</a></div>
                     <div className={styles.field}>
                     {
                        tabs==0?
                        <PhoneInput
                        country={'gb'}
                        value={phone}
                        onChange={e => setPhone(e)}
                        inputStyle={{
                          width:'100%',
                          height:'3rem'
                        }}
                      />
                      :
                      <TextField
                      fullWidth
                      value={phone}
                      onChange={(e)=>{setPhone(e.target.value)}}
                      />
                      }
                    </div>
                  </div>
                  <div className={styles.largeInputs1}>
                 
                  <>
                     <div className={styles.label1}>Password</div>
                    <div style={{width:'100%'}}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        type={show ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                  >
                                      {show ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                          )
                      }}
                    /> 
                    </div>
                    </>
                    <div id="recaptcha-container" /> 
                    <div className={styles.helperText1}>
                    <Link href={tabs === 0?"/resetpassword-phone":"/resetpassword"}>
                     <p className={styles.thisIsA2}>
                    Forgot Password
                  </p>
                  </Link>
                    </div>
                      
                  </div>
                </div>
                <button
                  className={styles.largeButtons}
                  onClick={signin}
                  disabled={loading}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                  <b className={styles.label2}>Log in</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                </button>
              </div>
              <div className={styles.demarcation}>
                <div className={styles.orWrapper}>
                  <div className={styles.or}>OR</div>
                </div>
                <div className={styles.demarcationChild} />
              </div>
              <div className={styles.socialLogin}>
                <button className={styles.largeButtons1} onClick={signInWithFacebook}>
                  <img
                    className={styles.iconsfacebook}
                    alt=""
                    src="/iconsfacebook.svg"
                  />
                  <b className={styles.label2}>Continue with Facebook</b>
                  <img
                    className={styles.iconsfacebook1}
                    alt=""
                    src="/iconsfacebook.svg"
                  />
                </button>
                <button className={styles.largeButtons2} onClick={signInWithGoogle}>
                  <img
                    className={styles.iconsfacebook}
                    alt=""
                    src="/iconsgoogle.svg"
                  />
                  <b className={styles.label4}>Continue with Google</b>
                  <img
                    className={styles.iconsfacebook1}
                    alt=""
                    src="/iconsgoogle.svg"
                  />
                </button>
                {
            tabs==1?
            <button onClick={()=>setTab(0)} className="w-full h-[48px] text-[#000000] font-bold py-2 px-4 border border-[#000000] rounded flex justify-center items-center mb-4">
            <div className="icon">
            <img
                src={"/assets/icons/tabler_phone.svg"}
                height={20}
                width={20}
                alt="icon"
                className="mr-2"
              />
            </div>
            <span>Continue with Phone number</span>
          </button>
          :
          <button onClick={()=>setTab(1)}  className="w-full h-[48px] text-[#000000] font-bold py-2 px-4 border border-[#000000] rounded flex justify-center items-center mb-4">
          <div className="icon">
          <img
              src={"/assets/icons/Icons_mail2.svg"}
              height={20}
              width={20}
              alt="icon"
              className="mr-2"
            />
          </div>
          <span>Continue with Email</span>
        </button>
          }
              </div>
            </div>
            <div
              className={styles.dontHaveAnContainer}
            >
              <span
                className={styles.dontHaveAn}
              >{`Don’t have an account? `}</span>
              <Link className={styles.signUp} href="/sign-up">Sign up</Link>

            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <Verify final={final} phone={phone} check={true} password={password} recaptchaRef={recaptchaRef}/>
    }
      
    </>
   
  );
};

export default SignIn;
