import { useCallback } from "react";
import styles from "./Attachment.module.css";
const Attachment = ({onQuote}) => {
  const onLeftContainerClick = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onLeftContainer1Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.attachment}>
      <div className={styles.leftParent}>
        <div className={styles.left} onClick={onQuote}>
          <img className={styles.iconWithBg} alt="" src="/group-66565.svg" />
          <img
            className={styles.iconsplaceholder}
            alt=""
            src="/group-66565.svg"
          />
          <div className={styles.quote}>Quote</div>
        </div>
        <div className={styles.left} onClick={onLeftContainer1Click}>
          <img className={styles.iconWithBg} alt="" src="/group-66565.svg" />
          <img
            className={styles.iconsplaceholder}
            alt=""
            src="/group-66563.svg"
          />
          <div className={styles.quote}>Offer</div>
        </div>
        <div className={styles.left2}>
          <img className={styles.iconWithBg} alt="" src="/group-66558.svg" />
          <img
            className={styles.iconsplaceholder}
            alt=""
            src="/group-66558.svg"
          />
          <div className={styles.quote}>Document</div>
        </div>
        <div className={styles.left2}>
          <img className={styles.iconWithBg} alt="" src="/group-66561.svg" />
          <img
            className={styles.iconsplaceholder}
            alt=""
            src="/group-66561.svg"
          />
          <div className={styles.quote}>Picture</div>
        </div>
        <div className={styles.left2}>
          <img className={styles.iconWithBg} alt="" src="/group-66560.svg" />
          <img
            className={styles.iconsplaceholder}
            alt=""
            src="/group-66560.svg"
          />
          <div className={styles.quote}>Camera</div>
        </div>
        <div className={styles.left2}>
          <img className={styles.iconWithBg} alt="" src="/group-66562.svg" />
          <img
            className={styles.iconsplaceholder}
            alt=""
            src="/group-66562.svg"
          />
          <div className={styles.quote}>Location</div>
        </div>
      </div>
    </div>
  );
};

export default Attachment;
