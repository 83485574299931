import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Settings.module.css";
import { auth, db } from "../firebase";
import { AppBar, Avatar, FormControlLabel, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ShareModal from "../components/shareModal.tsx";
import PortalPopup from "../components/PortalPopup";
import navstyles from "./Profile.module.css";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const Settings = () => {
 const [name, setName] = useState()
  const navigate = useNavigate()
  const [verification, setVerification] = useState(0)
  const [license_done, setLicense] =useState(false)
  const [id_done, setID] =useState(false)
  const [edu_done, setEdu] =useState(false)
  const [work_done, setWork] =useState(false)
  const [galleryImages, setGalleryImages] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [modalPop, setModalPopUp] = useState(false);
  const [org, setOrg] = useState('');
  
  useEffect(() => {
    if(auth.currentUser)
    {
        db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
            if(snapshot.data())
            {
              setName(snapshot.data().org_name)
              setOrg(snapshot.data().org_name)
              if (snapshot.data().verification!=null) {
                setVerification(snapshot.data().verification)
              }
              setProfileImage(snapshot.data().profileImage);
            }
          });

          const galleryRef = db.collection('users')
          .doc(auth.currentUser.uid)
          .collection('gallery');
    
        const unsubscribe = galleryRef.onSnapshot((snapshot) => {
          const images = [];
          snapshot.forEach((doc) => {
            images.push(doc.data().imageUrl);
          });
          setGalleryImages(images);
           // Set the first image as imagePreview if available
           if (images.length > 0) {
            setImagePreview(images[0]);
          }
        });
    
        // Unsubscribe when the component unmounts
        return () => {
          unsubscribe();
        };
    }
  
  }, [])

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onSmallButtonsContainer1Click = useCallback(() => {
    navigate('/edit_profile')
  }, []);

  const onLargeButtonsContainerClick = useCallback(() => {
    navigate("/promotion");
  }, [navigate]);

  const onFrameContainer14Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onListContainerClick = useCallback(() => {
    navigate('/mypersonal')
  }, []);

  const onListContainer1Click = useCallback(() => {
    navigate('/security')
  }, []);

  const onListContainer3Click = useCallback(() => {
    navigate('/notificationsetting')
  }, []);

  const onListContainer4Click = useCallback(() => {
    navigate('/payment_payout')
  }, []);

  const onListContainer5Click = useCallback(() => {
   navigate('/plans')
  }, []);

  const onListContainer6Click = useCallback(() => {
    navigate('/help')
  }, []);

  const onButtonContainer1Click = useCallback(() => {
    navigate("/promotion");
  }, [navigate]);

  const onAccountTextClick = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  return (
    <div className={styles.settings}>
      <div className={styles.settings1}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665223.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5} style={{cursor:'pointer'}}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account6.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.component102Parent}>
        <div className={styles.component102}>
           
            <b className={styles.licenseAndCertification}>Profile</b>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.profileSettingsWrapper}>
              <div className={styles.profileSettings}>
             
                <div className={styles.mergeHeaderContent}>
                  <div className={styles.profileSection}>
                  <div className={styles.imgContainer}>
                     <Carousel
          showArrows={true} // Display navigation arrows
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide status indicators
          showThumbs={false} // Hide thumbnail navigation
          autoPlay={true} // Disable auto-play
          className={styles.imgContainer}
        >
          {galleryImages.map((imageUrl, index) => (
            <div key={index} className={styles.imgContainer}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                className={styles.imgContainer}
              />
            </div>
          ))}
        </Carousel>
        
               
        <div
                        className={styles.smallButtons1}
                        onClick={onSmallButtonsContainer1Click}
                      >
                        <div className={styles.iconsaddParent}>
                          <img
                            className={styles.iconsadd}
                            alt=""
                            src="/iconsedit2.svg"
                          />
                          <div className={styles.button}>Edit</div>
                        </div>
                      </div>
                  </div>
                   
                    <div className={styles.profile}>
                      <div className={styles.profileHeading}>
                        <div className={styles.avatarParent}>
                        <Avatar
        className={styles.avatarIcon1}
        alt=""
        src={profileImage}
      />
                          <div className={styles.frameGroup}>
                            <div className={styles.iconsaddParent}>
                              <div className={styles.dysonElectricals}>
                                {name}
                              </div>
                              {verification>=30?<img
                                className={styles.iconsadd}
                                alt=""
                                src="/guarantee-11.svg"
                              />:null}
                              
                            </div>
                            <div className={styles.transactionPills}>
                            <div className={styles.success}>{verification>=30?'verified':'unverified'}</div>
                          </div>
                          </div>
                        </div>
                        <div className={styles.awards}>
                          <div className={styles.rookie} />
                          <div className={styles.rookie} />
                          <div className={styles.rookie} />
                          <div className={styles.rookie} />
                          <div className={styles.rookie} />
                          <div className={styles.rookie} />
                          <div className={styles.ambassador}>
                            <img
                              className={styles.ambassadorChild}
                              alt=""
                              src="/group-669668.svg"
                            />
                            <img
                              className={styles.ambassadorItem}
                              alt=""
                              src="/group-669672.svg"
                            />
                            <img
                              className={styles.ambassadorInner}
                              alt=""
                              src="/group-669506.svg"
                            />
                            <div className={styles.legendary}>Ambassador</div>
                            <img
                              className={styles.vectorIcon}
                              alt=""
                              src="/vector-397.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.buttons}>
                        <div
                          className={styles.largeButtons}
                          onClick={onLargeButtonsContainerClick}
                        >
                          <img
                            className={styles.icons20pxplaceholder}
                            alt=""
                            src="/icons20pxplaceholder3.svg"
                          />
                          <b className={styles.label}>Promote Profile</b>
                          <img
                            className={styles.icons20pxplaceholder}
                            alt=""
                            src="/icons20pxplaceholder3.svg"
                          />
                        </div>
                        <button className={styles.buttonsInner} onClick={()=>{setModalPopUp(true)}}>
                          <div className={styles.shareParent}>
                            <b className={styles.share}>Share</b>
                            <img
                              className={styles.iconshome}
                              alt=""
                              src="/iconsshare1.svg"
                            />
                          </div>
                        </button>
                      </div>
                      <div className={styles.prototypeSettings}>
                        <div className={styles.twoTabSwitch}>
                          <div
                            className={styles.tab1Wrapper}
                            onClick={onFrameContainer14Click}
                          >
                            <div className={styles.logout}>Account</div>
                          </div>
                          <div className={styles.tab2Wrapper}>
                            <div className={styles.tab2}>Settings</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.content1}>
                    <div className={styles.list} onClick={onListContainerClick}>
                      <div className={styles.content2}>
                        <div className={styles.left}>
                          <img
                            className={styles.iconWithBg}
                            alt=""
                            src="/icon-with-bg7.svg"
                          />
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconsuser.svg"
                          />
                          <div className={styles.logout}>Personal</div>
                        </div>
                        <div className={styles.right}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconschevronright3.svg"
                          />
                          <div className={styles.toggle}>
                            <div className={styles.toggleChild} />
                            <div className={styles.toggleItem} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.list}
                      onClick={onListContainer1Click}
                    >
                      <div className={styles.content2}>
                        <div className={styles.left}>
                          <img
                            className={styles.iconWithBg}
                            alt=""
                            src="/icon-with-bg7.svg"
                          />
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconssecurity.svg"
                          />
                          <div className={styles.logout}>Security</div>
                        </div>
                        <div className={styles.right}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconschevronright3.svg"
                          />
                          <div className={styles.toggle}>
                            <div className={styles.toggleChild} />
                            <div className={styles.toggleItem} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.list}
                      onClick={onListContainer3Click}
                    >
                      <div className={styles.content2}>
                        <div className={styles.left}>
                          <img
                            className={styles.iconWithBg}
                            alt=""
                            src="/icon-with-bg7.svg"
                          />
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconsnotification.svg"
                          />
                          <div className={styles.logout}>Notifications</div>
                        </div>
                        <div className={styles.right}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconschevronright3.svg"
                          />
                          <div className={styles.toggle}>
                            <div className={styles.toggleChild} />
                            <div className={styles.toggleItem} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.list}
                      onClick={onListContainer4Click}
                    >
                      <div className={styles.content2}>
                        <div className={styles.left}>
                          <img
                            className={styles.iconWithBg}
                            alt=""
                            src="/icon-with-bg7.svg"
                          />
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconscard.svg"
                          />
                          <div className={styles.logout}>
                            Payments and payouts
                          </div>
                        </div>
                        <div className={styles.right}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconschevronright3.svg"
                          />
                          <div className={styles.toggle}>
                            <div className={styles.toggleChild} />
                            <div className={styles.toggleItem} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.list}
                      onClick={onListContainer5Click}
                    >
                      <div className={styles.content2}>
                        <div className={styles.left}>
                          <img
                            className={styles.iconWithBg}
                            alt=""
                            src="/icon-with-bg7.svg"
                          />
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconspounds.svg"
                          />
                          <div className={styles.logout}>Subscriptions</div>
                        </div>
                        <div className={styles.right}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconschevronright3.svg"
                          />
                          <div className={styles.toggle}>
                            <div className={styles.toggleChild} />
                            <div className={styles.toggleItem} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.list}
                      onClick={onListContainer6Click}
                    >
                      <div className={styles.content2}>
                        <div className={styles.left}>
                          <img
                            className={styles.iconWithBg}
                            alt=""
                            src="/icon-with-bg7.svg"
                          />
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconshelp.svg"
                          />
                          <div className={styles.logout}>Help</div>
                        </div>
                        <div className={styles.right}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconschevronright3.svg"
                          />
                          <div className={styles.toggle}>
                            <div className={styles.toggleChild} />
                            <div className={styles.toggleItem} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.left} style={{cursor:'pointer'}} onClick={()=>{
              auth.signOut();}} >
                    <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconslogout.svg"
                    />
                    <div className={styles.logout}>Logout</div>
                  </div>
                  <div className={styles.version100Parent}>
                    <div className={styles.logout}>Version 1.0.0</div>
                    <div className={styles.privacyPolicyParent}>
                      <div className={styles.privacyPolicy}
                       style={{cursor:'pointer'}}
                       onClick={()=>{window.open('http://www.loggworks.co.uk/privacy', '_blank');}}>Privacy Policy</div>
                      <div className={styles.frameChild} />
                      <div
                        className={styles.termsCondition}
                        style={{cursor:'pointer'}}
                        onClick={()=>{window.open('http://www.loggworks.co.uk/terms', '_blank');}}
                      >{`Terms & Condition`}</div>
                      <div className={styles.frameChild} />
                      <div className={styles.partners}
                      style={{cursor:'pointer'}}
                      onClick={()=>{window.open('http://www.loggworks.co.uk/partners', '_blank');}}>Partners</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.connectSocialAccountsParent}>
                <div className={styles.connectSocialAccounts}>
                  Connect Social accounts
                </div>
                <img
                  className={styles.frameInner}
                  alt=""
                  src="/group-667533.svg"
                />
                <img
                  className={styles.groupIcon}
                  alt=""
                  src="/group-667543.svg"
                />
                <img
                  className={styles.frameChild1}
                  alt=""
                  src="/group-667553.svg"
                />
              </div>
              <div className={styles.referAndEarn}>
                <div className={styles.rectangleParent}>
                  <div className={styles.groupChild} />
                  <div className={styles.groupItem} />
                  <div className={styles.groupInner} />
                  <div className={styles.groupChild1} />
                  <div className={styles.groupChild2} />
                  <div className={styles.groupChild3} />
                  <div className={styles.groupChild4} />
                  <div className={styles.groupChild5} />
                  <div className={styles.groupChild6} />
                  <div className={styles.groupChild7} />
                  <div className={styles.groupChild8} />
                  <div className={styles.groupChild9} />
                  <div className={styles.groupChild10} />
                  <div className={styles.groupChild11} />
                  <div className={styles.groupChild12} />
                  <div className={styles.groupChild13} />
                  <div className={styles.groupChild14} />
                  <div className={styles.groupChild15} />
                  <div className={styles.groupChild16} />
                  <div className={styles.groupChild17} />
                  <div className={styles.groupChild18} />
                  <div className={styles.groupChild19} />
                  <div className={styles.groupChild20} />
                  <div className={styles.groupChild21} />
                  <div className={styles.groupChild22} />
                  <div className={styles.groupChild23} />
                  <div className={styles.groupChild24} />
                  <div className={styles.groupChild25} />
                </div>
                <div className={styles.giftBoxParent}>
                  <img
                    className={styles.giftBoxIcon}
                    alt=""
                    src="/gift-box4.svg"
                  />
                  <div className={styles.referEarnParent}>
                    <div
                      className={styles.dysonElectricals}
                    >{`Refer & earn.`}</div>
                    <div className={styles.referABusiness}>
                      Refer a business to earn points
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
             <div className={ navstyles.bottomNav1}>
            <div className={ navstyles.home8} onClick={()=>{navigate('/home')}}>
              <div className={ navstyles.home1}>Home</div>
              <img
                className={ navstyles.brandLogoIcon}
                alt=""
                src="/brand-logo31.svg"
              />
            </div>
            <div className={ navstyles.jobs2}  onClick={()=>{navigate('/jobs')}}>
              <div className={ navstyles.jobs1}>Jobs</div>
              <img
                className={ navstyles.bibriefcaseFillIcon}
                alt=""
                src="/iconsjobs.svg"
              />
            </div>
            <div className={ navstyles.wallet2}  onClick={()=>{navigate('/wallet')}}>
              <div className={ navstyles.wallet1}>Wallet</div>
              <img
                className={ navstyles.zondiconswallet}
                alt=""
                src="/iconslog-cash.svg"
              />
            </div>
            <div className={ navstyles.account4}>
              <b className={ navstyles.account3}>Account</b>
              <img
                className={ navstyles.mdiaccountIcon}
                alt=""
                src="/account3.svg"
              />
            </div>
          </div>
          </AppBar>
      </div>
      <div className={styles.settingsmob}>
        <div className={styles.component29}>
        <Carousel
          showArrows={true} // Display navigation arrows
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide status indicators
          showThumbs={false} // Hide thumbnail navigation
          autoPlay={true} // Disable auto-play
          className={styles.imgContainer}
        >
          {galleryImages.map((imageUrl, index) => (
            <div key={index} className={styles.imgContainer}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                className={styles.imgContainer}
              />
               
            </div>
          ))}
        </Carousel>
        
               
        <div
                        className={styles.smallButtons1}
                        onClick={onSmallButtonsContainer1Click}
                      >
                        <div className={styles.iconsaddParent}>
                          <img
                            className={styles.iconsadd}
                            alt=""
                            src="/iconsedit2.svg"
                          />
                          <div className={styles.button}>Edit</div>
                        </div>
                      </div>
        </div>
        <div className={styles.title}>
          <div className={styles.frameParent1}>
            <div className={styles.frameParent2}>
              <div className={styles.groupParent}>
                <div className={styles.rectangleWrapper2}>
                  <Avatar
        className={styles.groupChild26}
        alt=""
        src={profileImage}
      />
                </div>
                <div className={styles.referEarnParent}>
                  <div className={styles.iconsaddParent}>
                    <b className={styles.smithsConstruction}>
                      {name}
                    </b>
                    {verification>=30?<img
                      className={styles.guarantee1Icon}
                      alt=""
                      src="/guarantee-1.svg"
                    />:null}
                  </div>
                  <div className={styles.applianceRepairWrapper}>
                    <div className={styles.applianceRepair}>
                    <div className={styles.transactionPills}>
                            <div className={styles.success}>{verification>=30?'verified':'unverified'}</div>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.awards1}>
                <div className={styles.ambassador}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-669669.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.rookieInner}
                    alt=""
                    src="/group-669507.svg"
                  />
                  <div className={styles.legendary}>Rookie</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-398.svg"
                  />
                </div>
                <div className={styles.intermediate1}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-6696610.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.rookieInner}
                    alt=""
                    src="/group-669507.svg"
                  />
                  <div className={styles.legendary}>Intermediate</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-399.svg"
                  />
                </div>
                <div className={styles.skilled1}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-6696611.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.skilledInner}
                    alt=""
                    src="/group-669508.svg"
                  />
                  <div className={styles.legendary}>Skilled</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-3910.svg"
                  />
                </div>
                <div className={styles.rookie}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-6696612.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.semiProInner}
                    alt=""
                    src="/group-669509.svg"
                  />
                  <div className={styles.legendary}>Semi pro</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-3911.svg"
                  />
                </div>
                <div className={styles.rookie}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-6696613.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.professionalInner}
                    alt=""
                    src="/group-6695010.svg"
                  />
                  <div className={styles.legendary}>Professional</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-3912.svg"
                  />
                </div>
                <div className={styles.rookie}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-6696614.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.lengendaryInner}
                    alt=""
                    src="/group-6695011.svg"
                  />
                  <div className={styles.legendary}>Legendary</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-3913.svg"
                  />
                </div>
                <div className={styles.skilled1}>
                  <img
                    className={styles.ambassadorChild}
                    alt=""
                    src="/group-6696615.svg"
                  />
                  <img
                    className={styles.ambassadorItem}
                    alt=""
                    src="/group-669673.svg"
                  />
                  <img
                    className={styles.ambassadorInner}
                    alt=""
                    src="/group-669507.svg"
                  />
                  <div className={styles.legendary}>Ambassador</div>
                  <img
                    className={styles.rookieChild1}
                    alt=""
                    src="/vector-3914.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonParent}>
              <div className={styles.button3} onClick={onButtonContainer1Click}>
                <div className={styles.iconsParent}>
                  <img className={styles.icons} alt="" src="/icons9.svg" />
                  <div className={styles.proceedWrapper}>
                    <b className={styles.proceed}>Promote profile</b>
                  </div>
                </div>
              </div>
              <button className={styles.button4} onClick={()=>{setModalPopUp(true)}}>
                <div className={styles.iconsParent}>
                  <img className={styles.icons1} alt="" src="/icons11.svg" />
                  <div className={styles.proceedWrapper}>
                    <b className={styles.proceed}>Share</b>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.settings2}>
          <div className={styles.settingsChild} />
          <div className={styles.tab}>
            <div className={styles.tabChild} />
            <div className={styles.tabItem} />
            <div className={styles.account} onClick={onAccountTextClick}>
              Account
            </div>
            <div className={styles.settings3}>Settings</div>
          </div>
        </div>
        <div className={styles.account1} onClick={onListContainerClick}>
          <div className={styles.accountChild} />
          <div className={styles.accountItem} />
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/chevrondown.svg"
          />
          <div className={styles.toggle7}>
            <div className={styles.toggleChild11} />
            <div className={styles.toggleChild12} />
          </div>
          <div className={styles.iconsContainer}>
            <img className={styles.iconshome} alt="" src="/icons12.svg" />
            <div className={styles.personal}>Personal</div>
          </div>
        </div>
        <div className={styles.account1} onClick={onListContainer1Click}>
          <div className={styles.accountChild} />
          <div className={styles.accountItem} />
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/chevrondown1.svg"
          />
          <div className={styles.toggle7}>
            <div className={styles.toggleChild11} />
            <div className={styles.toggleChild12} />
          </div>
          <div className={styles.iconsContainer}>
            <img className={styles.iconshome} alt="" src="/icons13.svg" />
            <div className={styles.personal}>Security</div>
          </div>
        </div>
        <div className={styles.account1} onClick={onListContainer3Click}>
          <div className={styles.accountChild} />
          <div className={styles.accountItem} />
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/chevrondown.svg"
          />
          <div className={styles.toggle7}>
            <div className={styles.toggleChild11} />
            <div className={styles.toggleChild12} />
          </div>
          <div className={styles.iconsContainer}>
            <img className={styles.iconshome} alt="" src="/bell.svg" />
            <div className={styles.personal}>Notifications</div>
          </div>
        </div> 
        <div className={styles.account1} onClick={onListContainer4Click}>
          <div className={styles.accountChild} />
          <div className={styles.accountItem} />
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/chevrondown1.svg"
          />
          <div className={styles.toggle7}>
            <div className={styles.toggleChild11} />
            <div className={styles.toggleChild12} />
          </div>
          <div className={styles.iconsContainer}>
            <img className={styles.iconshome} alt="" src="/icons15.svg" />
            <div className={styles.personal}>Payments and payouts</div>
          </div>
        </div>
        <div className={styles.account1} onClick={onListContainer5Click}>
          <div className={styles.accountChild} />
          <div className={styles.accountItem} />
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/chevrondown.svg"
          />
          <div className={styles.toggle7}>
            <div className={styles.toggleChild11} />
            <div className={styles.toggleChild12} />
          </div>
          <div className={styles.iconsContainer}>
            <img className={styles.iconshome} alt="" src="/iconspounds.svg" />
            <div className={styles.personal}>Subscriptions</div>
          </div>
        </div>
       
        <div className={styles.account1} onClick={onListContainer6Click}>
          <div className={styles.accountChild} />
          <div className={styles.accountItem} />
          <img
            className={styles.chevronDownIcon}
            alt=""
            src="/chevrondown2.svg"
          />
          <div className={styles.toggle7}>
            <div className={styles.toggleChild11} />
            <div className={styles.toggleChild12} />
          </div>
          <div className={styles.iconsContainer}>
            <img className={styles.iconshome} alt="" src="/icons16.svg" />
            <div className={styles.personal}>Help</div>
          </div>
        </div>
        <div className={styles.logOutParent}  
        style={{cursor:'pointer'}}
        onClick={()=>{
              auth.signOut();}} >
          <img className={styles.logOutIcon} alt="" src="/logout.svg" />
          <div className={styles.logout1}>Logout</div>
        </div>
        <div className={styles.version1001}>Version 1.0.0</div>
        <div className={styles.privacyPolicyGroup}>
          <div className={styles.personal}  
           style={{cursor:'pointer'}}
          onClick={()=>{window.open('http://www.loggworks.co.uk/privacy', '_blank');}}>Privacy policy</div>
          <div className={styles.frameChild6} />
          <div className={styles.personal}
           style={{cursor:'pointer'}}
           onClick={()=>{window.open('http://www.loggworks.co.uk/terms', '_blank');}}
          >{`Terms & Conditions`}</div>
          <div className={styles.frameChild6} />
          <div className={styles.personal}
           style={{cursor:'pointer'}}
           onClick={()=>{window.open('http://www.loggworks.co.uk/partners', '_blank');}}>Partners</div>
        </div>
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <div className={ navstyles.bottomNav}>
          <div className={ navstyles.home}  onClick={()=>{navigate('/home')}}>
            <div className={ navstyles.home1}>Home</div>
            <img
              className={ navstyles.brandLogoIcon}
              alt=""
              src="/brand-logo31.svg"
            />
          </div>
          <div className={ navstyles.wallet} onClick={()=>{navigate('/wallet')}}>
            <div className={ navstyles.wallet1}>Wallet</div>
            <img
              className={ navstyles.zondiconswallet}
              alt=""
              src="/iconslog-cash.svg"
            />
          </div>
          <div className={ navstyles.account2}>
            <b className={ navstyles.account3}>Account</b>
            <img
              className={ navstyles.mdiaccountIcon}
              alt=""
              src="/account3.svg"
            />
          </div>
          <div className={ navstyles.jobs}  onClick={()=>{navigate('/jobs')}}>
            <div className={ navstyles.jobs1}>Jobs</div>
            <img
              className={ navstyles.bibriefcaseFillIcon}
              alt=""
              src="/iconsjobs.svg"
            />
          </div>
        </div>
        </AppBar>
        <div className={styles.nav1}>
          {/*<div className={styles.bottomNav}>
            <div className={styles.home6}>
              <div className={styles.home7}>Home</div>
              <img
                className={styles.brandLogoIcon}
                alt=""
                src="/brand-logo2.svg"
              />
            </div>
            <div className={styles.wallet}>
              <div className={styles.wallet1}>Wallet</div>
              <img
                className={styles.zondiconswallet}
                alt=""
                src="/zondiconswallet21.svg"
              />
            </div>
            <div className={styles.account7}>
              <b className={styles.account8}>Account</b>
              <img
                className={styles.mdiaccountIcon}
                alt=""
                src="/mdiaccount2.svg"
              />
            </div>
            <div className={styles.jobs}>
              <div className={styles.jobs1}>Jobs</div>
              <img
                className={styles.bibriefcaseFillIcon}
                alt=""
                src="/bibriefcasefill.svg"
              />
            </div>
        </div>*/}
         <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
             <div className={ navstyles.bottomNav1}>
            <div className={ navstyles.home8} onClick={()=>{navigate('/home')}}>
              <div className={ navstyles.home1}>Home</div>
              <img
                className={ navstyles.brandLogoIcon}
                alt=""
                src="/brand-logo31.svg"
              />
            </div>
            <div className={ navstyles.jobs2}  onClick={()=>{navigate('/jobs')}}>
              <div className={ navstyles.jobs1}>Jobs</div>
              <img
                className={ navstyles.bibriefcaseFillIcon}
                alt=""
                src="/iconsjobs.svg"
              />
            </div>
            <div className={ navstyles.wallet2}  onClick={()=>{navigate('/wallet')}}>
              <div className={ navstyles.wallet1}>Wallet</div>
              <img
                className={ navstyles.zondiconswallet}
                alt=""
                src="/iconslog-cash.svg"
              />
            </div>
            <div className={ navstyles.account4}>
              <b className={ navstyles.account3}>Account</b>
              <img
                className={ navstyles.mdiaccountIcon}
                alt=""
                src="/account3.svg"
              />
            </div>
          </div>
          </AppBar>
        </div>
        {modalPop && (
           <PortalPopup
           overlayColor="rgba(113, 113, 113, 0.3)"
           placement="Centered"
           onOutsideClick={()=>setModalPopUp(false)}
         >
           <ShareModal
                    setModalPopUp={setModalPopUp}
                    modalPop={modalPop}
                    uid={org}
                  />
         </PortalPopup>
                 
                )}
      </div>
    </div>
  );
};

export default Settings;
