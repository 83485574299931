import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Security.module.css";
import {  IconButton,
  InputAdornment,
  TextField,
  Snackbar,
  Alert,} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { auth } from "../firebase";
const Security = () => {
  const navigate = useNavigate();
  const [password,setPassword] = useState('')
  const [password2,setPassword2] = useState('')
  const [ msg, setMsg] = useState('')
  const [password3,setPassword3] = useState('')
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  const handleClickShowPassword = () => {
    setShow(!show);
  };
  const handleClickShowPassword2 = () => {
    setShow2(!show2);
  };
  const handleClickShowPassword3 = () => {
    setShow3(!show3);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  
  const handleChangePassword = async () => {
    // Check if new password and confirm new password match
    if (password2 !== password3) {
      setSnackbarSeverity("error");
      setSnackbarMessage("New passwords do not match.");
      setSnackbarOpen(true);
      return;
    }

    try {
      const user = auth.currentUser;

      // Reauthenticate the user to change the password
      const credential = auth.EmailAuthProvider.credential(
        user.email,
        password
      );
      await user.reauthenticateWithCredential(credential);

      // Change the password
      await user.updatePassword(password2);

      // If successful, display success message
      setSnackbarSeverity("success");
      setSnackbarMessage("Password changed successfully.");
      setSnackbarOpen(true);

      // Clear the password fields
      setPassword("");
      setPassword2("");
      setPassword3("");
    } catch (error) {
      // If there's an error, display an error message
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to change password. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  return (
    <div className={styles.security}>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-66522.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconshome5.svg"
                />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home}>Wallet</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home}>Message</div>
              </div>
            </div>
            <div className={styles.default5}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account6.svg"
                />
                <div className={styles.home5}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.titlesParent}>
        <div className={styles.titles}>
          <div className={styles.back} style={{cursor:'pointer'}}
          onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.activities}>Security</div>
        </div>
        <div className={styles.component102}>
          <b className={styles.licenseAndCertification}>Security</b>
          <div className={styles.icon} style={{cursor:'pointer'}}
          onClick={()=>navigate(-1)}>
            <img
              className={styles.iconshome}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.inputParent}>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Old Password</div>
              </div>
              <div style={{width:'100%'}}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        type={show ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                  >
                                      {show ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                          )
                      }}
                    /> 
                    </div>
            </div>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>New Password</div>
              </div>
              <div style={{width:'100%'}}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        type={show2 ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password2}
                        onChange={e => setPassword2(e.target.value)}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword2}
                                      onMouseDown={handleMouseDownPassword}
                                  >
                                      {show ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                          )
                      }}
                    /> 
                    </div>
            </div>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Confirm New Password</div>
              </div>
              <div style={{width:'100%'}}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        type={show3 ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password3}
                        onChange={e => setPassword3(e.target.value)}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword3}
                                      onMouseDown={handleMouseDownPassword}
                                  >
                                      {show ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                          )
                      }}
                    /> 
                    </div>
            </div>
          </div>
          <div className={styles.largeButtons} style={{cursor:'pointer'}} onClick={handleChangePassword}>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder3.svg"
            />
            <b className={styles.label3}>Change Password</b>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder3.svg"
            />
          </div>
        </div>
        <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </div>
    </div>
  );
};

export default Security;
