import { useCallback, useState } from "react";
import { TextField } from "@mui/material";
import styles from "./QAModal.module.css";
import { auth, db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
const QAModal = ({ onClose,selectedQ,selectkey }) => {
  const [quest,setQuest]=useState('')
  const [ans,setAns]=useState(selectkey)

  const onLargeButtonsContainerClick =() => {
    if(selectedQ==''){
    if (quest=='') {
      return
    }
    const keyid = db.collection("tmp").doc().id
    db.collection('myqa/'+auth.currentUser.uid+'/list').doc(keyid).set({
      uid:auth.currentUser.uid,
      unix: Date.now(),
      timestamp: serverTimestamp(),
       key: keyid,
       quest: quest,
       ans:ans
    })
  }
  if(selectedQ!=''){
    if (ans=='') {
      return
    }
    db.collection('myqa/'+auth.currentUser.uid+'/list').doc(selectkey).update({
      unix: Date.now(),
      timestamp: serverTimestamp(),
      ans:ans
    })
    console.log('hee')
  }
    setQuest('')
    setAns('')
    onClose()
  };

  return (
    <div className={styles.qamodal}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>{`Q & A`}</div>
            <img
              className={styles.iconsclose}
              alt=""
              src="/iconsclose1.svg"
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.modalTitleParent}>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Type your question</div>
              </div>
              <TextField
                className={styles.baseField}
                color="primary"
                variant="outlined"
                multiline
                margin="none"
                disabled={selectedQ!=''?true:false}
                fullWidth
                minRows={3}
                value={selectedQ!=''?selectedQ:quest}
                onChange={(e)=>{setQuest(e.target.value)}}
              />
            </div>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Type your answer</div>
              </div>
              <TextField
                className={styles.baseField}
                color="primary"
                variant="outlined"
                multiline
                margin="none"
                minRows={3}
                fullWidth
                value={ans}
                onChange={(e)=>{setAns(e.target.value)}}
              />
            </div>
          </div>
          <div
            className={styles.largeButtons}
            onClick={onLargeButtonsContainerClick}
          >
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder.svg"
            />
            {
              selectedQ==''?
              <b className={styles.label2}>Add Q&A</b>
              :
              <b className={styles.label2}>Reply Question</b>
            }
            
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QAModal;
