import styles from "./RefDescription.module.css";

const RefDescription = ({title,desc}) => {
  return (
    <div className={styles.refdescription}>
      <div className={styles.requestForElectricianParent}>
        <div className={styles.requestForElectrician}>
          Request for {title}
        </div>
        <div className={styles.imInNeed}>
          {desc}
        </div>
      </div>
    </div>
  );
};

export default RefDescription;
