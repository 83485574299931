import { useState, useCallback, useEffect, useRef } from "react";
import { Avatar, IconButton, TextField } from "@mui/material";
import { Alert, Chip, Snackbar } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AddSkill from "../components/AddSkill";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./EditProfile.module.css";
import { auth, db, storage } from "../firebase";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CloudUpload } from "@mui/icons-material";
import moment from "moment";
import Qastyles from "./Qa.module.css";
import QAModal from "../components/QAModal";
import CloseIcon from '@mui/icons-material/Close';
import firebase from 'firebase/compat/app'

const EditProfile = () => {
  const [isAddSkillPopupOpen, setAddSkillPopupOpen] = useState(false);
  const [isAddSkillPopup1Open, setAddSkillPopup1Open] = useState(false);
  const [isLocationModalPopupOpen, setLocationModalPopupOpen] = useState(false);
  const [name, setName] = useState()
  const navigate = useNavigate()
  const [verification, setVerification] = useState(0)
  const [serviceDescription, setServiceDescription] = useState('');
  const [license_done, setLicense] =useState(false)
  const [id_done, setID] =useState(false)
  const [edu_done, setEdu] =useState(false)
  const [work_done, setWork] =useState(false)
  const [galleryImages, setGalleryImages] = useState([]);
  const [skill, setSkill] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const fileInputRef = useRef(null);
  const [qahistory,setQahistory] = useState([])
  const [newqa,setNewQa] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);
  const [sugg, setSugg] = useState([])
  const [type, setType]=useState('')

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    getServiceTags(e.target.value)
  };
  const handleAddChip = () => {
    if (inputValue.trim() !== '') {
      setChips([...chips, inputValue.trim()]);
      db.collection('users')
      .doc(auth.currentUser.uid)
      .update({
        service_tags: chips
      })
      setInputValue('');
    }
  };
  
  async function getServiceTags(categ) {
   
    try {
      const usersRef = db.collection('serviceTags')
  
      // Query Firestore for users
      const querySnapshot = await usersRef.get();
        const subs = [];
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        data.id = doc.id;
        if(doc.id==type){
          data.subcategories.map((subs) => subs)
          .filter((x) => {
           if (x.toLowerCase().includes(categ.toLowerCase())||x.toLowerCase()==categ.toLowerCase()) {
            subs.push(x);
           }else{
            setSugg([]);
           }
          });
        }
      });
        setSugg(subs);
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  }

  useEffect(() => {
    if(auth.currentUser)
    {
      const proofID = db.collection("users").doc(auth.currentUser.uid).collection("proofID");
      const licenseColl = db.collection("users").doc(auth.currentUser.uid).collection("license");
      const workColl = db.collection("users").doc(auth.currentUser.uid).collection("work");
      const eduColl = db.collection("users").doc(auth.currentUser.uid).collection("education");
      proofID.get().then(function(snap){
        if (snap.size>0) {
          setID(true)
        }
      })
      licenseColl.get().then(function(snap){
        if (snap.size>0) {
          setLicense(true)
        }
      })
      workColl.get().then(function(snap){
        if (snap.size>0) {
          setWork(true)
        }
      })
      eduColl.get().then(function(snap){
        if (snap.size>0) {
          setEdu(true)
        }
      })
        db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
            if(snapshot.data())
            {
              setName(snapshot.data().org_name)
              setChips(snapshot.data().service_tags||[])
              setType(snapshot.data().category)
              if (snapshot.data().verification!=null) {
                setVerification(snapshot.data().verification)
              }
              setServiceDescription(snapshot.data().serviceDescription);
              setProfileImage(snapshot.data().profileImage);
            }
          });
          const galleryRef = db.collection('users')
      .doc(auth.currentUser.uid)
      .collection('gallery');

    const unsubscribe = galleryRef.onSnapshot((snapshot) => {
      const images = [];
      snapshot.forEach((doc) => {
        images.push(doc.data().imageUrl);
      });
      setGalleryImages(images);
       // Set the first image as imagePreview if available
       if (images.length > 0) {
        setImagePreview(images[0]);
      }
    });
    const skillsCollection = db.collection(`users/${auth.currentUser.uid}/skills`);

    // Listen for changes to the skills collection
    const unsubscribeskill = skillsCollection.onSnapshot((snapshot) => {
      const skills = [];
      snapshot.forEach((doc) => {
        skills.push({ id: doc.id, ...doc.data() });
      });
      setSkill(skills)
    });
    const unsubscribe1 = db.collection('myqa/' + auth.currentUser.uid + '/list')
    .where('ans', '==', '')
    .onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setNewQa(data);
    });

  const unsubscribe2 = db.collection('myqa/' + auth.currentUser.uid + '/list')
    .onSnapshot((snapshot) => {
      const data2 = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setQahistory(data2);
    });
    // Unsubscribe when the component unmounts
    return () => {
      unsubscribe();
      unsubscribeskill()
      unsubscribe1();
      unsubscribe2();
    }; 
    }
  
  }, [])
  const removeImage = (imageUrl) => {
    // Remove the image from Firestore
    db.collection('users')
      .doc(auth.currentUser.uid)
      .collection('gallery')
      .where('imageUrl', '==', imageUrl)
      .get()
      .then(function(snapshot) {
        snapshot.forEach((doc) => {
          doc.ref.delete();
        });
        console.log('Image removed from Firestore.');
      })
      .catch((error) => {
        console.error('Error removing image:', error);
      });
  };

  const handleProfileImageUpload = (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      // Create a reference to the profile image in Firebase Storage
      const storageRef = storage.ref(`profileImages/${auth.currentUser.uid}`);
      // Upload the image to Firebase Storage
      storageRef.put(imageFile).then((snapshot) => {
        // Get the download URL of the uploaded image
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          // Update the profile image URL in Firestore
          db.collection("users")
            .doc(auth.currentUser.uid)
            .update({ profileImage: downloadURL })
            .then(() => {
              // Update the profile image in the state
              setProfileImage(downloadURL);
            });
        });
      });
    }
  };

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onSmallButtonsContainer1Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const openAddSkillPopup = useCallback(() => {
    setAddSkillPopupOpen(true);
  }, []);

  const closeAddSkillPopup = useCallback(() => {
    setAddSkillPopupOpen(false);
  }, []);

  const openAddSkillPopup1 = useCallback(() => {
    setAddSkillPopup1Open(true);
  }, []);

  const closeAddSkillPopup1 = useCallback(() => {
    setAddSkillPopup1Open(false);
  }, []);
  const saveServiceDescription = () => {
    if (auth.currentUser) {
      db.collection('users')
        .doc(auth.currentUser.uid)
        .update({ serviceDescription })
        .then(() => {
          console.log('Service description saved to Firestore.');
        })
        .catch((error) => {
          console.error('Error saving service description:', error);
        });
    }
  };
  function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const handleImageUpload = (event) => {
    if (galleryImages.length >= 5) {
      // Display a message to the user indicating that they've reached the limit
      alert('You can only upload up to 5 images.');
      return;
    }

    const file = event.target.files[0];
    if (file) {
      const storageRef = storage.ref(`userImages/${auth.currentUser.uid}/${generateRandomString(10)}`);
      const uploadTask = storageRef.put(file);

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Handle progress (if needed)
        },
        (error) => {
          console.error('Error uploading image:', error);
        },
        () => {
          // Handle successful upload
          storageRef.getDownloadURL()
            .then((downloadURL) => {
              // Save the image URL in Firestore
              db.collection('users')
                .doc(auth.currentUser.uid)
                .collection('gallery')
                .add({
                  imageUrl: downloadURL,
                })
                .then(() => {
                  console.log('Image uploaded and URL saved to Firestore.');
                })
                .catch((error) => {
                  console.error('Error saving image URL:', error);
                });
            });
        }
      );
    }
  };
  const deleteSkill = async (skillId) => {

    const skillsCollection = db.collection(`users/${auth.currentUser.uid}/skills`);

    try {
      await skillsCollection.doc(skillId).delete();
    } catch (error) {
      console.error("Error deleting skill from Firestore:", error);
    }
  };
  return (
    <>
      <div className={styles.editprofile}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-66522.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account5.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back} onClick={()=>navigate(-1)}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.notifications}>Profile</div>
          </div>
          <div className={styles.component102}>
            <div className={styles.smallButtons}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
                <div className={styles.button}>Add Item</div>
              </div>
            </div>
            <div className={styles.icon} style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar2.svg" />
            <b className={styles.licenseAndCertification}>Profile</b>
          </div>
          <div className={styles.editProfileWrapper}>
            <div className={styles.editProfile}>
              <div className={styles.profileSection}>
              <div className={styles.imgContainer}>
                   <Carousel
          showArrows={true} // Display navigation arrows
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide status indicators
          showThumbs={false} // Hide thumbnail navigation
          autoPlay={true} // Disable auto-play
          className={styles.imgContainer}
        >
          {galleryImages.map((imageUrl, index) => (
            <div key={index} className={styles.imgContainer}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                className={styles.imgContainer}
              />
               
                  <div
                    className={styles.smallButtons1}
                    onClick={onSmallButtonsContainer1Click}
                  >
                    <div className={styles.iconsaddParent}>
                      <img
                        className={styles.iconsadd}
                        alt=""
                        src="/iconsedit.svg"
                      />
                      <div className={styles.button}>Edit</div>
                    </div>
                  </div>
            </div>
          ))}
        </Carousel>
                  </div>
             
                <div className={styles.profile}>
                  <div className={styles.profileHeading}>
                    <div className={styles.avatarParent}>
                    <Avatar
        className={styles.avatarIcon1}
        alt=""
        src={profileImage}
        onClick={() => {
          // Trigger the file input click when the Avatar is clicked
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        }}
      />
      {/* Add an input element for profile image upload */}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleProfileImageUpload}
        ref={fileInputRef}
      />
      <label htmlFor="profile-image-upload">
       
      </label>
                      <div className={styles.frameParent}>
                        <div className={styles.iconsaddParent}>
                          <div className={styles.dysonElectricals}>
                           {name}
                          </div>
                          {verification>=30?<img
                            className={styles.iconsadd}
                            alt=""
                            src="/guarantee-11.svg"
                          />:null}
                          
                        </div>
                        <div className={styles.transactionPills}>
                          <div className={styles.success}>{verification>=30?'verified':'unverified'}</div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className={styles.merge}>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label1}>Service Description</div>
                  </div>
                  <TextField
                    className={styles.baseField}
                    color="primary"
                    variant="outlined"
                    multiline
                    rows={2}
                    margin="none"
                    fullWidth
                    value={serviceDescription}
                    onChange={(e) => setServiceDescription(e.target.value)}
                    onBlur={saveServiceDescription}
                  />
                </div>
                <div className={styles.gallery}>
                  <div className={styles.title}>
                    <div className={styles.skillsAndPrice}>Gallery</div>
                  </div>
                  <div className={styles.content1}>
                    <div className={styles.uploadImageplaceholder}>
                    <img
              className={styles.imageFrameIcon}
              alt=""
              src={galleryImages.length === 0 ? "/image-frame2.svg" : imagePreview}
            />
                      <div className={styles.addcancelCircle} />
                    </div>
                    <div className={styles.avatar}>
                    {galleryImages.map((imageUrl, index) => (
              <div key={index} className={styles.uploadImage}>
                <div className={styles.imageFrame}>
                  <div className={styles.boundary} />
                  <div
                    className={styles.imageArea}
                    style={{ backgroundImage: `url(${imageUrl})`,backgroundPosition:'center'
                    ,backgroundSize:'cover',backgroundRepeat:'no-repeat' }}
                  />
                  <img
                    className={styles.addcancelCircleIcon}
                    alt=""
                    src="./cancel-circle.svg"
                    onClick={() => removeImage(imageUrl)}
                  />
                </div>
              </div>
            ))}

                      <input
                    type="file"
                    accept="image/*"
                   style={{display:'none'}}
                    alt=""
                    src="/iconsadd2.svg"
                    id="profile-pic-input"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                  <label htmlFor="profile-pic-input" className={styles.uploadImage}>
                     <div className={styles.uploadImage} >
                        <div className={styles.imageFrame}>
                          <div className={styles.boundary} />
                          <div className={styles.imageArea} />
                          <img
                            className={styles.iconsadd1}
                            alt=""
                            src="/iconsadd2.svg"
                          />
                        </div>
                       
                      </div>
                  </label>
                     
                    </div>
                  </div>
                </div>
                <div className={styles.verificationStatus}>
                  <div className={styles.title1}>
                    <div className={styles.skillsAndPrice}>
                      Verification Status
                    </div>
                  </div>
                  <div className={styles.verification}>
                  {
                              license_done||work_done||id_done||edu_done?
                              <div style={{display:'flex', gap:'1rem', flexWrap:'wrap'}}>
                                {
                                  license_done?
                                  <img
                              src="./licenseVer.svg"
                              />
                              :null
                                }
                                {
                                  work_done?
                                  <img
                              src="./workVer.svg"
                              />
                              :null
                                }
                                {
                                  id_done?
                                  <img
                              src="./idVer.svg"
                              />
                              :null
                                }
                                {
                                  edu_done?
                                  <img
                              src="./educationVer.svg"
                              />
                              :null
                                }
                              
                              </div>
                              :
                              <div className={styles.nothingHereParent}>
                              <img
                                className={styles.nothingHereIcon}
                                alt=""
                                src="./nothing-here.svg"
                              />
                              <div className={styles.standOutAsAProfessionalGParent}>
                                <div className={styles.standOutAs}>
                                  Stand out as a professional. Get verified
                                </div>
                                <div className={styles.smallButtons2} onClick={()=>{navigate('/profile')}}>
                                  <div className={styles.buttonWrapper}>
                                    <div className={styles.button}>Verify Me</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            }
                   
                  </div>
                </div>
                <div className={styles.verificationStatus}>
                  <div className={styles.title2}>
                    <div className={styles.skillsAndPrice}>
                      Skills and Price Tags
                    </div>
                    <div
                      className={styles.smallButtons3}
                      onClick={openAddSkillPopup}
                    >
                      <div className={styles.iconsaddParent}>
                        <img
                          className={styles.iconsadd}
                          alt=""
                          src="/iconsedit.svg"
                        />
                        <div className={styles.button}>Add</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.verification1}>
                    {
                      skill.length>0?
                       <div className={styles.skillsPriceTagParent}>
                        {skill.map((skillData) => (
         <div className={styles.skillsPriceTag1}>
         <div className={styles.label}>{skillData.skill}</div>
         <div className={styles.skillsPriceTagChild} />
         <div className={styles.skillsPriceTagItem} />
         <div className={styles.label}>£{skillData.price}/hr</div>
         <img className={styles.xIcon} alt="" src="/x.svg" />
         <img
           className={styles.iconsclose}
           alt=""
           src="/iconsclose.svg"
           onClick={() => deleteSkill(skillData.id)}
         />
       </div>
      ))}
                     
                    </div>
                    :
                    <div className={styles.standOutAsAProfessionalGParent}>
                      <div className={styles.createAService}>
                        Create a service catalogue. Add your services and their
                        price per hour
                      </div>
                      <div
                        className={styles.smallButtons4}
                        onClick={openAddSkillPopup1}
                      >
                        <div className={styles.buttonWrapper}>
                          <div
                            className={styles.button}
                          >{`Add Skill & Price Tag`}</div>
                        </div>
                      </div>
                    </div>
                    }
                    
                   
                  </div>
                </div>
                <div className={styles.verificationStatus}>
                <div className={styles.title}>
                    <div
                      className={styles.skillsAndPrice}
                    >{`Question & Answer`}</div>
                  </div>
                  {
                    qahistory.length>0?
                    <>
                     {qahistory.map((data)=>{
          return(
            <>
            <div className={Qastyles.input}>
            <div className={Qastyles.frameParent}>
              <div className={Qastyles.frameGroup}>
                {data.name?
                <div className={Qastyles.avatarParent}>
                <img
                  className={Qastyles.avatarIcon}
                  alt=""
                  src={data.profile}
                />
                <div className={Qastyles.peoplename}>{data.name}</div>
              </div>
              :null
              }
                
                <div className={Qastyles.time}>{moment(data.unix).fromNow()}</div>
              </div>
              <div className={Qastyles.howDoYou}>
               {data.quest}
              </div>
            </div>
          </div>
          <div className={Qastyles.answer}>
              <div className={Qastyles.input}>
                <div className={Qastyles.answer1}>
                  {data.ans}
                </div>
              </div>
            </div>
            </>
            
          );})}
          <br></br>
          <div className={styles.standOutAsAProfessionalGParent}>
             <div className={styles.smallButtons5} style={{cursor:'pointer'}} onClick={()=>{setLocationModalPopupOpen(true)}}>
                          <div className={styles.buttonWrapper}>
                            <div
                              className={styles.button}
                            >{`Add Question & Answer`}</div>
                          </div>
                        </div>
          </div>
          
                    </>
                    :
                     <>
                 
                  <div className={styles.verification2}>
                    <div className={styles.chatLineParent}>
                      <img
                        className={styles.chatLineIcon}
                        alt=""
                        src="./chat-line.svg"
                      />
                      <div className={styles.standOutAsAProfessionalGParent}>
                        <div className={styles.buildACustom}>
                          Build a custom FAQ section for your business. Answer
                          questions from service consumers or create questions
                          and answer them
                        </div>
                        <div className={styles.smallButtons5} style={{cursor:'pointer'}} onClick={()=>{setLocationModalPopupOpen(true)}}>
                          <div className={styles.buttonWrapper}>
                            <div
                              className={styles.button}
                            >{`Add Question & Answer`}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                  }
                </div>
              <div style={{width:'100%'}}>
                <div className={styles.skillsAndPrice}>Service Tag</div>
                 <div style={{width:'100%'}}>
                 <div style={{ marginTop: '10px' }}>
        {chips?.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={() => {
              const updatedChips = [...chips];
              updatedChips.splice(index, 1);
              setChips(updatedChips);
              db.collection('users')
              .doc(auth.currentUser.uid).update({
                service_tags: firebase.firestore.FieldValue.delete()
              })
              db.collection('users')
              .doc(auth.currentUser.uid)
              .update({
                service_tags: updatedChips
              })
            }}
            style={{ margin: '4px' }}
            deleteIcon={<CloseIcon style={{color:'red'}}/>}
            variant="outlined"
          />
        ))}
      </div>
                 <div className="relative">
      <TextField
       fullWidth
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      />
      <button className="absolute inset-y-0 right-0 px-4 bg-blue-500 text-white rounded-md"
      style={{background:'white', color:'green', 
      borderColor:'green',borderStyle:'solid',borderWidth:'1.5px',margin:'10px 0px'}}
      onClick={handleAddChip}>
      +  Add Service Tag
      </button>
    </div> 
    <br/>
   { sugg.length>0?
   <>
    <b>Suggestions</b>
    <div style={{ marginTop: '10px' , maxWidth:'300px'}}>
        {sugg.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={() => {
              setChips([...chips, chip.trim()]);
              setSugg([])
              db.collection('users')
      .doc(auth.currentUser.uid)
      .update({
        service_tags: [chip.trim()]
      })
            }}
            onClick={()=>{
              setChips([...chips, chip.trim()]);
              setSugg([])
              db.collection('users')
              .doc(auth.currentUser.uid)
              .update({
                service_tags: [chip.trim()]
              })
            }}
            style={{ margin: '4px' }}
            deleteIcon={<AddIcon />}
            variant="outlined"
          />
        ))}
      </div>
    </>
    :null
    }
    
            </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAddSkillPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAddSkillPopup}
        >
          <AddSkill onClose={closeAddSkillPopup} />
        </PortalPopup>
      )}
      {isAddSkillPopup1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAddSkillPopup1}
        >
          <AddSkill onClose={closeAddSkillPopup1} />
        </PortalPopup>
      )}
       {isLocationModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setLocationModalPopupOpen(false)}
        >
          <QAModal onClose={()=>setLocationModalPopupOpen(false)} selectedQ={''} selectkey={''}/>
        </PortalPopup>
      )}
    </>
  );
};

export default EditProfile;
