import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Activity.module.css";
import Qa from "./Qa";
import { auth, db } from "../firebase";
import moment from "moment";
import { Badge, styled } from "@mui/material";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const Activity = () => {
  const navigate = useNavigate();
  const [toptab, setTopTab] = useState(0)
  const[bottomtab, setBottomTab] =useState(0)
  const [allData, setAllData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [promoData, setPromoData] = useState([]);
  const [quoteData, setQuoteData] = useState([]);
  const [msg, setMsg]= useState([])
  const [activ, setActivity]= useState([])

  useEffect(() => {
    const fetchData = async () => {
      const activityRef = db.collection('activity/prof/' + auth.currentUser.uid).orderBy('stamp','desc');
      
      // Fetch data for 'booking' type
      const bookingSnapshot = await activityRef.get();
      const bookingDocs = bookingSnapshot.docs
        .filter((doc) => doc.data().type === 'booking')
        .map((doc) => doc.data());
      setBookingData(bookingDocs);
    
      // Fetch data for 'payment' type
      const paymentDocs = bookingSnapshot.docs
        .filter((doc) => doc.data().type === 'payment')
        .map((doc) => doc.data());
      setPaymentData(paymentDocs);
    
      // Fetch data for 'promo' type
      const promoDocs = bookingSnapshot.docs
        .filter((doc) => doc.data().type === 'promo')
        .map((doc) => doc.data());
      setPromoData(promoDocs);
    
      // Fetch data for 'quote' type
      const quoteDocs = bookingSnapshot.docs
        .filter((doc) => doc.data().type === 'quote')
        .map((doc) => doc.data());
      setQuoteData(quoteDocs);
    
      // Fetch all data
      const allDocs = bookingSnapshot.docs.map((doc) => doc.data());
      setAllData(allDocs);
    };
    
    fetchData();
    const collectionRef = db.collection('users').doc(auth.currentUser.uid).collection('noti_m');
    const collectionRef2 = db.collection('users').doc(auth.currentUser.uid).collection('noti_act');
    const updatedList = [];
    const actList = [];
    const unsubscribe1 = collectionRef.onSnapshot((snapshot) => {
      // Handle the snapshot changes
      snapshot.forEach((doc) => {
        const docData = doc.data();
        updatedList.push(docData);
      });
      setMsg(updatedList)
      });
      const unsubscribe2 = collectionRef2.onSnapshot((snapshot) => {
      // Handle the snapshot changes
      snapshot.forEach((doc) => {
        const docData = doc.data();
        actList.push(docData);
      });
      setActivity(actList)
      });
      
      return () => {
      unsubscribe1();
      unsubscribe2();
      };
  }, []);

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);
  const onDefaultContainer5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onFrameContainer7Click = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);
//position map to center  at all time
  const onFrameContainer8Click = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onAllContainerClick = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onPaymentContainerClick = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onPromotionContainerClick = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onMessageContainerClick = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  return (
    <>
    {
      toptab==0?
      <div className={styles.activity}>
      <div className={styles.notifications}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default2}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                <StyledBadge badgeContent={activ.length} color="primary" >
                   <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity1.svg"
                  />
                </StyledBadge>
                 
                  <div className={styles.home2}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                <StyledBadge badgeContent={msg.length} color="primary" >
                   <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                </StyledBadge>
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}
              onClick={onDefaultContainer5Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back} onClick={()=>{navigate(-1)}}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.activities}>Activities</div>
          </div>
          <div className={styles.component102}>
              <b className={styles.licenseAndCertification}>Activities</b>
            </div>
          <div className={styles.component102Parent}>
          
            <div className={styles.activitiesTabParent}>
              <div className={styles.activitiesTab}>
                <div className={styles.twoTabSwitch}>
                  <div
                    className={styles.tab1Wrapper}
                    onClick={onFrameContainer7Click}
                  >
                    <div className={styles.tab1}>Notifications</div>
                  </div>
                  <div
                    className={styles.tab2Wrapper}
                    onClick={()=>{setTopTab(1)}}
                  >
                    <div className={styles.tab2}>Questions</div>
                  </div>
                </div>
              </div>
              <div className={styles.prototypeSwitch}>
                <div className={styles.tabsSubSwitch}>
                  <div className={styles.allParent}>
                    <div className={bottomtab==0?styles.booking1:styles.all} 
                    onClick={()=>{setBottomTab(0)}}>
                      <div className={bottomtab==0?styles.booking2:styles.booking}>All</div>
                    </div>
                    <div className={bottomtab==1?styles.booking1:styles.all} 
                    onClick={()=>{setBottomTab(1)}}>
                      <div className={bottomtab==1?styles.booking2:styles.booking}>Jobs</div>
                    </div>
                    <div
                      className={bottomtab==2?styles.booking1:styles.all} 
                      onClick={()=>{setBottomTab(2)}}
                    >
                      <div className={bottomtab==2?styles.booking2:styles.booking}>Payment</div>
                    </div>
                    <div
                      className={bottomtab==3?styles.booking1:styles.all} 
                      onClick={()=>{setBottomTab(3)}}
                    >
                      <div className={bottomtab==3?styles.booking2:styles.booking}>Promotion</div>
                    </div>
                    <div
                      className={bottomtab==4?styles.booking1:styles.all} 
                      onClick={()=>{setBottomTab(4)}}
                    >
                      <div className={bottomtab==4?styles.booking2:styles.booking}>Quotes</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.notifcationsParent}>
            {
              bottomtab==0?
              allData.map((data)=>{
                return(
                  <div className={styles.notifcations}>
              <div className={styles.container}>
                <div className={styles.content}>
                  <div className={styles.top}>
                    <div className={styles.bookingParent}>
                      <div className={styles.booking6}>
                        {data.type=='booking'?`Booking: `:
                        data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</div>
                    
                    </div>
                    <div className={styles.minsAgo}>{moment(data.unix).fromNow}</div>
                  </div>
                  <div className={styles.youHaveAnContainer}>
                    <span>{data.msg}</span>
                    <span className={styles.smithsConstructions}>
                      {data.name}
                    </span>
                    <span> on {data.date}</span>
                  </div>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder7.svg"
                    />
                    <b className={styles.label}>Mark Job as Completed</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder7.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
                );
              })
              
            :bottomtab==1?
            bookingData.map((data)=>{
              return(
                <div className={styles.notifcations}>
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.bookingParent}>
                    <div className={styles.booking6}>
                      {data.type=='booking'?`Booking: `:
                      data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</div>
                  
                  </div>
                  <div className={styles.minsAgo}>{moment(data.unix).fromNow}</div>
                </div>
                <div className={styles.youHaveAnContainer}>
                  <span>{data.msg}</span>
                  <span className={styles.smithsConstructions}>
                    {data.name}
                  </span>
                  <span> on {data.date}</span>
                </div>
                <div className={styles.largeButtons}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder7.svg"
                  />
                  <b className={styles.label}>Mark Job as Completed</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder7.svg"
                  />
                </div>
              </div>
            </div>
          </div>
              );
            })
            :bottomtab==2?
            paymentData.map((data)=>{
              return(
                <div className={styles.notifcations}>
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.bookingParent}>
                    <div className={styles.booking6}>
                      {data.type=='booking'?`Booking: `:
                      data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</div>
                  
                  </div>
                  <div className={styles.minsAgo}>{moment(data.unix).fromNow}</div>
                </div>
                <div className={styles.youHaveAnContainer}>
                  <span>{data.msg}</span>
                  <span className={styles.smithsConstructions}>
                    {data.name}
                  </span>
                  <span> on {data.date}</span>
                </div>
                <div className={styles.largeButtons}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder7.svg"
                  />
                  <b className={styles.label}>Mark Job as Completed</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder7.svg"
                  />
                </div>
              </div>
            </div>
          </div>
              );
            })
            :bottomtab==3?
            promoData.map((data)=>{
              return(
                <div className={styles.notifcations}>
            <div className={styles.container}>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.bookingParent}>
                    <div className={styles.booking6}>
                      {data.type=='booking'?`Booking: `:
                      data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</div>
                  
                  </div>
                  <div className={styles.minsAgo}>{moment(data.unix).fromNow}</div>
                </div>
                <div className={styles.youHaveAnContainer}>
                  <span>{data.msg}</span>
                  <span className={styles.smithsConstructions}>
                    {data.name}
                  </span>
                  <span> on {data.date}</span>
                </div>
                <div className={styles.largeButtons}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder7.svg"
                  />
                  <b className={styles.label}>Mark Job as Completed</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder7.svg"
                  />
                </div>
              </div>
            </div>
          </div>
              );
            })
            : 
              quoteData.map((data)=>{
                return(
                  <div className={styles.notifcations}>
              <div className={styles.container}>
                <div className={styles.content}>
                  <div className={styles.top}>
                    <div className={styles.bookingParent}>
                      <div className={styles.booking6}>
                        {data.type=='booking'?`Booking: `:
                        data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</div>
                    
                    </div>
                    <div className={styles.minsAgo}>{moment(data.unix).fromNow}</div>
                  </div>
                  <div className={styles.youHaveAnContainer}>
                    <span>{data.msg}</span>
                    <span className={styles.smithsConstructions}>
                      {data.name}
                    </span>
                    <span> on {data.date}</span>
                  </div>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder7.svg"
                    />
                    <b className={styles.label}>Mark Job as Completed</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder7.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
                );
              })
            }
         
            {
              /*

            <div className={styles.notifcations}>
              <div className={styles.container}>
                <div className={styles.content}>
                  <div className={styles.top1}>
                    <div className={styles.bookingParent}>
                      <div className={styles.booking6}>{`Job: `}</div>
                      <div className={styles.sun01July}>
                        Sun 01 July at 7:20pm
                      </div>
                    </div>
                    <div className={styles.minsAgo}>3mins ago</div>
                  </div>
                  <div className={styles.youHaveAnContainer}>
                    <span>{`Job booking has been marked complete by `}</span>
                    <span className={styles.smithsConstructions}>
                      Smith’s constructions
                    </span>
                    <span>{` `}</span>
                  </div>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder7.svg"
                    />
                    <b className={styles.label}>Mark Job as Completed</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder7.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
              */
            }
            
          </div>
        </div>
      </div>
      <div className={styles.signup}>
        <div className={styles.titles1}>
          <div className={styles.back} onClick={()=>{navigate(-1)}}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.activities}>Activities</div>
        </div>
        <div className={styles.activitiesTabParent}>
              <div className={styles.activitiesTab}>
                <div className={styles.twoTabSwitch}>
                  <div
                    className={styles.tab1Wrapper}
                    onClick={onFrameContainer7Click}
                  >
                    <a className={styles.tab1}>Notifications</a>
                  </div>
                  <div
                    className={styles.tab2Wrapper}
                    onClick={()=>{setTopTab(1)}}
                  >
                    <a className={styles.tab2}>Questions</a>
                  </div>
                </div>
              </div>
            </div>
        <div className={styles.notificationsLowerTab}>
          <div className={styles.titles2}>
            <div className={styles.allGroup} onClick={()=>{setBottomTab(0)}}
            style={bottomtab==0?{color:`var(--logg-green)`}:{}}>
              <div className={styles.all1}>All</div>
              <div className={styles.frameChild} 
              style={bottomtab==0?{backgroundColor:`var(--logg-green)`,width:'29px'}:{}}/>
            </div>
            <div className={styles.allGroup} onClick={()=>{setBottomTab(1)}}
            style={bottomtab==1?{color:`var(--logg-green)`}:{}}>
              <div className={styles.jobs}>Jobs</div>
              <div className={styles.frameChild} 
              style={bottomtab==1?{backgroundColor:`var(--logg-green)`,width:'35px'}:{}}/>
            </div>
            <div className={styles.allGroup} onClick={()=>{setBottomTab(2)}}
            style={bottomtab==2?{color:`var(--logg-green)`}:{}}>
               <div className={styles.jobs}> Payment</div>
              <div className={styles.frameChild} 
              style={bottomtab==2?{backgroundColor:`var(--logg-green)`,width:'70px'}:{}}/>
            </div>
            <div className={styles.allGroup} onClick={()=>{setBottomTab(3)}}
            style={bottomtab==3?{color:`var(--logg-green)`}:{}}>
              <div className={styles.jobs}>Promotions</div>
              <div className={styles.frameChild}
              style={bottomtab==3?{backgroundColor:`var(--logg-green)`,width:'50px'}:{}} />
            </div>
            <div className={styles.allGroup} onClick={()=>{setBottomTab(4)}}
            style={bottomtab==4?{color:`var(--logg-green)`}:{}}>
              <div className={styles.payment1}>Quote</div>
              <div className={styles.frameChild} 
              style={bottomtab==4?{backgroundColor:`var(--logg-green)`,width:'70px'}:{}}
              />
            </div>
          </div>
        </div>
        <div className={styles.notificationsParent}>
        {
              bottomtab==0?
              allData.map((data)=>{
                return(
                  <div className={styles.notifications2}>
                  <div className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                      <div className={styles.bookingSun01JulyAt720pmParent}>
                        <div className={styles.bookingSun01Container}>
                          <span className={styles.offer}>{data.type=='booking'?`Booking: `:
                        data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</span>
                          <span className={styles.sun01July2}>
                            {" "}
                           
                          </span>
                        </div>
                        <div className={styles.minsAgo2}>{moment(data.unix).fromNow}</div>
                      </div>
                      <div className={styles.frameItem} />
                    </div>
                    <div className={styles.iAmEnjoyingTheseComputerParent}>
                      <div
                        className={styles.iAmEnjoying}
                      >
                        <span>{data.msg}</span>
                    <span className={styles.smithsConstructions}>
                      {data.name}
                    </span>
                    <span> on {data.date}</span>
                      </div>
                      <div className={styles.button}>
                        <div className={styles.iconsParent}>
                          <img className={styles.icons} alt="" src="/icons3.svg" />
                          <b className={styles.proceed}>Proceed</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                );
              })
              
            :bottomtab==1?
            bookingData.map((data)=>{
              return(
                <div className={styles.notifications2}>
                  <div className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                      <div className={styles.bookingSun01JulyAt720pmParent}>
                        <div className={styles.bookingSun01Container}>
                          <span className={styles.offer}>{data.type=='booking'?`Booking: `:
                        data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</span>
                          <span className={styles.sun01July2}>
                            {" "}
                           
                          </span>
                        </div>
                        <div className={styles.minsAgo2}>{moment(data.unix).fromNow}</div>
                      </div>
                      <div className={styles.frameItem} />
                    </div>
                    <div className={styles.iAmEnjoyingTheseComputerParent}>
                      <div
                        className={styles.iAmEnjoying}
                      >
                        <span>{data.msg}</span>
                    <span className={styles.smithsConstructions}>
                      {data.name}
                    </span>
                    <span> on {data.date}</span>
                      </div>
                      <div className={styles.button}>
                        <div className={styles.iconsParent}>
                          <img className={styles.icons} alt="" src="/icons3.svg" />
                          <b className={styles.proceed}>Proceed</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            :bottomtab==2?
            paymentData.map((data)=>{
              return(
                <div className={styles.notifications2}>
                  <div className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                      <div className={styles.bookingSun01JulyAt720pmParent}>
                        <div className={styles.bookingSun01Container}>
                          <span className={styles.offer}>{data.type=='booking'?`Booking: `:
                        data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</span>
                          <span className={styles.sun01July2}>
                            {" "}
                           
                          </span>
                        </div>
                        <div className={styles.minsAgo2}>{moment(data.unix).fromNow}</div>
                      </div>
                      <div className={styles.frameItem} />
                    </div>
                    <div className={styles.iAmEnjoyingTheseComputerParent}>
                      <div
                        className={styles.iAmEnjoying}
                      >
                        <span>{data.msg}</span>
                    <span className={styles.smithsConstructions}>
                      {data.name}
                    </span>
                    <span> on {data.date}</span>
                      </div>
                      <div className={styles.button}>
                        <div className={styles.iconsParent}>
                          <img className={styles.icons} alt="" src="/icons3.svg" />
                          <b className={styles.proceed}>Proceed</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            :bottomtab==3?
            promoData.map((data)=>{
              return(
                <div className={styles.notifications2}>
                <div className={styles.frameParent}>
                  <div className={styles.frameGroup}>
                    <div className={styles.bookingSun01JulyAt720pmParent}>
                      <div className={styles.bookingSun01Container}>
                        <span className={styles.offer}>{data.type=='booking'?`Booking: `:
                      data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</span>
                        <span className={styles.sun01July2}>
                          {" "}
                         
                        </span>
                      </div>
                      <div className={styles.minsAgo2}>{moment(data.unix).fromNow}</div>
                    </div>
                    <div className={styles.frameItem} />
                  </div>
                  <div className={styles.iAmEnjoyingTheseComputerParent}>
                    <div
                      className={styles.iAmEnjoying}
                    >
                      <span>{data.msg}</span>
                  <span className={styles.smithsConstructions}>
                    {data.name}
                  </span>
                  <span> on {data.date}</span>
                    </div>
                    <div className={styles.button}>
                      <div className={styles.iconsParent}>
                        <img className={styles.icons} alt="" src="/icons3.svg" />
                        <b className={styles.proceed}>Proceed</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            })
            : 
              quoteData.map((data)=>{
                return(
                  <div className={styles.notifications2}>
                  <div className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                      <div className={styles.bookingSun01JulyAt720pmParent}>
                        <div className={styles.bookingSun01Container}>
                          <span className={styles.offer}>{data.type=='booking'?`Booking: `:
                        data.type=='payment'?`Payment: `:data.type=='promo'?`Promotion: `:'Quote: '}</span>
                          <span className={styles.sun01July2}>
                            {" "}
                           
                          </span>
                        </div>
                        <div className={styles.minsAgo2}>{moment(data.unix).fromNow}</div>
                      </div>
                      <div className={styles.frameItem} />
                    </div>
                    <div className={styles.iAmEnjoyingTheseComputerParent}>
                      <div
                        className={styles.iAmEnjoying}
                      >
                        <span>{data.msg}</span>
                    <span className={styles.smithsConstructions}>
                      {data.name}
                    </span>
                    <span> on {data.date}</span>
                      </div>
                      <div className={styles.button}>
                        <div className={styles.iconsParent}>
                          <img className={styles.icons} alt="" src="/icons3.svg" />
                          <b className={styles.proceed}>Proceed</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                );
              })
            }
          {
            /*
            <div className={styles.notifications2}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.bookingSun01JulyAt720pmParent}>
                  <div className={styles.bookingSun01Container}>
                    <span className={styles.offer}>Offer:</span>
                    <span className={styles.sun01July2}>
                      {" "}
                      Sun 01 July at 7:20pm
                    </span>
                  </div>
                  <div className={styles.minsAgo2}>3mins ago</div>
                </div>
                <div className={styles.frameItem} />
              </div>
              <div className={styles.iAmEnjoyingTheseComputerParent}>
                <div
                  className={styles.iAmEnjoying}
                >{`I am enjoying these “computer” speakers. They are sinced with my phone. I had Bose speakers and bought these as a replacement. `}</div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.proceed}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.notifications2}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.bookingSun01JulyAt720pmParent}>
                  <div className={styles.bookingSun01Container}>
                    <span className={styles.offer}>Quote:</span>
                    <span className={styles.sun01July2}>
                      {" "}
                      Sun 01 July at 7:20pm
                    </span>
                  </div>
                  <div className={styles.minsAgo2}>3mins ago</div>
                </div>
                <div className={styles.frameItem} />
              </div>
              <div className={styles.iAmEnjoyingTheseComputerParent}>
                <div
                  className={styles.iAmEnjoying}
                >{`I am enjoying these “computer” speakers. They are sinced with my phone. I had Bose speakers and bought these as a replacement. `}</div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.proceed}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.notifications2}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.bookingSun01JulyAt720pmParent}>
                  <div className={styles.bookingSun01Container}>
                    <span className={styles.offer}>Review:</span>
                    <span className={styles.sun01July2}>
                      {" "}
                      Sun 01 July at 7:20pm
                    </span>
                  </div>
                  <div className={styles.minsAgo2}>3mins ago</div>
                </div>
                <div className={styles.frameItem} />
              </div>
              <div className={styles.iAmEnjoyingTheseComputerParent}>
                <div
                  className={styles.iAmEnjoying}
                >{`I am enjoying these “computer” speakers. They are sinced with my phone. I had Bose speakers and bought these as a replacement. `}</div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.proceed}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.notifications2}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.bookingSun01JulyAt720pmParent}>
                  <div className={styles.bookingSun01Container}>
                    <span className={styles.offer}>Offer:</span>
                    <span className={styles.sun01July2}>
                      {" "}
                      Sun 01 July at 7:20pm
                    </span>
                  </div>
                  <div className={styles.minsAgo2}>3mins ago</div>
                </div>
                <div className={styles.frameItem} />
              </div>
              <div className={styles.iAmEnjoyingTheseComputerParent}>
                <div
                  className={styles.iAmEnjoying}
                >{`I am enjoying these “computer” speakers. They are sinced with my phone. I had Bose speakers and bought these as a replacement. `}</div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.proceed}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.notifications2}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.bookingSun01JulyAt720pmParent}>
                  <div className={styles.bookingSun01Container}>
                    <span className={styles.offer}>Offer:</span>
                    <span className={styles.sun01July2}>
                      {" "}
                      Sun 01 July at 7:20pm
                    </span>
                  </div>
                  <div className={styles.minsAgo2}>3mins ago</div>
                </div>
                <div className={styles.frameItem} />
              </div>
              <div className={styles.iAmEnjoyingTheseComputerParent}>
                <div
                  className={styles.iAmEnjoying}
                >{`I am enjoying these “computer” speakers. They are sinced with my phone. I had Bose speakers and bought these as a replacement. `}</div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.proceed}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
            */
          }
          
        </div>
      </div>
    </div>
      :
      <Qa onActivity={()=>{setTopTab(0)}}
      />
    }
    
    </>
   
  );
};

export default Activity;
