import { useState, useCallback, useEffect } from "react";
import PayoutMethod from "../components/PayoutMethod";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./PayoutPref.module.css";
import { styled } from '@mui/material/styles';
import verify_styles from "./GladHere.module.css";
import CloseIcon from '@mui/icons-material/Close';
import { auth, db } from "../firebase";
import { Box, Menu, MenuItem, Modal } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding:'1rem'
};
const PayoutPref = () => {
  const [isPayoutMethodPopupOpen, setPayoutMethodPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [cards, setCards] = useState([]); 
  const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("payout");
  const [selected, setSelected]=useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [ avail, setAvail]= useState(false)
  const [ verified, setVerified]= useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
    useEffect(() => {
    // Use onSnapshot to listen for real-time changes in the "cards" subcollection
    const unsubscribe = cardsCollection.onSnapshot((querySnapshot) => {
      const cardsData = [];
      querySnapshot.forEach((doc) => {
        cardsData.push({ id: doc.id, ...doc.data() });
      });
      setCards(cardsData);
    });

    // Cleanup the listener when the component unmounts
    return () => {
      unsubscribe();
    }
  }, []);


  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onIconContainerClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onIconsMoreVerticalClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const openPayoutMethodPopup = useCallback(async() => {
    const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
    if (snapshot.exists) {
      if(snapshot.data().verification>=35)
      {
        setPayoutMethodPopupOpen(true);
      }
      else{
        setAvail(true)
      }
    } 
   
  }, []);

  const closePayoutMethodPopup = useCallback(() => {
    setPayoutMethodPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.payoutpref}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665221.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome3.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account2.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back} onClick={()=>navigate(-1)}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.notifications}>Payout preferences</div>
          </div>
          <div className={styles.component102}>
            <div className={styles.smallButtons}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
                <div className={styles.button}>Add Item</div>
              </div>
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar5.svg" />
            <b className={styles.licenseAndCertification}>Payout preferences</b>
            <div className={styles.icon} onClick={onIconContainerClick}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
          </div>
          <div className={styles.banksParent}>
           {verified&& <p style={{color:'red'}}>You need need to verify your proof of ID for your payouts to work</p>}
          {cards.length > 0 && (
        <div>
          {cards.map((card) => (
             <div className={styles.banks}>
             <div className={styles.frameParent}>
               <div className={styles.frameGroup}>
                 <img
                   className={styles.frameChild}
                   alt=""
                   src="/frame-67641.svg"
                 />
                 <div className={styles.clydesdaleBankPlcParent}>
                   <div className={styles.clydesdaleBankPlc}>
                   {card.bankName}
                   </div>
                   <div className={styles.parent}>
                     <div className={styles.div}>{card.accountNumber}</div>
                     <div className={styles.frameItem} />
                     <div className={styles.div}>{card.accountType}</div>
                   </div>
                   <div className={styles.div}>{card.accountName}</div>
                 </div>
               </div>
               <img
                 className={styles.iconsmoreVertical}
                 alt=""
                 src="/iconsmore-vertical1.svg"
                 style={{cursor:'pointer'}}
                 id={card.id}
                 aria-controls={open ? card.id : undefined}
                 aria-haspopup="true"
                 aria-expanded={open ? 'true' : undefined}
                 onClick={(e)=>{
                   setSelected(card.id)
                   handleClick(e)
                 }}
               />
             </div>
           </div>
            ))}
        </div>
      )}
           
            <div
              className={styles.largeButtons}
              onClick={openPayoutMethodPopup}
            >
              <img className={styles.iconsadd1} alt="" src="/iconsadd4.svg" />
              <b className={styles.div}>Add Another Bank</b>
              <img className={styles.iconsadd2} alt="" src="/iconsadd5.svg" />
            </div>
          </div>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem style={{color:'red'}} onClick={()=>{
          db.collection("users").doc(auth.currentUser.uid).collection("payout").doc(selected).delete()
          handleClose()
          }}>Delete</MenuItem>
      </Menu>
      <Modal
        open={avail}
        onClose={()=>setAvail(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className={verify_styles.gladheremodal}>
          <div>
            <div style={{ display:'flex', justifyContent:'right',marginRight:'1rem'}} onClick={()=>setAvail(false)}>
             <CloseIcon/>
            </div>
            <div className={verify_styles.frameWrapper}>
            <div className={verify_styles.weAreGladYoureHereParent}>
              <b className={verify_styles.weAreGlad}>We are glad you’re here.</b>
              <div className={verify_styles.youWillNeedContainer}>
                <p className={verify_styles.youWillNeed}>
                  You will need to upload some ID documents
                </p>
                <p className={verify_styles.youWillNeed}>
                  to verify you as a Service provider
                </p>
              </div>
            </div>
          </div>
          <div style={{width:'100%', display:'flex', justifyContent:'center'}} onClick={()=>setAvail(false)}>
          <img className={verify_styles.hiFiveIcon} alt="" src="/hifive.svg" />
            </div>
          <div className={verify_styles.largeButtonsParent}>
            <button className={verify_styles.largeButtons} onClick={()=>{navigate('/profile')}}>
              <b className={verify_styles.label}>Verify Profile Now</b>
            </button>
          </div>
          </div>
        </div>
         
        </Box>
      </Modal>
      {isPayoutMethodPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePayoutMethodPopup}
        >
          <PayoutMethod onClose={closePayoutMethodPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default PayoutPref;
