import { useCallback, useEffect, useState } from "react";
import styles from "./PromoPayModal.module.css";
import { auth, db } from "../firebase";
import { Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useStripe } from "@stripe/react-stripe-js";

const PromoPayModal = ({ onClose, done, amount, data,onClose_suc }) => {
  const navigate =useNavigate()
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardId, setCardId] = useState("");
  const [userId, setUserId]=useState('')
  const [email, setEmail]=useState('')
  const stripe = useStripe();

  const onLargeButtonsContainerClick = async () => {
    // Create a payment object with the card information
    const paymentData = {
      cardNumber,
      expirationDate,
      cvv,
      amount: amount, 
      userId,
      custid:userId,
      email,
      cardId,
      data,
      url: window.location.href
    };

    try {
      // Send a POST request to your server to initiate the payment process
      const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/promote', paymentData);
      if (response.data.requires_action) {
        const { setupIntent, error } = await stripe?.confirmCardPayment(
          response.data.payment_intent_client_secret,  // The payment_intent_client_secret returned by the server
          { payment_method: cardId }
      );
      if (error) {
        // Handle errors
        console.error('Error confirming payment:', error.message);
    } else if (setupIntent.status === 'succeeded') {
        // The payment succeeded, handle the result
        console.log('Payment succeeded:', setupIntent);
        onClose_suc()  
        // Perform additional actions on success
    } else if (setupIntent.status === 'requires_action') {
        // The payment requires additional action (e.g., 3D Secure)
        handleCardAction(setupIntent.client_secret);
    }
    } else if (response.data.success) {
      onClose_suc()  
    }
      console.log('Payment response:', response.data);
      onClose_suc()
    } catch (error) {
      console.error('Error:', error);
      alert('Your promotion cost is way too low, please cover more radius.')
      // Handle the error case
    }
  };

  const handleCardAction = async (clientSecret) => {
    const { paymentIntent, error } = await stripe?.handleCardAction(clientSecret);

    if (error) {
        // Handle errors
        console.error('Error handling card action:', error.message);
       
    } else if (paymentIntent.status === 'succeeded') {
        // The payment succeeded after 3D Secure
        console.log('Payment succeeded:', paymentIntent);
       done()
    } else {
        // Handle other paymentIntent statuses after 3D Secure
        console.log('Unexpected PaymentIntent status after 3D Secure:', paymentIntent.status);
        
    }
};

  const [cards, setCards] = useState([]); 
  const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");

  useEffect(() => {
    db.collection('users').doc(auth.currentUser.uid).get().then(function (snap){
      setUserId(snap.data().Strip_payId)
      setEmail(snap.data().email)
    })
    db.collection('users').doc(auth.currentUser?.uid).get().then(function(snapshot) {
      if(snapshot.data())
      {
   // Use onSnapshot to listen for real-time changes in the "cards" subcollection
   const unsubscribe = cardsCollection.onSnapshot((querySnapshot) => {
    const cardsData = [];
    querySnapshot.forEach((doc) => {
      if(doc.id==snapshot.data()?.defaultCardId){
        cardsData.push({ id: doc.id, ...doc.data() });
        if (cardsData.length>0) {
          setCardNumber(cardsData[0].cardNumber)
       setExpirationDate(cardsData[0].expiryDatef)
       setCvv(cardsData[0].cvv)
       setCardId(cardsData[0].cardId)
       console.log(cardsData[0].cardId)
       }
      }
    });
    setCards(cardsData);
  });

  // Cleanup the listener when the component unmounts
  return () => unsubscribe();
      }
    });
   
  }, []);

  function trimCardNumberToLast4Digits(cardNumber) {
    if (typeof cardNumber !== "string") {
      console.error("Invalid card number format. Expected a string.");
      return cardNumber; // Return the input as is if it's not a string
    }
  
    // Use string slicing to get the last 4 digits of the card number
    const last4Digits = cardNumber.slice(-4);
    return last4Digits;
  }

  return (
    <div className={styles.promopaymodal}>
      <div className={styles.modalTitle}>
        <div className={styles.addANewCardParent}>
          <div className={styles.addANew}>Payment</div>
          <img className={styles.iconsclose} alt="" src="/iconsclose1.svg" onClick={onClose}/>
        </div>
      </div>
      <div className={styles.savedCardsParent}>
      {cards.length > 0 && (
        <div style={{width:'100%'}}>
          {cards.map((card) => (
             <div className={styles.savedCards} onClick={()=>{
              setCardNumber(card.cardNumber)
              setExpirationDate(card.expiryDatef)
              setCvv(card.cvv)
              setCardId(card.cardId)
             }}>
             <div className={styles.frameParent}>
               <div className={styles.crosbyOdennehyParent}>
                 <div className={styles.crosbyOdennehy}>{card.nameOnCard}</div>
                 <div className={styles.transactionPills}>
                   <div className={styles.success}>DEFAULT</div>
                 </div>
               </div>
               <div className={styles.div}>**** **** **** {trimCardNumberToLast4Digits(card.cardNumber)}</div>
             </div>
             <div className={styles.iconsplaceholderParent}>
               <img
                 className={styles.iconsplaceholder}
                 alt=""
                 src="/iconsplaceholder1.svg"
               />
               <div className={styles.mastercard1Parent}>
                 <img
                   className={styles.mastercard1Icon}
                   alt=""
                   src="/mastercard-1.svg"
                 />
                <Radio
                  
                  />
               </div>
             </div>
           </div>
            ))}
        </div>
      )}
       
        <div className={styles.iconsaddParent} style={{cursor:'pointer'}} onClick={()=>navigate('/paymentmethod')}>
          <img className={styles.iconsadd} alt="" src="/iconsadd.svg" />
          <b className={styles.addAnotherCard}>Add another card</b>
        </div>
      </div>
      <div
        className={styles.largeButtons}
        onClick={onLargeButtonsContainerClick}
      >
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder31.svg"
        />
        <b className={styles.addAnotherCard}>Make payment</b>
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder31.svg"
        />
      </div>
    </div>
  );
};

export default PromoPayModal;
