import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Verify.module.css";
import container from "./SignIn.module.css";
import PinInput from 'react-pin-input';
import app, { firebase,auth, db } from "../firebase";

const Verify = ({  phone,
  final,check,password}) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('')
  const [error ,setError]= useState('')
  const [succ ,setSucc]= useState('')
  const recaptchaRef = useRef(null);
  let final_=final
  useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      // You can provide optional parameters here
      size: "invisible", // "normal" or "compact"
      callback: () => {
        // This callback will be triggered when the reCAPTCHA verification is successful
      },
      "expired-callback": () => {
        // This callback will be triggered when the reCAPTCHA verification expires
      },
    });
    // Assign the RecaptchaVerifier instance to the ref
    recaptchaRef.current = recaptchaVerifier;

  }, [])
const ValidateOtp = () => {
        if (otp === null || final_ === null)
            return;
        final_.confirm(otp).then((result) => {
          if(!check){
            db.collection('phone_passwords').doc(phone).set({
              password
            })
          }
          if(auth.currentUser.uid)
          {
          db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
            if(!snapshot.data()?.name)
            {
              db.collection('users').doc(auth.currentUser.uid).set({
                phone:phone
              })
              navigate('/personalinfo')
            }
            else
            {
              db.collection('users').doc(auth.currentUser.uid).update({
              phone:phone
              })
              navigate('/home')
            }
          });
          }
        }).catch((err) => {
          console.log(otp)
            setError("Wrong 2fa code, please try again.");
        })
    }
  const resendOtp =() => {
    const phoneNo = "+" + phone;
      app
        .auth()
        .signInWithPhoneNumber(phoneNo, recaptchaRef.current)
        .then((result) => {
          final_=result;
          setSucc('OTP sent successfully')
          setError('')
        })
        .catch((err) => {
          console.log(err.message);
          setError('Unable to send OTP please try again later')
          setSucc('')
          // Handle error, such as displaying an error message to the user
        });
  }

  return (
    <div className={styles.verify}>
      <div className={container.img}>
        <img className={container.logoIcon} alt="" src="/logo.svg" />
        <div className={container.testimonial}>
          <div className={container.greatSessionDaniWasSuperParent}>
            <div className={container.greatSessionDani}>
              "Great session! Dani was super helpful. She shared some practical
              advice on how can we go about refining our service offerings."
            </div>
            <div className={container.frameParent}>
              <div className={container.ellipseParent}>
                <img
                  className={container.frameChild}
                  alt=""
                  src="/ellipse-249@2x.png"
                />
                <div className={container.cameronWilliamson}>
                  Cameron Williamson
                </div>
              </div>
              <div className={container.starRating}>
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar11.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.verifyInner}>
        <div className={styles.frameParent}>
          <div className={styles.verifyPhoneNumberParent}>
            <b className={styles.verifyPhoneNumber}>Verify phone number</b>
            <div className={styles.enterTheVerification}>
              Enter the verification number sent to
            </div>
            <div className={styles.parent}>
              <div className={styles.div}> +{phone}</div>
          <div className={styles.edit} style={{cursor:'pointer'}} onClick={()=>window.location.reload()}>Edit</div>
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.otpInputParent}>
             <PinInput 
  length={6} 
  initialValue="" 
  onChange={(value, index) => {setOtp(value)}} 
  style={{padding: '10px'}}  
  inputStyle={{borderColor: `${error!='' ? '#FF0000' : 'var(--grey-70, #cccccc)'}`,}}
  inputFocusStyle={{borderColor: 'blue'}}
  autoSelect={true}
/>
            </div>
            <p style={{color:'red',textAlign:'start',width:'95%'}}>{error}</p>
            <p style={{color:'green',textAlign:'start',width:'95%'}}>{succ}</p>
            <div className={styles.div}> </div>
            <div id="recaptcha-container" /> 
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.didntGetTheOtpParent}>
              <div className={styles.enterTheVerification}>
                Didn’t get the OTP?
              </div>
              <div className={styles.resendOtp} 
              style={{cursor:'pointer'}} onClick={resendOtp}>Resend OTP</div>
            </div>
            <button
              className={styles.largeButtons}
              onClick={ValidateOtp}
            >
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder5.svg"
              />
              <b className={styles.label}>Verify OTP</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder5.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
