import axios from "axios";
import { auth, db } from "../firebase";
import styles from "./ChangePlanPsgl.module.css";
import posthog from 'posthog-js'

const ChangePlanPsgl = ({ onClose, reload,payg }) => {
  return (
    <div className={styles.changeplanpsgl}>
      <div className={styles.merge}>
        <div className={styles.merge1}>
          <div className={styles.mergeInner}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <div className={styles.payAsYouGoParent}>
                  <div className={styles.payAsYou}>Pay as you go</div>
                  <div className={styles.commissionJob}>
                    <span>
                      <span className={styles.span}>{payg}%</span>
                    </span>
                    <span className={styles.commissionJob1}>
                      <span>{` `}</span>
                      <span>Commission /Job</span>
                    </span>
                  </div>
                </div>
                <div className={styles.frameContainer}>
                  <div className={styles.subscriptionListWrapper}>
                    <div className={styles.subscriptionList}>
                      <div className={styles.iconsscrewParent}>
                        <img
                          className={styles.iconsscrew}
                          alt=""
                          src="/iconsscrew1.svg"
                        />
                        <div className={styles.jobManagement}>
                          Everything in Monthly subscription
                        </div>
                      </div>
                      <div
                        className={styles.automaticallyManageYourJobsWrapper}
                      >
                        <div className={styles.automaticallyManageYour}>
                          Get all the perks in our monthly subscription plan
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subscriptionListWrapper}>
                    <div className={styles.subscriptionList}>
                      <div className={styles.iconsscrewParent}>
                        <img
                          className={styles.iconsscrew}
                          alt=""
                          src="/iconsscrew1.svg"
                        />
                        <div className={styles.jobManagement}>Promotion</div>
                      </div>
                      <div
                        className={styles.automaticallyManageYourJobsWrapper}
                      >
                        <div className={styles.automaticallyManageYour}>
                          Send offers to service consumers directly to improve
                          chances of getting jobs.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.subscriptionListWrapper}>
                    <div className={styles.subscriptionList}>
                      <div className={styles.iconsscrewParent}>
                        <img
                          className={styles.iconsscrew}
                          alt=""
                          src="/iconsscrew1.svg"
                        />
                        <div className={styles.jobManagement}>Logg Jobs</div>
                      </div>
                      <div
                        className={styles.automaticallyManageYourJobsWrapper}
                      >
                        <div className={styles.automaticallyManageYour}>
                          Get jobs directly on your dashboard at no extra cost.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.largeButtonsWrapper}
               style={{cursor:'pointer'}}
               onClick={()=>{

                db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
                   const paymentData = {
                 subid:snap.data().subscription_id
                };
                axios.post('https://loggworks-c15c9e56f169.herokuapp.com/mangopay/cancelSub', paymentData);

                 db.collection('users').doc(auth.currentUser.uid).update({
                  subType:0
                 })
                })
               posthog.capture('Subcription: Monthly')
                 reload()
                 onClose()
                 
              }}>
                <div className={styles.largeButtons}>
                  <img
                    className={styles.iconsmessage}
                    alt=""
                    src="/iconsmessage1.svg"
                  />
                  <b className={styles.label}>Change Plan</b>
                  <img
                    className={styles.iconsmessage}
                    alt=""
                    src="/iconsmessage2.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanPsgl;
