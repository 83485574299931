import { useCallback, useState } from "react";
import { TextField, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./AddressproofModal.module.css";
import { auth, db, storage } from "../firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { v4 as uuidv4 } from 'uuid';
import Tesseract from "tesseract.js";
import SSIM from 'ssim.js';
import posthog from 'posthog-js'

 

const AddressproofModal = ({ onClose }) => {
  const navigate = useNavigate();
  const [noExp,setNoExp]=useState(false)
  const [name,setName]=useState('')
  const [date,setDate]=useState('')
  const [imageUrls, setImageUrls] = useState([]);
  const [loading,setLoading]=useState(false)

  const addCertificateToFirestore = () => {
    if (name.trim() === '' || imageUrls.length === 0) {
      alert('Please fill in the name and provide at least one image.');
      return;
    }

    // Check if noExp is false and date is empty
    if (!noExp && date.trim() === '') {
      alert('Please provide a date for the address.');
      return;
    }
    // Get a reference to the 'users' collection
    const usersCollection = db.collection("users");
  
    // Get a reference to the 'verification' subcollection within the user's document
    const verificationCollection = usersCollection.doc(auth.currentUser.uid).collection("proofID");
  
    // Define the data you want to add
    const certificateData = {
      certificationName: name,
      expirationDate: date,
      imageUrls:imageUrls
    };
  if (name!='') {
    // Add the data to Firestore
    verificationCollection.add(certificateData)
    .then((docRef) => {
      verificationCollection.get().then((querySnapshot) => {
        if (querySnapshot.size <= 1) {
          usersCollection.doc(auth.currentUser.uid).update({
            verification: firebase.firestore.FieldValue.increment(15)})
        } 
      });
     
      onClose()
    })
    .catch((error) => {
      console.error("Error adding certificate: ", error);
    });
  }
    
  };
  const removeImageUrl = (index) => {
    const newImageUrls = [...imageUrls];
    newImageUrls.splice(index, 1);
    setImageUrls(newImageUrls);
  };

  function calculateTextSimilarity(text1, text2) {
    // Convert texts to lowercase to make the comparison case-insensitive
    text1 = text1.toLowerCase();
    text2 = text2.toLowerCase();

    // Split texts into arrays of words
    const words1 = text1.split(/\s+/);
    const words2 = text2.split(/\s+/);

    // Count the number of words in text1 that appear in text2
    let matchingWords = 0;
    words1.forEach(word1 => {
        if (words2.includes(word1)) {
            matchingWords++;
        }
    });

    // Calculate the similarity percentage
    const similarityPercentage = (matchingWords / words1.length) * 100;

    return similarityPercentage;
}

function isAtLeast80PercentSimilar(text1, text2) {
    const similarityPercentage = calculateTextSimilarity(text1, text2);
    return similarityPercentage >= 80;
}

const handleImageUpload = async (e) => {
  const files = e.target.files;
  const storageRef = storage.ref(); // Get a reference to the Firebase Storage
  setLoading(true);
  try {
      // Check if files array is not undefined and has at least one file
      if (!files || files.length === 0) {
          throw new Error("No files selected for upload.");
      }

      const result = await Tesseract.recognize(
          files[0], // Assuming you want to process only the first file
          'eng', // Language code
          { logger: (info) => console.log(info) } // Log additional information
      );

      console.log(result.data.text);

      const userSnapshot = await db.collection('users').doc(auth.currentUser.uid).get();

      if (!result.data.text.includes(userSnapshot.data().name)) {
          alert('Your account name does not match the name on the provided document');
          e.target.value = '';
          setLoading(false);
          return;
      } else if (!result.data.text.includes(userSnapshot.data().address)) {
          alert('Your account address does not match the address on the provided document');
          e.target.value = '';
          setLoading(false);
          return;
      }

      const promises = [];

      // Check 'proofID' collection
      const proofIDQuerySnapshot = await db.collection('users').doc(auth.currentUser.uid).collection('proofID').get();
      proofIDQuerySnapshot.forEach((doc) => {
          for (let i = 0; i < files.length; i++) {
              console.log('Checking proofID', i);
              if (doc.data().imageUrls && doc.data().imageUrls[i]) { // Ensure imageUrls exist and have an index
                  const checkPromise = Tesseract.recognize(doc.data().imageUrls[i], 'eng').then((check) => {
                      const isSimilar = isAtLeast80PercentSimilar(check.data.text, result.data.text);
                      if (isSimilar) {
                          alert('You have posted this document before. Please select a different one.');
                          e.target.value = '';
                          setLoading(false);
                          return;
                      }
                  });
                  promises.push(checkPromise);
              }
          }
      });

      // Check 'license' collection
      const licenseQuerySnapshot = await db.collection('users').doc(auth.currentUser.uid).collection('license').get();
      licenseQuerySnapshot.forEach((doc) => {
          for (let i = 0; i < files.length; i++) {
              console.log('Checking license', i);
              if (doc.data().imageUrls && doc.data().imageUrls[i]) { // Ensure imageUrls exist and have an index
                  const checkPromise = Tesseract.recognize(doc.data().imageUrls[i], 'eng').then((check) => {
                      const isSimilar = isAtLeast80PercentSimilar(check.data.text, result.data.text);
                      if (isSimilar) {
                          alert('You have posted this document before. Please select a different one.');
                          e.target.value = '';
                          setLoading(false);
                          return;
                      }
                  });
                  promises.push(checkPromise);
              }
          }
      });

      // Check 'education' collection
      const educationQuerySnapshot = await db.collection('users').doc(auth.currentUser.uid).collection('education').get();
      educationQuerySnapshot.forEach((doc) => {
          for (let i = 0; i < files.length; i++) {
              console.log('Checking education', i);
              if (doc.data().imageUrls && doc.data().imageUrls[i]) { // Ensure imageUrls exist and have an index
                  const checkPromise = Tesseract.recognize(doc.data().imageUrls[i], 'eng').then((check) => {
                      const isSimilar = isAtLeast80PercentSimilar(check.data.text, result.data.text);
                      if (isSimilar) {
                          alert('You have posted this document before. Please select a different one.');
                          e.target.value = '';
                          setLoading(false);
                          return;
                      }
                  });
                  promises.push(checkPromise);
              }
          }
      });

      // Wait for all checks to complete
      await Promise.all(promises);

      // If no similarity found, proceed with file upload
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileExtension = file.name.split('.').pop(); // Get the file extension
          const randomFileName = `${uuidv4()}.${fileExtension}`; // Generate a random name with the original extension
          const imageRef = storageRef.child(`images/${randomFileName}`);
          const snapshot = await imageRef.put(file);
          const downloadURL = await snapshot.ref.getDownloadURL();
          setImageUrls((prevImageUrls) => [...prevImageUrls, downloadURL]);
          console.log("Uploaded a file and added data to Firestore:", randomFileName);
      }
      posthog.capture('Verification of Address completed', { AddressProof:true})
      setLoading(false);

  } catch (error) {
      console.error('Error handling image upload:', error.message);
      // Handle the error appropriately
      setLoading(false);
  }
};





  return (
    <div className={styles.addressproofmodal}>
      <div className={styles.modalTitle}>
        <div className={styles.addAddressProofParent}>
          <div className={styles.addAddressProof}>Add Address Proof</div>
          <img
            className={styles.iconsclose}
            alt=""
            src="/iconsclose1.svg"
            onClick={onClose}
          />
        </div>
      </div>
      <div className={styles.inputParent}>
        <div className={styles.input}>
          <div className={styles.labelContainer}>
            <div className={styles.label}>Document Name</div>
          </div>
          <TextField
            className={styles.baseField}
            fullWidth
            color="primary"
            variant="outlined"
            type="text"
            size="medium"
            margin="none"
            value={name}
            onChange={(e)=>{setName(e.target.value)}}
          />
          <div className={styles.helperTextContainer}>
            <div className={styles.thisIsA}>This is a helper text</div>
            <div className={styles.div}>1/100</div>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Expiration Date</div>
            </div>
            <TextField
              className={styles.baseField1}
              color="primary"
              variant="outlined"
              type="date"
              size="medium"
              margin="none"
              disabled={noExp}
              value={date}
            onChange={(e)=>{setDate(e.target.value)}}
            />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <FormControlLabel
            label="ID does not expire"
            labelPlacement="end"
            control={<Checkbox color="primary" size="medium" value={noExp} onChange={()=>{setNoExp(!noExp)}}/>}
          />
        </div>
      </div>
      <div className={styles.uploadImageParent}>
        <div className={styles.uploadImage}>Upload Image</div>
        <div className={styles.uploadImageGroup}>
        <input type="file" accept="image/*" id='upload-button' 
         onChange={handleImageUpload} style={{display:'none'}}/>
        <label htmlFor="upload-button" style={{cursor:'pointer'}}>
<div className={styles.uploadImage1}>
            <div className={styles.imageFrame}>
              <div className={styles.boundary} />
              <div className={styles.imageArea} />
              <img className={styles.iconsadd} alt="" src="/iconsadd1.svg" />
            </div>
          </div>
        </label>
        {imageUrls.length > 0 && (
        <>
          {imageUrls.map((url, index) => (
           <div className={styles.uploadImage1}>
           <div className={styles.imageFrame}>
             <div className={styles.boundary} />
             <div className={styles.imageArea} style={{backgroundImage:`url(${url})`
             ,backgroundSize:'cover',backgroundPosition:'center'}}/>
           </div>
           <img
             className={styles.addcancelCircleIcon}
             alt=""
             style={{cursor:'pointer'}}
             src="/addcancel-circle2.svg"
             onClick={() => removeImageUrl(index)}
           />
         </div>
          ))}
        </>
      )}
    { 
    loading&&<div>
       <b>checking</b>
      <CircularProgress color="success" />
    </div> 
     }
        </div>
      </div>
      <br/>
      <br/>
      <button className={styles.largeButtons} onClick={addCertificateToFirestore}>
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder3.svg"
        />
        <b className={styles.label2}>Save</b>
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder3.svg"
        />
      </button>
    </div>
  );
};

export default AddressproofModal;
