import { useCallback, useState } from "react";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./WorkModal.module.css";
import { auth, db } from "../firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
const WorkModal = ({ onClose }) => {
  const [noExp,setNoExp]=useState(false)
  const [name,setName]=useState('')
  const [date,setDate]=useState('')
  const [end_date,setEndDate]=useState('')
  const [degree,setDegree]=useState('')

  const addCertificateToFirestore = () => {
    if (name.trim() === '' || degree.trim() === '' || date.trim() === '') {
      alert('Please fill in the name, degree, and date fields.');
      return;
    }

    // Check if noExp is false and end_date is empty
    if (!noExp && end_date.trim() === '') {
      alert('Please provide an end date.');
      return;
    }
    // Get a reference to the 'users' collection
    const usersCollection =db.collection("users");
  
    // Get a reference to the 'verification' subcollection within the user's document
    const verificationCollection = usersCollection.doc(auth.currentUser.uid).collection("work");
  
    // Define the data you want to add
    const certificateData = {
      schoolName: name,
      date: date,
      degree:degree,
      end:end_date
    };
  if (name!='') {
    // Add the data to Firestore
    verificationCollection.add(certificateData)
      .then((docRef) => {
        verificationCollection.get().then((querySnapshot) => {
          if (querySnapshot.size <= 1) {
            usersCollection.doc(auth.currentUser.uid).update({
              verification: firebase.firestore.FieldValue.increment(15)})
          } 
        });
       
        onClose()
      })
      .catch((error) => {
        console.error("Error adding certificate: ", error);
      });
  }
    
  };
  return (
    <div className={styles.educationmodal}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>Work Experience</div>
            <img
              className={styles.iconsclose}
              alt=""
              src="/iconsclose1.svg"
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.inputParent}>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Company Name</div>
            </div>
            <TextField
              className={styles.baseField}
              fullWidth
              color="primary"
              variant="outlined"
              type="text"
              size="medium"
              margin="none"
              value={name}
                  onChange={(e)=>{setName(e.target.value)}}
            />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Job Title</div>
            </div>
            <TextField
              className={styles.baseField}
              fullWidth
              color="primary"
              variant="outlined"
              type="text"
              size="medium"
              margin="none"
              value={degree}
                  onChange={(e)=>{setDegree(e.target.value)}}
            />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.inputGroup}>
              <div className={styles.input2}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Start Date</div>
                </div>
                <TextField
                  className={styles.baseField}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  type="date"
                  size="medium"
                  margin="none"
                  value={date}
                  onChange={(e)=>{setDate(e.target.value)}}
                />
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
              <div className={styles.input2}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>End Date</div>
                </div>
                <TextField
                  className={styles.baseField}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  type="date"
                  size="medium"
                  margin="none"
                  disabled={noExp}
                  value={end_date}
                  onChange={(e)=>{setEndDate(e.target.value)}}
                />
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
            </div>
            <FormControlLabel
              label="I’m currently working here"
              labelPlacement="end"
              control={<Checkbox color="primary" size="medium" value={noExp} 
              onChange={(e)=>{setNoExp(!noExp)}}/>}
            />
          </div>
          <button
            className={styles.largeButtons}
            onClick={addCertificateToFirestore}
          >
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder1.svg"
            />
            <b className={styles.label4}>Save</b>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder1.svg"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkModal;
