import React, { useRef, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";


const ShareModal: React.FC<{
  setModalPopUp?: any;
  modalPop?: boolean;
  uid:any
}> = ({ setModalPopUp, modalPop,uid }) => {
  const url=`https://www.professional.loggworks.co.uk/logg_user/${uid.replace(/ /g, '%20')}`
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`, '_blank');
  };

  const shareOnInstagram = () => {
    window.open(`https://www.instagram.com/?url=${encodeURIComponent(url)}`, '_blank');
  };

  const shareOnWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`, '_blank');
  };
  return (
    <div className="relative">
      <div className=" w-full max-w-2xl  mx-auto flex items-center justify-center">
          <div className="relative bg-white w-[361px] h-[261px] md:rounded-md rounded-lg shadow dark:bg-gray-700 px-8 py-6 flex flex-col  items-center md:justify-between">
            <div className="bg-white flex w-full items-center justify-between mb-2">
              <h1 className="text-[20px] font-bold">Share</h1>

              <div
                className="max-sm:hidden close cursor-pointer"
                onClick={() => setModalPopUp(!modalPop)}
              >
                <XMarkIcon width={24} height={24} />
              </div>
            </div>

            <div className="social flex items-center w-full justify-around">
              <img
                src={"/assets/icons/socials/facebook.svg"}
                width={32}
                height={32}
                alt="brand_logo min-w-[40px] max-h-[40px]"
                className="mr-2"
                style={{cursor:'pointer'}}
                onClick={shareOnFacebook}
              />
              <img
                src={"/assets/icons/socials/twitter.svg"}
                width={32}
                height={32}
                alt="brand_logo min-w-[40px] max-h-[40px]"
                className="mr-2"
                style={{cursor:'pointer'}}
                onClick={shareOnTwitter}
              />
              <img
                src={"/assets/icons/socials/insta.svg"}
                width={32}
                height={32}
                alt="brand_logo min-w-[40px] max-h-[40px]"
                className="mr-2"
                style={{cursor:'pointer'}}
                onClick={shareOnInstagram}
              />
              <img
                src={"/assets/icons/socials/whatsapp.svg"}
                width={32}
                height={32}
                alt="brand_logo min-w-[40px] max-h-[40px]"
                className="mr-2"
                style={{cursor:'pointer'}}
                onClick={shareOnWhatsApp}
              />
            </div>

            <div className="flex w-full  bg-[#F6F6F6] text-[16px] justify-between items-center">
              {/* the target */}
              <p id="copy-target" className="px-4 w-[70%]" style={{ wordWrap: 'break-word' }}>
               https://www.professional.loggworks.co.uk/logg_user/{uid.replace(/ /g, '%20')}
              </p>
              <button
                id="copy-button"
                data-te-clipboard-init
                data-te-clipboard-target="#copy-target"
                data-te-ripple-init
                data-te-ripple-color="light"
                className="bg-[#ECECEC] py-3 px-5 text-dark"
                onClick={()=>{
                  const textToCopy = document.getElementById('copy-target')?.innerText;

                  // Create a temporary textarea element
                  const textarea:any = document.createElement('textarea');
                  textarea.value = textToCopy;
                  document.body.appendChild(textarea);
            
                  // Select and copy the text
                  textarea.select();
                  document.execCommand('copy');
            
                  // Remove the temporary textarea
                  document.body.removeChild(textarea);
            
                  // Alert the user or perform any other action
                  alert('copied to clipboard!');
                }}
              >
                copy
              </button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ShareModal;
