
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { auth, db } from '../firebase';
import { useEffect, useRef } from 'react';

const LiveChat = () => {

    const tawkMessengerRef = useRef(null);
    const fetchUserData = async () => {
        try {
            // Assuming you're using Firebase authentication
            const user = auth.currentUser;
            if (user) {
                const userDoc = await db.collection('users').doc(user.uid).get();
                if (userDoc.exists) {
                    const userData = userDoc.data();
                    if (tawkMessengerRef.current && tawkMessengerRef.current.setAttributes) {
                        tawkMessengerRef.current.setAttributes({
                            name: userData.name,
                            email: userData.email
                        });
                       
                    }
                }
            }
           
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
    useEffect(() => {
        fetchUserData();
    }, []);

    const onLoad = () => {
        tawkMessengerRef.current.maximize();
    };
    if(tawkMessengerRef){
    return (
        <div>
            <TawkMessengerReact
            propertyId="65e043329131ed19d97326e4"
            widgetId="1hnq0d0gf"
            onLoad={onLoad}
                ref={tawkMessengerRef}/>
        </div>
    );
}else{
    fetchUserData();
}
}
export default LiveChat;