import { Avatar, TextField } from "@mui/material";
import { serverTimestamp } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import styles from "./RecentChat.module.css";
import quotebox from "../pages/MyQuote.module.css";
const SelectQuote = ({ onClose }) => {
   const navigate = useNavigate();
  let {id} = useParams();
   const [draft_list, setDraft]=useState([]);
useEffect(() => {
  if (auth.currentUser) {
    db.collection('my_draft/' + auth.currentUser.uid + '/list/').onSnapshot(snapshot => {
      let cald = [];
      snapshot.forEach(snap => {
        const data = snap.data();
        const currentTime = new Date()
        const { jdate, jtime } = data;
        const dateTime = new Date(`${jdate}T${jtime}`);
        const id = snap.id;
        cald.push({ ...data, id }); 
      });
      setDraft(cald);
    });
  }
}, []);

  const onSend=(qkey,q_id)=>{
      var date = new Date();
       var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  db.collection('messages/'+auth.currentUser.uid+'/'+id).add({
      senderid: 'qoute',
      time: strTime,
      stamp: serverTimestamp(),
      qkey:qkey,
      q_id:q_id
    })
     db.collection('cust_messages/'+id+'/'+auth.currentUser.uid).add({
      senderid: 'qoute',
      time: strTime,
      stamp: serverTimestamp(),
      qkey:qkey,
      q_id:q_id
    })
     db.collection('my_draft/'+auth.currentUser.uid+'/list/').doc(q_id).update({
      status:'pend'
     })
    let key = db.collection("tmp").doc().id
    db.collection('users').doc(auth.currentUser.uid).get().then(snaps=> {
      db.collection('cust_contacts/'+id+'/my/').where('uid','==',auth.currentUser.uid).get().then(snapshot=>{
        if(snapshot.empty)
        {
          db.collection('cust_contacts/'+id+'/my/').doc(key).set({
      uid:auth.currentUser.uid,
      unix: Date.now(),
      timestamp: serverTimestamp(),
      name: snaps.data().name,
      key: key
    }) 
     }
     else
        {
         snapshot.forEach((snap) => {
        const key_id = snap.data().key;
        db.collection(`cust_contacts/${id}/my`).doc(key_id).update({
          unix: Date.now(),
          timestamp: serverTimestamp(),
          name: snaps.data().name
        });
      });
        }
      })
      })
      onClose()
  }
  return (
    <div className={styles.recentchat}>
      <div className={styles.modalTitle}>
        <div className={styles.jobDetails}>Select Quote</div>
        
      </div>
      <div className={styles.searchContacts}>
        <TextField
          className={styles.input}
          fullWidth
          color="primary"
          variant="outlined"
          type="text"
          placeholder="Search"
          size="medium"
          margin="none"
        />
        <div className={styles.contactsParent}>
          {
        draft_list.map((data)=>{
          return(
           <div className={quotebox.quote3} 
           style={{cursor:'pointer'}} onClick={()=>onSend(data.qkey, data.id)}>
                  <div className={quotebox.jobs}>
                    <div className={quotebox.mergeParent}>
                      <div className={quotebox.merge}>
                        <div className={quotebox.citiessummary}>
                          <span>{`Quote No: `}</span>
                          <span className={quotebox.span}>{data.qkey}</span>
                        </div>
                        <div className={quotebox.transactionPills}>
                          <div className={quotebox.success4}>SUCCESS</div>
                        </div>
                      </div>
                      {/*<img
                        className={quotebox.moreVerticalIcon}
                        alt=""
                        src="/iconsmore-vertical1.svg"
          />*/}
                    </div>
                    <div className={quotebox.container}>
                      <div className={quotebox.profileWrapper}>
                        <div className={quotebox.profile}>
                          <Avatar
                          />
                          <div className={quotebox.merge1}>
                            <div className={quotebox.peoplename6}>
                             {data.cname}
                            </div>
                            <div className={quotebox.dateTime}>
                              <div className={quotebox.date}>{data.jdate}</div>
                              <div className={quotebox.dateTimeChild} />
                              <div className={quotebox.date}>{data.jtime}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={quotebox.div}>£{data.total}</div>
                    </div>
                  </div>
                </div>
            
          )
        })
       }
       <div style={{display:'flex',flexDirection:'row',justifyContent:'right',width:'100%'}}>
 <button
                className={quotebox.createqoute}
                onClick={()=>{navigate('/createquote/new')}}
              >
                <div className={quotebox.iconsaddParent}>
                  <img
                    className={quotebox.iconsadd}
                    alt=""
                    src="/iconsadd11.svg"
                  />
                  <div className={quotebox.button}>Create Quote</div>
                </div>
              </button>
       </div>
          
         
        </div>
      </div>
    </div>
  );
};

export default SelectQuote;
