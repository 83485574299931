import { useCallback, useEffect, useState } from "react";
import styles from "./PreviewModal.module.css";
import { auth, db } from "../firebase";
import { Avatar } from "@mui/material";

const PreviewModal = ({ onClose,tags ,type,
  image,
  time,
  date,
  disc,
  type_offer,
  book,
  spend,
  cust}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [name, setName] = useState()
useEffect(() => {
  db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
    if(snapshot.data())
    {
      setName(snapshot.data().name)
      setProfileImage(snapshot.data().profileImage);
    }
  });
  const galleryRef = db.collection('users')
  .doc(auth.currentUser.uid)
  .collection('gallery');

const unsubscribe = galleryRef.onSnapshot((snapshot) => {
  const images = [];
  snapshot.forEach((doc) => {
    images.push(doc.data().imageUrl);
  });
   if (images.length > 0) {
    setImagePreview(images[0]);
  }
});

// Unsubscribe when the component unmounts
return () => {
  unsubscribe();
};
}, [])
function calculateTimeRemaining(targetDate) {
  // Parse the target date string to create a Date object
  const targetDateTime = new Date(targetDate);

  // Get the current date and time
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = targetDateTime - now;

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  // Format the result
  const formattedTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;

  return formattedTime;
}
function truncateText(text) {
  if (text.length <= 40) {
    return text; // No need to truncate if it's shorter than the max length
  } else {
    return text.slice(0, 40) + "..."; // Truncate and add ellipsis
  }
}

  return (
    <div className={styles.previewmodal}>
      <div className={styles.modalTitle}>
        <div className={styles.addANewCardParent}>
          <div className={styles.addANew}>Preview</div>
          <img
            className={styles.iconsclose}
            alt=""
            src="/iconsclose1.svg"
            onClick={onClose}
          />
        </div>
      </div>
      <div className={styles.dealOfTheDay}>
        <img
          className={styles.dealOfTheDayChild}
          alt=""
          src={image}
        />
        <div className={styles.frameParent}>
          <div className={styles.frameGroup}>
            <div className={styles.groupParent}>
             
              {tags.map((tag, index) => (
          <div className={styles.rectangleParent}>
                <div className={styles.groupChild} />
                <div className={styles.serviceType}>{tag}</div>
              </div>
        ))}
            </div>
            <div className={styles.offerParent}>
              <div className={styles.offer}>
              {
               type_offer=='perc'?
               truncateText(`Get ${disc}% off when you book us`)
               :type_offer=='amount'?
               truncateText(`Get ${disc}£ off when you book us`)
               :type_offer=='spend'?
               truncateText(`Get £${disc} off when you spend £${spend}`)
               :type_offer=='spend%'?
               truncateText(`Get ${disc}% off when you spend £${spend}`)
               :type_offer=='book'?
               truncateText(`Get £${disc} off when you book us for ${book}`)
                :type_offer=='book%'?
                truncateText(`Get ${disc}% off when you book us for ${book}`)
               :
               truncateText(cust)
              }
              
              </div>
              <div className={styles.countdownParent}>
                <div className={styles.countdown}>Ends in:</div>
                <div className={styles.countdown} style={{color:'red'}}>{type!='Deal of the day'?calculateTimeRemaining(`${date}T${time}:00`): '24h:00m:00s'}</div>
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.colWrapper}>
                <div className={styles.col}>
                  <div className={styles.avatarName}>
                    <Avatar
                      className={styles.avatarIcon}
                      alt=""
                      src={profileImage}
                    />
                    <div className={styles.businessName}>{name}</div>
                  </div>
                  <div className={styles.ratings}>
                    <div className={styles.starRating}>
                      <img
                        className={styles.icons16pxstar}
                        alt=""
                        src="/icons16pxstar6.svg"
                      />
                      <img
                        className={styles.icons16pxstar}
                        alt=""
                        src="/icons16pxstar6.svg"
                      />
                      <img
                        className={styles.icons16pxstar}
                        alt=""
                        src="/icons16pxstar6.svg"
                      />
                      <img
                        className={styles.icons16pxstar3}
                        alt=""
                        src="/icons16pxstar7.svg"
                      />
                      <img
                        className={styles.icons16pxstar}
                        alt=""
                        src="/icons16pxstar8.svg"
                      />
                    </div>
                    <div className={styles.offer}>5.0 (300K)</div>
                  </div>
                </div>
              </div>
              <div className={styles.offerWrapper}>
                <div className={styles.offer}>Job Sub-category</div>
              </div>
            </div>
          </div>
          <div className={styles.smallButtons}>
            <div className={styles.buttonWrapper}>
              <div className={styles.button}>Request Quote</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
