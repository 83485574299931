import { useCallback, useEffect, useState } from "react";
import { Avatar, MenuItem, TextField } from "@mui/material";
import styles from "./RecentChat.module.css";
import { auth, db } from "../firebase";
const RecentChat = ({ onClose,onCaddy,onCname }) => {
  const [list, setList]= useState([])
   
  useEffect(() => {
     (async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    if (auth.currentUser) {
      let cald = [];
      
      db.collection('contacts/' + auth.currentUser.uid + '/my/')
        .orderBy('timestamp', 'desc')
        .get()
        .then(snapshot => {
          snapshot.forEach(snap => {
            const id=snap.id
            cald.push({id:id, ...snap.data()});
          });
          setList(cald);
        });
    }
  })();
  }, [])
  return (
    <div className={styles.recentchat}>
      <div className={styles.modalTitle}>
        <div className={styles.jobDetails}>Recent chat contact</div>
        <img
          className={styles.iconsclose}
          alt=""
          src="/iconsclose.svg"
          onClick={onClose}
        />
      </div>
      <div className={styles.searchContacts}>
        <TextField
          className={styles.input}
          fullWidth
          color="primary"
          variant="outlined"
          type="text"
          placeholder="Search"
          size="medium"
          margin="none"
        />
        <div className={styles.contactsParent}>
          {
        list.map(data=>{
          return(
           <MenuItem className={styles.contacts}>
            <Accounts
            id={data.uid}
            name={data.name}
            onClose={onClose} 
            onCname={onCname}
            onCaddy={onCaddy}
            />
            <img className={styles.checkboxIcon} alt="" src="/checkbox.svg" />
          </MenuItem>
          )
        })
      }
        </div>
      </div>
    </div>
  );
};

export default RecentChat;

const Accounts=({id, name,onCaddy,onClose,onCname})=>{
  const [profile,setProfile]=useState('')
  const [addy,setAddy]=useState('')
useEffect(() => {
  db.collection('customers').doc(id).get().then(function (snap) {
    setProfile(snap.data()?.profileImage)
    setAddy(snap.data()?.address)

  })
}, [])

  return(
<>
<div className={styles.avatarParent}
onClick={()=>{
  onCname(name)
  onCaddy(addy)
  onClose()
  
 }}>
              <Avatar className={styles.avatarIcon} alt="" src={profile||""} />
              <div className={styles.peoplename}>{name}</div>
            </div>
</>
  )};
