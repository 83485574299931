import { useCallback, useEffect, useState } from "react";
import Container from "../components/Container";
import { useNavigate } from "react-router-dom";
import styles from "./JobCat.module.css";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { auth, db } from "../firebase";
import { Alert, Chip, Snackbar, TextField } from "@mui/material";
import Highlighter from "react-highlight-words";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import posthog from 'posthog-js'

 

const JobCat = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('');
  const [sub_type, setSubType] = useState('');
  const [sugg, setSugg] = useState([])
  const [others, setOthers] = useState('')
  const [value, setValue] = useState('')
  const [categories, setCategories] = useState([]); // State to hold fetched categories
  const [alertMessage, setAlertMessage] = useState("");
  const [otherDial,setOtherDial]=useState(false)
  const [alertOpen, setAlertOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    getServiceTags(e.target.value)
  };

  const handleAddChip = () => {
    if (inputValue.trim() !== '') {
      setChips([...chips, inputValue.trim()]);
      setInputValue('');
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // Fetch categories from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesRef = db.collection('categories');
      const snapshot = await categoriesRef.get();
      const categoriesData = snapshot.docs
      .filter((doc) => {
        const data = doc.id;
        return data === "Cleaning" || data === "Tradesmen";
      }).map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesData);
    };
    fetchCategories();
  }, []);
  const handleChange = (event) => {
    setType(event.target.value);
    // Reset sub_type when the main category changes
    setSubType('');
  };

  const handleChange2 = (event) => {
    setOtherDial(true)
    setSubType(event.target.value);
  };
  const proceed = () => {
    // Check if either Type of Service or Sub Service is empty
    if (type.trim() === '' || sub_type.trim() === '') {
      setAlertMessage('Please select a Type of Service and Sub Service.');
      setAlertOpen(true);
      return;
    }
  
    let data = {
      category: type,
      sub_cate: others||sub_type,
      service_tags:chips
    };
  
    db.collection('users')
      .doc(auth.currentUser.uid)
      .update(data)
      .then(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
      
              // Replace 'YOUR_GOOGLE_API_KEY' with your actual Google API key
              const apiKey = 'AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0';
              const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
      
              try {
                const response = await fetch(apiUrl);
                const data = await response.json();
      
                if (data.status === 'OK' && data.results && data.results.length > 0) {
                  const addressComponents = data.results[0].address_components;
                  let country, state;
      
                  for (let component of addressComponents) {
                    if (component.types.includes('country')) {
                      country = component.long_name;
                    }
                    if (component.types.includes('administrative_area_level_1')) {
                      state = component.long_name;
                    }
                  }
                  posthog.capture(`Signup completed`, { category: type, 'sub-cat':others||sub_type, 
                  location: `${country} ${state}`})
                } 
              } catch (error) {
                console.error('Error fetching address:', error);
               
              }
            },
            (error) => {
              console.error(`Geolocation error: ${error.message}`);
            }
          );
        } 
        navigate('/gladhere');
      })
      .catch((error) => {
        console.error('Error updating user data:', error);
      });
  };
  
  async function getServiceTags(categ) {
    setValue(categ)
    try {
      const usersRef = db.collection('serviceTags')
  
      // Query Firestore for users
      const querySnapshot = await usersRef.get();
        const subs = [];
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        data.id = doc.id;
        if(doc.id==type){
          data.subcategories.map((subs) => subs)
          .filter((x) => {
           if (x.toLowerCase().includes(categ.toLowerCase())||x.toLowerCase()==categ.toLowerCase()) {
            subs.push(x);
           }else{
            setSugg([]);
           }
          });
        }
      });
        setSugg(subs);
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  }
  return (
    <div className={styles.jobcat}>
      <Container
        logoHeight="unset"
        logoBackgroundImage={`url("/img2@3x.png")`}
        frame66982AlignSelf="stretch"
      />
      <div className={styles.jobcatInner}>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
          <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>
            <div className={styles.jobCategoryParent}>
              <b className={styles.jobCategory}>Job Category</b>
              <div className={styles.whatTypeOf}>
                What type of service do you offer?
              </div>
            </div>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progressBarChild} />
            <div className={styles.progressBarChild} />
            <div className={styles.progressBarInner} />
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.largeInputsParent}>
              <div className={styles.largeInputs}>
                <div className={styles.label}>Type of Service</div>
                 <div style={{width:'100%'}}>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={handleChange}
        >
       {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.id}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
            </div>
              </div>
              <div className={styles.largeInputs1} style={{width:'100%'}}>
                <div className={styles.label1}>Sub Service</div>
                 <div style={{width:'100%'}}>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sub_type}
          onChange={handleChange2}
        >
       {type &&
                categories.find((category) => category.id === type)?.subcategories.map((sub) => (
                  <MenuItem key={sub} value={sub}>
                    {sub!='others'?sub:`Others ${others?`(${others})`:''}`}
                  </MenuItem>
                ))}
         
        </Select>
      </FormControl>
      {type&&sub_type=='others'&&otherDial?
    <>
    <div className="relative">
      <TextField
        type="text"
        value={value}
        onChange={(e)=>getServiceTags(e.target.value)}
        placeholder="Enter other service type"
        fullWidth
      />
      {
        value==''?
<button className="absolute inset-y-0 right-0 px-4 bg-blue-500 text-white rounded-md"
      style={{background:'white', color:'green', 
      borderColor:'green',borderStyle:'solid',borderWidth:'1.5px',margin:'5px 0px'}}
      onClick={(e)=>{
        e.preventDefault()
        setOtherDial(false)
        setOthers(value)
        }}>
        Done
      </button>
      :
      <button className="absolute inset-y-0 right-0 px-4 bg-blue-500 text-white rounded-md"
      style={{background:'green', color:'white',margin:'5px 0px'}}
      onClick={(e)=>{
        e.preventDefault()
        setOtherDial(false)
        setOthers(value)
        }}>
        Add
      </button>
      }
      
    </div>
    <div>
        {
         sugg.map((x, index) => (
          <MenuItem key={index}
          onClick={()=>{
            setValue(x)
          }}>
            <Highlighter
highlightClassName="YourHighlightClass"
searchWords={[value]}
autoEscape={true}
textToHighlight= {x}
/>
            </MenuItem> 
        ))
          }  
    </div>
    </>
    :null
    }
            </div>
              </div>
              <div className={styles.largeInputs}>
                <div className={styles.label}>Service Tag</div>
                 <div style={{width:'100%'}}>
                 <div style={{ marginTop: '10px' }}>
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={() => {
              const updatedChips = [...chips];
              updatedChips.splice(index, 1);
              setChips(updatedChips);
            }}
            style={{ margin: '4px' }}
            deleteIcon={<CloseIcon style={{color:'red'}}/>}
            variant="outlined"
          />
        ))}
      </div>
                 <div className="relative">
      <TextField
       fullWidth
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
      />
      <button className="absolute inset-y-0 right-0 px-4 bg-blue-500 text-white rounded-md"
      style={{background:'white', color:'green', 
      borderColor:'green',borderStyle:'solid',borderWidth:'1.5px',margin:'10px 10px'}}
      onClick={handleAddChip}>
      +  Add Service Tag
      </button>
    </div> 
    <br/>
   { sugg.length>0?
   <>
    <b>Suggestions</b>
    <div style={{ marginTop: '10px' }}>
        {sugg.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={() => {
              setChips([...chips, chip.trim()]);
              setSugg([])
            }}
            onClick={()=>{
              setChips([...chips, chip.trim()]);
              setSugg([])
            }}
            style={{ margin: '4px' }}
            deleteIcon={<AddIcon />}
            variant="outlined"
          />
        ))}
      </div>
    </>
    :null
    }
    
            </div>
              </div>
            </div>
            <button
              className={styles.largeButtons}
              onClick={proceed}
            >
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder61.svg"
              />
              <b className={styles.label2}>Sign Up</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder61.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCat;
