import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./OfferPromo.module.css";
const OfferPromo = () => {
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onFrameContainer8Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onFrameContainer9Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onFrameContainer10Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onUploadImageContainerClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.offerpromo}>
      <div className={styles.offerpromo1}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-66522.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account5.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.activities}>Promote profile</div>
          </div>
          <div className={styles.component102}>
            <div className={styles.icon}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
            <b className={styles.licenseAndCertification}>Promotions</b>
            <div className={styles.smallButtons}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd.svg" />
                <div className={styles.button}>Add Item</div>
              </div>
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar30.svg" />
          </div>
          <div className={styles.promotionsTabPrototypeWrapper}>
            <div className={styles.promotionsTabPrototype}>
              <div className={styles.twoTabFlatSwitch}>
                <div
                  className={styles.tab1Wrapper}
                  onClick={onFrameContainer8Click}
                >
                  <div className={styles.tab1}>New</div>
                </div>
                <div
                  className={styles.tab2Wrapper}
                  onClick={onFrameContainer9Click}
                >
                  <div className={styles.reach}>Current</div>
                </div>
                <div
                  className={styles.tab3Wrapper}
                  onClick={onFrameContainer10Click}
                >
                  <div className={styles.reach}>Past</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formsWrapper}>
            <div className={styles.forms}>
              <div className={styles.inputParent}>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>
                      What would you like to promote?
                    </div>
                  </div>
                  <div className={styles.baseField}>
                    <img
                      className={styles.iconschevronDown}
                      alt=""
                      src="/iconschevrondown1.svg"
                    />
                    <div className={styles.userInput}>An Offer</div>
                    <div className={styles.dot}>
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                    </div>
                  </div>
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
                <div className={styles.uploadImageParent}>
                  <div
                    className={styles.uploadImage}
                    onClick={onUploadImageContainerClick}
                  >
                    <div className={styles.imageFrame}>
                      <div className={styles.boundary} />
                      <div className={styles.imageArea} />
                      <img
                        className={styles.iconsadd1}
                        alt=""
                        src="/iconsadd1.svg"
                      />
                    </div>
                    <img
                      className={styles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                  </div>
                  <div className={styles.uploadAnImage}>
                    Upload an image for your Ad (145PX x 130PX Recommended)
                  </div>
                </div>
                <div className={styles.distanceSlider}>
                  <div className={styles.reach}>Reach</div>
                  <div className={styles.bgParent}>
                    <div className={styles.bg}>
                      <div className={styles.bgChild} />
                    </div>
                    <div className={styles.slider}>
                      <div className={styles.sliderChild} />
                      <div className={styles.sliderItem} />
                    </div>
                  </div>
                  <div className={styles.noOfPeopleParent}>
                    <div className={styles.home}>No of People</div>
                    <div className={styles.input1}>
                      <div className={styles.labelContainer1}>
                        <div className={styles.label}>Label</div>
                      </div>
                      <div className={styles.baseField1}>
                        <img
                          className={styles.iconsplaceholder}
                          alt=""
                          src="/iconsplaceholder31.svg"
                        />
                        <div className={styles.userInput1}>0</div>
                        <div className={styles.dot}>
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                        </div>
                      </div>
                      <div className={styles.helperTextContainer1}>
                        <div className={styles.thisIsA1}>
                          This is a helper text
                        </div>
                        <div className={styles.div}>1/100</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Offer Type</div>
                  </div>
                  <div className={styles.baseField2}>
                    <img
                      className={styles.iconschevronDown}
                      alt=""
                      src="/iconschevrondown1.svg"
                    />
                    <div className={styles.userInput}>Select an offer type</div>
                    <div className={styles.dot}>
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                    </div>
                  </div>
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Discounted Services</div>
                  </div>
                  <div className={styles.baseField2}>
                    <img
                      className={styles.iconsplaceholder}
                      alt=""
                      src="/iconsplaceholder31.svg"
                    />
                    <div className={styles.userInput}>
                      Eg. Cleaning, repair, maintenance etc.
                    </div>
                    <div className={styles.dot}>
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                      <div className={styles.dotChild} />
                    </div>
                  </div>
                  <div className={styles.helperTextContainer3}>
                    <div className={styles.thisIsA}>
                      To add a service, simply hit the enter key after typing
                      it.
                    </div>
                    <div className={styles.div3}>1/100</div>
                  </div>
                </div>
                <div className={styles.inputGroup}>
                  <div className={styles.input4}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>Expiry Time</div>
                    </div>
                    <div className={styles.baseField}>
                      <img
                        className={styles.iconschevronDown}
                        alt=""
                        src="/iconsclock1.svg"
                      />
                      <div className={styles.userInput4}>Profile</div>
                      <div className={styles.dot}>
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                      </div>
                    </div>
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                  <div className={styles.input4}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>Expiry Date</div>
                    </div>
                    <div className={styles.baseField}>
                      <img
                        className={styles.iconschevronDown}
                        alt=""
                        src="/iconscalendar1.svg"
                      />
                      <div className={styles.userInput4}>Profile</div>
                      <div className={styles.dot}>
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                      </div>
                    </div>
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frameParent}>
                <div className={styles.largeButtonsParent}>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                    <b className={styles.uploadAnImage}>Preview Offer</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                  </div>
                  <div className={styles.previewBeforePromotion}>
                    Preview before promotion
                  </div>
                </div>
                <div className={styles.largeButtons1}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder1.svg"
                  />
                  <b className={styles.uploadAnImage}>Promote offer</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.signup}>
        <div className={styles.titles1}>
          <div className={styles.activities1}>Promote profile</div>
          <div className={styles.back2}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.button1}>
            <div className={styles.iconsaddParent}>
              <img className={styles.edit2Icon} alt="" src="/edit21.svg" />
              <div className={styles.edit}>Edit</div>
            </div>
          </div>
        </div>
        <div className={styles.tab}>
          <div className={styles.frameGroup}>
            <div className={styles.newWrapper}>
              <div className={styles.new}>New</div>
            </div>
            <div className={styles.currentWrapper}>
              <div className={styles.current}>Current</div>
            </div>
            <div className={styles.currentWrapper}>
              <div className={styles.current}>Past</div>
            </div>
          </div>
        </div>
        <div className={styles.input6}>
          <div className={styles.inputChild} />
          <div className={styles.jackDorsey}>An Offer</div>
          <div className={styles.selectOption}>
            What would you like to promote?
          </div>
          <img className={styles.chevronUpIcon} alt="" src="/chevronup1.svg" />
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <div className={styles.uploadParent}>
            <img className={styles.uploadIcon} alt="" src="/upload.svg" />
            <div className={styles.x130}>145 X 130</div>
          </div>
          <div className={styles.uploadAnImage1}>
            Upload an image for your Ad
          </div>
        </div>
        <div className={styles.input6}>
          <div className={styles.email}>
            <div className={styles.emailChild} />
            <div className={styles.jackDorsey1}>Jack Dorsey</div>
          </div>
          <div className={styles.selectOption}>Location</div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.input8}>
            <div className={styles.email}>
              <div className={styles.emailChild} />
              <div className={styles.jackDorsey2}>Discount %</div>
            </div>
            <div className={styles.selectOption}>Offer Type</div>
            <img
              className={styles.icondeviceaccessTime}
              alt=""
              src="/icondeviceaccess-time.svg"
            />
            <img
              className={styles.chevronUpIcon1}
              alt=""
              src="/chevronup.svg"
            />
          </div>
          <div className={styles.input8}>
            <div className={styles.email}>
              <div className={styles.emailChild} />
              <div className={styles.jackDorsey2}>Jack Dorsey</div>
              <div className={styles.div6}>%</div>
            </div>
            <div className={styles.selectOption}>Discount</div>
          </div>
        </div>
        <div className={styles.input6}>
          <div className={styles.email}>
            <div className={styles.emailChild} />
            <div className={styles.jackDorsey1}>Jack Dorsey</div>
          </div>
          <div className={styles.selectOption}>Discounted Services</div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.input8}>
            <div className={styles.email}>
              <div className={styles.emailChild} />
              <div className={styles.jackDorsey5}>Jack Dorsey</div>
            </div>
            <div className={styles.selectOption}>Expiry Date</div>
            <img className={styles.calendarIcon} alt="" src="/calendar.svg" />
          </div>
          <div className={styles.input8}>
            <div className={styles.email}>
              <div className={styles.emailChild} />
              <div className={styles.jackDorsey5}>Jack Dorsey</div>
            </div>
            <div className={styles.selectOption}>Expiry Time</div>
            <img className={styles.calendarIcon} alt="" src="/clock.svg" />
          </div>
        </div>
        <div className={styles.button2}>
          <div className={styles.iconsParent}>
            <img className={styles.icons} alt="" src="/icons.svg" />
            <b className={styles.proceed}>Preview Offer</b>
          </div>
        </div>
        <div className={styles.previewBeforePromoting}>
          Preview before promoting
        </div>
        <div className={styles.button3}>
          <div className={styles.iconsParent}>
            <img className={styles.icons} alt="" src="/icons1.svg" />
            <b className={styles.proceed}>Promote Offer</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPromo;
