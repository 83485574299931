import styles from "./GroupComponent.module.css";
const GroupComponent = () => {
  return (
    <div className={styles.groupParent}>
      <div className={styles.rectangleParent}>
        <img
          className={styles.groupChild}
          alt=""
          src="/rectangle-121371@2x.png"
        />
        <b className={styles.smithsConstruction}>Smith’s Construction</b>
        <div className={styles.templeWayWitham}>
          63 Temple Way, Witham On The Hill
        </div>
        <div className={styles.div}>+449030445766</div>
        <a
          className={styles.smithmarkeygmailcom}
          href="mailto:Smithmarkey@gmail.com"
          target="_blank"
        >
          Smithmarkey@gmail.com
        </a>
      </div>
      <div className={styles.invoiceNoParent}>
        <div className={styles.invoiceNo}>Invoice No</div>
        <div className={styles.dueDate}>Due Date</div>
        <div className={styles.div1}>000001</div>
        <div className={styles.date11112021}>Date: 11/11/2021</div>
        <div className={styles.div2}>11/11/2021</div>
      </div>
      <div className={styles.descriptionParent}>
        <b className={styles.description}>DESCRIPTION</b>
        <b className={styles.rate}>RATE</b>
        <b className={styles.subtotal}>SUBTOTAL</b>
        <b className={styles.discount}>DISCOUNT</b>
        <b className={styles.tax}>TAX</b>
        <b className={styles.total}>TOTAL</b>
        <b className={styles.b}>£5,355</b>
        <b className={styles.b1}>£545</b>
        <b className={styles.b2}>£268</b>
        <b className={styles.b3}>£5,355</b>
        <b className={styles.qty}>QTY</b>
        <b className={styles.discount1}>DISCOUNT</b>
        <b className={styles.tax1}>TAX</b>
        <b className={styles.amount}>AMOUNT</b>
        <div className={styles.groupItem} />
        <div className={styles.groupInner} />
        <div className={styles.component22}>
          <div className={styles.electricalInsulator}>Electrical Insulator</div>
          <div className={styles.div3}>£10</div>
          <div className={styles.div4}>10</div>
          <div className={styles.div5}>-£5</div>
          <div className={styles.div6}>5%</div>
          <div className={styles.div7}>2%</div>
          <div className={styles.div8}>£2</div>
          <div className={styles.div9}>£95</div>
          <div className={styles.component22Child} />
        </div>
        <div className={styles.component23}>
          <div className={styles.electricalInsulator}>20KVA generator</div>
          <div className={styles.div10}>£300</div>
          <div className={styles.div11}>1</div>
          <div className={styles.div12}>-£15</div>
          <div className={styles.div6}>5%</div>
          <div className={styles.div7}>2%</div>
          <div className={styles.div8}>£6</div>
          <div className={styles.div16}>£285</div>
          <div className={styles.component22Child} />
        </div>
        <div className={styles.lineDiv} />
        <div className={styles.component24}>
          <div className={styles.electricalInsulator}>Solar panels</div>
          <div className={styles.div17}>£100</div>
          <div className={styles.div18}>5</div>
          <div className={styles.div19}>-£25</div>
          <div className={styles.div6}>5%</div>
          <div className={styles.div7}>2%</div>
          <div className={styles.div22}>£10</div>
          <div className={styles.div16}>£475</div>
          <div className={styles.component22Child} />
        </div>
        <div className={styles.component25}>
          <div className={styles.electricalInsulator}>Booking fee</div>
          <div className={styles.div24}>£5000</div>
          <div className={styles.div25}>5</div>
          <div className={styles.div26}>-£500</div>
          <div className={styles.div6}>5%</div>
          <div className={styles.div7}>5%</div>
          <div className={styles.div29}>£225</div>
          <div className={styles.div30}>£5355</div>
          <div className={styles.component22Child} />
        </div>
      </div>
    </div>
  );
};

export default GroupComponent;
