import { auth, db } from "../firebase";
import styles from "./EndPromo.module.css";

const EndPromo = ({ trash = false, exclamation = true , onClose, selected}) => {
  return (
    <div className={styles.endPromo}>
      <div className={styles.vStack}>
        <div className={styles.hStack}>
          {exclamation && (
            <img
              className={styles.exclamationMark1Icon}
              alt=""
              src="/exclamationmark-1.svg"
            />
          )}
          {trash && (
            <img
              className={styles.trashLineIcon}
              alt=""
              src="/trash-line1.svg"
            />
          )}
          <div className={styles.youAreAbout}>
            Are you sure you want to end this promotion?
          </div>
        </div>
        <div className={styles.ctas}>
          <div className={styles.button} onClick={onClose}>
            <div className={styles.iconsParent}>
              <img className={styles.icons} alt="" src="/icons2.svg" />
              <b className={styles.proceed}>Close</b>
            </div>
          </div>
          <div className={styles.button1} onClick={()=>{
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 1);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            db.collection('my_promo/'+auth.currentUser.uid+'/list').doc(selected).update({
              date:formattedDate
          })
          db.collection('promo/').doc(selected).update({
            date:formattedDate
        })
          onClose()
          }}>
            <div className={styles.iconsParent}>
              <img className={styles.icons} alt="" src="/icons3.svg" />
              <b className={styles.proceed}>End promotion</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndPromo;
