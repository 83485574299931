import { useCallback, useEffect, useState } from "react";
import Container from "../components/Container";
import { useNavigate } from "react-router-dom";
import styles from "./GladHere.module.css";
import { auth, db } from "../firebase";
const GladHere = () => {
  const navigate = useNavigate();
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [address, setAddress] = useState('');

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
       setLat(position.coords.latitude)
      setLng(position.coords.longitude)
      getLocationAddress(position.coords.latitude, position.coords.longitude);
    });
  }, [])
  
  const onSkipForNowClick = () => {
    db.collection('users').doc(auth.currentUser.uid).update({
      addy1: address,
      lat: lat,
      lng: lng,
      subType: 0,
      distance: 5
    })
    navigate("/home");
  };
  const onVerifyAc = () => {
    db.collection('users').doc(auth.currentUser.uid).update({
      addy1: address,
      lat: lat,
      lng: lng,
      subType: 0,
      distance: 5
    })
    navigate("/profile");
  };
  const getLocationAddress = async (lat, lng) => {
    try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDrIc0MDLHaoFOaSLFKPRcGG-bM3Xpt-w0`
        );
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const fullAddress = data.results[0].formatted_address;
          setAddress(fullAddress)
          console.log(fullAddress);
          // Do something with the full address (e.g., store it in state or display it)
        } else {
          console.log('No address found for the given coordinates.');
        }
      } catch (error) {
        console.error('Error fetching address:', error);
      }
    };

  return (
    <div className={styles.gladhere}>
      <Container />
      <div className={styles.gladhereInner}>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.weAreGladYoureHereParent}>
              <b className={styles.weAreGlad}>We are glad you’re here.</b>
              <div className={styles.youWillNeedContainer}>
                <p className={styles.youWillNeed}>
                  You will need to upload some documents
                </p>
                <p className={styles.youWillNeed}>
                  to verify you as a Service provider?
                </p>
              </div>
            </div>
          </div>
          <img className={styles.hiFiveIcon} alt="" src="/hifive.svg" />
          <div className={styles.largeButtonsParent}>
            <button className={styles.largeButtons} onClick={onVerifyAc}>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder61.svg"
              />
              <b className={styles.label}>Verify Profile Now</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder61.svg"
              />
            </button>
            <div className={styles.skipForNow} onClick={onSkipForNowClick}>
              Skip for now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GladHere;
