import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./RescheduleJobContainer.module.css";
import { auth, db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import posthog from 'posthog-js'

 


const RescheduleJobContainer = ({onClose,selected,cid}) => {
  const navigate = useNavigate();
  const[newdate,setNewDate]=useState('')
  function formatCurrentDate() {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour < 12 ? 'am' : 'pm';
    const formattedTime = `${hour % 12 || 12}:${minute.toString().padStart(2, '0')}${period}`;
  
    return formattedDate ;
  }
  
  const formattedCurrentDate = formatCurrentDate();
  
  const handleClick=()=>{
    db.collection('cust_booking/'+cid+'/list').doc(selected).update({
      'qoute.jdate':newdate
    })
    db.collection('prof_booking/'+auth.currentUser?.uid+'/list').doc(selected).update({
      'qoute.jdate':newdate
    })
    db.collection('bookings/').doc(selected).update({
      'qoute.jdate':newdate
    })
    db.collection('users/').doc(auth.currentUser?.uid).get().then(function (snap) {
      db.collection('activity/cust/'+cid).add({
        uid:cid,
        type:'booking',
        msg:'Your job was rescheduled by',
        date: formattedCurrentDate,
        name: snap.data().name,
        unix: Date.now(),
        stamp:serverTimestamp()
      })
    })
    db.collection('customers').doc(cid).get().then(function (snap) {
      db.collection('bookings/').doc(selected).get().then(function (data) {
        db.collection('customers').doc(cid).get().then(function (customer) {
          posthog.capture(`Job Rescheduled`,{'customers name': snap.data().name, 
          Service: snap.data().category, location: data.data().qoute.jloc, 'job date': data.data().jdate,
           QuoteNum:data.data().qid, 'job desc':data.data().jdesc})
          if (customer.data().emailNotifications) {
            fetch('https://loggworks-c15c9e56f169.herokuapp.com/prof_booking_resch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: snap.data().email,
        name: snap.data().name,
        quote: data.data().qid,
        location:data.data().qoute.jloc,
        schedule:data.data().qoute.jdate,
        service:snap.data().category
      }),
    })
          }
        })

      })
    })
    db.collection('customers').doc(cid).get().then(function (customer) {
      if (customer.data().pushNotifications) {
         db.collection('customers').doc(cid).collection('noti_act').add({
      last: 'Your booking has been rescheduled',
      name: '',
     })
      }
    })
    
    onClose()
  }
  return (
    <div className={styles.rescheduleJob}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>Reschedule job</div>
            <img className={styles.iconsclose} alt="" src="/iconsclose.svg"  style={{cursor:'pointer'}} onClick={onClose} />
          </div>
        </div>
        <div className={styles.inputParent}>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>New job date</div>
            </div>
            <input className={styles.baseField} type="date" 
             onChange={(e)=>setNewDate(e.target.value)}
             min={new Date().toISOString().split('T')[0]}
             />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Reason</div>
            </div>
            <textarea className={styles.baseField1} />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <button className={styles.largeButtons} onClick={handleClick}>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder.svg"
            />
            <b className={styles.label2}>Reschedule</b>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder.svg"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RescheduleJobContainer;
