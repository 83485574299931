import { useCallback, useState } from "react";
import styles from "./AddItem.module.css";
import Items from "../pages/Items";
import BookingFee from "./BookingFee";
import BookingFeeMic from "./BookingFeeMel";
const AddItem = ({ onClose, onOservice,
  onOitem,onOmic, tool_desc,
  unit_cost,
  quant,
  rate,
  tool_disc_t,
  tool_disc,
  tool_tax_t,
  tool_tax,
  tool_detail,
  onTool_desc,
  onUnit_cost,
  onQuant,
  onTool_disc_t,
  onTool_disc,
  onTool_tax_t,
  onTool_tax,
  onTool_detail,
  onServices,
  onRate,
    tools,onBack,onTools,onServices2}) => {
  const [tab, setTab] = useState(0)
  const onIconsCloseClick = useCallback(() => {
   onClose()
  }, []);

  return (
    <>
    {
      tab==0?
       <div className={styles.additem}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>Add Item</div>
            <img
              className={styles.iconsclose}
              alt=""
              src="/iconsclose.svg"
              onClick={onIconsCloseClick}
            />
          </div>
        </div>
        <div className={styles.listParent}>
          <div className={styles.list}  onClick={(onOitem)}>
            <div className={styles.content}>
              <div className={styles.left} onClick={onOitem}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Tools and Materials</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight}
                  alt=""
                  src="/iconschevronright1.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.list2} 
          style={{cursor:'pointer'}}
          onClick={()=>setTab(2)}>
            <div className={styles.content}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg1.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Service Fee</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight}
                  alt=""
                  src="/iconschevronright1.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.list4}>
            <div className={styles.content}
             style={{cursor:'pointer'}}
             onClick={()=>setTab(3)}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg2.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Miscellaneous</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight}
                  alt=""
                  src="/iconschevronright1.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    :tab==1?
    <Items
    tool_desc={tool_desc}
unit_cost={unit_cost}
quant={quant}
tool_disc_t={tool_disc_t}
tool_disc={tool_disc}
tool_tax_t={tool_tax_t}
tool_tax={tool_tax}
tool_detail={tool_detail}
tools={tools}
onTool_desc={onTool_desc}
onUnit_cost={onUnit_cost}
onQuant={onQuant}
onTool_disc_t={onTool_disc_t}
onTool_disc={onTool_disc}
onTool_tax_t={onTool_tax_t}
onTool_tax={onTool_tax}
onTool_detail={onTool_detail}
onTools={onTools}
onBack= {onBack}
  />
    :tab==2?
    <BookingFee 
    onClose={()=>setTab(0)} 
    tool_desc={tool_desc}
    unit_cost={unit_cost}
    quant={quant}
    tool_disc_t={tool_disc_t}
    tool_disc={tool_disc}
    tool_tax_t={tool_tax_t}
    tool_tax={tool_tax}
    tool_detail={tool_detail}
    tools={tools}
    rate={rate}
    onTool_desc={onTool_desc}
    onUnit_cost={onUnit_cost}
    onQuant={onQuant}
    onTool_disc_t={onTool_disc_t}
    onTool_disc={onTool_disc}
    onTool_tax_t={onTool_tax_t}
    onTool_tax={onTool_tax}
    onTool_detail={onTool_detail}
    onServices={onServices}
    onRate={onRate}/>
    :
    <BookingFeeMic onClose={()=>setTab(0)} 
         tool_desc={tool_desc}
         unit_cost={unit_cost}
         quant={quant}
         tool_disc_t={tool_disc_t}
         tool_disc={tool_disc}
         tool_tax_t={tool_tax_t}
         tool_tax={tool_tax}
         tool_detail={tool_detail}
         tools={tools}
         rate={rate}
         onTool_desc={onTool_desc}
         onUnit_cost={onUnit_cost}
         onQuant={onQuant}
         onTool_disc_t={onTool_disc_t}
         onTool_disc={onTool_disc}
         onTool_tax_t={onTool_tax_t}
         onTool_tax={onTool_tax}
         onTool_detail={onTool_detail}
         onServices={onServices2}
         onRate={onRate}/>
    }
    </>
    
   
  );
};

export default AddItem;
