import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Qa.module.css";
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import PortalPopup from "../components/PortalPopup";
import QAModal from "../components/QAModal";
import { auth, db } from "../firebase";
import moment from "moment";
const Qa = ({onActivity}) => {
  const navigate = useNavigate();
  const [tab, setTab]= useState(0)
  const [isLocationModalPopupOpen, setLocationModalPopupOpen] = useState(false);
  const [qahistory,setQahistory] = useState([])
  const [newqa,setNewQa] = useState([])

  useEffect(() => {
    const unsubscribe1 = db.collection('myqa/' + auth.currentUser.uid + '/list')
      .where('ans', '==', '')
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setNewQa(data);
      });
  
    const unsubscribe2 = db.collection('myqa/' + auth.currentUser.uid + '/list')
      .where('ans', '!=', '')
      .onSnapshot((snapshot) => {
        const data2 = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setQahistory(data2);
      });
  
    // Return cleanup functions to unsubscribe when the component unmounts
    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  }, []);
  
  

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDefaultContainer5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onFrameContainer9Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);
  const [selectedQ,setSelectedQ]=useState('')
  const [selectkey,setKey]=useState('')
  const openLocationModalPopup =() => {
    setLocationModalPopupOpen(true);
  }

  const closeLocationModalPopup = () => {
    setLocationModalPopupOpen(false);
  }
  return (
    <div className={styles.qa}>
      <div className={styles.qa1}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default2}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity11.svg"
                  />
                  <div className={styles.peoplename}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer5Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back} onClick={()=>navigate(-1)}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.activities}>{`Question & Answer`}</div>
          </div>
          
          <div className={styles.frameWrapper}>
            <div className={styles.activitiesTabParent}>
            <div className={styles.component102}>
            <b className={styles.licenseAndCertification}>Activities</b>
            <div className={styles.smallButtons} style={{cursor:'pointer'}} 
            onClick={()=>{
              setKey('')
              setSelectedQ('')
              openLocationModalPopup()}}>
              <div className={styles.edit2Parent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd.svg" />
                <div className={styles.button1}>{`Add Q & A`}</div>
              </div>
            </div>
          </div>
              <div className={styles.activitiesTab}>
                <div className={styles.twoTabSwitch}>
                  <div
                    className={styles.notificationsWrapper}
                    onClick={onActivity}
                  >
                    <div className={styles.notifications}>Notifications</div>
                  </div>
                  <div
                    className={styles.questionsWrapper}
                  >
                    <div className={styles.questions}>Questions</div>
                  </div>
                </div>
              </div>
              <div className={styles.activitiesTab}>
                <div className={styles.tabsSubSwitch}>
                  <div className={styles.allParent}>
                    <div className={tab==0?styles.booking1:styles.all} onClick={()=>{setTab(0)}}>
                      <div className={tab==0?styles.booking2:styles.booking}>New Questions</div>
                    </div>
                    <div className={tab==1?styles.booking1:styles.all} onClick={()=>{setTab(1)}}>
                      <div className={tab==1?styles.booking2:styles.booking}>History</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.qaParent}>
            { tab==0?
      newqa.map((data)=>{
        return(
          <div className={styles.input} 
          style={{cursor:'pointer'}}
          onClick={()=>{
            setKey(data.key)
            setSelectedQ(data.quest)
            openLocationModalPopup()}}>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              {data.name?
              <div className={styles.avatarParent}>
              <img
                className={styles.avatarIcon}
                alt=""
                src={data.profile}
              />
              <div className={styles.peoplename}>{data.name}</div>
            </div>
            :null
            }
              
              <div className={styles.time}>{moment(data.unix).fromNow()}</div>
            </div>
            <div className={styles.howDoYou}>
             {data.quest}
            </div>
          </div>
        </div>
        );})
        :
        qahistory.map((data)=>{
          return(
            <>
            <div className={styles.input}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                {data.name?
                <div className={styles.avatarParent}>
                <img
                  className={styles.avatarIcon}
                  alt=""
                  src={data.profile}
                />
                <div className={styles.peoplename}>{data.name}</div>
              </div>
              :null
              }
                
                <div className={styles.time}>{moment(data.unix).fromNow()}</div>
              </div>
              <div className={styles.howDoYou}>
               {data.quest}
              </div>
            </div>
          </div>
          <div className={styles.answer}>
              <div className={styles.input}>
                <div className={styles.answer1}>
                  {data.ans}
                </div>
              </div>
            </div>
            </>
            
          );})
      }
          </div>
        </div>
        <Fab size="medium" color="primary" aria-label="add" 
      style={{background:'green', position:'absolute',right:'5%',top:'80%'}}
      className={styles.Fab}
      onClick={()=>{
        setSelectedQ('')
        setKey('')
        openLocationModalPopup()}}
      >
        <AddIcon />
      </Fab>
      </div>
      {isLocationModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLocationModalPopup}
        >
          <QAModal onClose={closeLocationModalPopup} selectedQ={selectedQ} selectkey={selectkey}/>
        </PortalPopup>
      )}
    </div>
  );
};

export default Qa;
