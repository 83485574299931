import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ResetPassword.module.css";
import app, { auth,firebase } from "../firebase";
import PhoneInput from "react-phone-input-2";
import PinInput from "react-pin-input";
const ResetPasswordPhone = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [final, setfinal] = useState("");
  const [sent, setSent] = useState(false);
  const recaptchaRef = useRef(null);
  const [otp, setOtp] = useState('')
  const [error ,setError]= useState('')
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const ValidateOtp = (e) => {
    e.preventDefault();
          if (otp === null || final === null)
              return;
          final.confirm(otp).then((result) => {
            navigate('/confirm-password-phone')
          }).catch((err) => {
            console.log(otp)
              setError("Wrong 2fa code, please try again.");
          })
      }
  
    useEffect(() => {  
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        // You can provide optional parameters here
        size: "invisible", // "normal" or "compact"
        callback: () => {
          // This callback will be triggered when the reCAPTCHA verification is successful
        },
        "expired-callback": () => {
          // This callback will be triggered when the reCAPTCHA verification expires
        },
      });
    
      // Assign the RecaptchaVerifier instance to the ref
      recaptchaRef.current = recaptchaVerifier;
    }, [])

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleOnChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: event,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var phone =formData.phone
    if (phone === "" || phone.length < 10) {
      setAlertMessage("Please enter a valid phone number");
      setAlertOpen(true);
      return;
    }
    const phoneNo = "+" + formData.phone;
    app
      .auth()
      .signInWithPhoneNumber(phoneNo, recaptchaRef.current)
      .then((result) => {
        setfinal(result);
        setSent(true);
      })
      .catch((err) => {
        console.log(err.message);
        setError('Unable to send OTP please try again later')
        // Handle error, such as displaying an error message to the user
      });
    
  };

  const onBackToLoginClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.resetpassword}>
      {
        !sent?
        <>
       <div className={styles.logo}>
        <img className={styles.logoChild} alt="" src="/group-66524.svg" />
        <img className={styles.logoItem} alt="" src="/group-66525.svg" />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.frameGroup}>
            <div className={styles.resetPasswordParent}>
              <b className={styles.resetPassword}>Reset Password</b>
              <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>

              <div className={styles.pleaseEnterYour}>
                Please enter your email address and we will send you a password
                reset link
              </div>
            </div>
            <div className={styles.verifyForm}>
            <PhoneInput
                      country={"gb"}
                      value={formData.phone}
                      onChange={handleOnChange}
                      inputStyle={{
                        width: "100%",
                        height: "48px",
                        border: "1px solid var(--grey-70, #DFDFDF)",
                      }}
                      inputProps={{
                        name: "phone",
                      }}
                    />
              <button
                className={styles.largeButtons}
                onClick={handleSubmit}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder41.svg"
                />
                <b className={styles.label}>Send reset code</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder41.svg"
                />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.backToLogin} onClick={onBackToLoginClick}>
          Back to Login
        </div>
      </div>
      <div className={styles.privacyPolicy}
       style={{cursor:'pointer'}}
       onClick={()=>{window.open('http://www.loggworks.co.uk/privacy', '_blank');}}>Privacy policy</div>
      </>
      :
      <div className="right flex flex-col formInput px-5 md:w-4/12 md:mx-auto md:my-auto ">
      <div className="md:hidden mobile_nav absolute top-10">
       <img
         src={"/assets/icons/navBack.svg"}
         width={24}
         height={24}
         alt=""
       />
     </div>
     <h1 className="max-sm:mt-20 form_header text-center text-3xl font-bold text-greyDark ">
Verify phone number
     </h1>
     <div className="">
       <form action="" onSubmit={ValidateOtp}>
         <h3 className="text-pColor my-1 text-center">
           Enter the verification number sent to
         </h3>

         <div className="user_phone flex justify-center items-center gap-2">
         <h3 className="text-center">+{formData.phone}</h3>
           <div
             className="text-green cursor-pointer capitalize"
             onClick={() => {setSent(false)}}
           >
             edit
           </div>
         </div>
         <div className="mt-10">
           <div className="flex justify-center items-center mb-3">
                <PinInput
length={6} 
initialValue="" 
onChange={(value, index) => {setOtp(value)}} 
style={{padding: '10px'}}  
inputStyle={{borderColor: `${error!='' ? 'red' : 'var(--grey-70, #cccccc)'}`,}}
inputFocusStyle={{borderColor: 'blue'}}
autoSelect={true}
/>
           </div>
<p style={{color:'red',textAlign:'left'}}>{error}</p>
           <div className="resendCount">
             <h3 className="text-right">
              {/* {timer}
               <span>s</span>*/}
             </h3>
           </div>
         </div>

         <h4 className="flex flex-col max-sm:mt-10 mb-8 justify-center items-center">
           Didn’t get the OTP?
           <span className="text-green ml-1" 
           onClick={()=>{
             const phoneNo = "+" + formData.phone;
             app
               .auth()
               .signInWithPhoneNumber(phoneNo, recaptchaRef.current)
               .then((result) => {
                 setfinal(result);
                 setSent(true);
                 alert('OTP code was sent successfully')
               })
               .catch((err) => {
                 console.log(err.message);
                 setError('Unable to send OTP please try again later')
                 // Handle error, such as displaying an error message to the user
               });
           }}>
             Resend OTP
           </span>{" "}
         </h4>
         <div className="md:mb-4">
         <button className="w-full h-[48px] bg-green  font-bold py-2 px-4 rounded">
               Verify OTP
             </button>
         </div>
       </form>
     </div>
     </div>
      }
     <div id="recaptcha-container"></div>
    </div>
  );
};

export default ResetPasswordPhone;
