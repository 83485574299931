import { Avatar } from "@mui/material";
import styles from "./Jobdetailscomp.module.css";
import { auth, db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Jobdetailscomp = ({uid,desc,addy,to,from,name,title,onClose}) => {
  const navigate = useNavigate()
  return (
    <div className={styles.jobdetailscomp}>
      <div className={styles.modalTopBar}>
      <img
          className={styles.evacloseOutlineIcon}
          alt=""
          src="/evacloseoutline1.svg"
          onClick={onClose}
        />
        <div className={styles.addCertificate}>Job detail</div>
      </div>
      <div className={styles.ellipseParent}>
        <Avatar className={styles.frameChild} alt="" src={''} />
        <div className={styles.crosbyOdennehy}>{name}</div>
      </div>
      <div className={styles.requestForElectricianParent}>
        <div className={styles.requestForElectrician}>
          Request for {title}
        </div>
        <div className={styles.imInNeed}>
          {desc}
        </div>
      </div>
      <div className={styles.locationParent}>
        <div className={styles.location}>Location</div>
        <div className={styles.citiessummary}>
          {addy}
        </div>
      </div>
      <div className={styles.locationParent}>
        <div className={styles.location}>Budget</div>
        <div className={styles.div}>£{from} - £{to}</div>
      </div>
      <div className={styles.button}
      onClick={()=>{
        //navigate('/log-cash-transactions17/'+id)
              var date = new Date();
     var hours = date.getHours();
var minutes = date.getMinutes();
var ampm = hours >= 12 ? 'pm' : 'am';
hours = hours % 12;
hours = hours ? hours : 12; // the hour '0' should be '12'
minutes = minutes < 10 ? '0'+minutes : minutes;
var strTime = hours + ':' + minutes + ' ' + ampm;
        const key = db.collection("tmp").doc().id
        db.collection('customers/').doc(uid).get().then(snap=> {
          db.collection('contacts/'+auth.currentUser.uid+'/my/').where('uid','==',uid).get().then(snapshot=>{
           if(snapshot.empty)
           {
              db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(key).set({
         uid:uid,
         last: '',
         unix: Date.now(),
         timestamp: serverTimestamp(),
         name: snap.data().fname+' '+snap.data().lname,
         key: key,
         desc: desc
       }) 
       
           }
           else
           {
             snapshot.forEach((prev)=>{
               db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(prev.data().key).update({
         unix: Date.now(),
         timestamp: serverTimestamp(),
         name: snap.data().fname+' '+snap.data().lname,
         desc: desc
       }) 
             })
               
           }
          })
            
         })
         localStorage.setItem('title', title);
         localStorage.setItem('desc', desc);
        navigate('/chat/'+uid)
        }}>
        <div className={styles.iconsParent}>
          <img className={styles.icons} alt="" src="/icons1.svg" />
          <b className={styles.proceed}>Send Quote</b>
        </div>
      </div>
    </div>
  );
};

export default Jobdetailscomp;
