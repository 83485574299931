import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MyPersonalInfo.module.css";
import { Alert, Button, FormControl, MenuItem, Paper, Select, Snackbar, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { auth, db } from "../firebase";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
}  from "react-places-autocomplete";
const MyPersonalInfo = () => {
  const navigate = useNavigate();
  const [ name, setName] = useState('')
  const [ email, setEmail] = useState('')
  const [ success, setSuccess] = useState(false)
  const [type, setType] = useState('');
  const [phone,setPhone] = useState(" ")
   const [org_name, setOrg] = useState('');
   const [address, setAddress] = useState('');
   const [num, setNum] = useState(0);
   const [servtype, setServType] = useState('');
   const [sub_type, setSubType] = useState('');
  const [categories, setCategories] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [city, setCity] = useState('')
  const [reg, setReg] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  function getAddressComponentValue(addressComponents, type) {
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
  
    return component ? component.long_name : '';
  }
  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const selectedResult = results[0];
      const { formatted_address, address_components, geometry } = selectedResult;

      const city = getAddressComponentValue(address_components, 'locality');
      const state = getAddressComponentValue(address_components, 'administrative_area_level_1');
      const country = getAddressComponentValue(address_components, 'country');
      const zip = getAddressComponentValue(address_components, 'postal_code');

      setCity(city);
      setReg(state);
      setCountry(country);
      setZip(zip);
      console.log(city)
    } catch (error) {
      console.error(error);
    }
  };
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);
  useEffect(() => {
   db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
    setName(snapshot.data().name)
    setPhone(snapshot.data().phone)
    setEmail(snapshot.data().email||auth.currentUser?.uid)
    setAddress(snapshot.data().address)
    setServType(snapshot.data().category)
    setType(snapshot.data().type)
    setOrg(snapshot.data().org_name)
    setNum(snapshot.data().num)
    setSubType(snapshot.data().sub_cate)
  });
  const fetchCategories = async () => {
    const categoriesRef = db.collection('categories');
    const snapshot = await categoriesRef.get();
    const categoriesData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setCategories(categoriesData);
  };
  fetchCategories();
  }, [])
  const submit =async () => {
    const usersCollection = db.collection('users');
    const querySnapshot = await usersCollection.where('org_name', '==', org_name).get();
    
    if (!querySnapshot.empty) {
      querySnapshot.docs.forEach((doc) => {
        if (doc.id !== auth.currentUser.uid) {
          setAlertMessage('The Organization Name you provided is used. Please provide a unique one.');
          setAlertOpen(true);
          return;
        }
      });
    }
    
    
    // Check if any variable is undefined and replace it with an empty string
    const data = {
      name: name || '', // Use empty string if name is undefined
      email: email || '', // Use empty string if email is undefined
      phone: phone || '', // Use empty string if phone is undefined
      category: servtype || '', // Use empty string if type is undefined
      sub_cate: sub_type || '', // Use empty string if sub_type is undefined
      type: type || '', // Use empty string if type is undefined
      org_name: org_name || '', // Use empty string if org_name is undefined
      num: num || '', // Use empty string if num is undefined
      address: address || '',
      city,
      zip,
      reg,country
    };
  
    db.collection('users')
      .doc(auth.currentUser.uid)
      .update(data)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error('Error updating Firestore document:', error);
      });
  };
  
  
  return (
    <div className={styles.personalinfo}>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-6652211.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconshome5.svg"
                />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home}>Wallet</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home}>Message</div>
              </div>
            </div>
            <div className={styles.default5}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account51.svg"
                />
                <div className={styles.personalInformation}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.titlesParent}>
        <div className={styles.titles}>
          <div className={styles.back} onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.personal}>Personal</div>
         
        </div>
        <div className={styles.component102}>
         
          <b className={styles.licenseAndCertification}>Personal</b>
          <div className={styles.icon} style={{cursor:'pointer'}}
          onClick={()=>navigate(-1)}>
            <img
              className={styles.iconshome}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.frameParent}>
            <div className={styles.personalInformationParent}>
            <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>
            <Snackbar
                    open={Boolean(success)}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    autoHideDuration={8000} 
                    onClose={() => setSuccess(false)}
                >
                    <Alert
                        onClose={() => setSuccess(false)} 
                        severity="success"
                        variant='filled'
                    >
                        Successful 
                    </Alert>
                </Snackbar>
              <div className={styles.personalInformation}>
                Personal Information
              </div>
              <div className={styles.inputParent}>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Fullname</div>
                  </div>
                  <div style={{width:'100%'}}>
                  <TextField
                    fullWidth
                    value={name}
                    onChange={(e)=>setName(e.target.value)}
                  />
                </div>
                </div>
                <div className={styles.largeInputs}>
                  <div className={styles.label1}>Phone number</div>
                  <div className={styles.field}>
                  <PhoneInput
                  country={'gb'}
                  value={phone}
                  onChange={e => setPhone(e)}
                  inputStyle={{
                    width:'100%',
                    height:'3rem'
                  }}
                />
                  </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Email Address</div>
                  </div>
                  <div style={{width:'100%'}}>
                  <TextField
                    fullWidth
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Address</div>
                  </div>
                  <div style={{width:'100%'}}>
                  <PlacesAutocomplete className={styles.baseField} value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{width:'100%'}}>
          <TextField
            {...getInputProps({
              placeholder: 'Address',
              className: 'form-control',
            })}
            
            fullWidth
          />
          <Paper className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <MenuItem key={index} {...getSuggestionItemProps(suggestion, { className })}>
                  {suggestion.description}
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.personalInformationParent}>
              <div className={styles.companyInformation}>
                Company Information
              </div>
              <div className={styles.inputParent}>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Organization Type</div>
                  </div>
                  <div style={{width:'100%'}}>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={(e)=>{setType(e.target.value)
            if(e.target.value=='Sole Proprietorship')
            {
              setNum(1)
            }
          }}
        >
          <MenuItem value={'Limited Company'}>Limited Company</MenuItem>
          <MenuItem value={'Sole Proprietorship'}>Sole Proprietorship</MenuItem>
        </Select>
      </FormControl>
            </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Organization Name</div>
                  </div>
                  <div style={{width:'100%'}}>
              <TextField
                fullWidth
                value={org_name}
                onChange={(e)=>setOrg(e.target.value)}
              />
            </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Number of employees</div>
                  </div>
                  <div style={{width:'100%'}}>
             <TextField
                fullWidth
                type={'number'}
                value={num}
                onChange={(e)=>{
                  if(type=='Sole Proprietorship')
                  {
                    setNum(1)
                  }
                  else{
                     setNum(e.target.value)
                  }
                }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onKeyPress: (e) => {
                    const isNumber = /[0-9]/.test(e.key);
                    if (!isNumber) {
                      e.preventDefault();
                    }
                  },
                }}
              />
            </div>
                </div>
              </div>
            </div>
            <div className={styles.personalInformationParent}>
              <div className={styles.jobInformation}>Job Information</div>
              <div className={styles.inputParent}>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Type of Service</div>
                  </div>
                  <div style={{width:'100%'}}>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={servtype}
          onChange={(e)=>{setServType(e.target.value)
            setSubType('');}}
        >
          {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.id}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
            </div>
                </div>
                <div className={styles.input}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Sub Service</div>
                  </div>
                  <div style={{width:'100%'}}>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sub_type}
          onChange={(e)=>setSubType(e.target.value)}
        >
      {servtype &&
                categories.find((category) => category.id === servtype)?.subcategories.map((sub) => (
                  <MenuItem key={sub} value={sub}>
                    {sub}
                  </MenuItem>
                ))}
         
        </Select>
      </FormControl>
            </div>
                </div>
              </div>
            </div>
            <div className={styles.smallButtons} style={{cursor:'pointer'}} onClick={submit}>
            <div className={styles.buttonWrapper}>
              <div className={styles.button}>Save Changes</div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPersonalInfo;
