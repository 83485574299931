import styles from "./WithdrawSucc.module.css";

const WithdrawSucc = ({onClose}) => {
  return (
    <div className={styles.withdrawsucc}>
      <div className={styles.modalTitle}>
        <div className={styles.jobDetails}>Job Details</div>
        <img className={styles.iconsclose} alt="" src="/iconsclose.svg" onClick={onClose}/>
      </div>
      <div className={styles.illustrationsParent}>
        <img
          className={styles.illustrationsIcon}
          alt=""
          src="/illustrations.svg"
        />
        <div className={styles.withdrawalSuccessfulParent}>
          <div className={styles.withdrawalSuccessful}>
            Withdrawal successful
          </div>
          <div className={styles.youWillGet}>
            You will get your funds in 4-5 days
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawSucc;
