import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NotificationSettings.module.css";
import { AppBar, FormControlLabel, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import { auth, db } from "../firebase";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const NotificationSettings = () => {
  const navigate = useNavigate();
  const [pushNotifications, setPushNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [promotionManagement, setPromotionManagement] = useState(false);

  // Retrieve notification settings from Firestore when the component mounts
  useEffect(() => {
    // Replace 'auth.currentUser.uid' with the actual UID of the user
    const userUid = auth.currentUser.uid;

    // Reference to the Firestore document for the user's notification settings
    const notificationSettingsRef = db.collection("users").doc(userUid);

    // Fetch the document and update the component state
    notificationSettingsRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setPushNotifications(data.pushNotifications || false);
        setEmailNotifications(data.emailNotifications || false);
        setPromotionManagement(data.promotionManagement || false);
      }
    });
  }, []);

  // Function to update notification settings in Firestore
  const updateNotificationSettings = (push,email,promo) => {
    console.log(push)
    // Replace 'auth.currentUser.uid' with the actual UID of the user
    const userUid = auth.currentUser.uid;

    // Reference to the Firestore document for the user's notification settings
    const notificationSettingsRef = db.collection("users").doc(userUid);

    // Update the document with the current state of the switches
    notificationSettingsRef
      .update({
        pushNotifications:push,
        emailNotifications:email,
        promotionManagement:promo,
      })
      .then(() => {
        // Update successful
        console.log("Notification settings updated in Firestore");
      })
      .catch((error) => {
        // Handle errors
        console.error("Error updating notification settings:", error);
      });
  };


  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  return (
    <div className={styles.notificationsettings}>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-66522.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconshome5.svg"
                />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home}>Wallet</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home}>Message</div>
              </div>
            </div>
            <div className={styles.default5}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account5.svg"
                />
                <div className={styles.home5}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.component102Parent}>
        <div className={styles.component102}>
          <div className={styles.smallButtons}>
            <div className={styles.iconsaddParent}>
              <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
              <div className={styles.button}>Add Item</div>
            </div>
          </div>
          <img className={styles.avatarIcon} alt="" src="/avatar8.svg" />
          <b className={styles.licenseAndCertification}>Notifications</b>
          <div className={styles.icon}
          style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
            <img
              className={styles.iconshome}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.titles}>
          <div className={styles.back} onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.notifications}>Notifications</div>
        </div>
        <div className={styles.listParent}>
          <div className={styles.list}>
            <div className={styles.content}>
              <div className={styles.left}>
               
                <div className={styles.list1}>Push Notifications</div>
              </div>
              <div className={styles.right}>
              <FormControlLabel
              onChange={() => {
                setPushNotifications(!pushNotifications)
                updateNotificationSettings(!pushNotifications,emailNotifications,promotionManagement)
              }}
             control={<IOSSwitch sx={{ m: 1 }}  
             checked={pushNotifications}
            />}
           />
              </div>
            </div>
          </div>
          <div className={styles.list}>
            <div className={styles.content}>
              <div className={styles.left}>
                <div className={styles.list1}>Email Notifications</div>
              </div>
              <div className={styles.right}>
              <FormControlLabel
              onChange={() => {
                setEmailNotifications(!emailNotifications)
                updateNotificationSettings(pushNotifications,!emailNotifications,promotionManagement)}}
             control={<IOSSwitch sx={{ m: 1 }}  
             checked={emailNotifications}
            />}
           />
              </div>
            </div>
          </div>
          <div className={styles.list4}>
            <div className={styles.content}>
              <div className={styles.left}>
                <div className={styles.list1}>Promotion Management</div>
              </div>
              <div className={styles.right}>
              <FormControlLabel
               onChange={() => {
                setPromotionManagement(!promotionManagement)
                updateNotificationSettings(pushNotifications,emailNotifications,!promotionManagement)}}
             control={<IOSSwitch sx={{ m: 1 }}  
             checked={promotionManagement}
           />}
           />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
