import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import App from "./App";
import "./global.css";
import reportWebVitals from "./reportWebVitals";
import posthog from 'posthog-js'

posthog.init('phc_6aNfl9tBeqTkMS0Jzks0Pq3MV70AaxiA6JBDfFTtQwh', { api_host: 'https://eu.posthog.com' })


const muiTheme = createTheme();
const emotionCache = createCache({ key: "emotion-cache", prepend: true });

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CacheProvider value={emotionCache}>
          <App />
        </CacheProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
