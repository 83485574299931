import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./SignIn.module.css";
import app, { firebase, auth, signInWithGoogle, db } from '../firebase';
import { TextField, IconButton, InputAdornment } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Verify from "./Verify";

const EmailVery = () => {
  const navigate = useNavigate();

  return (
    <>
       <div className={styles.signIn}>
      <div className={styles.img}>
        <img className={styles.logoIcon} alt="" src="/logo.svg" />
        <div className={styles.testimonial}>
          <div className={styles.greatSessionDaniWasSuperParent}>
            <div className={styles.greatSessionDani}>
              "Great session! Dani was super helpful. She shared some practical
              advice on how can we go about refining our service offerings."
            </div>
            <div className={styles.frameParent}>
              <div className={styles.ellipseParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="/ellipse-249@2x.png"
                />
                <div className={styles.cameronWilliamson}>
                  Cameron Williamson
                </div>
              </div>
              <div className={styles.starRating}>
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar11.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.loginformWrapper}>
      <div className={styles.emailverify}>
      <img
        className={styles.mdisuccessCircleOutlineIcon}
        alt=""
        src="/mdisuccesscircleoutline.svg"
      />
      <div className={styles.emailSuccessfullyConfirmed}>
        Email successfully confirmed.
      </div>
      <div className={styles.youWillBe}>
        You will be automatically redirected to complete your profile setup
        or...
      </div>
      <div className={styles.completeProfileSetupWrapper} onClick={()=>{navigate('/personalinfo')}}>
        <div className={styles.completeProfileSetup}>
          Complete profile setup
        </div>
      </div>
    </div>
      </div>
    </div>
    </>
   
  );
};

export default EmailVery;
