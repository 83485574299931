import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Verify.module.css";
import container from "./SignIn.module.css";
import PinInput from 'react-pin-input';
import app, { firebase,auth, db } from "../firebase";

const Verify_email = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('')
  const [error ,setError]= useState('')
  const [succ ,setSucc]= useState('')
  const {email} = useParams()

const ValidateOtp = () => {
       db.collection('otp').doc(email).get().then(function (snap) {
        console.log(snap.data()?.otp)
        if (snap.data()?.otp==String(otp)) {
          db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
            db.collection('otp').doc(email).update({
              otp:'verified'
            })
            if(!snapshot.data()?.name)
            {
              navigate('/personalinfo')
            }
            else
            {
              navigate('/home')
            }
          });
        }else{
          setError("Wrong 2fa code, please try again.");
        }
       })
    }
    function generateRandomCode() {
      // Generate a random number between 10000 and 99999 (inclusive)
      return Math.floor(100000 + Math.random() * 900000);
    }
  const resendOtp =async() => {
  const code = generateRandomCode()
  db.collection('otp').doc(email).update({
    otp:`${code}`
  })
   await fetch('https://loggworks-c15c9e56f169.herokuapp.com/verifyuser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to: email,
      name: 'user',
      code: code,
    }),
  });
  setSucc('OTP sent successfully')
  }

  return (
    <div className={styles.verify}>
      <div className={container.img}>
        <img className={container.logoIcon} alt="" src="/logo.svg" />
        <div className={container.testimonial}>
          <div className={container.greatSessionDaniWasSuperParent}>
            <div className={container.greatSessionDani}>
              "Great session! Dani was super helpful. She shared some practical
              advice on how can we go about refining our service offerings."
            </div>
            <div className={container.frameParent}>
              <div className={container.ellipseParent}>
                <img
                  className={container.frameChild}
                  alt=""
                  src="/ellipse-249@2x.png"
                />
                <div className={container.cameronWilliamson}>
                  Cameron Williamson
                </div>
              </div>
              <div className={container.starRating}>
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar11.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.verifyInner}>
        <div className={styles.frameParent}>
          <div className={styles.verifyPhoneNumberParent}>
            <b className={styles.verifyPhoneNumber}>Verify Email</b>
            <div className={styles.enterTheVerification}>
              Enter the verification number sent to
            </div>
            <div className={styles.parent}>
              <div className={styles.div}> {email}</div>
          <div className={styles.edit} style={{cursor:'pointer'}} onClick={()=>{
            auth.signOut()
            navigate('/')
          }}>Back to login</div>
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.otpInputParent}>
             <PinInput 
  length={6} 
  initialValue="" 
  onChange={(value, index) => {setOtp(value)}} 
  style={{padding: '10px'}}  
  inputStyle={{borderColor: `${error!='' ? '#FF0000' : 'var(--grey-70, #cccccc)'}`,}}
  inputFocusStyle={{borderColor: 'blue'}}
  autoSelect={true}
/>
            </div>
            <p style={{color:'red',textAlign:'start',width:'95%'}}>{error}</p>
            <p style={{color:'green',textAlign:'start',width:'95%'}}>{succ}</p>
            <div className={styles.div}> </div>
            <div id="recaptcha-container" /> 
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.didntGetTheOtpParent}>
              <div className={styles.enterTheVerification}>
                Didn’t get the OTP?
              </div>
              <div className={styles.resendOtp} 
              style={{cursor:'pointer'}} onClick={resendOtp}>Resend OTP</div>
            </div>
            <button
              className={styles.largeButtons}
              onClick={ValidateOtp}
            >
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder5.svg"
              />
              <b className={styles.label}>Verify OTP</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder5.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify_email;
