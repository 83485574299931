import React, { useEffect } from 'react'; // Import your Firestore instance
import { db } from '../firebase';

const categoriesData = [
  {
    name: 'Catering',
    subcategories: [
      'kitchen porter',
      'short-order cook',
      'caterer',
      'others',
    ],
  },
  {
    name: 'Childcare',
    subcategories: [
      'babysitters',
      'childminders',
      'Nannies',
      'Virtual Nannies',
      'Nursery',
      'others',
    ],
  },
  {
    name: 'Cleaning',
    subcategories: [
      'domestic cleaners',
      'window cleaners',
      'property maintenance',
      'drain and pipe cleaning',
      'domestic and commercial cleaners',
      'others',
    ],
  },
  {
    name: 'Entertainment',
    subcategories: [
      'Event planner',
      'party planner',
      'Event coverage',
      'Event and party planner',
      'Event planner and coverage services',
      'DJ and disco hire',
      'function rooms and banqueting facilities',
      'organisers and planners',
      'wedding and reception venues',
      'photography and film',
      'florist',
      'others',
    ],
  },
  {
    name: 'Appliance Repair',
    subcategories: ['Domestic Appliance Repairs', 'Appliance Repairs','others',],
  },
  {
    name: 'Mobile Mechanic',
    subcategories: ['Mobile mechanic','others',],
  },
  {
    name: 'Personal Assistance',
    subcategories: ['remote personal assistance', 'household personal assistance', 'others'],
  },
  {
    name: 'Pet Groomers',
    subcategories: ['pet grooming', 'pet training', 'pet grooming and training', 'dog walker', 'others'],
  },
  {
    name: 'Recovery Services',
    subcategories: ['recovery','others',],
  },
  {
    name: 'Removal Services',
    subcategories: ['removal', 'Domestic removals', 'Commercial removals', 'local removals','others',],
  },
  {
    name: 'Tradesmen',
    subcategories: [
      'Tradesmen & Construction',
      'Construction',
      'gardening & landscaping',
      'gardener',
      'painter',
      'decorating',
      'plumber',
      'handyman',
      'electrician',
      'builder',
      'plasterers',
      'architect',
      'carpentry and joiners',
      'groundworkers',
      'roofer',
      'tiler',
      'paving and driveway',
      'flooring',
      'locksmiths',
      'interior designers',
      'Landscaper',
      'Carpenter',
      'Plasterer',
      'Driveways/patio',
      'fencing',
      'boiler repair',
      'Tree Surgeons',
      'Carpet Repairs',
      'others',
    ],
  },
  {
    name: 'Transport Services',
    subcategories: ['taxi', 'bus and coach hire', 'van and truck hire', 'chauffeur and limousine hire', 'car hire','others',],
  },
  {
    name: 'Computer & Phone repair',
    subcategories: ['phone repair', 'computer repair', 'computer and phone repair', 'others'],
  },
  {
    name: 'Dry Cleaning & Laundry',
    subcategories: ['dry cleaner','others',],
  },
  {
    name: 'Health, Wellness & Beauty',
    subcategories: [
      'Counselling',
      'mentoring',
      'life coaching',
      'Nutritionist and dieticians',
      'personal trainers',
      'relationship and marriage counselling',
      'life coaching and mentoring',
      'therapist',
      'others',
    ],
  },
  {
    name: 'Tuition & Classes',
    subcategories: [
      'academic Tutor',
      'language',
      'driving lessons and instructors',
      'music',
      'guitar',
      'piano',
      'drum',
      'singing',
      'clarinet',
      'saxophone',
      'violin',
      'others',
    ],
  },
];

const otherData=[
  {
    name: 'Catering',
    subcategories: [
      'Wedding Caterer',
      'Corporate Event Caterer',
      'Private Party Caterer',
      'Cuisine-Specific Caterer',
      'Italian Food Caterer',
      'Indian Food Caterer',
      'Chinese Food Caterer',
      'African Food Caterer',
      'Vegetarian and Vegan Caterer',
      'Gluten-Free Caterer',
      'Buffet Caterer',
      'Sit-down Meal Caterer',
      'Mobile Caterer',
      'Wine Caterer',
      'Mobile Bar Services',
      'Gourmet caterer',
      'filipino caterer',
      'Halal and Kosher Caterer',
      'Catering Equipment Rental',
      'Furniture and Decor Rentals for Events',
      'Linen and Tableware Rentals',
      'Mexican food caterer',
      'Sushi Caterer'
    ],
  },
  {
    name: 'Childcare',
    subcategories: [
      'Nursery School Teachers',
      'Preschool Teachers',
      'Montessori Teachers',
      'Special Education Teachers for Early Years',
      'Childcare Providers:',
      'Registered Childminders',
      'Au Pairs',
      'After-School Care Providers',
      'Child Psychologists',
      'Speech and Language Therapists',
      'Occupational Therapists for Children',
      'Early Childhood Development Consultants',
      'Pediatric Nurses',
      'Childcare Health Consultants',
      'First Aid Professional',
      "Children's Music Teachers",
      'Art Teachers',
      'Swimming Coach',
      'Gymnastics Coach',
      'Tennis Coach',
      'Dance Instructors',
      'Parenting Coaches',
      'Childcare Advisors',
      'Lactation Consultants',
      'Family Therapists',
      'Inclusion Coordinators',
      'Special Needs Childcare Workers',
      'Behavioral Therapists for Children',
      'Sign Language Interpreter',
      'Chess Coach'
    ],
  },
  {
    name: 'Cleaning',
    subcategories: [
      'Carpet and Upholstery Cleaners',
      'Window Cleaners',
      'Pressure Washers',
      'Office Cleaners',
      'Retail Space Cleaners',
      'Industrial Cleaners',
      'House Cleaners',
      'Apartment Cleaners',
      'Maid Services',
      'Move-Out Cleaners',
      'Move-in Cleaners',
      'Construction Site Cleaners'

    ],
  },
  {
    name: 'Entertainment',
    subcategories: [
      'Engagement Party Planners',
      'Birthday planner',
    ],
  },
  {
    name: 'Appliance Repair',
    subcategories: [
      'Whirlpool Repair Professionals', 
      'Samsung Repair Specialists',
      'LG Repair Experts',
      'General Electric (GE) Repair Technicians',
      'Frigidaire Repair Services',
      'Maytag Washing Machine Repair Experts',
      'Bosch Washing Machine Specialists',
      'Samsung Washing Machine Technicians',
      'LG Washing Machine Repair Professionals',
      'Whirlpool Washing Machine Repair Services',
      'GE Oven and Range Repair Specialists',
      'Samsung Oven and Range Repair Services',
      'LG Oven and Range Repair Technicians',
      'Bosch Oven and Range Repair Experts',
      'Whirlpool Oven and Range Repair Professionals',
      'Bosch Dishwasher Repair Experts',
      'KitchenAid Dishwasher Repair Specialists',
      'Samsung Dishwasher Repair Technicians',
      'GE Dishwasher Repair Services',
      'Whirlpool Dishwasher Repair Professionals',
      'Samsung Microwave Repair Specialists',
      'LG Microwave Repair Experts',
      'Panasonic Microwave Repair Technicians',
      'Whirlpool Microwave Repair Services',
      'GE Microwave Repair Professionals',
      'LG Air Conditioner Repair Experts',
      'Samsung Air Conditioner Repair Specialists',
      'Carrier Air Conditioner Repair Technicians',
      'Whirlpool Air Conditioner Repair Services',
      'GE Air Conditioner Repair Professionals',
      'Samsung Dryer Repair Specialists',
      'LG Dryer Repair Experts',
      'Whirlpool Dryer Repair Technicians',
      'Bosch Dryer Repair Services',
      'Maytag Dryer Repair Professionals'
    ],
  },
  {
    name: 'Mobile Mechanic',
    subcategories: [
      'Ford Specialists',
      'Chevrolet Mechanics',
      'Dodge Experts',
      'Tesla Technicians',
      'BMW Specialists',
      'Mercedes-Benz Mechanics',
      'Audi Technicians',
      'Volkswagen Experts',
      'Volvo Specialists',
      'Land Rover and Jaguar Mechanics',
      'Toyota Specialists',
      'Honda Mechanics',
      'Nissan Technicians',
      'Subaru Experts',
      'Mazda Specialists',
      'Mitsubishi Mechanics',
      'Hyundai Specialists',
      'Kia Mechanics',
      'Porsche Technicians',
      'Ferrari Specialists',
      'Lamborghini Mechanics',
      'Maserati Experts',
      'Aston Martin Technicians',
      'Renault Specialists',
      'Peugeot Mechanics',
      'Fiat Technicians',
      'Skoda Experts',
      'Electric Vehicle Specialists',
      'Hybrid Vehicle Experts',
      'Classic Car Specialists'
    ],
  },
  {
    name: 'Personal Assistance',
    subcategories: [
      'remote personal assistance',
     'household personal assistance', 
     'others'
    ],
  },
  {
    name: 'Pet Groomers',
    subcategories: [
      'Dog Trainers', 
      'Dog Groomers', 
      'Canine Behavior Specialists',
      'Dog Walkers',
      'Veterinary Professionals',
      'Dog Boarding and Daycare Providers',
      'Cat Behavior Consultants',
      'Feline Groomers',
      'Cat Boarding and Sitting Services',
      'Avian Trainers',
      'Bird Care Specialists',
      'Bird Boarding and Sitting Services',
      'Goldfish specialist',
      'Rabbits specialist',
      'Guinea Pig specialist',
      'Hamsters Specialist',
      'Exotic Pet Specialists',
      'Equine Trainers',
      'Horse Groomer'
    ],
  },
  {
    name: 'Recovery Services',
    subcategories: ['Car Recovery',],
  },
  {
    name: 'Removal Services',
    subcategories: [
      'ear wax removal',
      'tree removal',
      'furniture removal',
      'weed removal',
      'pest removal',
      'pet urine removal',
      'pigeon removal',
      'junk removal',
      'mould removal',
      'leaf removal',
      'lice removal',
      'large rock removal',
      'hazardous waste removal',
      'hoarding removal',
      'horse waste removal',
      'animal removal',
      'animal waste removal',
      'body removal',
      'odour removal'
    ],
  },
  {
    name: 'Tradesmen',
    subcategories: [
      
    ],
  },
  {
    name: 'Transport Services',
    subcategories: [
    ],
  },
  {
    name: 'Computer & Phone repair',
    subcategories: [
      'Apple Repair Technicians',
      'macOS Repair Specialist',
      'Microsoft Surface Repair Specialists',
      'Windows OS Expert',
      'Hardware and Software Repair',
      'Dell Certified Repair Technicians',
      'HP Laptop and Desktop Repair Experts',
      'HP Hardware and Software Expert',
      'Lenovo Certified Repair Technicians',
      'Lenovo Hardware and Software Experts',
      'Asus Device Repair Specialists',
      'Phone Repair Specialists',
      'iPhone Repair Experts',
      'Specialists in iOS Troubleshooting',
      'Apple Hardware Repair Technicians',
      'Samsung Galaxy Repair Specialists',
      'Samsung Hardware and Software Expert',
      'Google Pixel Repair Technicians',
      'Android OS Experts',
      'Huawei Phone Repair Experts',
      'Huawei Hardware and Software Experts',
      'OnePlus Phone Repair Specialists',
      'OnePlus Experts',
      'LG Phone Repair Technicians'
    ],
  },
  {
    name: 'Dry Cleaning & Laundry',
    subcategories: [
      
    ],
  },
  {
    name: 'Health, Wellness & Beauty',
    subcategories: [
      'Counselling',
      'Yoga and Pilates Instructors',
      'Mental Health Therapists and Counselors',
      'Chiropractors',
      'Physiotherapists',
      'Acupuncturists',
      'Homeopathic and Naturopathic Practitioners',
      'Massage Therapists',
      'Reflexologists',
      'Aromatherapists',
      'Reiki and Energy Healers',
      'Meditation and Mindfulness Coaches',
      'Herbalists',
      'Wellness Retreat Coordinators',
      'Hairstylists and Barbers',
      'Makeup Artists',
      'Estheticians (Skin Care Specialists)',
      'Nail Technicians',
      'Eyelash and Eyebrow Technicians',
      'Cosmetic Tattoo Artists',
      'Tanning Specialists',
      'Ayurvedic Consultants',
      'Osteopaths',
      'Hypnotherapists',
      'Cupping Therapists',
      'Gym Instructors',
      'Martial Arts Trainers',
      'Dance Instructors',
      'Sports Coaches',
      'Crystal Healing Practitioners',
      'Sound Therapists',
      'Shamanic Healers',
      'Tai Chi and Qigong Instructors',
      'Traditional Chinese Medicine Practitioners',
      'Ayurvedic Medicine Experts',
      'Homeopathy Specialists',
      'Naturopathic Doctors',
      'Dermatologists',
      'Aesthetic Medicine Professionals (e.g., Botox, Fillers)',
      'Trichologists (Hair and Scalp Specialists)',
      'Permanent Makeup Artists',
      'Spa Therapists',
      'Sauna Therapy Specialists',
      'Hydrotherapy Experts',
      'Zumba Instructors',
      'Crossfit Coaches',
      'Piloxing Instructors',
      'Aqua Fitness Instructors',
      'Doulas and Midwives',
      'Postnatal Fitness Coaches',
      'Lactation Consultants',
      'Pregnancy Yoga and Pilates Instructors',
      'Pediatric Nutritionists',
      'Child Psychologists',
      'Adolescent Yoga Instructors',
      'Geriatric Fitness Specialists',
      'Senior Yoga and Tai Chi Instructors',
      'Memory Care Specialists',
      'Makeup and Beauty Workshops',
      'Wellness and Mindfulness Workshops',
      'DIY Natural Beauty Product Classes',
      'Life Coaches',
      'Wellness Coaches',
      'Stress Management Coaches'
    ],
  },
  {
    name: 'Tuition & Classes',
    subcategories: [
      'Mathematics Tutors',
      'Science Tutors',
      'English and Literature Experts',
      'History and Social Studies Tutors',
      'Foreign Language Tutors',
      'Exam Preparation Specialists',
      'Piano and Keyboard Instructors',
      'Guitar and String Instrument Experts',
      'Vocal and Singing Coaches',
      'Art Teachers (Painting, Drawing)',
      'Drama and Acting Tutors',
      'Dance Instructors (Ballet, Contemporary, Hip-Hop, etc.)',
      'Coding and Programming Experts',
      'Graphic Design Instructors',
      'Digital Marketing Educators',
      'Web Development Tutors',
      'Tutors for Dyslexia and Learning Disabilities',
      'Autism Spectrum Disorder Educators',
      'Speech and Language Therapists',
      'Preschool Education Experts',
      'Early Reading and Math Instructors',
      'Montessori and Waldorf Educators',
      'Adult Language Teachers',
      'Continuing Education Instructors',
      'Career Development and Skills Trainers',
      'Personal Training and Fitness Coaches',
      'Nutrition and Health Educators',
      'Yoga and Wellness Teachers',
      'Culinary and Cooking Instructors',
      'Photography Tutors',
      'Gardening and Horticulture Educators',
      'Craft and DIY Experts',
      'Healthcare Training Professionals',
      'Legal and Paralegal Studies Instructors',
      'Ecology and Conservation Educators',
      'Sustainable Living Instructors',
      'Renewable Energy Experts',
      'Algebra Tutors',
      'Calculus Tutors',
      'Geometry Tutors',
      'Trigonometry Tutors',
      'Statistics Tutors',
      'Physics Tutors',
      'Chemistry Tutors',
      'Biology Tutors',
      'Earth Science Tutors',
      'Environmental Science Tutors',
      'English Grammar and Composition Tutors',
      'Literature Analysis Tutors',
      'Creative Writing Tutors',
      'English as a Second Language (ESL) Tutors',
      'Reading Comprehension Specialists',
      'World History Tutors',
      'American/European/Asian History Tutors',
      'Geography Tutors',
      'Government and Politics Tutors',
      'Economics Tutors',
      'French Language Tutors',
      'Spanish Language Tutors',
      'Mandarin Chinese Tutors',
      'German Language Tutors',
      'Italian Language Tutors',
      'Arabic Language Tutors',
      'Japanese Language Tutors',
      'Russian Language Tutors',
      'SAT Prep Tutors',
      'GCSE Prep Tutors',
      'A-Levels Prep Tutors',
      'ACT Prep Tutors',
      'Advanced Placement (AP) Exam Tutors',
      'International Baccalaureate (IB) Tutors'
    ],
  },
]

function Add() {
  useEffect(() => {
    // Function to add categories and subcategories to Firestore
    const addCategoriesToFirestore = async () => {
      const categoriesCollection = db.collection('categories');
      const tagCollection = db.collection('serviceTags');

      // Loop through the categoriesData array and add them to Firestore
      for (const category of categoriesData) {
        const categoryDoc = categoriesCollection.doc(category.name);

        // Add subcategories as an array
        await categoryDoc.set({ subcategories: category.subcategories });
      }
      // Loop through the categoriesData array and add them to Firestore
      for (const category of otherData) {
        const categoryDoc = tagCollection.doc(category.name);

        // Add subcategories as an array
        await categoryDoc.set({ subcategories: category.subcategories });
      }

      console.log('Categories added to Firestore!');
    };

    addCategoriesToFirestore();
  }, []);

  return (
    <div className="App">
      <h1>Adding Categories and Subcategories to Firestore</h1>
    </div>
  );
}

export default Add;
