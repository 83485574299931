import styles from "./Promosubmited.module.css";

const Promosubmited = ({ onClose }) => {
  return (
    <div className={styles.promosubmited}>
      <div className={styles.modalTitle}>
        <div className={styles.jobDetails}>Job Details</div>
        <img className={styles.iconsclose} alt="" src="/iconsclose.svg" />
      </div>
      <div className={styles.priceTag1Parent}>
        <img className={styles.priceTag1Icon} alt="" src="/pricetag-1.svg" />
        <div className={styles.frameWrapper}>
          <div className={styles.yourPromotionRequestHasBeeParent}>
            <div className={styles.yourPromotionRequest}>
              Your Promotion request has been submitted
            </div>
            <div className={styles.youWillRecieve}>
              You will recieve a notification when approved
            </div>
          </div>
        </div>
        <button className={styles.largeButtons} onClick={onClose}>
          <img
            className={styles.icons20pxplaceholder}
            alt=""
            src="/icons20pxplaceholder1.svg"
          />
          <b className={styles.label}>Close</b>
          <img
            className={styles.icons20pxplaceholder}
            alt=""
            src="/icons20pxplaceholder1.svg"
          />
        </button>
      </div>
    </div>
  );
};

export default Promosubmited;
