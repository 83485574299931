import { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  TextField,
  Box,
  Autocomplete,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Promotion.module.css";
import offerstyles from "./OfferPromo.module.css";
import profilestyles from "./ProfilePromo.module.css";
import MapSelect from "../components/map/MapSelect";
import Chip from '@mui/material/Chip';
import { auth, db, storage } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import PortalPopup from "../components/PortalPopup";
import PromoPrev from "../components/PromoPrev";
import PromoPayModal from "../components/PromoPayModal";
import PromoSuccessful from "../components/PromoSuccessful";
import PreviewModal from "../components/PreviewModal";
import PaymentSuccessful from "../components/PaymentSuccessful";
import Promosubmited from "../components/Promosubmited";
import posthog from 'posthog-js'


const Promotion = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('')
   const [image, setImage] = useState('');
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [disc, setDisc] = useState('');
  const [book, setBook] = useState('');
  const [spend, setSpend] = useState('');
  const [cust, setCust] = useState('');
  const [type_offer, setType_offer] = useState('perc');
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState([]);
  const [sub, setSub] = useState(1)
  const [cost_no,setCostNo] = useState(0)
  const [name, setName] = useState('');
  const [isPreview, setPreviewPopupOpen] = useState(false);
  const [isPayPromo, setPromoPayPopupOpen] = useState(false);
  const [isPromoSucc, setPromoSuccPopupOpen] = useState(false);
  const [isPromoSubmited, setPromoSubmitPopupOpen] = useState(false);
  const [prof,setProf]=useState(0)
  const [dod,setDod]=useState(0)
  const [off,setOff]=useState(0)
  const [error, setError] = useState('');
  const [profileImage, setProfileImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };

  const handleTagInputKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      addTag(tagInput.trim());
    }
  };

  const addTag = (tag) => {
    if (tag) {
      setTags((prevTags) => [...prevTags, tag]);
      setTagInput('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

 

  const handleChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    const formatTime = (date) => {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    };
    const currentTime = new Date();
    setTime(formatTime(currentTime));
     const fetchData = async () => {
     await new Promise(resolve => setTimeout(resolve, 1000));
    if (auth.currentUser) {
      try {
        const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
        if (snapshot.exists) {
          setName(snapshot.data().org_name)
          setProfileImage(snapshot.data().profileImage)
              setSub(snapshot.data().subType)
              if (snapshot.data().subType==1) {
                db.collection('pricing').doc('profm').get().then(function (snap) {
                  if(snap.exists){
                  setProf(snap.data().value)}
                 })
                 db.collection('pricing').doc('dodm').get().then(function (snap) {
                  if(snap.exists){
                  setDod(snap.data().value)
                  }
                 })
                 db.collection('pricing').doc('offm').get().then(function (snap) {
                  if(snap.exists){
                  setOff(snap.data().value)
                  }
                 })
              }
              else{
                db.collection('pricing').doc('prof').get().then(function (snap) {
                  if(snap.exists){
                  setProf(snap.data().value)}
                 })
                 db.collection('pricing').doc('dod').get().then(function (snap) {
                  if(snap.exists){
                  setDod(snap.data().value)
                  }
                 })
                 db.collection('pricing').doc('off').get().then(function (snap) {
                  if(snap.exists){
                  setOff(snap.data().value)
                  }
                 })
              }
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    }
  };
  const galleryRef = db.collection('users')
  .doc(auth.currentUser.uid)
  .collection('gallery');

const unsubscribe = galleryRef.onSnapshot((snapshot) => {
  const images = [];
  snapshot.forEach((doc) => {
    images.push(doc.data().imageUrl);
  });
   if (images.length > 0) {
    setImagePreview(images[0]);
  }
});

// Unsubscribe when the component unmounts
  fetchData();
  return () => {
    unsubscribe();
  };
  }, [])
  
   const onImagechange= (event) =>
        {
            if (event.target.files && event.target.files[0]) {

                let id_pic = db.collection('tmp').doc().id;
                const uploadTask = storage.ref(`images/${id_pic}.png`).put(event.target.files[0]);
          uploadTask.on('state_changed', 
          (snapshot) => {
            // progrss function ....
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          }, 
          (error) => {
               // error function ....
            console.log(error);
            
          }, 
        () => {
            // complete function ....
            storage.ref('images').child(id_pic+".png").getDownloadURL().then(url => {
            setImage(url)
            })
        });
              }
        }
        const location =(lat, lng)=>{
          setLat(lat)
          setLng(lng)
        }

        const handleChangeCust = (e) => {
          const userInput = e.target.value;
      
          // Regular expressions to check for phone numbers, email addresses, and links
          const phoneNumberPattern = /\b\d{10,12}\b/; // Modify as per your phone number format
          const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/;
          const linkPattern = /(http[s]?:\/\/\S+|www\.\S+)/;
          const otherPattern= /dot/
      
          if (
            phoneNumberPattern.test(userInput) ||
            emailPattern.test(userInput) ||
            linkPattern.test(userInput)||
            otherPattern.test(userInput)
          ) {
            // Invalid input detected
            setError("Phone numbers, email addresses, and links are not allowed.");
            setCust('')
          } else {
            // Valid input, update the state
            setCust(userInput);
            setError('')
          }
        };
const proceed=()=>{
  if (type.trim() === '' || name.trim() === '') {
    setAlertMessage('Please fill in all the required fields.');
    setAlertOpen(true);
    return;
  }
  if(type=="My Profile"){
    setPromoPayPopupOpen(true)
  }
  else{
    console.log(cost_no*10000)
    if(cost_no*10000!==0){
      setPromoPayPopupOpen(true)
        }
        else{
          const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 1);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            const hours = currentDate.getHours();
const minutes = currentDate.getMinutes();
const formattedHours = hours.toString().padStart(2, '0');
const formattedMinutes = minutes.toString().padStart(2, '0');
const currentTimeString = `${formattedHours}:${formattedMinutes}`;
          let id = db.collection('tmp').doc().id;
          db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot){
           let name= snapshot.data().name
           db.collection('my_promo/'+auth.currentUser.uid+'/list').doc(id).set({
             type:type,
             image:image,
             lat:lat,
             lng:lng,
             time:type=="Deal of the day"?currentTimeString:time,
             date:type=="Deal of the day"?formattedDate:date||formattedDate,
             disc:disc,
             type_offer:type_offer,
             unix: Date.now(),
             stamp: serverTimestamp(),
             book:book,
             spend:spend,
             cust:cust,
             tags,
             name:name
           })
           db.collection('promo').doc(id).set({
             type:type,
             image:image,
             lat:lat,
             lng:lng,
             time:type=="Deal of the day"?currentTimeString:time,
             date:type=="Deal of the day"?formattedDate:date||formattedDate,
             disc:disc,
             type_offer:type_offer,
             unix: Date.now(),
             stamp: serverTimestamp(),
             book:book,
             spend:spend,
             cust:cust,
             tags,
             name: name,
             uid: auth.currentUser.uid
           })
          })
          sendToCust()
          db.collection('users').doc(auth.currentUser.uid).get().then(function (prof) {
            if (prof.data().pushNotifications) {
              db.collection('users').doc(auth.currentUser.uid).collection('noti_act').add({
    last: 'Your Promotion is now live!!',
    name: '',
   })
            }
          })
  
   db.collection('activity/prof/'+auth.currentUser.uid).add({
    uid:auth.currentUser.uid,
    type:'promo',
    msg:'Your Promotion is now live!!',
    date: formattedCurrentDate,
    name: '',
    unix: Date.now(),
    stamp:serverTimestamp()
  })
          setPromoSubmitPopupOpen(true)
        }
        
  }
  
}
function formatCurrentDate() {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  
  const date = new Date();
  const formattedDate = date.toLocaleDateString('en-US', options);

  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour < 12 ? 'am' : 'pm';
  const formattedTime = `${hour % 12 || 12}:${minute.toString().padStart(2, '0')}${period}`;

  return formattedDate ;
}

const formattedCurrentDate = formatCurrentDate();
const paidfor=()=>{
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const hours = currentDate.getHours();
const minutes = currentDate.getMinutes();
const formattedHours = hours.toString().padStart(2, '0');
const formattedMinutes = minutes.toString().padStart(2, '0');
const currentTimeString = `${formattedHours}:${formattedMinutes}`;
  let id = db.collection('tmp').doc().id;
  db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot){
   let name= snapshot.data().name
   db.collection('my_promo/'+auth.currentUser.uid+'/list').doc(id).set({
     type:type,
     image:image,
     lat:lat,
     lng:lng,
     time:type=="Deal of the day"?currentTimeString:time,
     date:type=="Deal of the day"?formattedDate:date,
     disc:disc,
     type_offer:type_offer,
     unix: Date.now(),
     stamp: serverTimestamp(),
     book:book,
     spend:spend,
     cust:cust,
     tags,
     name:name
   })
   db.collection('promo').doc(id).set({
     type:type,
     image:image,
     lat:lat,
     lng:lng,
     time:type=="Deal of the day"?currentTimeString:time,
     date:type=="Deal of the day"?formattedDate:date,
     disc:disc,
     type_offer:type_offer,
     unix: Date.now(),
     stamp: serverTimestamp(),
     book:book,
     spend:spend,
     cust:cust,
     tags,
     name: name,
     uid: auth.currentUser.uid
   })
  })
  sendToCust()
  posthog.capture('Promotion Completed',{type})
  db.collection('users').doc(auth.currentUser.uid).get().then(function (prof) {
    if (prof.data().pushNotifications) {
      db.collection('users').doc(auth.currentUser.uid).collection('noti_act').add({
    last: 'Your Promotion is now live!!',
    name: '',
   })
    }
  })
  
   db.collection('activity/prof/'+auth.currentUser.uid).add({
    uid:auth.currentUser.uid,
    type:'promo',
    msg:'Your Promotion is now live!!',
    date: formattedCurrentDate,
    name: '',
    unix: Date.now(),
    stamp:serverTimestamp()
  })
}
async function sendToCust() {
  try {
    const snapshot = await db.collection('customers')
    .get()
  snapshot.forEach((doc) => {
    const userData = doc.data();
    const userLatitude = userData.lat;
    const userLongitude = userData.lng;
    const userRad = 10000;
     // Calculate distance between two points using Haversine formula
     const distance = calculateHaversineDistance(lat, lng, userLatitude, userLongitude);
     if (distance <= userRad) {
      fetch('https://loggworks-c15c9e56f169.herokuapp.com/promocust', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: userData.email,
          name: userData.fname,
          user: name,
          type:type,
          title: type_offer=='perc'?
          `Get ${disc}% off when you book us`
          :type_offer=='amount'?
         `Get ${disc}£ off when you book us`
          :type_offer=='spend'?
          `Get £${disc} off when you spend £${spend}`
          :type_offer=='spend%'?
          `Get ${disc}% off when you spend £${spend}`
          :type_offer=='book'?
          `Get £${disc} off when you book us for ${book}`
           :type_offer=='book%'?
           `Get ${disc}% off when you book us for ${book}`
          :
          cust,
          date:date,
          dura:'24 hours',
          url: ''
        }),
      }).then(()=>{
        console.log('sent')
      })
     }

  });
  } catch (error) {
    console.log(error)
  }
}
const calculateHaversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Earth radius in kilometers

  const deg2rad = (degree) => {
    return degree * (Math.PI / 180);
  };

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in kilometers

  return distance;
};
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onFrameContainer15Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onFrameContainer16Click = useCallback(() => {
    navigate("/my_current_promo");
  }, []);

  const onFrameContainer17Click = useCallback(() => {
    navigate("/my_past_promo");
  }, []);

  return (
    <div className={styles.promotion}>
      <div className={styles.promomob}>
        <div className={styles.titles}>
          <div className={styles.activities}>Promote profile</div>
          <div className={styles.back} onClick={()=>{navigate('/profile')}}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.button}>
            <div className={styles.edit2Parent}>
              <img className={styles.edit2Icon} alt="" src="/edit2.svg" />
              <div className={styles.edit}>Edit</div>
            </div>
          </div>
        </div>
        <div className={styles.tabParent}>
          <div className={styles.tab}>
            <div className={styles.frameParent}>
              <div className={styles.newWrapper}>
                <div className={styles.new}>New</div>
              </div>
              <div className={styles.currentWrapper} onClick={onFrameContainer16Click}>
                <div className={styles.current}>Current</div>
              </div>
              <div className={styles.currentWrapper} onClick={onFrameContainer17Click}>
                <div className={styles.current}>Past</div>
              </div>
            </div>
          </div>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>
                What would you like to promote?
              </div>
            </div>
            <FormControl className={styles.baseField} variant="outlined">
              <InputLabel color="primary">Choose an option</InputLabel>
              <Select color="primary" size="medium" label="Choose an option"
              onChange={(e)=>{setType(e.target.value)}}
              value={type}>
                <MenuItem value="My Profile">My Profile</MenuItem>
                <MenuItem value="Offer">My Offer</MenuItem>
                <MenuItem value="Deal of the day">Deal of the day</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
          {
            type==''?
          <>
             <img
            className={styles.loudspeaker1Icon}
            alt=""
            src="/loudspeaker-11.svg"
          />
          <div className={styles.youCanPromote}>
            You can promote your profile, an offer or a deal of the day
          </div>
          </>
          :type=='My Profile'?
          <>
             <div className={profilestyles.frameDiv}>
          <div className={profilestyles.distanceSliderParent}>
            <div className={profilestyles.profilePromotions}>
              <div className={profilestyles.frameParent1}>
                <div className={profilestyles.previewWrapper}>
                  <div className={profilestyles.preview}>Preview</div>
                </div>
                <div className={profilestyles.rectangleParent}>
                  <div className={profilestyles.groupChild} />
                  <img
                    className={profilestyles.unsplashmlo6ilubadaIcon}
                    alt=""
                    src={imagePreview||''}
                  />
                  <div className={profilestyles.electrician}>{`Electrician  `}</div>
                
                  <div className={profilestyles.rectangleGroup}>
                    <Avatar
                      className={profilestyles.frameChild}
                      alt=""
                      src={profileImage||''}
                    />
                    <div className={profilestyles.dysonElectricalsParent}>
                      <b className={profilestyles.dysonElectricals}>
                       {name}
                      </b>
                      <div className={profilestyles.groupParent}>
                        <img
                          className={profilestyles.frameItem}
                          alt=""
                          src="/group-66649.svg"
                        />
                        <div className={profilestyles.div4}>4.0(20)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={offerstyles.input6}>
              <div style={{width:'100%'}}>
             <MapSelect
              location={location}
              cost ={(num)=>{
                if(num>=10000)
                {
                  setCostNo(num*prof)
                }
                else{
                  setCostNo(prof*10000)
                }
              }}
             />
            </div>
            </div>
            
          </div>
          <div className={profilestyles.inputParent1}>
            <div className={profilestyles.inputParent2}>
             
              <div className={profilestyles.input6}>
              <div className={profilestyles.selectOption}>Expiry Date</div>
              <input
                type='date'
                className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                onChange={(e)=>{setDate(e.target.value)}}
                min={new Date().toISOString().split('T')[0]}
              />
                
              </div>
            </div>
          </div>
        </div>
        <div className={profilestyles.quoteCards}>
                <div className={profilestyles.quoteNumber}>Amount Payable:</div>
                <b className={profilestyles.qn0001}>£{cost_no}</b>
              </div>
            
        <div className={profilestyles.payment}
         onClick={proceed}
        >
          <div className={profilestyles.button2}>
            <div className={profilestyles.iconsParent}>
              <img className={profilestyles.icons} alt="" src="/icons4.svg" />
              <b className={profilestyles.proceed}>Proceed to pay</b>
            </div>
          </div>
        </div>
          </>
         :type=='Offer'?
         <>
           <input type="file" accept="image/*" id="upload-button" style={{ display: "none" }} onChange={onImagechange}/>
        <label htmlFor="upload-button" style={{cursor:'pointer'}}>
               <div className={offerstyles.rectangleParent}>
          <div className={offerstyles.frameChild} 
           style={image?{backgroundImage:`url(${image})`,
           backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}:{}}/>
          <div className={offerstyles.uploadParent}>
            <img className={offerstyles.uploadIcon} alt="" src="/upload.svg" />
            <div className={offerstyles.x130}>145 X 130</div>
          </div>
          <div className={offerstyles.uploadAnImage1}>
            Upload an image for your Ad
          </div>
        </div>
        </label>
          
        <div className={offerstyles.input6}>
            <div style={{width:'100%'}}>
             <MapSelect
              location={location}
              cost ={(num)=>{
                if(num>=10000)
                {
                  setCostNo(num*off)
                }
                else{
                  setCostNo(off*10000)
                }
              }}
             />
            </div>
        </div>
        <div className={offerstyles.inputContainer} style={{width:'100%'}}>
          <div className={offerstyles.input8} style={{width:'100%'}}>
           <div className={offerstyles.selectOption}>Offer Type</div>
             <div style={{width:'100%'}}>
                  <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type_offer}
          onChange={(e)=>{
            setType_offer(e.target.value)
          }}
        >
          <MenuItem value={'perc'}>Discount percentage</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. 10% off</FormHelperText>
           <MenuItem value={'amount'}>Discount amount</MenuItem>
           <FormHelperText style={{marginLeft:'1rem'}}>  Ex. £10 off</FormHelperText>
          <MenuItem value={'spend'}>Spend A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get £5 off</FormHelperText>
          <MenuItem value={'spend%'}>Spend A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get 5% off</FormHelperText>
          <MenuItem value={'book'}>Book A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get £10 off</FormHelperText>
          <MenuItem value={'book%'}>Book A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get 10% off</FormHelperText>
          <MenuItem value={'cust'}>Custom</MenuItem>
        </Select>
      </FormControl>
              </div>
           
          </div>
            {
              type_offer=='perc'?
          <div className={offerstyles.input8}>
          <div className={offerstyles.selectOption}>Discount %</div>
            <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
            
          </div>
          :type_offer=='amount'? 
           <div className={offerstyles.input8}>
            <div className={offerstyles.selectOption}>Discount Amount</div>
            <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
           
          </div>
          :null
          }
        </div>
         {type_offer=='book'||type_offer=='book%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Booking time</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setBook(e.target.value)}}
                />
              </div>
            </div>
             <div style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='book%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
            :type_offer=='spend'||type_offer=='spend%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div  style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Spend (£)</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setSpend(e.target.value)}}
                />
              </div>
            </div>
             <div  style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='spend%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>

            :type_offer=='cust'?
            <div style={{width:'100%'}}>
             <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Offer description</div>
              </div>
            <textarea
              style={{width:'100%',height:'5rem',borderColor:'black',borderStyle:'solid'}}
              onChange={handleChangeCust}
            />
            <span style={{color:'red'}}>{error}</span>
            </div>
            :null
              }
        <div className={offerstyles.input6}>
         <div className={offerstyles.selectOption} style={{marginBottom:'0.5rem'}}>Discounted Services</div>
          <div style={{width:'100%'}}>
               <Box sx={{ width: '100%', maxWidth: 600}}>
               <Autocomplete
      multiple
      id="tags-filled"
      options={[]} // You can provide options for autocompletion if needed
      value={tags}
      onChange={(_, newValue) => setTags(newValue)}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => removeTag(tag)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Services"
          variant="outlined"
          helperText='To add a service, simply hit the enter key after typing it.'
          value={tagInput}
          onChange={handleTagInputChange}
          onKeyDown={handleTagInputKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => removeTag(tag)}
              />
            )),
          }}
        />
      )}
    />
    </Box>
            </div>
        </div>
        <div className={offerstyles.frameDiv}>
          <div className={offerstyles.input8}>
            <div className={offerstyles.selectOption}>Expiry Date</div>
            <input
                type='date'
                className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                onChange={(e)=>{setDate(e.target.value)}}
                min={new Date().toISOString().split('T')[0]}
              />
          </div>
          
        </div>
        <div className={profilestyles.quoteCards}>
                <div className={profilestyles.quoteNumber}>Amount Payable:</div>
                <b className={profilestyles.qn0001}>£{cost_no}</b>
              </div>
            
        <div className={offerstyles.button2} style={{cursor:'pointer'}} onClick={()=>{setPreviewPopupOpen(true)}}>
          <div className={offerstyles.iconsParent}>
            <img className={offerstyles.icons} alt="" src="/icons.svg" />
            <b className={offerstyles.proceed}>Preview Offer</b>
          </div>
        </div>
        <div className={offerstyles.previewBeforePromoting}>
          Preview before promoting
        </div>
        <div className={offerstyles.button3} style={{cursor:'pointer'}} onClick={proceed}>
          <div className={offerstyles.iconsParent}>
            <img className={offerstyles.icons} alt="" src="/icons1.svg" />
            <b className={offerstyles.proceed}>Promote Offer</b>
          </div>
        </div>
         </>
         :
          <>
            <input type="file" accept="image/*" id="upload-button" style={{ display: "none" }} onChange={onImagechange}/>
        <label htmlFor="upload-button" style={{cursor:'pointer'}}>
        
               <div className={offerstyles.rectangleParent}>
          <div className={offerstyles.frameChild} 
           style={image?{backgroundImage:`url(${image})`,
           backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}:{}}/>
          <div className={offerstyles.uploadParent}>
            <img className={offerstyles.uploadIcon} alt="" src="/upload.svg" />
            <div className={offerstyles.x130}>145 X 130</div>
          </div>
          <div className={offerstyles.uploadAnImage1}>
            Upload an image for your Ad
          </div>
        </div>
        </label>
          
        <div className={offerstyles.input6}>
            <div style={{width:'100%'}}>
             <MapSelect
              location={location}
              cost ={(num)=>{
                if(num>=10000)
                {
                  setCostNo(num*dod)
                }
                else{
                  setCostNo(dod*10000)
                }
              }}
             />
            </div>
        </div>
        <div className={offerstyles.inputContainer} style={{width:'100%'}}>
          <div className={offerstyles.input8} style={{width:'100%'}}>
           <div className={offerstyles.selectOption}>Offer Type</div>
             <div style={{width:'100%'}}>
                  <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type_offer}
          onChange={(e)=>{
            setType_offer(e.target.value)
          }}
        >
          <MenuItem value={'perc'}>Discount percentage</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. 10% off</FormHelperText>
           <MenuItem value={'amount'}>Discount amount</MenuItem>
           <FormHelperText style={{marginLeft:'1rem'}}>  Ex. £10 off</FormHelperText>
          <MenuItem value={'spend'}>Spend A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get £5 off</FormHelperText>
          <MenuItem value={'spend%'}>Spend A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get 5% off</FormHelperText>
          <MenuItem value={'book'}>Book A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get £10 off</FormHelperText>
          <MenuItem value={'book%'}>Book A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get 10% off</FormHelperText>
          <MenuItem value={'cust'}>Custom</MenuItem>
        </Select>
      </FormControl>
              </div>
           
          </div>
            {
              type_offer=='perc'?
          <div className={offerstyles.input8}>
          <div className={offerstyles.selectOption}>Discount %</div>
            <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
            
          </div>
          :type_offer=='amount'? 
           <div className={offerstyles.input8}>
            <div className={offerstyles.selectOption}>Discount Amount</div>
            <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
           
          </div>
          :null
          }
        </div>
         {type_offer=='book'||type_offer=='book%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Booking time</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setBook(e.target.value)}}
                />
              </div>
            </div>
             <div style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='book%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
            :type_offer=='spend'||type_offer=='spend%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Spend £</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setSpend(e.target.value)}}
                />
              </div>
            </div>
             <div>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='spend%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
            :type_offer=='cust'?
            <div style={{width:'100%'}}>
             <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Offer description</div>
              </div>
            <textarea
              style={{width:'100%',height:'5rem',borderColor:'black',borderStyle:'solid'}}
              onChange={handleChangeCust}
            />
            <span style={{color:'red'}}>{error}</span>
            </div>
            :null
              }
        <div className={offerstyles.input6}>
         <div className={offerstyles.selectOption} style={{marginBottom:'0.5rem'}}>Discounted Services</div>
          <div style={{width:'100%'}}>
               <Box sx={{ width: '100%', maxWidth: 600}}>
               <Autocomplete
      multiple
      id="tags-filled"
      options={[]} // You can provide options for autocompletion if needed
      value={tags}
      onChange={(_, newValue) => setTags(newValue)}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => removeTag(tag)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Services"
          variant="outlined"
          helperText='To add a service, simply hit the enter key after typing it.'
          value={tagInput}
          onChange={handleTagInputChange}
          onKeyDown={handleTagInputKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => removeTag(tag)}
              />
            )),
          }}
        />
      )}
    />
    </Box>
            </div>
        </div>
        <div className={offerstyles.frameDiv}>
         <img
         src="/dealofday.svg"

         />
        </div>
        <div className={profilestyles.quoteCards}>
                <div className={profilestyles.quoteNumber}>Amount Payable:</div>
                <b className={profilestyles.qn0001}>£{cost_no}</b>
              </div>
        <div className={offerstyles.button2} style={{cursor:'pointer'}} onClick={()=>{setPreviewPopupOpen(true)}}>
          <div className={offerstyles.iconsParent}>
            <img className={offerstyles.icons} alt="" src="/icons.svg" />
            <b className={offerstyles.proceed}>Preview Offer</b>
          </div>
        </div>
        <div className={offerstyles.previewBeforePromoting}>
          Preview before promoting
        </div>
        <div className={offerstyles.button3} style={{cursor:'pointer'}} onClick={proceed}>
          <div className={offerstyles.iconsParent}>
            <img className={offerstyles.icons} alt="" src="/icons1.svg" />
            <b className={offerstyles.proceed}>Promote Deal of the day</b>
          </div>
        </div>
         </>
          }
        </div>
      </div>
      <div className={styles.navPrototypeParent}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account3.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles1}>
            <div className={styles.activities}>Promote profile</div>
            <div className={styles.back} onClick={()=>{navigate('/profile')}}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.button}>
              <div className={styles.edit2Parent}>
                <img className={styles.edit2Icon} alt="" src="/edit2.svg" />
                <div className={styles.edit}>Edit</div>
              </div>
            </div>
          </div>
          <div className={styles.component102}>
            <div className={styles.icon} style={{cursor:'pointer'}} onClick={()=>{navigate('/profile')}}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
            <b className={styles.licenseAndCertification}>Promotions</b>
           
          </div>
          <div className={styles.promotionsTabPrototypeWrapper}>
            <div className={styles.promotionsTabPrototype}>
              <div className={styles.twoTabFlatSwitch}>
                <div
                  className={styles.tab1Wrapper}
                  onClick={onFrameContainer15Click}
                >
                  <div className={styles.tab1}>New</div>
                </div>
                <div
                  className={styles.tab2Wrapper}
                  onClick={onFrameContainer16Click}
                >
                  <div className={styles.tab2}>Current</div>
                </div>
                <div
                  className={styles.tab3Wrapper}
                  onClick={onFrameContainer17Click}
                >
                  <div className={styles.tab2}>Past</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.input1}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>
                What would you like to promote?
              </div>
            </div>
            <FormControl className={styles.baseField} variant="outlined">
              <InputLabel color="primary">Choose an option</InputLabel>
              <Select color="primary" size="medium" label="Choose an option"
              onChange={(e)=>{setType(e.target.value)}}
              value={type}>
                <MenuItem value="My Profile">My Profile</MenuItem>
                <MenuItem value="Offer">My Offer</MenuItem>
                <MenuItem value="Deal of the day">Deal of the day</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
          {
            type==''?
            <div className={styles.loudspeaker1Parent}>
            <img
              className={styles.loudspeaker1Icon}
              alt=""
              src="/loudspeaker-12.svg"
            />
            <div className={styles.youCanPromote1}>
              You can promote your profile, an offer or a deal of the day
            </div>
          </div>
          :type=='My Profile'?
          <>
            <div className={profilestyles.inputParent}>
              <div className={profilestyles.promotions}>
                <div className={profilestyles.imgWrapper}>
                  <img className={profilestyles.imgIcon} alt="" src={imagePreview||''} />
                </div>
                <div className={profilestyles.description}>
                  <div className={profilestyles.descriptionInner}>
                    <div className={profilestyles.frameGroup}>
                      <div className={profilestyles.businessNameAndAvatarParent}>
                        <div className={profilestyles.businessNameAndAvatar}>
                          <Avatar
                            className={profilestyles.avatarIcon1}
                            alt=""
                            src={profileImage||''}
                          />
                          <div className={profilestyles.businessName}>
                           {name}
                          </div>
                        </div>
                        <div className={profilestyles.ratings}>
                          <div className={profilestyles.starRating}>
                            <img
                              className={profilestyles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={profilestyles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={profilestyles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={profilestyles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={profilestyles.iconsadd}
                              alt=""
                              src="/icons16pxstar1.svg"
                            />
                          </div>
                          <div className={profilestyles.ratingNumber}>5.0 (300K)</div>
                        </div>
                      </div>
                      <div className={profilestyles.jobCategory}>
                        Job category | Distance
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className={profilestyles.distanceSliderParent}>
                <div style={{width:'100%'}}>
             <MapSelect
              location={location}
              cost ={(num)=>{
                if(num>=10000)
                {
                  setCostNo(num*prof)
                }
                else{
                  setCostNo(prof*10000)
                }
              }}
             />
            </div>
                <div className={profilestyles.inputGroup}>
                  <div className={profilestyles.input2}>
                    <div className={profilestyles.labelContainer}>
                      <div className={profilestyles.label}>Expiry Date</div>
                    </div>
                     <input
                type='date'
                className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                onChange={(e)=>{setDate(e.target.value)}}
                min={new Date().toISOString().split('T')[0]}
              
              />
                  </div>
                </div>
              </div>
            </div>
            <div className={profilestyles.quoteCardsParent}>
             
        <div className={profilestyles.quoteCards}>
                <div className={profilestyles.quoteNumber}>Amount Payable:</div>
                <b className={profilestyles.qn0001}>£{cost_no}</b>
              </div>
              
              <div className={profilestyles.largeButtons1}
              onClick={proceed}>
                <img
                  className={profilestyles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder1.svg"
                />
                <b className={profilestyles.label4}>Proceed to Pay</b>
                <img
                  className={profilestyles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder1.svg"
                />
              </div>
            </div>
          </>
          :type=='Offer'?
            <div className={offerstyles.forms}>
              <div className={offerstyles.inputParent}>
              <input type="file" accept="image/*" id="upload-button" style={{ display: "none" }} onChange={onImagechange}/>
        <label htmlFor="upload-button" style={{cursor:'pointer'}}>
        
               <div className={offerstyles.uploadImageParent}>
                  <div
                    className={offerstyles.uploadImage}
                  >
                    <div className={offerstyles.imageFrame}>
                      <div className={offerstyles.boundary} />
                      <div className={offerstyles.imageArea} 
                      style={image?{backgroundImage:`url(${image})`,
                      backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}:{}}/>
                      <img
                        className={offerstyles.iconsadd1}
                        alt=""
                        src={"/iconsadd1.svg"}
                      />
                    </div>
                    <img
                      className={offerstyles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                   <img
                className={offerstyles.addcancelCircleIcon}
                 onClick={()=>{
                  if(image!='')
                  {
                    setImage('')
                  }
                  }}
                alt=""
                src={image?"../addcancel-circle.svg":"../iconsadd1.svg"}
              />
                  </div>
                  <div className={offerstyles.uploadAnImage}>
                    Upload an image for your Ad (145PX x 130PX Recommended)
                  </div>
                </div>
        </label> 
              
               <div style={{width:'100%'}}>
             <MapSelect
              location={location}
              cost ={(num)=>{
                if(num>=10000)
                {
                  setCostNo(num*off)
                }
                else{
                  setCostNo(off*10000)
                }
              }}
             />
            </div>
                <div className={offerstyles.input2}>
                  <div className={offerstyles.labelContainer}>
                    <div className={offerstyles.label}>Offer Type</div>
                  </div>
                   <div style={{width:'100%'}}>
                  <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type_offer}
          onChange={(e)=>{
            setType_offer(e.target.value)
          }}
        >
          <MenuItem value={'perc'}>Discount percentage</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. 10% off</FormHelperText>
           <MenuItem value={'amount'}>Discount amount</MenuItem>
           <FormHelperText style={{marginLeft:'1rem'}}>  Ex. £10 off</FormHelperText>
          <MenuItem value={'spend'}>Spend A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get £5 off</FormHelperText>
          <MenuItem value={'spend%'}>Spend A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get 5% off</FormHelperText>
          <MenuItem value={'book'}>Book A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get £10 off</FormHelperText>
          <MenuItem value={'book%'}>Book A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get 10% off</FormHelperText>
          <MenuItem value={'cust'}>Custom</MenuItem>
        </Select>
      </FormControl>
              </div>
                </div>
                 {
              type_offer=='perc'? 
              <div className={offerstyles.input}>
                  <div className={offerstyles.labelContainer}>
                    <div className={offerstyles.label}>Discount percentage</div>
                  </div>
                  <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
                </div>
              :type_offer=='amount'? 
              <div className={offerstyles.input}>
                  <div className={offerstyles.labelContainer}>
                    <div className={offerstyles.label}>Discounted Amount (£)</div>
                  </div>
                  <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
                </div>
                :type_offer=='spend'||type_offer=='spend%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Spend (£)</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setSpend(e.target.value)}}
                />
              </div>
            </div>
             <div style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='spend%'?
                'Discount percentage': 'Amount off (£)'} </div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
             :type_offer=='book'||type_offer=='book%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Booking time</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setBook(e.target.value)}}
                />
              </div>
            </div>
             <div style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='book%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
            :
            <div style={{width:'100%'}}>
             <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Offer description</div>
              </div>
            <textarea
              style={{width:'100%',height:'5rem',borderColor:'black',borderStyle:'solid'}}
              onChange={handleChangeCust}
            />
            <span style={{color:'red'}}>{error}</span>
            </div>
              }
                <div style={{width:'100%'}}>
            <div className={offerstyles.labelContainer}>
              <div className={offerstyles.label} >Discounted Services</div>
            </div>
            <div style={{width:'100%'}}>
               <Box sx={{ width: '100%', maxWidth: 600}}>
               <Autocomplete
      multiple
      id="tags-filled"
      options={[]} // You can provide options for autocompletion if needed
      value={tags}
      onChange={(_, newValue) => setTags(newValue)}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => removeTag(tag)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Services"
          variant="outlined"
          helperText='To add a service, simply hit the enter key after typing it.'
          value={tagInput}
          onChange={handleTagInputChange}
          onKeyDown={handleTagInputKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => removeTag(tag)}
              />
            )),
          }}
        />
      )}
    />
    </Box>
            </div>
          </div>
               
                <div className={offerstyles.inputGroup}>
                  <div className={offerstyles.input4}>
                    <div className={offerstyles.labelContainer}>
                      <div className={offerstyles.label}>Expiry Date</div>
                    </div>
                    <input
                type='date'
                className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                onChange={(e)=>{setDate(e.target.value)}}
                min={new Date().toISOString().split('T')[0]}
              />
                  </div>
                </div>
              </div>
              <div className={profilestyles.quoteCards}>
                <div className={profilestyles.quoteNumber}>Amount Payable:</div>
                <b className={profilestyles.qn0001}>£{cost_no}</b>
              </div>
            
              <div className={offerstyles.frameParent}>
                <div className={offerstyles.largeButtonsParent}>
                  <div className={offerstyles.largeButtons} style={{cursor:'pointer'}} onClick={()=>{setPreviewPopupOpen(true)}}>
                    <img
                      className={offerstyles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                    <b className={offerstyles.uploadAnImage}>Preview Offer</b>
                    <img
                      className={offerstyles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                  </div>
                  
                  <div className={offerstyles.previewBeforePromotion}>
                    Preview before promotion
                  </div>
                </div>
                <div className={offerstyles.largeButtons1}
                style={{cursor:'pointer'}}
                 onClick={proceed}>
                  <img
                    className={offerstyles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder1.svg"
                  />
                  <b className={offerstyles.uploadAnImage}>Promote offer</b>
                  <img
                    className={offerstyles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder1.svg"
                  />
                </div>
              </div>
            </div>
          :
           <div className={offerstyles.forms}>
              <div className={offerstyles.inputParent}>
              <input type="file" accept="image/*" id="upload-button" style={{ display: "none" }} onChange={onImagechange}/>
        <label htmlFor="upload-button" style={{cursor:'pointer'}}>
        
               <div className={offerstyles.uploadImageParent}>
                  <div
                    className={offerstyles.uploadImage}
                  >
                    <div className={offerstyles.imageFrame}>
                      <div className={offerstyles.boundary} />
                      <div className={offerstyles.imageArea} 
                       style={image?{backgroundImage:`url(${image})`,
                       backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'}:{}}/>
                      <img
                        className={offerstyles.iconsadd1}
                        alt=""
                        src={"/iconsadd1.svg"}
                      />
                    </div>
                    <img
                      className={offerstyles.addcancelCircleIcon}
                      alt=""
                      src="/addcancel-circle.svg"
                    />
                  <img
                className={offerstyles.addcancelCircleIcon}
                 onClick={()=>{
                  if(image!='')
                  {
                    setImage('')
                  }
                  }}
                alt=""
                src={image?"../addcancel-circle.svg":"../iconsadd1.svg"}
              />
                  </div>
                  <div className={offerstyles.uploadAnImage}>
                    Upload an image for your Ad (145PX x 130PX Recommended)
                  </div>
                </div>
        </label>
               
               <div style={{width:'100%'}}>
             <MapSelect
              location={location}
              cost ={(num)=>{
                if(num>=10000)
                {
                  setCostNo(num*dod)
                }
                else{
                  setCostNo(dod*10000)
                }
              }}
             />
            </div>
                <div className={offerstyles.input2}>
                  <div className={offerstyles.labelContainer}>
                    <div className={offerstyles.label}>Offer Type</div>
                  </div>
                   <div style={{width:'100%'}}>
                  <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type_offer}
          onChange={(e)=>{
            setType_offer(e.target.value)
          }}
        >
          <MenuItem value={'perc'}>Discount percentage</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. 10% off</FormHelperText>
          <MenuItem value={'amount'}>Discount amount</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. £10 off</FormHelperText>
          <MenuItem value={'spend'}>Spend A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get £5 off</FormHelperText>
          <MenuItem value={'spend%'}>Spend A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Spend £20, get 5% off</FormHelperText>
          <MenuItem value={'book'}>Book A, get B off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get £10 off</FormHelperText>
          <MenuItem value={'book%'}>Book A, get B% off</MenuItem>
          <FormHelperText style={{marginLeft:'1rem'}}>  Ex. Book 24 hours, get 10% off</FormHelperText>
          <MenuItem value={'cust'}>Custom</MenuItem>
        </Select>
      </FormControl>
              </div>
                </div>
                 {
              type_offer=='perc'? 
              <div className={offerstyles.input}>
                  <div className={offerstyles.labelContainer}>
                    <div className={offerstyles.label}>Discounted percentage</div>
                  </div>
                  <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
                </div>
              :type_offer=='amount'? 
              <div className={offerstyles.input}>
                  <div className={offerstyles.labelContainer}>
                    <div className={offerstyles.label}>Discounted Amount (£)</div>
                  </div>
                  <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
                </div>
                :type_offer=='spend'||type_offer=='spend%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Spend (£)</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setSpend(e.target.value)}}
                />
              </div>
            </div>
             <div  style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='spend%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
             :type_offer=='book'||type_offer=='book%'?
            <div style={{display:'flex', gap:'2rem',width:'100%'}}>
            <div style={{width:'100%'}}>
              <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Booking time</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setBook(e.target.value)}}
                />
              </div>
            </div>
             <div style={{width:'100%'}}>
                <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>{type_offer=='book%'?
                'Discount percentage': 'Amount off (£)'}</div>
              </div>
              <div style={{width:'100%'}}>
                <TextField
                fullWidth
                onChange={(e)=>{setDisc(e.target.value)}}
                />
              </div>
             </div>
            </div>
            :
            <div style={{width:'100%'}}>
             <div className={offerstyles.labelContainer}>
                <div className={offerstyles.label}>Offer description</div>
              </div>
            <textarea
              style={{width:'100%',height:'5rem',borderColor:'black',borderStyle:'solid'}}
              onChange={handleChangeCust}
            />
            <span style={{color:'red'}}>{error}</span>
            </div>
              }
                <div style={{width:'100%'}}>
            <div className={offerstyles.labelContainer}>
              <div className={offerstyles.label}>Discounted Services</div>
            </div>
            <div style={{width:'100%'}}>
               <Box sx={{ width: '100%', maxWidth: 600}}>
               <Autocomplete
      multiple
      id="tags-filled"
      options={[]} // You can provide options for autocompletion if needed
      value={tags}
      onChange={(_, newValue) => setTags(newValue)}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => removeTag(tag)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Services"
          variant="outlined"
          value={tagInput}
          helperText='To add a service, simply hit the enter key after typing it.'
          onChange={handleTagInputChange}
          onKeyDown={handleTagInputKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => removeTag(tag)}
              />
            )),
          }}
        />
      )}
    />
    </Box>
            </div>
          </div>
               
                <div className={offerstyles.inputGroup}>
                  <img
         src="/dealofday.svg"
         
         />
                </div>
              </div>
              <div className={profilestyles.quoteCards}>
                <div className={profilestyles.quoteNumber}>Amount Payable:</div>
                <b className={profilestyles.qn0001}>£{cost_no}</b>
              </div>
            
              <div className={offerstyles.frameParent}>
                <div className={offerstyles.largeButtonsParent}>
                  <div className={offerstyles.largeButtons} style={{cursor:'pointer'}} onClick={()=>{setPreviewPopupOpen(true)}}>
                    <img
                      className={offerstyles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                    <b className={offerstyles.uploadAnImage}>Preview Offer</b>
                    <img
                      className={offerstyles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                  </div>
                  <div className={offerstyles.previewBeforePromotion}>
                    Preview before promotion
                  </div>
                </div>
                <div className={offerstyles.largeButtons1}
                style={{cursor:'pointer'}}
                 onClick={proceed}>
                  <img
                    className={offerstyles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder1.svg"
                  />
                  <b className={offerstyles.uploadAnImage}>Promote Deal of the day</b>
                  <img
                    className={offerstyles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder1.svg"
                  />
                </div>
              </div>
            </div>

          }
          
        </div>
      </div>
      {isPreview && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setPreviewPopupOpen(false)}
        >
          <PreviewModal 
          onClose={()=>setPreviewPopupOpen(false)} 
          tags={tags}
          type={type}
          image={image}
          time={time}
          date={date}
          disc={disc}
          type_offer={type_offer}
          book={book}
          spend={spend}
          cust={cust}
          />
        </PortalPopup>
      )}
      {isPayPromo && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setPromoPayPopupOpen(false)}
        >
          <PromoPayModal onClose={()=>setPromoPayPopupOpen(false)} 
          onClose_suc={()=>{
            setPromoSuccPopupOpen(true)
            setPromoPayPopupOpen(false)
          }}
          done={()=>{
            paidfor()
            setPromoSuccPopupOpen(true)
            setPromoPayPopupOpen(false)
            }}
            data={{
              type:type,
              image:image,
              lat:lat,
              lng:lng,
              time:type=="Deal of the day"?currentTimeString:time,
              date:type=="Deal of the day"?formattedDate:date,
              disc:disc,
              type_offer:type_offer,
              unix: Date.now(),
              stamp: serverTimestamp(),
              book:book,
              spend:spend,
              cust:cust,
              tags,
              id: db.collection('tmp').doc().id,
              uid: auth.currentUser.uid,
             name:name
            }}
            amount={cost_no}/> 
        </PortalPopup>
      )}
       {isPromoSucc && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setPromoSuccPopupOpen(false)}
        >
          <PaymentSuccessful onClose={()=>{
            setPromoSuccPopupOpen(false)
            navigate('/my_current_promo')
          }} />
        </PortalPopup>
      )}
      {isPromoSubmited && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setPromoSubmitPopupOpen(false)}
        >
          <Promosubmited onClose={()=>{
            setPromoSubmitPopupOpen(false)
            navigate('/my_current_promo')
          }} />
        </PortalPopup>
      )}
    </div>
  );
};

export default Promotion;
