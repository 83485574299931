import { useNavigate } from "react-router-dom";
import styles from "./ReqMessageComp.module.css";

const ReqMessageComp = ({greetingText,time,title,desc}) => {
  const navigate=useNavigate()
  return (
    <div className={styles.reqmessagecomp}>
      <div className={styles.frameParent}>
        <div className={styles.requestForElectricianParent}>
          <div className={styles.requestForElectrician}>
            Request for {title}
          </div>
          <div className={styles.imInNeed}>
           {desc}
          </div>
        </div>
        <button className={styles.smallButtons} onClick={()=>{navigate('/createquote/new')}}>
          <div className={styles.buttonWrapper}>
            <div className={styles.button}>Draft Quote</div>
          </div>
        </button>
      </div>
      <div className={styles.helloIAmInterestedInThisParent}>
        <div className={styles.helloIAm}>
         {greetingText}
        </div>
        <div className={styles.pm}>{time}</div>
      </div>
    </div>
  );
};

export default ReqMessageComp;
