import { useCallback, useState } from "react";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ResetPassword.module.css";
import { auth } from "../firebase";
import axios from "axios";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const onLargeButtonsClick = async() => {
    // Validate email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.match(emailRegex)) {
      setAlertMessage("Invalid email format.");
      setAlertOpen(true);
      return;
    }

      // Send password reset email
      if (email) {
     
        const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/reset-password', { email:email});
       console.log(response.data.message)
       const customUrl = `https://www.professional.loggworks.co.uk/confirm-password/`+response.data.message;
       fetch('https://loggworks-c15c9e56f169.herokuapp.com/resetpass', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify({
       to:email,
       name: 'user',
       url: customUrl,
     }),
   })
   navigate("/confirmreset");
      }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const onLargeButtonsClick1 = useCallback(() => {
    navigate("/confirmreset");
  }, [navigate]);

  const onBackToLoginClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.resetpassword}>
      <div className={styles.logo}>
        <img className={styles.logoChild} alt="" src="/group-66524.svg" />
        <img className={styles.logoItem} alt="" src="/group-66525.svg" />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.frameGroup}>
            <div className={styles.resetPasswordParent}>
              <b className={styles.resetPassword}>Reset Password</b>
              <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>

              <div className={styles.pleaseEnterYour}>
                Please enter your email address and we will send you a password
                reset link
              </div>
            </div>
            <div className={styles.verifyForm}>
              <TextField
                className={styles.verifyFormChild}
                fullWidth
                color="primary"
                variant="outlined"
                type="text"
                placeholder="Email"
                size="medium"
                margin="none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className={styles.largeButtons}
                onClick={onLargeButtonsClick}
              >
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder41.svg"
                />
                <b className={styles.label}>Send reset link</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder41.svg"
                />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.backToLogin} onClick={onBackToLoginClick}>
          Back to Login
        </div>
      </div>
      <div className={styles.privacyPolicy}  
       style={{cursor:'pointer'}}
      onClick={()=>{window.open('http://www.loggworks.co.uk/privacy', '_blank');}}>Privacy policy</div>
    </div>
  );
};

export default ResetPassword;
