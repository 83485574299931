import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import {Grid} from '@mui/material';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import styles from "./Map.module.css";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  thumb: {
    width: '40px', // Adjust the width of the thumb
    height: '20px', // Adjust the height of the thumb
    borderRadius: '0', // Set border-radius to 0 for a rectangular shape
    color: 'black',
  },
  track: {
    color: 'black',
  },
  rail: {
    color: 'grey', // Set the color of the empty track to grey
  },
});

const mapContainerStyle = {
  width: '100%',
  height: '158px',
};
const Input = styled(MuiInput)`
  width: 42px;
`;
const center = {
  lat: 0, // Default center latitude
  lng: 0, // Default center longitude
};

const radiusOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
};

const MapSelect = ({location, cost}) => {
  const [currentLocation, setCurrentLocation] = useState(center);
  const [radius, setRadius] = useState(0);
  const [userLocations, setUserLocations] = useState([]);
  const [numUsersWithinRadius, setNumUsersWithinRadius] = useState(0);
  const [sub, setSub] = useState(1)
  const classes = useStyles();

  useEffect(() => {
    db.collection('users')
    const fetchUserLocations = async () => {
      navigator.geolocation.getCurrentPosition(function(position) {
      setCurrentLocation({
        lat:position.coords.latitude,
        lng:position.coords.longitude
      })
       location(
       position.coords.latitude,
       position.coords.longitude
       )
    });
      const usersCollectionRef = collection(db, 'customers');
      const q = query(usersCollectionRef);

      const querySnapshot = await getDocs(q);
      const locations = [];

      querySnapshot.forEach((doc) => {
        const { lat, lng } = doc.data();
        locations.push({ lat, lng });
      });

      setUserLocations(locations);
    };
     const fetchData = async () => {
     await new Promise(resolve => setTimeout(resolve, 1000));
    if (auth.currentUser) {
      try {
        const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
        if (snapshot.exists) {
              setSub(snapshot.data().subType)
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    }
  };
  fetchData();
    fetchUserLocations();
  }, []);

  const handleMapClick = (event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    location(event.latLng.lat(),event.latLng.lng())
    setCurrentLocation(newLocation);
  };

  const handleRadiusChange = (event, value) => {
    setRadius(value);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadius(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (radius < 0) {
      setRadius(0);
    } else if (radius > 100) {
      setRadius(100);
    }
  };

  const handleGoToCurrentLocation = () => {
     navigator.geolocation.getCurrentPosition(function(position) {
      setCurrentLocation({
        lat:position.coords.latitude,
        lng:position.coords.longitude
      })
       location(
       position.coords.latitude,
       position.coords.longitude
       )
    });
  };

  useEffect(() => {
    // Count the number of users within the radius
    const countUsersWithinRadius = () => {
      let count = 0;

      userLocations.forEach((location) => {
        const distance = calculateDistance(currentLocation, location);
        if (distance <= radius) {
          count++;
        }
      });

      setNumUsersWithinRadius(count);
      console.log(count)
      cost(count)
    };

    countUsersWithinRadius();
  }, [radius, currentLocation, userLocations]);

  const calculateDistance = (location1, location2) => {
    const lat1 = location1.lat;
    const lon1 = location1.lng;
    const lat2 = location2.lat;
    const lon2 = location2.lng;

    // Calculation of distance between two latitudes and longitudes
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };

  return (
    <>
    <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={radius ? Math.floor(14 - Math.log2(radius)) : 2}
        center={currentLocation}
        onClick={handleMapClick}
      >
      <Marker position={currentLocation} animation="BOUNCE"/>
        {userLocations.map((location, index) => (
          <Marker key={index} position={location} />
        ))}
        {radius > 0 && <Circle center={currentLocation} radius={radius * 1000} options={radiusOptions} />}
      </GoogleMap>
      <div style={{width:'100%'}}>
        <br/>
      <div className={styles.iconslocationParent} style={{cursor:'pointer'}} 
            onClick={handleGoToCurrentLocation}>
              <img
                className={styles.iconsclose}
                alt=""
                src="/iconslocation1.svg"
              />
              <div className={styles.useMyCurrent}>Use my current location</div>
            </div>
            <br/>
            <b>Reach</b>
      <Grid container spacing={2} alignItems="center" style={{width:'100%'}}>
        <Grid item xs>
        <Slider
        value={radius}
        min={0}
        max={100}
        step={1}
        onChange={handleRadiusChange}
        style={{
          marginLeft: '10px',
        }}
        classes={{ thumb: classes.thumb , track: classes.track, rail: classes.rail}}
      />
        </Grid>
        <Grid item>
          <Input
            value={radius}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      <p>There are <span 
      style={{padding:'5px 20px',borderStyle:'solid',borderColor:'grey',borderWidth:'1px',background:'#f5f5f5',borderRadius:'2px'}}>{numUsersWithinRadius}</span> no of users within <b>{radius} km/miles</b> radius from you</p>
      </div>
     
      
    </>
  );
};

export default MapSelect;
