import { useCallback } from "react";
import { TextField, InputAdornment, Icon, IconButton, Avatar } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import ChatContainer from "../components/ChatContainer";
import styles from "./Chat.module.css";
import AttachmentIcon from '@mui/icons-material/Attachment';
import MicIcon from '@mui/icons-material/Mic';
import Menu from '@mui/material/Menu';
import Attachment from "../components/Attachment";
import FrameComponent from "../components/FrameComponent";
import PortalPopup from "../components/PortalPopup";
import SelectQuote from "../components/SelectQuote";
import RefDescription from "../components/RefDescription";
import ReqMessageComp from "../components/ReqMessageComp";
import posthog from 'posthog-js'

 

const Chat = () => {
  const navigate = useNavigate();
     let {id} = useParams()
  const [list, setList]= useState([])
    const [prof, setProf]= useState('')
  const [name, setName]= useState('')
  const [messages, setMessages]= useState([])
  const [text, setText]= useState('')
  const [attach, setAttach] = useState(false)
  const [profile, setProfile] = useState('')
   const [anchorEl, setAnchorEl] = useState(null);
   const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const open = Boolean(anchorEl);
   const [isQuote, setQuotePopupOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElMob, setAnchorElMob] = useState(null);
  const openMob = Boolean(anchorElMob);
  const handleClickMob = (event) => {
    setAnchorElMob(event.currentTarget);
  };
  const handleCloseMob = () => {
    setAnchorElMob(null);
  };
   
  useEffect(() => {
     (async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    if (auth.currentUser) {
      let cald = [];
      
      db.collection('contacts/' + auth.currentUser.uid + '/my/')
        .orderBy('timestamp', 'desc')
        .get()
        .then(snapshot => {
          snapshot.forEach(snap => {
            cald.push(snap.data());
          });
          setList(cald);
        });
         db.collection('customers').doc(id).get().then(snapshot=>{
      setName(snapshot.data().fname+' '+snapshot.data().lname)
     setProfile(snapshot.data().profileImage)
    })
   db.collection('messages/'+auth.currentUser.uid+'/'+id).orderBy('stamp','asc').onSnapshot(snapshot=>{
    let list = []
    snapshot.forEach(snap => {
              list.push(snap.data());
          });
    setMessages(list)
   })
    }
  })();
  const savedTitle = localStorage.getItem('title');
  const savedDesc = localStorage.getItem('desc');
  if (savedTitle !== null) {
    setTitle(savedTitle);
    localStorage.removeItem('title'); // Clear after loading
  }
  
  if (savedDesc !== null) {
    setDesc(savedDesc);
    localStorage.removeItem('desc'); // Clear after loading
  }
  }, [])
  const onText=(e)=>{
    setText(e.target.value);
  }
  
  const onSend=(e)=>{
    if(e.key == 'Enter'){
      
      var date = new Date();
       var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  if(title==''){
         db.collection('messages/'+auth.currentUser.uid+'/'+id).add({
      message: e.target.value,
      senderid: 'proff',
      time: strTime,
      stamp: serverTimestamp(),
    })
     db.collection('cust_messages/'+id+'/'+auth.currentUser.uid).add({
      message: e.target.value,
      senderid: 'proff',
      time: strTime,
      stamp: serverTimestamp()
    })
    let key = db.collection("tmp").doc().id
    db.collection('users').doc(auth.currentUser.uid).get().then(snaps=> {
      db.collection('cust_contacts/'+id+'/my/').where('uid','==',auth.currentUser.uid).get().then(snapshot=>{
        if(snapshot.empty)
        {
          db.collection('cust_contacts/'+id+'/my/').doc(key).set({
      uid:auth.currentUser.uid,
      last: e.target.value,
      unix: Date.now(),
      timestamp: serverTimestamp(),
      name: snaps.data().name,
      key: key,
      desc: desc
    }) 
     }
     else
        {
         snapshot.forEach((snap) => {
        const key_id = snap.data().key;
        db.collection(`cust_contacts/${id}/my`).doc(key_id).update({
          last: e.target.value,
          unix: Date.now(),
          timestamp: serverTimestamp(),
          name: snaps.data().name,
          desc: desc
        });
      });
        }
      })
      db.collection('customers').doc(cid).get().then(function (customer) {
        if (customer.data().pushNotifications) {
            db.collection('customers').doc(id).collection('noti_m').add({
        last: e.target.value,
        name: snaps.data()?.name,
       })
        }
      })
    
      })
    }
    else{
      db.collection('messages/'+auth.currentUser.uid+'/'+id).add({
        message: e.target.value,
        senderid: 'proref',
        time: strTime,
        stamp: serverTimestamp(),
        title: title,
        desc:desc
      })
       db.collection('cust_messages/'+id+'/'+auth.currentUser.uid).add({
        message: e.target.value,
        senderid: 'proref',
        time: strTime,
        stamp: serverTimestamp(),
        title: title,
        desc:desc
      })
      let key = db.collection("tmp").doc().id
      db.collection('users').doc(auth.currentUser.uid).get().then(snaps=> {
        db.collection('cust_contacts/'+id+'/my/').where('uid','==',auth.currentUser.uid).get().then(snapshot=>{
          if(snapshot.empty)
          {
            db.collection('cust_contacts/'+id+'/my/').doc(key).set({
        uid:auth.currentUser.uid,
        last: e.target.value,
        unix: Date.now(),
        timestamp: serverTimestamp(),
        name: snaps.data().name,
        key: key,
      }) 
       }
       else
          {
           snapshot.forEach((snap) => {
          const key_id = snap.data().key;
          db.collection(`cust_contacts/${id}/my`).doc(key_id).update({
            last: e.target.value,
            unix: Date.now(),
            timestamp: serverTimestamp(),
            name: snaps.data().name,
          });
        });
          }
        })
        db.collection('customers').doc(cid).get().then(function (customer) {
          if (customer.data().pushNotifications) {
            db.collection('customers').doc(id).collection('noti_m').add({
          last: e.target.value,
          name: snaps.data()?.name,
         })
          }
        })
        
        })
    }
    db.collection('users').doc(auth.currentUser?.uid).get().then(function (prof) {
      db.collection('customers').doc(`${id}`).get().then(function (cust) {
        db.collection('customers').doc(cid).get().then(function (customer) {
          if (customer.data().emailNotifications) {
            fetch('https://loggworks-c15c9e56f169.herokuapp.com/message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: cust.data().email,
            name: cust.data().fname+' '+cust.data().lname,
            user: prof.data().name,
            msg: text
          }),
        })
          }
        })
        
      })
    })
    posthog.capture('message/quote sent',)
    setTitle('')
    setDesc('')
    setText(''); 
    }
  }
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    // Please sync "HOME" to the project
  }, []);

  const onDefaultContainer5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  return (
    <div className={styles.chat}>
      <div className={styles.signup}>
        <div className={styles.chatHeader}>
          <div className={styles.groupParent}>
            <div className={styles.ellipseParent}>
              <Avatar
                className={styles.groupChild}
                alt=""
                src={''}
              />
              <b className={styles.peoplename}>{name}</b>
            </div>
            <img className={styles.backIcon} alt="" src="/back.svg" onClick={()=>{navigate(-1)}}/>
          </div>
       
        </div>
        <div className={styles.groupContainer}>
          <div className={styles.groupWrapper}>
            <div className={styles.rectangleParent}>
              <div className={styles.groupItem} />
              <div className={styles.today}>Today</div>
            </div>
          </div>
         <div style={{width:'100%',height:'70vh',overflowY:'auto'}}>
      {
        messages.map(data=>{
          return(
            <div>
                
                {
                  data.senderid=='proff'?
                  <div style={{marginTop:'1rem'}}>
                     <ChatContainer greetingText={data.message}  time={data.time}/>
                  </div>
                 
                  :data.senderid=='qoute'?
                  <div style={{marginTop:'1rem'}}>
                    <div className={styles.chatLeft2}>
            <img
              className={styles.chatRightChild}
              alt=""
              src="/vector-262.svg"
            />
            <div className={styles.pmParent}>
            <div className={styles.pm4} style={{display:'flex',justifyContent:'space-between'}}>
                <a>{data.time}</a>
                {data.status && (
  <div className={styles.acceptRequest}>
    <button
      style={{
        position:'relative',
        textTransform: 'capitalize',
        color: data.status === 'decl' ? '#F02D16' : '#2FA500',
        fontWeight: 'bold',
      }}
    >
      {data.status === 'decl' ? 'Declined' : 'Paid'}
    </button>
  </div>
)}

                </div>
              <div className={styles.quote}>
                <div className={styles.quoteBtn}>
                  <img
                    className={styles.quoteBtnChild}
                    alt=""
                    src="/rectangle-12260@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
                  </div>
                   :data.senderid=='proref'?
                   <div style={{marginTop:'1rem'}}>
                    <div className={styles.chatRight}>
                   <ReqMessageComp greetingText={data.message} 
                   title={data.title} time={data.time}
                   desc={data.desc}/>
                   </div>
                </div>
                  :
                  <div style={{marginTop:'1rem'}}>
                     <div className={styles.chatLeft}>
              <img
                className={styles.chatRightChild}
                alt=""
                src="/vector-26.svg"
              />
              <div className={styles.chatLeftInner2}>
                <div className={styles.hiDonaldNiceToMeetYouHParent}>
                  <div className={styles.hiDonaldNice}>
                   {data.message}
                  </div>
                  <div className={styles.pm5}>{data.time}</div>
                </div>
              </div>
            </div>
                  </div>
                  
                 
                }
            </div>
          )
        })
      }
      </div>
        </div>
        <div className={styles.chatInput}>
            <Menu
        id="demo-positioned-menuMob"
        aria-labelledby="demo-positioned-buttonMob"
        anchorEl={anchorElMob}
        open={openMob}
        onClose={handleCloseMob}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
       <FrameComponent
        onQuote={()=>{
            setQuotePopupOpen(true)
            handleCloseMob()
            }}
       />
      </Menu>
      { title!=''?<RefDescription
            title={title}
            desc={desc}
            />:null}
          <div className={styles.cameraParent}>
            <TextField
              className={styles.frameChild}
              fullWidth
              color="primary"
              variant="outlined"
              type="text"
               value={text}
          onChange={onText}
          onKeyDown={onSend}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon 
                     id="demo-positioned-buttonMob"
        aria-controls={openMob ? 'demo-positioned-menuMob' : undefined}
        aria-haspopup="true"
        aria-expanded={openMob ? 'true' : undefined}
        onClick={handleClickMob}
                    >attachment_sharp</Icon>
                  </InputAdornment>
                ),
              }}
              placeholder="Type something"
              size="medium"
              margin="none"
            />
           { /*<img className={styles.cameraIcon} alt="" src="/mic.svg" />*/}
          </div>
        </div>
      </div>
      <div className={styles.home}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-665222.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.cameraIcon}
                  alt=""
                  src="/iconshome5.svg"
                />
                <div className={styles.home1}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.cameraIcon}
                  alt=""
                  src="/iconsjobs.svg"
                />
                <div className={styles.home1}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.cameraIcon}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home1}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.cameraIcon}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home1}>Wallet</div>
              </div>
            </div>
            <div className={styles.default4} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.cameraIcon}
                  alt=""
                  src="/iconsmessage1.svg"
                />
                <div className={styles.home5}>Message</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer5Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account4.svg"
                />
                <div className={styles.home1}>Profile</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.avatarnameWrapper}>
            <div className={styles.avatarname}>
              <b className={styles.peoplename1}>Messages</b>
            </div>
          </div>
          <div className={styles.chatParent}>
        
           {
        list.map(data=>{
          return(
              <div 
          className={id==data.uid?styles.chat1:styles.chat2}
           onClick={()=>{navigate('/chat/'+data.uid)}}
          >
              <div className={styles.avatarParent}>
               <Avatar
               src={data.profileImage||''}
               />
                <div className={styles.namedateParent}>
                  <div className={styles.namedate}>
                    <b className={styles.peoplename2}>{data.name}</b>
                    <div className={styles.yesterday}>{moment(data.unix).fromNow()}</div>
                  </div>
                  <div className={styles.msgCounter}>
                    <div className={styles.citiessummary}>
                     {data.last}
                    </div>
                    <div className={styles.chatCounter}>
                      <div className={styles.div}>2+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
          </div>
          <div className={styles.input}>
            <div style={{width:'100%'}}>
        <TextField 
        label='Search a name'
        fullWidth
        />
        </div>
          </div>
        </div>
        <div className={styles.chatHeaderParent}>
          <div className={styles.chatHeader1}>
            <div className={styles.avatarname1}>
             <Avatar
             src={profile}
             />
              <div className={styles.peoplename8}>{name}</div>
            </div>
          </div>
          <div className={styles.groupParent1}>
            <div className={styles.rectangleGroup}>
              <div className={styles.groupInner} />
              <div className={styles.today1}>Today</div>
            </div>
            <div style={{width:'100%',height:'60vh',overflowY:'auto'}}>
      {
        messages.map(data=>{
          return(
            <div>
                
                {
                  data.senderid=='proff'?
                  <div style={{marginTop:'1rem'}}>
                     <ChatContainer greetingText={data.message}  time={data.time}/>
                  </div>
                  :data.senderid=='proref'?
                  <div style={{marginTop:'1rem'}}>
                    <div className={styles.chatRight}>
                  <ReqMessageComp greetingText={data.message} 
                  title={data.title} time={data.time}
                  desc={data.desc}/>
                  </div>
                  </div>
                  :data.senderid=='qoute'?
                  <div style={{marginTop:'1rem'}}>
                    <div className={styles.chatRight}>
            <img
              className={styles.chatRightChild}
              alt=""
              src="/vector-262.svg"
            />
            <div className={styles.pmParent}>
             
              <div className={styles.quote}>
                <div className={styles.quoteBtn}>
                  <img
                    className={styles.quoteBtnChild}
                    alt=""
                    src="/rectangle-12260@2x.png"
                  />
                </div>
              </div> 
              <div className={styles.pm4} style={{display:'flex',justifyContent:'space-between'}}>
                <a>{data.time}</a>
                {data.status && (
  <div className={styles.acceptRequest}>
    <button
      style={{
        position:'relative',
        textTransform: 'capitalize',
        color: data.status === 'decl' ? '#F02D16' : '#2FA500',
        fontWeight: 'bold',
      }}
    >
      {data.status === 'decl' ? 'Declined' : 'Paid'}
    </button>
  </div>
)}

                </div>
             
            </div>
          </div>
                  </div>
                 
                  :
                  <div style={{marginTop:'1rem'}}>
                     <div className={styles.chatLeft}>
              <img
                className={styles.chatRightChild}
                alt=""
                src="/vector-26.svg"
              />
              <div className={styles.chatLeftInner2}>
                <div className={styles.hiDonaldNiceToMeetYouHParent}>
                  <div className={styles.hiDonaldNice}>
                   {data.message}
                  </div>
                  <div className={styles.pm5}>{data.time}</div>
                </div>
              </div>
            </div>
                  </div>
                  
                 
                }
            </div>
          )
        })
      }
      </div>
          </div>
          <div className={styles.input1}>
           { title!=''?<RefDescription
            title={title}
            desc={desc}
            />:null}
           <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
       <Attachment
        onQuote={()=>{
            setQuotePopupOpen(true)
            handleClose()
            }}
       />
      </Menu>
           <TextField
      className={styles.messageInput}
      fullWidth
      color="primary"
      variant="outlined"
      type="text"
      label="Type something"
      size="medium"
      margin="none"
       value={text}
          onChange={onText}
          onKeyDown={onSend}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
            id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
            >
              <AttachmentIcon />
            </IconButton>
            <IconButton>
              <MicIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
          </div>
        </div>
      </div>
       {isQuote && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setQuotePopupOpen(false)}
        >
         <SelectQuote
          onClose={()=>setQuotePopupOpen(false)}
         />
        </PortalPopup>
      )}
    </div>
  );
};

export default Chat;
