import { useCallback } from "react";
import { TextField, InputAdornment, Icon, Avatar } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import styles from "./Message.module.css";
const Message = () => {
  const navigate = useNavigate();
    let {id} = useParams()
  const [list, setList]= useState([])
   
  useEffect(() => {
     (async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    if (auth.currentUser) {
      let cald = [];
      
      db.collection('contacts/' + auth.currentUser.uid + '/my/')
        .orderBy('timestamp', 'desc')
        .get()
        .then(snapshot => {
          snapshot.forEach(snap => {
            cald.push(snap.data());
          });
          setList(cald);
        });
    }
  })();
  }, [])
 
  const onFrameContainer5Click = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  const onChatContainerClick = useCallback(() => {
    navigate("/chat");
  }, [navigate]);

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    // Please sync "HOME" to the project
  }, []);
  const onDefaultContainer5Click = useCallback(() => {
     navigate("/profile");
  }, []);

  const onChatContainer6Click = useCallback(() => {
    navigate("/chat");
  }, [navigate]);
   const chatStyle = useMemo(() => {
   
  }, []);

  return (
    <div className={styles.message}>
      <div className={styles.signup}>
        <div className={styles.titles}>
          <div className={styles.back}  onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.activities}>Messages</div>
        </div>
        <div className={styles.inputWrapper}>
          <TextField
            className={styles.input}
            fullWidth
            color="primary"
            variant="outlined"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search_sharp</Icon>
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            size="medium"
            margin="none"
          />
        </div>
        <div className={styles.instanceParent}>
        {
        list.map(data=>{
          return(
            <div className={styles.frameParent} onClick={()=>{navigate('/chat/'+data.uid)}}>
            <div className={styles.peopleavatarParent}>
              <Avatar
                className={styles.peopleavatarIcon}
                alt=""
                src={''}
              />
              <div className={styles.frameGroup}>
                <div className={styles.peoplenameParent}>
                  <div className={styles.peoplename}>{data.name}</div>
                  <div className={styles.yesterday}>{moment(data.unix).fromNow()}</div>
                </div>
                <div className={styles.citiessummaryParent}>
                  <div className={styles.citiessummary}>
                  {data.last}
                  </div>
                  <div className={styles.rectangleParent}>
                    <div className={styles.groupChild} />
                    <div className={styles.div}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.instanceChild} />
          </div>
          )
        })
      }
        
        
        </div>
        <div className={styles.chatParent}>
        {
        list.map(data=>{
          return(
             <div
          className={styles.chat}
          style={id==data.uid?chatStyle:null}
          onClick={()=>{navigate('/chat/'+data.uid)}}
        >
          <div className={styles.avatarParent}>
            <Avatar className={styles.avatarIcon} alt="" src={" "}/>
            <div className={styles.namedateParent}>
              <div className={styles.namedate}>
                <div className={styles.peoplename1}>{data.name}</div>
                <div className={styles.yesterday}>{moment(data.unix).fromNow()}</div>
              </div>
              <div className={styles.msgCounter}>
                <div className={styles.citiessummary}>
                  {data.last}
                </div>
                <div className={styles.chatCounter}>
                  <div className={styles.div}>2+</div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        })
      }
        </div>
      </div>
      <div className={styles.home}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-66522.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome4.svg"
                  />
                  <div className={styles.home1}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs2.svg"
                  />
                  <div className={styles.home1}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity2.svg"
                  />
                  <div className={styles.home1}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash2.svg"
                  />
                  <div className={styles.home1}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default4}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage2.svg"
                  />
                  <div className={styles.home5}>Message</div>
                </div>
              </div>
              <div className={styles.default5}
              onClick={onDefaultContainer5Click} style={{cursor:'pointer'}}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home1}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent7}>
          <div className={styles.avatarnameWrapper}>
            <div className={styles.avatarname}>
              <b className={styles.peoplename11}>Messages</b>
            </div>
          </div>
          <div className={styles.chatGroup}>
          {
        list.map(data=>{
          return(
             <div
          className={styles.chat}
          style={id==data.uid?chatStyle:null}
          onClick={()=>{navigate('/chat/'+data.uid)}}
        >
          <div className={styles.avatarParent}>
            <Avatar className={styles.avatarIcon} alt="" src={""} />
            <div className={styles.namedateParent}>
              <div className={styles.namedate}>
                <b className={styles.peoplename1}>{data.name}</b>
                <div className={styles.yesterday}>{moment(data.unix).fromNow()}</div>
              </div>
              <div className={styles.msgCounter}>
                <div className={styles.citiessummary}>
                  {data.last}
                </div>
                <div className={styles.chatCounter}>
                  <div className={styles.div}>2+</div>
                </div>
              </div>
            </div>
          </div>
        </div>
          )
        })
      }
          </div>
          <div className={styles.input1}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Label</div>
            </div>
           <div style={{width:'100%'}}>
        <TextField 
        label='Search a name'
        fullWidth
        />
        </div>
            
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.inboxLineParent}>
            <img
              className={styles.inboxLineIcon}
              alt=""
              src="/inbox-line.svg"
            />
            <b className={styles.inbox}>Inbox</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
