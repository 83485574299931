import React, { useEffect, useState } from 'react'
import styles from "../pages/Home.module.css";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, styled } from '@mui/material';
import { auth, db } from '../firebase';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
function MobileHeader() {
    const navigate = useNavigate()
    const [msg, setMsg]= useState([])
    const [activ, setActivity]= useState([])
    const location = useLocation(); // Use useLocation hook
    const pathname = location.pathname;
    useEffect(() => {
      const collectionRef = db.collection('users').doc(auth.currentUser.uid).collection('noti_m');
      const collectionRef2 = db.collection('users').doc(auth.currentUser.uid).collection('noti_act');
      const updatedList = [];
      const actList = [];
// Subscribe to real-time updates using onSnapshot
const unsubscribe = collectionRef.onSnapshot((snapshot) => {
  // Handle the snapshot changes
  snapshot.forEach((doc) => {
    const docData = doc.data();
    updatedList.push(docData);
  });
  setMsg(updatedList)
});
const unsubscribe2 = collectionRef2.onSnapshot((snapshot) => {
  // Handle the snapshot changes
  snapshot.forEach((doc) => {
    const docData = doc.data();
    actList.push(docData);
  });
  setActivity(actList)
});

return () => {
  unsubscribe();
  unsubscribe2();
};
    }, [pathname])
    
  return (
    <div className={styles.header}>
            <img
              className={styles.brandLogoIcon}
              alt=""
              src="/brand-logo1.svg"
            />
            <img className={styles.headerChild} alt="" src="/frame-66953.svg" />
            <div className={styles.frame}>
              <div className={styles.frameParent} onClick={()=>navigate('/activities')}>
              <StyledBadge badgeContent={activ.length} color="primary" >
                <img
                src='/iconsactivity.svg'
                />
              </StyledBadge>
                
              </div>
              <div className={styles.frameGroup} onClick={()=>navigate('/messages')}>
              <StyledBadge badgeContent={msg.length} color="primary" >
                 <img
                src='/iconsmessage.svg'
                />
              </StyledBadge>
              </div>
            </div>
          </div>
  )
}

export default MobileHeader