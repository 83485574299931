import { useCallback } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./MyNetwork.module.css";

const MyNetwork = () => {
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onInputClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.mynetwork}>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-665221.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconshome3.svg"
                />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home}>Wallet</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home}>Message</div>
              </div>
            </div>
            <div className={styles.default5}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account2.svg"
                />
                <div className={styles.home5}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.component102Parent}>
        <div className={styles.component102}>
          
          <img className={styles.avatarIcon} alt="" src="/avatar1.svg" />
          <b className={styles.licenseAndCertification}>My Network</b>
          <div className={styles.icon} style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
            <img
              className={styles.iconshome}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.titles}>
          <div className={styles.back}  onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.notifications}>My Network</div>
        </div>
        <div className={styles.input}>
 <TextField
          color="primary"
          placeholder="Search professionals to add to your network"
          fullWidth={true}
          variant="outlined"
        />
        </div>
       
        <div className={styles.myNetworkParent}>
          <img className={styles.myNetworkIcon} alt="" src="./my-network.svg" />
          <div className={styles.searchAProfessional}>
            Search a professional in your network and add to your network in
            Loggworks.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNetwork;
