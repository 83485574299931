import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CurandPastPromo.module.css";
import { auth, db } from "../firebase";
import moment from "moment";
import { Avatar, Menu, MenuItem } from "@mui/material";
import PortalPopup from "../components/PortalPopup";
import EndPromo from "../components/EndPromo";
const CurandPastPromo = () => {
  const navigate = useNavigate();
  const [tab,setTab]=useState(0)
  const [offers, setOffers] = useState([])
  const [deals, setDealsOfDay] = useState([])
  const [myprofile, setMyProfile] = useState([])
  const [profile,setProfile]=useState('')
  const [cate, setCate]=useState('')
  const [gal, setGal]=useState('')
  const [selected, setSelected]=useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal,setModal]=useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (auth.currentUser) {
        try {
          const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
          if (snapshot.exists) {
            setProfile(snapshot.data().profileImage)
            setCate(snapshot.data().category)
          }
  
          db.collection('my_promo/' + auth.currentUser.uid + '/list').orderBy('stamp','desc').onSnapshot((snapshot) => {
            let offers = [];
            let dealsOfDay = [];
            let myProfile = [];
  
            const currentTime = new Date();
  
            snapshot.forEach((snap) => {
              const data = snap.data();
              const { date, time, type } = data;
              const dateTime = new Date(`${date}T${time}`);

              data.id = snap.id;
              // Check if the data is in the past
              if (dateTime >= currentTime) {
                if (type === 'Offer') {
                  offers.push(data);
                } else if (type === 'Deal of the day') {
                  dealsOfDay.push(data);
                } else if (type === 'My Profile') {
                  myProfile.push(data);
                }
              }
            });
  
            setOffers(offers);
            setDealsOfDay(dealsOfDay);
            setMyProfile(myProfile);
          });
        } catch (error) {
          console.log('Error fetching user data:', error);
        }
      }
    };
    fetchData();
    const unsubscribe = db.collection('users')
    .doc(auth.currentUser.uid)
    .collection('gallery').onSnapshot((snapshot) => {
      const images = [];
      snapshot.forEach((doc) => {
        images.push(doc.data().imageUrl);
      });
       // Set the first image as imagePreview if available
       if (images.length > 0) {
         setGal(images[0]);
      }
    });
   
    // Unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  function calculateTimeRemaining(targetDate) {
    // Parse the target date string to create a Date object
    const targetDateTime = new Date(targetDate);
  
    // Get the current date and time
    const now = new Date();
  
    // Calculate the time difference in milliseconds
    const timeDifference = targetDateTime - now;
  
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
    // Format the result
    const formattedTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;
  
    return formattedTime;
  }
  
  const onFrameContainerClick = useCallback(() => {
    navigate("/promotion");
  }, [navigate]);

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onFrameContainer25Click = useCallback(() => {
    navigate("/promotion");
  }, [navigate]);

  const onFrameContainer27Click = useCallback(() => {
    navigate('/my_past_promo')
  }, []);


  return (
    <div className={styles.curandpastpromo}>
      <div className={styles.signup}>
        <div className={styles.titles}>
          <div className={styles.activities}>Promote profile</div>
          <div className={styles.back}>
            <div className={styles.back1} onClick={()=>navigate('/profile')}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
        </div>
        <div className={styles.profilePromotion}>
          <div className={styles.tab}>
            <div className={styles.frameParent}>
              <div
                className={styles.newWrapper}
                onClick={onFrameContainerClick}
              >
                <div className={styles.new}>New</div>
              </div>
              <div className={styles.currentWrapper}>
                <div className={styles.current}>Current</div>
              </div>
              <div className={styles.pastWrapper} onClick={onFrameContainer27Click}>
                <div className={styles.new}>Past</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.promotionSubTabs}>
          <div className={styles.promotionSubTabsChild} />
          <div className={styles.offerParent} onClick={()=>setTab(0)}>
            <div className={tab==0?styles.offer:styles.dealOfThe}>Offer</div>
            <div className={tab==0?styles.frameChild:styles.frameItem} />
          </div>
          <div className={styles.dealOfTheDayParent} onClick={()=>setTab(1)}>
            <div className={tab==1?styles.offer:styles.dealOfThe}>Deal of the day</div>
            <div className={tab==1?styles.frameChild:styles.frameItem} />
          </div>
          <div className={styles.profileParent} onClick={()=>setTab(2)}>
            <div className={tab==2?styles.offer:styles.dealOfThe}>Profile</div>
            <div className={tab==2?styles.frameChild:styles.frameItem} />
          </div>
        </div>
        {
          tab==0?
          offers.map(data=>{
            return(
              <div className={styles.current1}>
          <div className={styles.frameGroup}>
            <img
              className={styles.frameChild1}
              alt=""
              src={data.image}
            />
            <div className={styles.frameWrapper}>
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameDiv}>
                      <div className={styles.rectangleParent}>
                        <Avatar
                          className={styles.rectangleIcon}
                          alt=""
                          src={profile}
                        />
                        <div className={styles.dysonElectricalsParent}>
                          <div className={styles.dysonElectricals}>
                           {data.name}
                          </div>
                          <div className={styles.groupParent}>
                             {/*<img
                              className={styles.groupIcon}
                              alt=""
                              src="/group-666492.svg"
                            />
            <b className={styles.b}>4.0(20)</b>*/}
                          
                          </div>
                        </div>
                      </div>
                      <b className={styles.off5Weeks}>
                      {
              data.type_offer=='perc'?
              `Get ${data.disc}% off when you book us`
              :data.type_offer=='amount'?
               `Get ${data.disc}£ off when you book us`
              :data.type_offer=='spend'?
               `Get £${data.disc} off when you spend £${data.spend}`
              :data.type_offer=='spend%'?
               `Get ${data.disc}% off when you spend £${data.spend}`
              :data.type_offer=='book'?
               `Get £${data.disc} off when you book us for ${data.book}`
               :data.type_offer=='book%'?
               `Get ${data.disc}% off when you book us for ${data.book}`
              :
              data.cust
              }
                      </b>
                    </div>
                    {/*<div className={styles.electricalWorksMaintainance}>
                      Electrical works, maintainance and installations
            </div>*/}
                  </div>
                  <div className={styles.endsIn03Container}>
                    <span className={styles.endsIn}>Ends in:</span>
                    <b className={styles.days043440}>
                      <span className={styles.span}>{`  `}</span>
                      <span>{calculateTimeRemaining(`${data.date}T${data.time}:00`)}</span>
                    </b>
                  </div>
                </div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons2.svg" />
                    <b className={styles.off5Weeks}>Get quote</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </div>
               
            )
          })
          
          :tab==1?
          deals.map(data=>{
            return(
              <div className={styles.current1}>
          <div className={styles.frameGroup}>
            <img
              className={styles.frameChild1}
              alt=""
              src={data.image}
            />
            <div className={styles.frameWrapper}>
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameDiv}>
                      <div className={styles.rectangleParent}>
                        <img
                          className={styles.rectangleIcon}
                          alt=""
                          src={data.image}
                        />
                        <div className={styles.dysonElectricalsParent}>
                          <div className={styles.dysonElectricals}>
                           {data.name}
                          </div>
                          <div className={styles.groupParent}>
                             {/*<img
                              className={styles.groupIcon}
                              alt=""
                              src={"/group-666492.svg"}
                            />
            <b className={styles.b}>4.0(20)</b>*/}
                          </div>
                        </div>
                      </div>
                      <b className={styles.off5Weeks}>
                      {
              data.type_offer=='perc'?
              `Get ${data.disc}% off when you book us`
              :data.type_offer=='amount'?
               `Get ${data.disc}£ off when you book us`
              :data.type_offer=='spend'?
               `Get £${data.disc} off when you spend £${data.spend}`
              :data.type_offer=='spend%'?
               `Get ${data.disc}% off when you spend £${data.spend}`
              :data.type_offer=='book'?
               `Get £${data.disc} off when you book us for ${data.book}`
               :data.type_offer=='book%'?
               `Get ${data.disc}% off when you book us for ${data.book}`
              :
              data.cust
              }
                      </b>
                    </div>
                     {/*<div className={styles.electricalWorksMaintainance}>
                      Electrical works, maintainance and installations
            </div>*/}
                  </div>
                  <div className={styles.endsIn03Container}>
                    <span className={styles.endsIn}>Ends in:</span>
                    <b className={styles.days043440}>
                      <span className={styles.span}>{`  `}</span>
                      <span>{calculateTimeRemaining(`${data.date}T${data.time}:00`)}</span>
                    </b>
                  </div>
                </div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons2.svg" />
                    <b className={styles.off5Weeks}>Get quote</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </div>
               
            )
          })
          :
          myprofile.map(data=>{
            return(
              <div className={styles.current1}>
          <div className={styles.frameGroup}>
            <img
              className={styles.frameChild1}
              alt=""
              src={gal}
            />
            <div className={styles.frameWrapper}>
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <div className={styles.frameGroup}>
                    <div className={styles.frameDiv}>
                      <div className={styles.rectangleParent}>
                        <img
                          className={styles.rectangleIcon}
                          alt=""
                          src={profile}
                        />
                        <div className={styles.dysonElectricalsParent}>
                          <div className={styles.dysonElectricals}>
                           {data.name}
                          </div>
                          <div className={styles.groupParent}>
                          {/*<img
                              className={styles.groupIcon}
                              alt=""
                              src="/group-666492.svg"
                            />
            <b className={styles.b}>4.0(20)</b>*/}
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    <div className={styles.electricalWorksMaintainance}>
                      {cate}
                    </div>
                  </div>
                  <div className={styles.endsIn03Container}>
                    <span className={styles.endsIn}>Ends in:</span>
                    <b className={styles.days043440}>
                      <span className={styles.span}>{`  `}</span>
                      <span>{calculateTimeRemaining(`${data.date}T${data.time}:00`)}</span>
                    </b>
                  </div>
                </div>
                <div className={styles.button}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons2.svg" />
                    <b className={styles.off5Weeks}>Get quote</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </div>
               
            )
          })
        }
      </div>
      <div className={styles.pastpromos}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665224.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account3.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles1}>
            <div className={styles.back2} onClick={()=>navigate('/profile')}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.activities1}>Promote profile</div>
          </div>
          <div className={styles.component102}>
            <div className={styles.smallButtons}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd1.svg" />
                <div className={styles.button1}>Add Item</div>
              </div>
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar17.svg" />
            <b className={styles.licenseAndCertification}>Promotions</b>
            <div className={styles.icon}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
                onClick={()=>navigate('/profile')}
                style={{cursor:'pointer'}}
              />
            </div>
          </div>
          <div className={styles.promotionsTabPrototypeParent}>
            <div className={styles.promotionsTabPrototype}>
              <div className={styles.twoTabFlatSwitch}>
                <div
                  className={styles.tab1Wrapper}
                  onClick={onFrameContainer25Click}
                >
                  <div className={styles.tab1}>New</div>
                </div>
                <div className={styles.tab2Wrapper}>
                  <div className={styles.tab2}>Current</div>
                </div>
                <div
                  className={styles.tab3Wrapper}
                  onClick={onFrameContainer27Click}
                >
                  <div className={styles.tab1}>Past</div>
                </div>
              </div>
            </div>
            <div className={styles.promotionsSubTab}>
              <div className={styles.tabSwitch}>
                <div className={styles.tabSwitchChild} />
                <div className={styles.all} style={tab==0?
                  {color: `var(--primary-20base)`,
                  borderBottom: `2px solid var(--primary-20base)`}:{}}onClick={()=>{
                    setTab(0)
                  }}>
                  <div className={tab==0?styles.booking:styles.booking1}>Offer</div>
                </div>
                <div className={styles.all1} style={tab==1?
                  {color: `var(--primary-20base)`,
                  borderBottom: `2px solid var(--primary-20base)`}:{}} onClick={()=>{
                    setTab(1)
                  }}>
                  <div className={tab==1?styles.booking:styles.booking1}>Deal of the day</div>
                </div>
                <div className={styles.all2} style={tab==2?
                  {color: `var(--primary-20base)`,
                  borderBottom: `2px solid var(--primary-20base)`}:{}} onClick={()=>{
                    setTab(2)
                  }}>
                  <div className={tab==2?styles.booking:styles.booking1}>Profile</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.promotionsListParent}>
          {
          tab==0?
          offers.map(data=>{
            return(
              <div className={styles.promotionsList}>
              <div className={styles.rectangleGroup}>
                <img
                  className={styles.frameChild2}
                  alt=""
                  src={data.image}
                />
                <div className={styles.frameParent3}>
                  <div className={styles.frameParent4}>
                    <div className={styles.citiessummaryParent}>
                      <div className={styles.citiessummary}>
                      {
              data.type_offer=='perc'?
              `Get ${data.disc}% off when you book us`
              :data.type_offer=='amount'?
               `Get ${data.disc}£ off when you book us`
              :data.type_offer=='spend'?
               `Get £${data.disc} off when you spend £${data.spend}`
              :data.type_offer=='spend%'?
               `Get ${data.disc}% off when you spend £${data.spend}`
              :data.type_offer=='book'?
               `Get £${data.disc} off when you book us for ${data.book}`
               :data.type_offer=='book%'?
               `Get ${data.disc}% off when you book us for ${data.book}`
              :
              data.cust
              }
                      </div>
                      <img
                        className={styles.iconshome}
                        alt=""
                        src="/iconsmore-vertical3.svg"
                        style={{cursor:'pointer'}}
                        id={data.id}
                        aria-controls={open ? data.id : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e)=>{
                          setSelected(data.id)
                          handleClick(e)
                        }}
                      />
                    </div>
                    <div className={styles.frameWrapper1}>
                      <div className={styles.frameParent5}>
                        <div className={styles.iconscalendarParent}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconscalendar1.svg"
                          />
                          <div className={styles.home}>Ends:</div>
                          <div
                            className={styles.citiessummary2}
                          >{data.date}</div>
                        </div>
                        <div className={styles.iconsclockParent}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconsclock1.svg"
                          />
                          <div className={styles.citiessummary3}>{data.time}</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.price}>Price</div>
                  </div>
                  <div className={styles.citiessummary4}> {moment(data.unix).fromNow()}</div>
                </div>
              </div>
            </div>
            )
          })
          
          :tab==1?
          deals.map(data=>{
            return(
              <div className={styles.promotionsList}>
              <div className={styles.rectangleGroup}>
                <img
                  className={styles.frameChild2}
                  alt=""
                  src={data.image}
                />
                <div className={styles.frameParent3}>
                  <div className={styles.frameParent4}>
                    <div className={styles.citiessummaryParent}>
                      <div className={styles.citiessummary}>
                      {
               data.type_offer=='perc'?
               `Get ${data.disc}% off when you book us`
               :data.type_offer=='amount'?
                `Get ${data.disc}£ off when you book us`
               :data.type_offer=='spend'?
                `Get £${data.disc} off when you spend £${data.spend}`
               :data.type_offer=='spend%'?
                `Get ${data.disc}% off when you spend £${data.spend}`
               :data.type_offer=='book'?
                `Get £${data.disc} off when you book us for ${data.book}`
                :data.type_offer=='book%'?
                `Get ${data.disc}% off when you book us for ${data.book}`
               :
               data.cust
              }
                      </div>
                      <img
                        className={styles.iconshome}
                        alt=""
                        src="/iconsmore-vertical3.svg"
                        style={{cursor:'pointer'}}
                        id={data.id}
                        aria-controls={open ? data.id : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e)=>{
                          setSelected(data.id)
                          handleClick(e)
                        }}
                      />
                    </div>
                    <div className={styles.frameWrapper1}>
                      <div className={styles.frameParent5}>
                        <div className={styles.iconscalendarParent}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconscalendar1.svg"
                          />
                          <div className={styles.home}>Ends:</div>
                          <div
                            className={styles.citiessummary2}
                          >{data.date}</div>
                        </div>
                        <div className={styles.iconsclockParent}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconsclock1.svg"
                          />
                          <div className={styles.citiessummary3}>{data.time}</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.price}>Price</div>
                  </div>
                  <div className={styles.citiessummary4}> {moment(data.unix).fromNow()}</div>
                </div>
              </div>
            </div>
            )
          })
          :
          myprofile.map(data=>{
            return(
              <div className={styles.promotionsList}>
              <div className={styles.rectangleGroup}>
                <img
                  className={styles.frameChild2}
                  alt=""
                  src={gal}
                />
                <div className={styles.frameParent3}>
                  <div className={styles.frameParent4}>
                    <div className={styles.citiessummaryParent}>
                      <div className={styles.citiessummary}>
                      Your profile is currently being promoted 🎉
                      </div>
                      <img
                        className={styles.iconshome}
                        alt=""
                        src="/iconsmore-vertical3.svg"
                        style={{cursor:'pointer'}}
                        id={data.id}
                        aria-controls={open ? data.id : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={(e)=>{
                          setSelected(data.id)
                          handleClick(e)
                        }}
                      />
                    </div>
                    <div className={styles.frameWrapper1}>
                      <div className={styles.frameParent5}>
                        <div className={styles.iconscalendarParent}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconscalendar1.svg"
                          />
                          <div className={styles.home}>Ends:</div>
                          <div
                            className={styles.citiessummary2}
                          >{data.date}</div>
                        </div>
                        <div className={styles.iconsclockParent}>
                          <img
                            className={styles.iconshome}
                            alt=""
                            src="/iconsclock1.svg"
                          />
                          <div className={styles.citiessummary3}>{data.time}</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.price}>Price</div>
                  </div>
                  <div className={styles.citiessummary4}> {moment(data.unix).fromNow()}</div>
                </div>
              </div>
            </div>
            )
          })
        }
        </div>

        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem style={{color:'red'}} onClick={()=>{
          setModal(true)
          handleClose()
          }}>End Promotion</MenuItem>
      </Menu>
      {modal && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setModal(false)}
        >
          <EndPromo
          selected={selected}
          onClose={()=>setModal(false)}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default CurandPastPromo;
