import { useCallback, useEffect, useState } from "react";
import { TextField, InputAdornment, Icon } from "@mui/material";
import styles from "./WithdrawModal.module.css";
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { serverTimestamp } from "firebase/firestore";
import axios from "axios";
import Stripe from "stripe";


const WithdrawModal = ({ onClose ,onDone}) => {
  const [cards, setCards] = useState([]); 
  const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("payout");
  const [amount,setAmount]=useState(0)
  const navigate =useNavigate()

  useEffect(() => {
    // Use onSnapshot to listen for real-time changes in the "cards" subcollection
    const unsubscribe = cardsCollection.onSnapshot((querySnapshot) => {
      const cardsData = [];
      querySnapshot.forEach((doc) => {
        cardsData.push({ id: doc.id, ...doc.data() });
      });
      setCards(cardsData);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  const [payId, setPayId] = useState("");
  const [walletId, setWallet] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [trans, setTrans] = useState([])
  
  useEffect(() => {
    db.collection('wallet/'+auth.currentUser.uid+'/balance').orderBy('stamp','desc').onSnapshot(snapshot=>{
      let cald=[]
      snapshot.forEach(snap=>{
        cald.push(snap.data());
      })
      setTrans(cald)
    })
   db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
    setPayId(snap.data().connectId)
    setWallet(snap.data().wallet)
    setEmail(snap.data().email||auth.currentUser.email)
   })
  }, [])
  const mainbal = trans.filter((data) => data.status === 'success');
  const withbal = trans.filter((data) => data.status === 'withd');
  const cancel = trans.filter((data) => data.status === 'cancel');
  const others= ((withbal.reduce((a,v) =>  a = Number(a) + Number(v.amount),0 ))+(cancel.reduce((a,v) =>  a = Number(a) + Number(v.pend),0 )))||0
  const balance= ((mainbal.reduce((a,v) =>  a = Number(a) + Number(v.amount),0 ))-others).toFixed(2)||0
  const pend =trans.reduce((a,v) =>  a = Number(a) + Number(v.pend),0 ).toFixed(2)||0
  const overallmainbal=balance+pend
  const handlePay = async (bankId,number,sort,name) => {
    setLoading(true)
    console.log(amount,balance)
    if (balance > amount) {
      const bankData = {
        number,
        sort,
        name,
        amount,
        payId,
        email,bankId
      };
    
      axios.post('https://loggworks-c15c9e56f169.herokuapp.com/payout', bankData)
        .then((response) => {
          // Check if the payout request was successful
          if (response.status === 200) {
            // Proceed with other parts of the code
            const options = {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            };
            const key = db.collection("tmp").doc().id;
            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-US', options);
            db.collection('wallet/' + auth.currentUser.uid + '/balance').doc(key).set({
              type: 'withd',
              amount: amount,
              stamp: serverTimestamp(),
              status: 'withd',
              date: formattedDate,
              pend: 0
            });
    
            onDone();
            onClose();
          } else {
            // Handle unsuccessful payout request
            console.error('Payout request failed:', response);
            alert('Payout request failed. Please try again.');
          }
        })
        .catch((error) => {
          // Handle errors during the payout request
          console.error('Error during payout request:', error);
          alert('Your withdrawal is not active yet, please wait 2 days and try again');
        });
    } else {
      alert('Sorry, insufficient balance');
    }
    
    setLoading(false)
  };
  return (
    <div className={styles.withdrawmodal}>
      <div className={styles.modalTitle}>
        <div className={styles.addANewCardParent}>
          <div className={styles.addANew}>Withdraw to Bank</div>
          <img className={styles.iconsclose} alt="" src="/iconsclose1.svg" style={{cursor:'pointer'}} onClick={onClose} />
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>Withdrawal Amount</div>
        </div>
        <TextField
          className={styles.baseField}
          fullWidth
          color="primary"
          variant="outlined"
          type="number"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            onKeyPress: (e) => {
              const isNumber = /[0-9]/.test(e.key);
              if (!isNumber) {
                e.preventDefault();
              }
            },
          }}
          onChange={(e)=>{setAmount(e.target.value)}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CurrencyPoundIcon/>
              </InputAdornment>
            ),
          }}
          size="medium"
          margin="none"
        />
        <div className={styles.helperTextContainer}>
          <div className={styles.thisIsA}>This is a helper text</div>
          <div className={styles.div}>1/100</div>
        </div>
      </div>
      <div className={styles.selectWithdrawalMethodParent}>
        <div className={styles.selectWithdrawalMethod}>
          Select withdrawal method
        </div>

        {
          cards.map(data=>{
            return(
               <div className={styles.list}
               style={{cursor:'pointer'}}
               onClick={()=>{
                if(amount>0)
                {
                  if (!loading) {
                    handlePay(data.bankId, data.accountNumber, data.sortCode, data.accountName)
                  }
                }
               }}
               >
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.main}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg1.svg"
                />
                <img
                  className={styles.iconWithBg1}
                  alt=""
                  src="/icon-with-bg2.svg"
                />
                <div className={styles.list1}>{data.accountName}</div>
              </div>
              <div className={styles.subTextWrapper}>
                <div className={styles.subText}>
                  {data.bankName}
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img
                className={styles.iconsclose}
                alt=""
                src="/iconschevronright11.svg"
              />
              <div className={styles.toggle}>
                <div className={styles.toggleChild} />
                <div className={styles.toggleItem} />
              </div>
            </div>
          </div>
        </div>
            );
          })
        }
        <br></br>
        <br></br>
        <b style={{color:'green',cursor:'pointer'}} onClick={()=>{
          navigate('/payoutpref')
        }}>Add withdrawal method</b>
      </div>
    </div>
  );
};

export default WithdrawModal;
