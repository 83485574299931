import { Avatar, MenuItem, TextField } from "@mui/material";
import styles from "./RecentChats.module.css";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const RecentChats = ({ onClose, qkey }) => {
  const [list, setList]= useState([])
  const navigate = useNavigate()
   
  useEffect(() => {
     (async () => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    if (auth.currentUser) {
      let cald = [];
      
      db.collection('contacts/' + auth.currentUser.uid + '/my/')
        .orderBy('timestamp', 'desc')
        .get()
        .then(snapshot => {
          snapshot.forEach(snap => {
            cald.push(snap.data());
          });
          setList(cald);
        });
    }
  })();
  }, [])
  const onSend=(id)=>{
    var date = new Date();
     var hours = date.getHours();
var minutes = date.getMinutes();
var ampm = hours >= 12 ? 'pm' : 'am';
hours = hours % 12;
hours = hours ? hours : 12; // the hour '0' should be '12'
minutes = minutes < 10 ? '0'+minutes : minutes;
var strTime = hours + ':' + minutes + ' ' + ampm;
db.collection('messages/'+auth.currentUser.uid+'/'+id).add({
    senderid: 'qoute',
    time: strTime,
    stamp: serverTimestamp(),
    qkey:qkey,
    q_id:qkey
  })
   db.collection('cust_messages/'+id+'/'+auth.currentUser.uid).add({
    senderid: 'qoute',
    time: strTime,
    stamp: serverTimestamp(),
    qkey:qkey,
    q_id:qkey
  })
   db.collection('my_draft/'+auth.currentUser.uid+'/list/').doc(qkey).update({
    status:'pend'
   })
  let key = db.collection("tmp").doc().id
  db.collection('users').doc(auth.currentUser.uid).get().then(snaps=> {
    db.collection('cust_contacts/'+id+'/my/').where('uid','==',auth.currentUser.uid).get().then(snapshot=>{
      if(snapshot.empty)
      {
        db.collection('cust_contacts/'+id+'/my/').doc(key).set({
    uid:auth.currentUser.uid,
    unix: Date.now(),
    timestamp: serverTimestamp(),
    name: snaps.data().name,
    key: key
  }) 
   }
   else
      {
       snapshot.forEach((snap) => {
      const key_id = snap.data().key;
      db.collection(`cust_contacts/${id}/my`).doc(key_id).update({
        unix: Date.now(),
        timestamp: serverTimestamp(),
        name: snaps.data().name
      });
    });
      }
    })
    })
    navigate('/chat/'+id)
    onClose()
}
  return (
    <div className={styles.recentChats}>
      <div className={styles.modalTopBar}>
        <div className={styles.modalTopBarChild} />
        <div className={styles.addCertificate}>Recent chats</div>
        <img
          className={styles.evacloseOutlineIcon}
          alt=""
          src="/evacloseoutline.svg"
        />
        <img className={styles.icons} alt="" src="/icons10.svg" />
      </div>
      <TextField
        className={styles.input}
        fullWidth
        color="primary"
        variant="outlined"
        type="text"
        label="Search"
        placeholder="Placeholder"
        size="medium"
        margin="none"
      />
      <div className={styles.instanceParent}>
       
        {
        list.map(data=>{
          return(
           <MenuItem className={styles.frameParent} onClick={()=>onSend(data.uid)}>
          <div className={styles.peopleavatarParent}>
            <Avatar
              className={styles.peopleavatarIcon}
              alt=""
              src="/peopleavatar112@2x.png"
            />
            <div className={styles.frameGroup}>
              <div className={styles.peoplenameParent}>
                <div className={styles.peoplename}>{data.name}</div>
                <div className={styles.yesterday}>{/*moment(data.unix).fromNow()*/}</div>
              </div>
              <div className={styles.citiessummaryParent}>
                <div className={styles.citiessummary}>
                  
                </div>
                <div className={styles.rectangleParent}>
                  <div className={styles.groupChild} />
                  <div className={styles.div}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.instanceChild} />
        </MenuItem>
          )
        })
      }
      </div>
    </div>
  );
};

export default RecentChats;
