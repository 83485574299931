import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Wallet.module.css";
import navmob from "./Home.module.css";
import { AppBar } from "@mui/material";
import { auth, db } from "../firebase";
import PortalPopup from "../components/PortalPopup";
import WithdrawModal from "../components/WithdrawModal";
import { Badge, styled } from "@mui/material";
import WithdrawSucc from "../components/WithdrawSucc";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const Wallet = () => {
  const navigate = useNavigate();
  const [isWithdrawPopupOpen, setWithdrawPopupOpen] = useState(false);
  const [isWithdrawSuccPopupOpen, setWithdrawSuccPopupOpen] = useState(false);
  const [trans, setTrans] = useState([])
  const [msg, setMsg]= useState([])
  const [activ, setActivity]= useState([])
  
  useEffect(() => {
    db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
     
     })
    if(auth.currentUser)
    {
      db.collection('wallet/'+auth.currentUser.uid+'/balance').orderBy('stamp','desc').onSnapshot(snapshot=>{
        let cald=[]
        snapshot.forEach(snap=>{
          cald.push(snap.data());
        })
        setTrans(cald)
      })
    }
    const collectionRef = db.collection('users').doc(auth.currentUser.uid).collection('noti_m');
    const collectionRef2 = db.collection('users').doc(auth.currentUser.uid).collection('noti_act');
    const updatedList = [];
    const actList = [];
    const unsubscribe1 = collectionRef.onSnapshot((snapshot) => {
      // Handle the snapshot changes
      snapshot.forEach((doc) => {
        const docData = doc.data();
        updatedList.push(docData);
      });
      setMsg(updatedList)
      });
      const unsubscribe2 = collectionRef2.onSnapshot((snapshot) => {
      // Handle the snapshot changes
      snapshot.forEach((doc) => {
        const docData = doc.data();
        actList.push(docData);
      });
      setActivity(actList)
      });
      
      return () => {
      unsubscribe1();
      unsubscribe2();
      };
  }, [])
  const mainbal = trans.filter((data) => data.status === 'success');
  const withbal = trans.filter((data) => data.status === 'withd');
  const cancel = trans.filter((data) => data.status === 'cancel');
  const others= ((withbal.reduce((a,v) =>  a = Number(a) + Number(v.amount),0 ))+(cancel.reduce((a,v) =>  a = Number(a) + Number(v.pend),0 )))||0
  const balance= ((mainbal.reduce((a,v) =>  a = Number(a) + Number(v.amount),0 ))-others).toFixed(2)||0

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);
   const onDefaultContainer5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onLargeButtonsContainer1Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onSeeAllText1Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.wallet}>
      <div className={styles.walletmob}>
        <div className={styles.titles}>
          <div className={styles.activities}>Wallet</div>
        </div>
        <div className={styles.walletParent}>
          <div className={styles.wallet1}>
            <div className={styles.walletChild} />
            <div className={styles.button} onClick={()=>setWithdrawPopupOpen(true)}>
              <div className={styles.iconsParent}>
                <img className={styles.icons} alt="" src="/icons21.svg" />
                <b className={styles.proceed}>Withdraw to Bank</b>
              </div>
            </div>
            <div className={styles.rectangleParent}>
              <div className={styles.frameChild} />
              <div className={styles.frameItem} />
              <div className={styles.frameInner} />
              <b className={styles.b}>£{balance}</b>
              <div className={styles.availableBalance}>AVAILABLE BALANCE</div>
              <div className={styles.pendingBalance3560Container}>
                <span className={styles.pendingBalance}>PENDING BALANCE:</span>
                <b className={styles.b1}>
                  <span className={styles.span}>{` `}</span>
                  <span>£{trans.reduce((a,v) =>  a = Number(a) + Number(v.pend),0 ).toFixed(2)||0}</span>
                </b>
              </div>
              <div className={styles.lineDiv} />
            </div>
          </div>
          <div className={styles.transactions}>
            <b className={styles.recentTransactions}>Recent transactions</b>
            {
            trans.map(data=>{
              return(
                <div className={styles.wallettransactions}>
              <div className={styles.frameParent}>
                <div className={styles.iconStatusParent}>
                   <div className="flex bg-[#EDF9E6] items-center justify-center rounded-full w-[40px] h-[40px]">
                        <img
                          src={`/assets/icons/${
                            data.type != "job"
                              ? "transactionPending"
                              : "transactionSuccess"
                          }.svg`}
                          width={20}
                          height={20}
                          alt=""
                        />
                      </div>
                  <div className={styles.frameGroup}>
                    <div className={styles.withdrawalToBankParent}>
                      <div className={styles.withdrawalToBank}>
                      {data.type=='job'?'Job payment':'Withdraw to bank'}
                      </div>
                      <div className={styles.div}>£{Number(data.pend)==0?Number(data.amount)?.toFixed(2):Number(data.pend)?.toFixed(2)}</div>
                    </div>
                    <div className={styles.pmParent}>
                      <div className={styles.pm}>{data.date}</div>
                      <div className={styles.status}>
                        {
                          data.status=='pend'?
                          <div className={styles.pending}>
                          <div className={styles.pendingChild} />
                          <div className={styles.success1}>pending</div>
                        </div>
                         :
                         data.status=='withd'?
                         <div className={styles.pending}>
                         <div className={styles.pendingChild} style={{width:'60px'}}/>
                         <div className={styles.success1}>WITHDREW</div>
                       </div>
                        :
                        data.status=='success'?
                        <div className={styles.success}>
                        <div className={styles.successChild} />
                        <div className={styles.success1}>SUCCESS</div>
                      </div>
                      :
                       <div className={styles.failed}>
                          <div className={styles.failedChild} />
                          <div className={styles.success1}>failed</div>
                        </div>
                        }
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameChild1} />
              </div>
            </div>
              );
            })
          }
          </div>
        </div>
        <div className={styles.walletHomeWrapper}>
          <div className={styles.walletHome}>
          <div className={styles.top1}>
                <div className={styles.rectangleGroup}>
                  <div className={styles.frameChild7} />
                  <div className={styles.frameChild8} />
                  <div className={styles.frameChild9} />
                  <div className={styles.availableBalanceParent}>
                    <div className={styles.availableBalance1}>
                      Available Balance
                    </div>
                    <div className={styles.div6}>£{balance}</div>
                  </div>
                  <div className={styles.pendingBalanceParent}>
                    <div className={styles.availableBalance1}>
                      pending balance:
                    </div>
                    <div className={styles.availableBalance1}>£{trans.reduce((a,v) =>  a = Number(a) + Number(v.pend),0 ).toFixed(2)||0}</div>
                  </div>
                  <div className={styles.frameChild10} />
                </div>
                <div
                  className={styles.largeButtons}
                  onClick={()=>setWithdrawPopupOpen(true)}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder31.svg"
                  />
                  <b className={styles.label}>Withdraw to Bank</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder31.svg"
                  />
                </div>
              </div>
              <div className={styles.merge}>
                <div className={styles.title}>
                  <div className={styles.recentTransactions1}>
                    Recent transactions
                  </div>
                  <div className={styles.seeAll} onClick={onSeeAllText1Click}>
                    See all
                  </div>
                </div>
                <div className={styles.top}>
                {
            trans.map(data=>{
              return(
                <div className={styles.transactionHistory}>
                <div className={styles.transactionIconsParent}>
                <div className="flex bg-[#EDF9E6] items-center justify-center rounded-full w-[40px] h-[40px]">
                        <img
                          src={`/assets/icons/${
                            data.type != "job"
                              ? "transactionPending"
                              : "transactionSuccess"
                          }.svg`}
                          width={20}
                          height={20}
                          alt=""
                        />
                      </div>
                  <div className={styles.frameParent9}>
                    <div className={styles.depositParent}>
                      <div className={styles.deposit}>{data.type=='job'?'Job payment':'Withdraw to bank'}</div>
                      <div className={styles.div8}>£{Number(data.pend)==0?Number(data.amount)?.toFixed(2):Number(data.pend)?.toFixed(2)}</div>
                    </div>
                    <div className={styles.pmParent4}>
                      <div className={styles.pm6}>{data.date}</div>
                      <div className={styles.status}>
                        {
                          data.status=='pend'?
                          <div className={styles.pending}>
                          <div className={styles.pendingChild} />
                          <div className={styles.success1}>pending</div>
                        </div>
                         :
                         data.status=='withd'?
                         <div className={styles.pending}>
                          <div className={styles.pendingChild} style={{width:'60px'}}/>
                         <div className={styles.success1}>WITHDREW</div>
                       </div>
                        :
                        data.status=='success'?
                        <div className={styles.success}>
                        <div className={styles.successChild} />
                        <div className={styles.success1}>SUCCESS</div>
                      </div>
                      :
                       <div className={styles.failed}>
                          <div className={styles.failedChild} />
                          <div className={styles.success1}>failed</div>
                        </div>
                        }
                       
                        
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              );
            })
          }
                </div>
              </div>
          </div>
        </div>
          <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                      <div className={navmob.bottomNav1}>
                      <div className={navmob.wallet} onClick={()=>navigate('/home')}>
                        <div className={navmob.home2}>Home</div>
                        <img
                          className={navmob.brandLogoIcon1}
                          alt=""
                          src="/brand-logo31.svg"
                        />
                      </div>
                      <div className={navmob.jobs} onClick={()=>navigate('/jobs')}>
                        <div className={navmob.jobs1}>Jobs</div>
                        <img
                          className={navmob.bibriefcaseFillIcon}
                          alt=""
                          src="/iconsjobs.svg"
                        />
                      </div>
                      <div className={navmob.home1} onClick={()=>navigate('/wallet')}>
                        <b className={navmob.home2} style={{color:'#2fa500'}}>Wallet</b>
                        <img
                          className={navmob.zondiconswallet}
                          alt=""
                          src="/iconslog-cash1.svg"
                        />
                      </div>
                      <div className={navmob.account} onClick={()=>navigate('/profile')}>
                        <div className={navmob.account1}>Account</div>
                        <img
                          className={navmob.mdiaccountIcon}
                          alt=""
                          src="/account4.svg"
                        />
                      </div>
                    </div>
                     </AppBar>
                     <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
<div className={navmob.bottomNav}>
            <div className={navmob.wallet} onClick={()=>navigate('/home')}>
              <div className={navmob.wallet1}>Home</div>
              <img
                className={navmob.brandLogoIcon1}
                alt=""
                src="/brand-logo31.svg"
              />
            </div>
            <div className={navmob.jobs} onClick={()=>navigate('/jobs')}>
              <div className={navmob.jobs1}>Jobs</div>
              <img
                className={navmob.bibriefcaseFillIcon}
                alt=""
                src="/iconsjobs.svg"
              />
            </div>
            <div className={navmob.wallet} onClick={()=>navigate('/wallet')}>
              <b className={navmob.home2} style={{color:'#2fa500'}}>Wallet</b>
              <img
                className={navmob.zondiconswallet}
                alt=""
                src="/iconslog-cash1.svg"
              />
            </div>
            <div className={navmob.account} onClick={()=>navigate('/profile')}>
              <div className={navmob.account1}>Account</div>
              <img
                className={navmob.mdiaccountIcon}
                alt=""
                src="/account4.svg"
              />
            </div>
          </div>
           </AppBar>
      </div>
      <div className={styles.wallet4}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.transactionIconsParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home2}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.transactionIconsParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home2}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.transactionIconsParent}>
                <StyledBadge badgeContent={activ.length} color="primary" >
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                </StyledBadge>
                  
                  <div className={styles.home2}>Activities</div>
                </div>
              </div>
              <div className={styles.default3}>
                <div className={styles.transactionIconsParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash1.svg"
                  />
                  <div className={styles.home5}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.transactionIconsParent}>
                <StyledBadge badgeContent={msg.length} color="primary" >
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                </StyledBadge>
                  
                  <div className={styles.home2}>Message</div>
                </div>
              </div>
              <div className={styles.default5}
              onClick={onDefaultContainer5Click}
              >
                <div className={styles.transactionIconsParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home2}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles1}>
            <div className={styles.activities1}>Wallet</div>
          </div>
          <div className={styles.component102}>
                <b className={styles.licenseAndCertification}>Wallet</b>
              </div>
          <div className={styles.walletHomeContainer}>
            <div className={styles.walletHome1}>
             
              <div className={styles.top1}>
                <div className={styles.rectangleGroup}>
                  <div className={styles.frameChild7} />
                  <div className={styles.frameChild8} />
                  <div className={styles.frameChild9} />
                  <div className={styles.availableBalanceParent}>
                    <div className={styles.availableBalance1}>
                      Available Balance
                    </div>
                    <div className={styles.div6}>£{balance}</div>
                  </div>
                  <div className={styles.pendingBalanceParent}>
                    <div className={styles.availableBalance1}>
                      pending balance:
                    </div>
                    <div className={styles.availableBalance1}>£{trans.reduce((a,v) =>  a = Number(a) + Number(v.pend),0 ).toFixed(2)||0}</div>
                  </div>
                  <div className={styles.frameChild10} />
                </div>
                <div
                  className={styles.largeButtons}
                  onClick={()=>setWithdrawPopupOpen(true)}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder31.svg"
                  />
                  <b className={styles.label}>Withdraw to Bank</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder31.svg"
                  />
                </div>
              </div>
              <div className={styles.merge}>
                <div className={styles.title}>
                  <div className={styles.recentTransactions1}>
                    Recent transactions
                  </div>
                  <div className={styles.seeAll} onClick={onSeeAllText1Click}>
                    See all
                  </div>
                </div>
                <div className={styles.top}>
                {
            trans.map(data=>{
              return(
                <div className={styles.transactionHistory}>
                <div className={styles.transactionIconsParent}>
                <div className="flex bg-[#EDF9E6] items-center justify-center rounded-full w-[40px] h-[40px]">
                        <img
                          src={`/assets/icons/${
                            data.type != "job"
                              ? "transactionPending"
                              : "transactionSuccess"
                          }.svg`}
                          width={20}
                          height={20}
                          alt=""
                        />
                      </div>
                  <div className={styles.frameParent9}>
                    <div className={styles.depositParent}>
                      <div className={styles.deposit}>{data.type=='job'?'Job payment':'Withdraw to bank'}</div>
                      <div className={styles.div8}>£{Number(data.pend)==0?Number(data.amount)?.toFixed(2):Number(data.pend)?.toFixed(2)}</div>
                    </div>
                    <div className={styles.pmParent4}>
                      <div className={styles.pm6}>{data.date}</div>
                      <div className={styles.status}>
                        {
                          data.status=='pend'?
                          <div className={styles.pending}>
                          <div className={styles.pendingChild} />
                          <div className={styles.success1}>pending</div>
                        </div>
                        :
                        data.status=='withd'?
                        <div className={styles.pending}>
                         <div className={styles.pendingChild} style={{width:'60px'}}/>
                        <div className={styles.success1}>WITHDREW</div>
                      </div>
                      :
                        data.status=='success'?
                        <div className={styles.success}>
                        <div className={styles.successChild} />
                        <div className={styles.success1}>SUCCESS</div>
                      </div>
                      :
                       <div className={styles.failed}>
                          <div className={styles.failedChild} />
                          <div className={styles.success1}>failed</div>
                        </div>
                        }
                       
                        
                       
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
              );
            })
          }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isWithdrawPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setWithdrawPopupOpen(false)}
        >
          <WithdrawModal onClose={()=>setWithdrawPopupOpen(false)} onDone={()=>setWithdrawSuccPopupOpen(true)}/>
        </PortalPopup>
      )}
      {isWithdrawSuccPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setWithdrawSuccPopupOpen(false)}
        >
          <WithdrawSucc onClose={()=>setWithdrawSuccPopupOpen(false)}/>
        </PortalPopup>
      )}
    </div>
  );
};

export default Wallet;
