import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Profile.module.css";
import { auth, db } from "../firebase";
import { AppBar, Avatar, Box, LinearProgress } from "@mui/material";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PortalPopup from "../components/PortalPopup";
import InstagramModal from "../components/InstagramModal";
import FacebookModal from "../components/FacebookModal";
import TwitterModal from "../components/TwitterModal";
import ShareModal from "../components/shareModal.tsx";
const NavPrototype = () => {
   const [name, setName] = useState()
  const navigate = useNavigate()
  const [verification, setVerification] = useState(0)
  const [license_done, setLicense] =useState(false)
  const [id_done, setID] =useState(false)
  const [edu_done, setEdu] =useState(false)
  const [work_done, setWork] =useState(false)
  const [galleryImages, setGalleryImages] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [org, setOrg] = useState('');
  const [profileImage, setProfileImage] = useState("");
  const [instaOpen, setInstaOpen] =useState(false)
  const [twitterOpen, setTwitterOpen] =useState(false)
  const [facebookOpen, setFacebookOpen] =useState(false)
  const [modalPop, setModalPopUp] = useState(false);
  
  useEffect(() => {
    if(auth.currentUser)
    {
      const proofID = db.collection("users").doc(auth.currentUser.uid).collection("proofID");
      const licenseColl = db.collection("users").doc(auth.currentUser.uid).collection("license");
      const workColl = db.collection("users").doc(auth.currentUser.uid).collection("work");
      const eduColl = db.collection("users").doc(auth.currentUser.uid).collection("education");
      proofID.get().then(function(snap){
        if (snap.size>0) {
          setID(true)
        }
      })
      licenseColl.get().then(function(snap){
        if (snap.size>0) {
          setLicense(true)
        }
      })
      workColl.get().then(function(snap){
        if (snap.size>0) {
          setWork(true)
        }
      })
      eduColl.get().then(function(snap){
        if (snap.size>0) {
          setEdu(true)
        }
      })
        db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
            if(snapshot.data())
            {
              setName(snapshot.data().org_name)
              setOrg(snapshot.data().org_name)
              if (snapshot.data().verification!=null) {
                setVerification(snapshot.data().verification)
              }
              setProfileImage(snapshot.data().profileImage);
            }
          });
          const galleryRef = db.collection('users')
          .doc(auth.currentUser.uid)
          .collection('gallery');
    
        const unsubscribe = galleryRef.onSnapshot((snapshot) => {
          const images = [];
          snapshot.forEach((doc) => {
            images.push(doc.data().imageUrl);
          });
          setGalleryImages(images);
           // Set the first image as imagePreview if available
           if (images.length > 0) {
            setImagePreview(images[0]);
          }
        });
    
        // Unsubscribe when the component unmounts
        return () => {
          unsubscribe();
        };
        }
  }, [])

  const onButtonContainer1Click = useCallback(() => {
    navigate("/promotion");
  }, [navigate]);

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onSmallButtonsContainerClick = useCallback(() => {
    navigate('/edit_profile')
  }, []);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/promotion");
  }, [navigate]);

  const onFrameContainer55Click = useCallback(() => {
    navigate("/settings");
  }, []);

  const license =()=>{
    navigate('/license_proof')
  }

  const education =()=>{
    navigate('/education_proof')
  }

  const work =()=>{
    navigate('/work_proof')
  }

  const idproof =()=>{
    navigate('/id_proof')
  }

  const onFrameContainer63Click = useCallback(() => {
    // Please sync "How to earn points" to the project
  }, []);

  const onProfileListContainerClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.profile}>
      <div className={styles.account}>
        <div className={styles.component29}>
          <div className={styles.component29Child} />
          <div className={styles.frameParent}>
          <Carousel
          showArrows={true} // Display navigation arrows
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide status indicators
          showThumbs={false} // Hide thumbnail navigation
          autoPlay={true} // Disable auto-play
          className={styles.imgContainer}
        >
          {galleryImages.map((imageUrl, index) => (
            <div key={index} className={styles.imgContainer}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                className={styles.imgContainer}
              />
               
                   
            </div>
          ))}
        </Carousel>
        
        <div
                      className={styles.smallButtons}
                      onClick={onSmallButtonsContainerClick}
                    >
                      <div className={styles.edit2Parent}>
                        <img
                          className={styles.guarantee1Icon}
                          alt=""
                          src="/iconsedit.svg"
                        />
                        <div className={styles.button9}>Edit</div>
                      </div>
                    </div>
          </div>
          
        </div>
        <div className={styles.title}>
          <div className={styles.frameGroup}>
            <div className={styles.frameContainer}>
              <div className={styles.groupParent}>
                <div className={styles.groupDiv}>
                    <Avatar
        className={styles.groupChild}
        alt=""
        src={profileImage}
      />
                </div>
                <div className={styles.parent}>
                  <div className={styles.edit2Parent}>
                    <b className={styles.smithsConstruction}>
                     {name}
                    </b>
                    {verification>=30?<img
                      className={styles.guarantee1Icon}
                      alt=""
                      src="/guarantee-1.svg"
                    />:null}
                    
                  </div>
                  <div className={styles.applianceRepairWrapper}>
                    <div className={styles.applianceRepair}>
                    <div className={styles.transactionPills}>
                            <div className={styles.success}>{verification>=30?'verified':'unverified'}</div>
                          </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.awards}>
                <div className={styles.rookie}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-66966.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.rookieInner}
                    alt=""
                    src="/group-66950.svg"
                  />
                  <div className={styles.legendary}>Rookie</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-39.svg"
                  />
                </div>
                <div className={styles.intermediate}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-669661.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.rookieInner}
                    alt=""
                    src="/group-66950.svg"
                  />
                  <div className={styles.legendary}>Intermediate</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-391.svg"
                  />
                </div>
                <div className={styles.skilled}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-669662.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.skilledInner}
                    alt=""
                    src="/group-669501.svg"
                  />
                  <div className={styles.legendary}>Skilled</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-392.svg"
                  />
                </div>
                <div className={styles.semiPro}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-669663.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.semiProInner}
                    alt=""
                    src="/group-669502.svg"
                  />
                  <div className={styles.legendary}>Semi pro</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-393.svg"
                  />
                </div>
                <div className={styles.semiPro}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-669664.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.professionalInner}
                    alt=""
                    src="/group-669503.svg"
                  />
                  <div className={styles.legendary}>Professional</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-394.svg"
                  />
                </div>
                <div className={styles.semiPro}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-669665.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.lengendaryInner}
                    alt=""
                    src="/group-669504.svg"
                  />
                  <div className={styles.legendary}>Legendary</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-395.svg"
                  />
                </div>
                <div className={styles.skilled}>
                  <img
                    className={styles.rookieChild}
                    alt=""
                    src="/group-669666.svg"
                  />
                  <img
                    className={styles.rookieItem}
                    alt=""
                    src="/group-66967.svg"
                  />
                  <img
                    className={styles.ambassadorInner}
                    alt=""
                    src="/group-66950.svg"
                  />
                  <div className={styles.legendary}>Ambassador</div>
                  <img
                    className={styles.vectorIcon}
                    alt=""
                    src="/vector-396.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonParent}>
              <div className={styles.button1} onClick={onButtonContainer1Click}>
                <div className={styles.iconsParent}>
                  <img className={styles.icons} alt="" src="/icons3.svg" />
                  <div className={styles.proceedWrapper}>
                    <b className={styles.smithsConstruction}>Promote profile</b>
                  </div>
                </div>
              </div>
              <button className={styles.button2} onClick={()=>{setModalPopUp(true)}}>
                <div className={styles.iconsParent}>
                  <img className={styles.icons1} alt="" src="/icons5.svg" />
                  <div className={styles.proceedWrapper}>
                    <b className={styles.smithsConstruction}>Share</b>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.settings}>
          <div className={styles.settingsChild} />
          <div className={styles.tab}>
            <div className={styles.tabChild} />
            <div className={styles.tabItem} />
            <div className={styles.account1}>Account</div>
            <div className={styles.settings1} onClick={onFrameContainer55Click}>Settings</div>
          </div>
        </div>
        {/*<div className={styles.pointsCard}>
          <div className={styles.frameParent2}>
            <div className={styles.frameParent3}>
              <div className={styles.pointsParent}>
                <div className={styles.points}>
                  <div className={styles.div}>300</div>
                  <div className={styles.totalPoints}>Total Points</div>
                </div>
                <div className={styles.nextLevelIntermediateContainer}>
                  <p className={styles.nextLevel}>Next Level</p>
                  <p className={styles.intermediate2}>
                    <b>Intermediate</b>
                  </p>
                  <p className={styles.nextLevel}>2500 Points</p>
                </div>
              </div>
              <div className={styles.progressBar}>
                <div className={styles.progressBarChild} />
                <div className={styles.progressBarItem} />
                <div className={styles.progressBarInner} />
              </div>
            </div>
            <div className={styles.earn950Points}>
              Earn +950 points more to advance to the next level
            </div>
                    </div>
          <div className={styles.howToEarnPoints}>
            <div className={styles.howToEarn}>
              How to earn points and level up
            </div>
            <img
              className={styles.alertCircleIcon}
              alt=""
              src="/alertcircle.svg"
            />
          </div>
        </div>*/}
        <div className={styles.verification}>
          <div className={styles.verification1}>Verification</div>
          <div className={styles.rectangleParent}>
            <div className={styles.instanceChild} />
            <img className={styles.instanceItem} alt="" src="/vector-27.svg" />
            <div className={styles.youAreUnverifiedParent}>
            {
                        verification==0?
                        <div className={styles.youAreUnverified}>You are unverified</div>
                        :
                        <div className={styles.youAreUnverified}>Your verification is {verification}% complete</div>
  
                      }
             
              <div className={styles.completeVerificationTo}>
                Complete verification to earn points
              </div>
            </div>
            <img
              className={styles.exclamationMark1Icon}
              alt=""
              src="/exclamationmark-1.svg"
            />
            <div className={styles.progressBar1}>
            <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={verification} />
    </Box>
            </div>
          </div>
          <div className={styles.verificationInner}>
            <div className={styles.licenseAndCertificationParent}  onClick={idproof}>
            <div style={{display:'flex',justifyContent:'space-between',width:'350px'}}>
           <div style={{display:'flex',gap:'12px'}}>
              {
                              id_done?
                              <img
                              src="./IDproof_done.svg"
                              />
                              :
                              null
                            }
                            <div className={styles.howToEarn}>Proof of Identity</div>
            </div>
          {
                          id_done?
                          <img
                          src="/iconschevronright1.svg"
                          />
                          :
                          null
                        }
          
        </div>
           
            {
                              id_done?
                              null
                              :
                              <div className={styles.certificationDependingOnSerGroup}>
                <div className={styles.howToEarn}>
                  *Add documents to verify your identity
                </div>
                <div className={styles.button3} onClick={idproof}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.smithsConstruction}>Proceed</b>
                  </div>
                </div>
              </div>
                            }
              
            </div>
          </div>
          <div className={styles.verificationInner}>
            <div className={styles.licenseAndCertificationParent} onClick={license}>
            <div style={{display:'flex',justifyContent:'space-between',width:'350px'}}>
            <div style={{display:'flex',gap:'12px'}}>
              {
                              license_done?
                              <img
                              src="./license_done.svg"
                              />
                              :
                              null
                            }
                            <div className={styles.howToEarn}>License and certification</div>
              </div>
              {
                              license_done?
                              <img
                              src="/iconschevronright1.svg"
                              />
                              :
                              null
                            }
              
            </div>
             
           
              {
                              license_done?
                              null
                              :
                               <div className={styles.certificationDependingOnSerParent}>
                <div className={styles.howToEarn}>
                  *Certification depending on service type
                </div>
                
                <div className={styles.button3} onClick={license}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.smithsConstruction}>Proceed</b>
                  </div>
                </div>
              </div>
                            }
             
            </div>
          </div>
          <div className={styles.verificationInner}>
            <div className={styles.licenseAndCertificationParent} onClick={education}>
            <div style={{display:'flex',justifyContent:'space-between',width:'350px'}}>
          <div style={{display:'flex',gap:'12px'}}>
              {
                              edu_done?
                              <img
                              src="./education_done.svg"
                              width={32}
                              />
                              :
                              null
                            }
                             <div className={styles.howToEarn}>Education</div>
            </div>
          {
                          edu_done?
                          <img
                          src="/iconschevronright1.svg"
                          />
                          :
                          null
                        }
          
        </div>
            
            {
                              edu_done?
                              null
                              :
                               <div className={styles.certificationDependingOnSerGroup}>
                <div className={styles.howToEarn}>
                  *Complete your profile by adding education
                </div>
                <div className={styles.button3} onClick={education}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.smithsConstruction}>Proceed</b>
                  </div>
                </div>
              </div>
                            }
             
            </div>
          </div>
          <div className={styles.verificationInner}>
            <div className={styles.licenseAndCertificationParent} onClick={work}>
            <div style={{display:'flex',justifyContent:'space-between',width:'350px'}}>
            <div style={{display:'flex',gap:'12px'}}>
              {
                              work_done?
                              <img
                              src="./work_done.svg"
                              />
                              :
                              null
                            }
                            <div className={styles.howToEarn}>Work Experience</div>
            </div>
          {
                          work_done?
                          <img
                          src="/iconschevronright1.svg"
                          />
                          :
                          null
                        }
          
        </div>
          
            {
                              work_done?
                              null
                              :
                               <div className={styles.certificationDependingOnSerGroup}>
                <div className={styles.howToEarn}>
                  *Add references to help you stand out
                </div>
                <div className={styles.button3} onClick={work}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.smithsConstruction}>Proceed</b>
                  </div>
                </div>
              </div>
                            }
             
            </div>
          </div>
          <div className={styles.verificationInner}>
            <div className={styles.licenseAndCertificationParent}>
              <div className={styles.howToEarn}>My Network</div>
              <div className={styles.certificationDependingOnSerGroup}>
                <div className={styles.howToEarn}>
                  *Add people in the same field of service
                </div>
                <div className={styles.button3}  onClick={()=>navigate('/network_proof')}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.smithsConstruction}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.verificationInner}>
            <div className={styles.licenseAndCertificationParent}>
              <div className={styles.howToEarn}>References</div>
              <div className={styles.certificationDependingOnSerGroup}>
                <div className={styles.howToEarn}>
                  *Add references to help you stand out
                </div>
                <div className={styles.button3}  onClick={()=>navigate('/reference_proof')}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons3.svg" />
                    <b className={styles.smithsConstruction}>Proceed</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.referAndEarn}>
          <div className={styles.rectangleGroup}>
            <div className={styles.groupItem} />
            <div className={styles.groupInner} />
            <div className={styles.groupChild1} />
            <div className={styles.groupChild2} />
            <div className={styles.groupChild3} />
            <div className={styles.groupChild4} />
            <div className={styles.groupChild5} />
            <div className={styles.groupChild6} />
            <div className={styles.groupChild7} />
            <div className={styles.groupChild8} />
            <div className={styles.groupChild9} />
            <div className={styles.groupChild10} />
            <div className={styles.groupChild11} />
            <div className={styles.groupChild12} />
            <div className={styles.groupChild13} />
            <div className={styles.groupChild14} />
            <div className={styles.groupChild15} />
            <div className={styles.groupChild16} />
            <div className={styles.groupChild17} />
            <div className={styles.groupChild18} />
            <div className={styles.groupChild19} />
            <div className={styles.groupChild20} />
            <div className={styles.groupChild21} />
            <div className={styles.groupChild22} />
            <div className={styles.groupChild23} />
            <div className={styles.groupChild24} />
            <div className={styles.groupChild25} />
            <div className={styles.groupChild26} />
          </div>
          <div className={styles.giftBoxParent}>
            <img className={styles.giftBoxIcon} alt="" src="/gift-box.svg" />
            <div className={styles.referEarnParent}>
              <div className={styles.referEarn}>{`Refer & earn.`}</div>
              <div className={styles.referABusiness}>
                Refer a business to earn points
              </div>
            </div>
          </div>
        </div>
        <div className={styles.connectSocialAccount}>
          <div className={styles.connectSocialAccounts}>
            Connect Social accounts
          </div>
          <div className={styles.groupContainer}>
            <img className={styles.groupIcon} alt="" src="/group-66753.svg"
            style={{cursor:'pointer'}}
            onClick={()=>setTwitterOpen(true)} />
            <img className={styles.groupIcon} alt="" src="/group-66754.svg" 
            style={{cursor:'pointer'}}
            onClick={()=>setInstaOpen(true)}/>
            <img className={styles.groupIcon} alt="" src="/group-66755.svg" 
            style={{cursor:'pointer'}}
            onClick={()=>setFacebookOpen(true)}/>
          </div>
        </div>
        
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <div className={styles.bottomNav}>
          <div className={styles.home}  onClick={()=>{navigate('/home')}}>
            <div className={styles.home1}>Home</div>
            <img
              className={styles.brandLogoIcon}
              alt=""
              src="/brand-logo31.svg"
            />
          </div>
          <div className={styles.wallet} onClick={()=>{navigate('/wallet')}}>
            <div className={styles.wallet1}>Wallet</div>
            <img
              className={styles.zondiconswallet}
              alt=""
              src="/iconslog-cash.svg"
            />
          </div>
          <div className={styles.account2}>
            <b className={styles.account3}>Account</b>
            <img
              className={styles.mdiaccountIcon}
              alt=""
              src="/account3.svg"
            />
          </div>
          <div className={styles.jobs}  onClick={()=>{navigate('/jobs')}}>
            <div className={styles.jobs1}>Jobs</div>
            <img
              className={styles.bibriefcaseFillIcon}
              alt=""
              src="/iconsjobs.svg"
            />
          </div>
        </div>
        </AppBar>
        
      </div>
      <div className={styles.profile1}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.alertCircleIcon}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home2}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.alertCircleIcon}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home2}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.alertCircleIcon}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home2}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.alertCircleIcon}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home2}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.alertCircleIcon}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home2}>Message</div>
                </div>
              </div>
              <div className={styles.default5}  style={{cursor:'pointer'}}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account3.svg"
                  />
                  <div className={styles.home7}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent4}>
        <div className={styles.component102}>
                  <b className={styles.licenseAndCertification6}>Profile</b>
                </div>
          <div className={styles.frameParent5}>
            <div className={styles.profileWrapper}>
              <div className={styles.profile2}>
                
                <div className={styles.profileSection}>
                <div className={styles.imgContainer}>
                  <Carousel
          showArrows={true} // Display navigation arrows
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide status indicators
          showThumbs={false} // Hide thumbnail navigation
          autoPlay={true} // Disable auto-play
          className={styles.imgContainer}
        >
          {galleryImages.map((imageUrl, index) => (
            <div key={index} className={styles.imgContainer}>
              <img
                src={imageUrl}
                alt={`Image ${index}`}
                className={styles.imgContainer}
              />
        
            </div>
          ))}
        </Carousel>
        <div
                      className={styles.smallButtons}
                      onClick={onSmallButtonsContainerClick}
                    >
                      <div className={styles.edit2Parent}>
                        <img
                          className={styles.guarantee1Icon}
                          alt=""
                          src="/iconsedit.svg"
                        />
                        <div className={styles.button9}>Edit</div>
                      </div>
                    </div>
                </div>
                
                  <div className={styles.profile3}>
                    <div className={styles.profileHeading}>
                      <div className={styles.avatarParent}>
                      <Avatar
        className={styles.avatarIcon}
        alt=""
        src={profileImage}
      />
                        <div className={styles.frameParent6}>
                          <div className={styles.edit2Parent}>
                            <div className={styles.dysonElectricals}>
                             {name}
                            </div>
                             {verification>=30?<img
                            className={styles.iconsadd}
                            alt=""
                            src="/guarantee-11.svg"
                          />:null}
                          </div>
                          <div className={styles.transactionPills}>
                            <div className={styles.success}>{verification>=30?'verified':'unverified'}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.awards1}>
                        <div className={styles.semiPro} />
                        <div className={styles.semiPro} />
                        <div className={styles.semiPro} />
                        <div className={styles.semiPro} />
                        <div className={styles.semiPro} />
                        <div className={styles.semiPro} />
                        <div className={styles.rookie}>
                          <img
                            className={styles.rookieChild}
                            alt=""
                            src="/group-669667.svg"
                          />
                          <img
                            className={styles.rookieItem}
                            alt=""
                            src="/group-669671.svg"
                          />
                          <img
                            className={styles.ambassadorInner}
                            alt=""
                            src="/group-669505.svg"
                          />
                          <div className={styles.legendary}>Ambassador</div>
                          <img
                            className={styles.ambassadorChild5}
                            alt=""
                            src="/vector-396.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.buttons}>
                      <button
                        className={styles.largeButtons}
                        onClick={onLargeButtonsClick}
                      >
                        <img
                          className={styles.icons20pxplaceholder}
                          alt=""
                          src="/icons20pxplaceholder41.svg"
                        />
                        <b className={styles.label}>Promote Profile</b>
                        <img
                          className={styles.icons20pxplaceholder}
                          alt=""
                          src="/icons20pxplaceholder41.svg"
                        />
                      </button>
                      <button className={styles.buttonsInner} onClick={()=>{setModalPopUp(true)}}>
                        <div className={styles.shareParent}>
                          <b className={styles.share}>Share</b>
                          <img
                            className={styles.alertCircleIcon}
                            alt=""
                            src="/iconsshare.svg"
                          />
                        </div>
                      </button>
                    </div>
                    <div className={styles.prototypeSettings}>
                      <div className={styles.twoTabSwitch}>
                        <div className={styles.tab1Wrapper}>
                          <div className={styles.tab1}>Account</div>
                        </div>
                        <div
                          className={styles.tab2Wrapper}
                          onClick={onFrameContainer55Click}
                        >
                          <div className={styles.tab2}>Settings</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent7}>
                  {/*<div className={styles.frameParent8}>
                    <div className={styles.frameParent9}>
                      <div className={styles.frameParent10}>
                        <div className={styles.parent}>
                          <div className={styles.dysonElectricals}>300</div>
                          <div className={styles.totalPoints1}>
                            Total Points
                          </div>
                        </div>
                        <div className={styles.nextLevelParent}>
                          <div className={styles.nextLevel1}>Next Level</div>
                          <div className={styles.intermediate4}>
                            Intermediate
                          </div>
                          <div className={styles.nextLevel1}>2500 points</div>
                        </div>
                      </div>
                      <div className={styles.progressBar2}>
                        <div className={styles.progressBarInner1}>
                          <div className={styles.frameChild4} />
                        </div>
                        <div className={styles.frameParent11}>
                          <div className={styles.rectangleWrapper2}>
                            <div className={styles.frameChild5} />
                          </div>
                          <div className={styles.frameChild6} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.earn950Points1}>
                      Earn +950 points more to advance to the next level
                    </div>
                    <div
                      className={styles.howToEarnPointsAndLevelUParent}
                      onClick={onFrameContainer63Click}
                    >
                      <div className={styles.howToEarn1}>
                        How to earn points and level up
                      </div>
                      <img
                        className={styles.iconswarning}
                        alt=""
                        src="/iconswarning.svg"
                      />
                    </div>
                             </div>*/}
                  <div className={styles.verificationProgressBar}>
                    <img
                      className={styles.verificationProgressBarChild}
                      alt=""
                      src="/vector-271.svg"
                    />
                    <div className={styles.exclamationMark1Parent}>
                      <img
                        className={styles.exclamationMark1Icon1}
                        alt=""
                        src="/exclamationmark-11.svg"
                      />
                      <div className={styles.youAreUnverifiedGroup}>
                      {
                        verification==0?
                        <div className={styles.dysonElectricals}>
                          You are unverified
                        </div>
                        :
                        <div className={styles.dysonElectricals}>
                          Your verification is {verification}% complete
                        </div>
                      }
                        
                        <div className={styles.completeVerificationTo1}>
                          Complete verification to earn points
                        </div>
                       
                      </div>
                      
                    </div>
                    <div className={styles.progressBar3}>
                    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={verification} />
    </Box>
                    </div>
                  </div>
                  <div className={styles.frameWrapper}>
                    <div className={styles.youAreUnverifiedGroup}>
                    <div className={styles.profileList1}>
                        <div className={styles.frameParent12}
                        onClick={idproof}>
                          <div
                          style={{display:'flex',gap:'12px'}}>
                            {
                              id_done?
                              <img
                              src="./IDproof_done.svg"
                              />
                              :
                              null
                            }
                           
                          <div className={styles.titleParent}>
                            <div className={styles.tab2}>Proof of Identity</div>
                            <div className={styles.subtitle}>
                              Add documents to verify your identity
                            </div>
                          </div>
                          </div>
                          {
                              id_done?
                              <img
                              src="/iconschevronright1.svg"
                              />
                              :
                              <button className={styles.smallButtons1} onClick={idproof}>
                            <div className={styles.proceedWrapper}>
                              <div className={styles.button10}>
                                Verify Identity
                              </div>
                            </div>
                          </button>
                            }
                          
                        </div>
                      </div>
                      <div
                        className={styles.profileList}
                        onClick={license}
                      >
                       
                        <div className={styles.frameParent12}>
                        <div
                          style={{display:'flex',gap:'12px'}}>
                             {
                              license_done?
                              <img
                              src="./license_done.svg"
                              />
                              :
                              null
                            }
                          <div className={styles.titleParent}>
                            <div className={styles.tab2}>
                              License and certification
                            </div>
                            <div className={styles.subtitle}>
                              Certification depending on service type
                            </div>
                          </div>
                          </div>
                          {
                              license_done?
                              <img
                              src="/iconschevronright1.svg"
                              />
                              :
                              <button className={styles.smallButtons1} onClick={license}>
                              <div className={styles.proceedWrapper}>
                                <div className={styles.button10}>
                                  Verify license
                                </div>
                              </div>
                            </button>
                            }
                         
                        </div>
                      </div>
                      <div className={styles.profileList1}>
                        <div className={styles.frameParent12}
                        onClick={education}>
                        <div
                          style={{display:'flex',gap:'12px'}}>
 {
                              edu_done?
                              <img
                              src="./education_done.svg"
                              width={32}
                              />
                              :
                              null
                            }
                          <div className={styles.titleParent}>
                            <div className={styles.tab2}>Education</div>
                            <div className={styles.subtitle}>
                              Complete your profile by adding education
                            </div>
                          </div>
                          </div>
                          {
                              edu_done?
                              <img
                              src="/iconschevronright1.svg"
                              />
                              :
                               <button className={styles.smallButtons2} onClick={education}>
                            <div className={styles.proceedWrapper}>
                              <div className={styles.button10}>
                                Verify education
                              </div>
                            </div>
                          </button>
                            }
                         
                        </div>
                      </div>
                      <div className={styles.profileList1}>
                        <div className={styles.frameParent12}
                        onClick={work}>
                        <div
                          style={{display:'flex',gap:'12px'}}>
                            {
                              work_done?
                              <img
                              src="./work_done.svg"
                              />
                              :
                              null
                            }
                             <div className={styles.titleParent}>
                            <div className={styles.tab2}>Work Experience</div>
                            <div className={styles.subtitle}>
                              Add references to help you stand out
                            </div>
                          </div>
                           </div>
                           {
                              work_done?
                              <img
                              src="/iconschevronright1.svg"
                              />
                              :
                               <button className={styles.smallButtons2} onClick={work}>
                            <div className={styles.proceedWrapper}>
                              <div className={styles.button10}>
                                Verify experience
                              </div>
                            </div>
                          </button>
                            }
                         
                        </div>
                      </div>
                      <div className={styles.profileList1}>
                        <div className={styles.frameParent12}>
                          <div className={styles.titleParent}>
                            <div className={styles.tab2}>My Network</div>
                            <div className={styles.subtitle}>
                              Add people in the same field of service
                            </div>
                          </div>
                          <button className={styles.smallButtons1} onClick={()=>navigate('/network_proof')}>
                            <div className={styles.proceedWrapper}>
                              <div className={styles.button10}>Add network</div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className={styles.profileList5}>
                        <div className={styles.frameParent12}>
                          <div className={styles.titleParent}>
                            <div className={styles.tab2}>References</div>
                            <div className={styles.subtitle}>
                              Add references to help you stand out
                            </div>
                          </div>
                          <button className={styles.smallButtons1}  onClick={()=>navigate('/reference_proof')}>
                            <div className={styles.proceedWrapper}>
                              <div className={styles.button10}>
                                Add references
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent18}>
              <div className={styles.connectSocialAccountsParent}>
                <div className={styles.connectSocialAccounts1}>
                  Connect Social accounts
                </div>
                <img
                  className={styles.frameChild7}
                  alt=""
                  src="/group-667541.svg"
                  style={{cursor:'pointer'}}
                onClick={()=>setInstaOpen(true)}
                />
                <img
                  className={styles.frameChild8}
                  alt=""
                  src="/group-667531.svg"
                  style={{cursor:'pointer'}}
                onClick={()=>setTwitterOpen(true)}
                />
                <img
                  className={styles.frameChild9}
                  alt=""
                  src="/group-667551.svg"
                  style={{cursor:'pointer'}}
                onClick={()=>setFacebookOpen(true)}
                />
              </div>
              <div className={styles.referAndEarn1}>
                <div className={styles.rectangleParent1}>
                  <div className={styles.groupItem} />
                  <div className={styles.groupInner} />
                  <div className={styles.groupChild1} />
                  <div className={styles.groupChild30} />
                  <div className={styles.groupChild31} />
                  <div className={styles.groupChild32} />
                  <div className={styles.groupChild33} />
                  <div className={styles.groupChild34} />
                  <div className={styles.groupChild35} />
                  <div className={styles.groupChild36} />
                  <div className={styles.groupChild37} />
                  <div className={styles.groupChild38} />
                  <div className={styles.groupChild39} />
                  <div className={styles.groupChild40} />
                  <div className={styles.groupChild41} />
                  <div className={styles.groupChild42} />
                  <div className={styles.groupChild43} />
                  <div className={styles.groupChild44} />
                  <div className={styles.groupChild45} />
                  <div className={styles.groupChild46} />
                  <div className={styles.groupChild47} />
                  <div className={styles.groupChild48} />
                  <div className={styles.groupChild49} />
                  <div className={styles.groupChild50} />
                  <div className={styles.groupChild51} />
                  <div className={styles.groupChild52} />
                  <div className={styles.groupChild53} />
                  <div className={styles.groupChild54} />
                </div>
                <div className={styles.giftBoxGroup}>
                  <img
                    className={styles.giftBoxIcon1}
                    alt=""
                    src="/gift-box1.svg"
                  />
                  <div className={styles.parent}>
                    <div
                      className={styles.dysonElectricals}
                    >{`Refer & earn.`}</div>
                    <div className={styles.referABusiness1}>
                      Refer a business to earn points
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent19}>
            <div className={styles.connectSocialAccountsParent}>
              <div className={styles.connectSocialAccounts1}>
                Connect Social accounts
              </div>
              <img
                className={styles.frameChild7}
                alt=""
                src="/group-667542.svg"
                style={{cursor:'pointer'}}
                onClick={()=>setInstaOpen(true)}
              />
              <img
                className={styles.frameChild8}
                alt=""
                src="/group-667532.svg"
                style={{cursor:'pointer'}}
                onClick={()=>setTwitterOpen(true)}
              />
              <img
                className={styles.frameChild9}
                alt=""
                src="/group-667552.svg"
                style={{cursor:'pointer'}}
                onClick={()=>setFacebookOpen(true)}
              />
            </div>
            <div className={styles.referAndEarn1}>
              <div className={styles.rectangleParent1}>
                <div className={styles.groupItem} />
                <div className={styles.groupInner} />
                <div className={styles.groupChild1} />
                <div className={styles.groupChild30} />
                <div className={styles.groupChild31} />
                <div className={styles.groupChild32} />
                <div className={styles.groupChild33} />
                <div className={styles.groupChild34} />
                <div className={styles.groupChild35} />
                <div className={styles.groupChild36} />
                <div className={styles.groupChild37} />
                <div className={styles.groupChild38} />
                <div className={styles.groupChild39} />
                <div className={styles.groupChild40} />
                <div className={styles.groupChild41} />
                <div className={styles.groupChild42} />
                <div className={styles.groupChild43} />
                <div className={styles.groupChild44} />
                <div className={styles.groupChild45} />
                <div className={styles.groupChild46} />
                <div className={styles.groupChild47} />
                <div className={styles.groupChild48} />
                <div className={styles.groupChild49} />
                <div className={styles.groupChild50} />
                <div className={styles.groupChild51} />
                <div className={styles.groupChild52} />
                <div className={styles.groupChild53} />
                <div className={styles.groupChild54} />
              </div>
              <div className={styles.giftBoxGroup}>
                <img
                  className={styles.giftBoxIcon1}
                  alt=""
                  src="/gift-box3.svg"
                />
                <div className={styles.parent}>
                  <div
                    className={styles.dysonElectricals}
                  >{`Refer & earn.`}</div>
                  <div className={styles.referABusiness1}>
                    Refer a business to earn points
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
             <div className={styles.bottomNav1}>
            <div className={styles.home8} onClick={()=>{navigate('/home')}}>
              <div className={styles.home1}>Home</div>
              <img
                className={styles.brandLogoIcon}
                alt=""
                src="/brand-logo31.svg"
              />
            </div>
            <div className={styles.jobs2}  onClick={()=>{navigate('/jobs')}}>
              <div className={styles.jobs1}>Jobs</div>
              <img
                className={styles.bibriefcaseFillIcon}
                alt=""
                src="/iconsjobs.svg"
              />
            </div>
            <div className={styles.wallet2}  onClick={()=>{navigate('/wallet')}}>
              <div className={styles.wallet1}>Wallet</div>
              <img
                className={styles.zondiconswallet}
                alt=""
                src="/iconslog-cash.svg"
              />
            </div>
            <div className={styles.account4}>
              <b className={styles.account3}>Account</b>
              <img
                className={styles.mdiaccountIcon}
                alt=""
                src="/account3.svg"
              />
            </div>
          </div>
          </AppBar>
         
        </div>
      </div>
      {instaOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setInstaOpen(false)}
        >
          <InstagramModal onClose={()=>setInstaOpen(false)} />
        </PortalPopup>
      )}
       {twitterOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setTwitterOpen(false)}
        >
          <TwitterModal onClose={()=>setTwitterOpen(false)} />
        </PortalPopup>
      )}
       {facebookOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setFacebookOpen(false)}
        >
          <FacebookModal onClose={()=>setFacebookOpen(false)} />
        </PortalPopup>
      )}
        {modalPop && (
           <PortalPopup
           overlayColor="rgba(113, 113, 113, 0.3)"
           placement="Centered"
           onOutsideClick={()=>setModalPopUp(false)}
         >
           <ShareModal
                    setModalPopUp={setModalPopUp}
                    modalPop={modalPop}
                    uid={org}
                  />
         </PortalPopup>
                 
                )}
    </div>
  );
};

export default NavPrototype;
