import { useCallback, useState } from "react";
import styles from "./SendQuote.module.css";
import PortalPopup from "./PortalPopup";
import RecentChats from "./RecentChats";
import { auth } from "../firebase";
import posthog from 'posthog-js'

 

const SendQuote = ({ onClose,qkey,onDaft }) => {
  const onIconsCloseClick = useCallback(() => {
    onClose()
  }, []);
  const [isSendQuotePopupOpen, setSendQuotePopupOpen] = useState(0);

  return (
    <div className={styles.sendquote }>
      {
        isSendQuotePopupOpen==0?
          <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>Send Quote</div>
            <img
              className={styles.iconsclose}
              alt=""
              src="/iconsclose1.svg"
              onClick={onIconsCloseClick}
            />
          </div>
        </div>
        <div className={styles.listParent}>
          <div className={styles.list} onClick={()=>{setSendQuotePopupOpen(1)}}>
            <div className={styles.content}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg31.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Send to recent chat contact</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight}
                  alt=""
                  src="/iconschevronright11.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.list} onClick={()=>{
           if (typeof onDaft === 'function') {
            onDaft();
          }
             const emailAddress = ' '; // Replace with the recipient's email address
             const subject = 'My Quote';
             const body = 'Hi there,\n\nTake a look at my quote:\n\nhttps://www.consumer.loggworks.co.uk/quote/'+auth.currentUser.uid+'/'+qkey;
         
             const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
         
             // Open the user's default email client
             window.location.href = mailtoLink;
             posthog.capture('Send Quote: Mail',)
   
          }}>
            <div className={styles.content1}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg4.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Send via mail</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight1}
                  alt=""
                  src="/iconschevronright11.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.list} onClick={()=>{
        if (typeof onDaft === 'function') {
          onDaft();
        }
            const message = 'Hi there,\n\nTake a look at my quote:\n\nhttps://www.consumer.loggworks.co.uk/quote/'+auth.currentUser.uid+'/'+qkey;

            const whatsappLink = `https://wa.me/?text=${encodeURIComponent(message)}`;
        
            // Open the user's default web browser
            window.open(whatsappLink, '_blank');
            posthog.capture('Send Quote: WhatsApp',)
          }}>
            <div className={styles.content1}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg5.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Send via WhatsApp</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight1}
                  alt=""
                  src="/iconschevronright11.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.list}
          onClick={()=>{
            if (typeof onDaft === 'function') {
              onDaft();
            }
            const body = 'Hi there,\n\nTake a look at my quote:\n\nhttps://www.consumer.loggworks.co.uk/quote/'+auth.currentUser.uid+'/'+qkey;
            navigator.clipboard.writeText(body);
            posthog.capture('Send Quote: Copied/Shared Link',)
            alert('link copied')
          }}
          >
            <div className={styles.content1}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg6.svg"
                />
                <img
                  className={styles.iconsplaceholder}
                  alt=""
                  src="/iconsplaceholder1.svg"
                />
                <div className={styles.list1}>Copy link</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconschevronRight1}
                  alt=""
                  src="/iconschevronright11.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
<RecentChats onClose={()=>setSendQuotePopupOpen(0)} qkey={qkey}/>
      }
    
     
    </div>
  );
};

export default SendQuote;
