import { useCallback, useState } from "react";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./IDproofModal.module.css";
import { auth, db, storage } from "../firebase";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { v4 as uuidv4 } from 'uuid';

const IDproofModal = ({ onClose }) => {
  const navigate = useNavigate();
  const [noExp,setNoExp]=useState(false)
  const [name,setName]=useState('')
  const [date,setDate]=useState('')
  const [imageUrls, setImageUrls] = useState([]);

  const addCertificateToFirestore = () => {
    if (name.trim() === '' || imageUrls.length === 0) {
      alert('Please fill in the name and provide at least one image.');
      return;
    }

    // Check if noExp is false and date is empty
    if (!noExp && date.trim() === '') {
      alert('Please provide a date for the ID.');
      return;
    }
    
    // Get a reference to the 'users' collection
    const usersCollection = db.collection("users");
  
    // Get a reference to the 'verification' subcollection within the user's document
    const verificationCollection = usersCollection.doc(auth.currentUser.uid).collection("proofID");
  
    // Define the data you want to add
    const certificateData = {
      certificationName: name,
      expirationDate: date,
      imageUrls:imageUrls
    };
  if (name!='') {
    // Add the data to Firestore
    verificationCollection.add(certificateData)
    .then((docRef) => {
      verificationCollection.get().then((querySnapshot) => {
        if (querySnapshot.size <= 1) {
          usersCollection.doc(auth.currentUser.uid).update({
            verification: firebase.firestore.FieldValue.increment(15)})
        } 
      });
     
      onClose()
    })
    .catch((error) => {
      console.error("Error adding certificate: ", error);
    });
  }
    
  };
  const removeImageUrl = (index) => {
    const newImageUrls = [...imageUrls];
    newImageUrls.splice(index, 1);
    setImageUrls(newImageUrls);
  };
  const handleImageUpload = async (e) => {
    const files = e.target.files;
    const storageRef = storage.ref(); // Get a reference to the Firebase Storage
  
    // Loop through selected files and upload them
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split('.').pop(); // Get the file extension
      const randomFileName = `${uuidv4()}.${fileExtension}`; // Generate a random name with the original extension
      const imageRef = storageRef.child(`images/${randomFileName}`);
  
      // Upload the file to Firebase Storage
      const snapshot = await imageRef.put(file);
  
      // Get the download URL for the uploaded image
      const downloadURL = await snapshot.ref.getDownloadURL();
      setImageUrls((prevImageUrls) => [...prevImageUrls, downloadURL]);
  
      console.log("Uploaded a file and added data to Firestore:", randomFileName);
    }
  };
  return (
    <div className={styles.idproofmodal}>
      <div className={styles.modalTitle}>
        <div className={styles.addIdProofParent}>
          <div className={styles.addIdProof}>Add ID Proof</div>
          <img
            className={styles.iconsclose}
            alt=""
            src="/iconsclose1.svg"
            onClick={onClose}
          />
        </div>
      </div>
      <div className={styles.inputParent}>
        <div className={styles.input}>
          <div className={styles.labelContainer}>
            <div className={styles.label}>Document Name</div>
          </div>
          <TextField
            className={styles.baseField}
            fullWidth
            color="primary"
            variant="outlined"
            type="text"
            size="medium"
            margin="none"
            value={name}
            onChange={(e)=>{setName(e.target.value)}}
          />
          <div className={styles.helperTextContainer}>
            <div className={styles.thisIsA}>This is a helper text</div>
            <div className={styles.div}>1/100</div>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Expiration Date</div>
            </div>
            <TextField
              className={styles.baseField1}
              color="primary"
              variant="outlined"
              type="date"
              size="medium"
              margin="none"
              disabled={noExp}
              value={date}
            onChange={(e)=>{setDate(e.target.value)}}
            InputProps={{ inputProps: { min: new Date().toISOString().split('T')[0] } }}
            />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <FormControlLabel
            label="ID does not expire"
            labelPlacement="end"
            control={<Checkbox color="primary" size="medium" value={noExp} onChange={()=>{setNoExp(!noExp)}}/>}
          />
        </div>
      </div>
      <div className={styles.uploadImageParent}>
        <div className={styles.uploadImage}>Upload Image</div>
        <div className={styles.uploadImageGroup}>
        <input type="file" accept="image/*" id='upload-button' 
        multiple onChange={handleImageUpload} style={{display:'none'}}/>
        <label htmlFor="upload-button" style={{cursor:'pointer'}}>
<div className={styles.uploadImage1}>
            <div className={styles.imageFrame}>
              <div className={styles.boundary} />
              <div className={styles.imageArea} />
              <img className={styles.iconsadd} alt="" src="/iconsadd1.svg" />
            </div>
          </div>
        </label>
        {imageUrls.length > 0 && (
        <>
          {imageUrls.map((url, index) => (
           <div className={styles.uploadImage1}>
           <div className={styles.imageFrame}>
             <div className={styles.boundary} />
             <div className={styles.imageArea} style={{backgroundImage:`url(${url})`
             ,backgroundSize:'cover',backgroundPosition:'center'}}/>
           </div>
           <img
             className={styles.addcancelCircleIcon}
             alt=""
             style={{cursor:'pointer'}}
             src="/addcancel-circle2.svg"
             onClick={() => removeImageUrl(index)}
           />
         </div>
          ))}
        </>
      )}
          
        </div>
      </div>
      <br/>
      <br/>
      <button className={styles.largeButtons} onClick={addCertificateToFirestore}>
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder3.svg"
        />
        <b className={styles.label2}>Save</b>
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder3.svg"
        />
      </button>
    </div>
  );
};

export default IDproofModal;
