import { useMemo } from "react";
import container from "../pages/SignIn.module.css";
const Container = ({
  logoHeight,
  logoBackgroundImage,
  frame66982AlignSelf,
}) => {
  const iMGStyle = useMemo(() => {
    return {
      height: logoHeight,
      backgroundImage: logoBackgroundImage,
    };
  }, [logoHeight, logoBackgroundImage]);

  const testimonialStyle = useMemo(() => {
    return {
      alignSelf: frame66982AlignSelf,
    };
  }, [frame66982AlignSelf]);

  return (
    <div className={container.img}>
    <img className={container.logoIcon} alt="" src="/logo.svg" />
    <div className={container.testimonial}>
      <div className={container.greatSessionDaniWasSuperParent}>
        <div className={container.greatSessionDani}>
          "Great session! Dani was super helpful. She shared some practical
          advice on how can we go about refining our service offerings."
        </div>
        <div className={container.frameParent}>
          <div className={container.ellipseParent}>
            <img
              className={container.frameChild}
              alt=""
              src="/ellipse-249@2x.png"
            />
            <div className={container.cameronWilliamson}>
              Cameron Williamson
            </div>
          </div>
          <div className={container.starRating}>
            <img
              className={container.icons16pxstar}
              alt=""
              src="/icons16pxstar2.svg"
            />
            <img
              className={container.icons16pxstar}
              alt=""
              src="/icons16pxstar2.svg"
            />
            <img
              className={container.icons16pxstar}
              alt=""
              src="/icons16pxstar2.svg"
            />
            <img
              className={container.icons16pxstar}
              alt=""
              src="/icons16pxstar2.svg"
            />
            <img
              className={container.icons16pxstar}
              alt=""
              src="/icons16pxstar11.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Container;
