import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PandP.module.css";
const PandP = () => {
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onListContainerClick = useCallback(() => {
    navigate("/paymentmethod");
  }, [navigate]);

  const onListContainer1Click = useCallback(() => {
    navigate("/payoutpref");
  }, [navigate]);

  return (
    <div className={styles.pandp}>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-66522.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconshome5.svg"
                />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home}>Wallet</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home}>Message</div>
              </div>
            </div>
            <div className={styles.default5}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account5.svg"
                />
                <div className={styles.home5}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.component102Parent}>
        <div className={styles.component102}>
          <div className={styles.smallButtons}>
            <div className={styles.iconsaddParent}>
              <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
              <div className={styles.button}>Add Item</div>
            </div>
          </div>
          <img className={styles.avatarIcon} alt="" src="/avatar6.svg" />
          <b className={styles.licenseAndCertification}>Payments and payouts</b>
          <div className={styles.icon} style={{cursor:'pointer'}}
          onClick={()=>navigate(-1)}>
            <img
              className={styles.iconshome}
              alt=""
              src="/iconschevronright.svg"
            />
          </div>
        </div>
        <div className={styles.titles}>
          <div className={styles.back}  onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.notifications}>Payments and payouts</div>
        </div>
        <div className={styles.listParent}>
          <div className={styles.list} onClick={onListContainerClick}>
            <div className={styles.content}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg1.svg"
                />
                <img className={styles.iconshome} alt="" src="/iconscard.svg" />
                <div className={styles.list1}>Payment methods</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconschevronright3.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.list2} onClick={onListContainer1Click}>
            <div className={styles.content}>
              <div className={styles.left}>
                <img
                  className={styles.iconWithBg}
                  alt=""
                  src="/icon-with-bg1.svg"
                />
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/iconsplaceholder3.svg"
                />
                <div className={styles.list1}>Payout preferences</div>
              </div>
              <div className={styles.right}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconschevronright3.svg"
                />
                <div className={styles.toggle}>
                  <div className={styles.toggleChild} />
                  <div className={styles.toggleItem} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PandP;
