import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProfilePromo.module.css";
const ProfilePromo = () => {
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onFrameContainer8Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onFrameContainer9Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onFrameContainer10Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.profilepromo}>
      <div className={styles.profilepromo1}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665224.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account3.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.activities}>Promote profile</div>
          </div>
          <div className={styles.component102}>
            <div className={styles.icon}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
            <b className={styles.licenseAndCertification}>Promotions</b>
            <div className={styles.smallButtons}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd.svg" />
                <div className={styles.button}>Add Item</div>
              </div>
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar22.svg" />
          </div>
          <div className={styles.promotionsTabPrototypeWrapper}>
            <div className={styles.promotionsTabPrototype}>
              <div className={styles.twoTabFlatSwitch}>
                <div
                  className={styles.tab1Wrapper}
                  onClick={onFrameContainer8Click}
                >
                  <div className={styles.tab1}>New</div>
                </div>
                <div
                  className={styles.tab2Wrapper}
                  onClick={onFrameContainer9Click}
                >
                  <div className={styles.reach}>Current</div>
                </div>
                <div
                  className={styles.tab3Wrapper}
                  onClick={onFrameContainer10Click}
                >
                  <div className={styles.reach}>Past</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent}>
            <div className={styles.inputParent}>
              <div className={styles.input}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>
                    What would you like to promote?
                  </div>
                </div>
                <div className={styles.baseField}>
                  <img
                    className={styles.iconschevronDown}
                    alt=""
                    src="/iconschevrondown1.svg"
                  />
                  <div className={styles.userInput}>Profile</div>
                  <div className={styles.dot}>
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                    <div className={styles.dotChild} />
                  </div>
                </div>
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
              <div className={styles.promotions}>
                <div className={styles.imgWrapper}>
                  <img className={styles.imgIcon} alt="" src="/img@2x.png" />
                </div>
                <div className={styles.description}>
                  <div className={styles.descriptionInner}>
                    <div className={styles.frameGroup}>
                      <div className={styles.businessNameAndAvatarParent}>
                        <div className={styles.businessNameAndAvatar}>
                          <img
                            className={styles.avatarIcon1}
                            alt=""
                            src="/avatar110.svg"
                          />
                          <div className={styles.businessName}>
                            Business Name
                          </div>
                        </div>
                        <div className={styles.ratings}>
                          <div className={styles.starRating}>
                            <img
                              className={styles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={styles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={styles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={styles.iconsadd}
                              alt=""
                              src="/icons16pxstar.svg"
                            />
                            <img
                              className={styles.iconsadd}
                              alt=""
                              src="/icons16pxstar1.svg"
                            />
                          </div>
                          <div className={styles.ratingNumber}>5.0 (300K)</div>
                        </div>
                      </div>
                      <div className={styles.jobCategory}>
                        Job category | Distance
                      </div>
                    </div>
                  </div>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                    <b className={styles.ratingNumber}>Get quote</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.distanceSliderParent}>
                <div className={styles.distanceSlider}>
                  <div className={styles.reach}>Reach</div>
                  <div className={styles.bgParent}>
                    <div className={styles.bg}>
                      <div className={styles.bgChild} />
                    </div>
                    <div className={styles.slider}>
                      <div className={styles.sliderChild} />
                      <div className={styles.sliderItem} />
                    </div>
                  </div>
                  <div className={styles.noOfPeopleParent}>
                    <div className={styles.home}>No of People</div>
                    <div className={styles.input1}>
                      <div className={styles.labelContainer1}>
                        <div className={styles.label}>Label</div>
                      </div>
                      <div className={styles.baseField1}>
                        <img
                          className={styles.iconsplaceholder}
                          alt=""
                          src="/iconsplaceholder31.svg"
                        />
                        <div className={styles.userInput1}>0</div>
                        <div className={styles.dot}>
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                          <div className={styles.dotChild} />
                        </div>
                      </div>
                      <div className={styles.helperTextContainer1}>
                        <div className={styles.thisIsA1}>
                          This is a helper text
                        </div>
                        <div className={styles.div}>1/100</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.inputGroup}>
                  <div className={styles.input2}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>Expiry Time</div>
                    </div>
                    <div className={styles.baseField}>
                      <img
                        className={styles.iconschevronDown}
                        alt=""
                        src="/iconsclock1.svg"
                      />
                      <div className={styles.userInput2}>Profile</div>
                      <div className={styles.dot}>
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                      </div>
                    </div>
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                  <div className={styles.input2}>
                    <div className={styles.labelContainer}>
                      <div className={styles.label}>Expiry Date</div>
                    </div>
                    <div className={styles.baseField}>
                      <img
                        className={styles.iconschevronDown}
                        alt=""
                        src="/iconscalendar1.svg"
                      />
                      <div className={styles.userInput2}>Profile</div>
                      <div className={styles.dot}>
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                        <div className={styles.dotChild} />
                      </div>
                    </div>
                    <div className={styles.helperTextContainer}>
                      <div className={styles.thisIsA}>
                        This is a helper text
                      </div>
                      <div className={styles.div}>1/100</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteCardsParent}>
              <div className={styles.quoteCards}>
                <div className={styles.quoteNumber}>Amount Payable:</div>
                <b className={styles.qn0001}>£0</b>
                <div className={styles.transactionPills}>
                  <div className={styles.success}>DRAFTS</div>
                </div>
              </div>
              <div className={styles.largeButtons1}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder1.svg"
                />
                <b className={styles.label4}>Proceed to Pay</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder1.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.profilemob}>
        <div className={styles.titles1}>
          <div className={styles.back}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame2.svg" />
          </div>
          <div className={styles.activities}>Promote profile</div>
        </div>
        <div className={styles.tab}>
          <div className={styles.frameContainer}>
            <div className={styles.newWrapper}>
              <div className={styles.new}>New</div>
            </div>
            <div className={styles.currentWrapper}>
              <div className={styles.current}>Current</div>
            </div>
            <div className={styles.currentWrapper}>
              <div className={styles.current}>Past</div>
            </div>
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.distanceSliderParent}>
            <div className={styles.input4}>
              <div className={styles.inputChild} />
              <div className={styles.jackDorsey}>My Profile</div>
              <div className={styles.selectOption}>
                What would you like to promote?
              </div>
              <img
                className={styles.chevronUpIcon}
                alt=""
                src="/chevronup.svg"
              />
            </div>
            <div className={styles.profilePromotions}>
              <div className={styles.frameParent1}>
                <div className={styles.previewWrapper}>
                  <div className={styles.preview}>Preview</div>
                </div>
                <div className={styles.rectangleParent}>
                  <div className={styles.groupChild} />
                  <img
                    className={styles.unsplashmlo6ilubadaIcon}
                    alt=""
                    src="/unsplashmlo6ilubada@2x.png"
                  />
                  <div className={styles.electrician}>{`Electrician  `}</div>
                  <div className={styles.button1}>
                    <div className={styles.iconsParent}>
                      <img className={styles.icons} alt="" src="/icons2.svg" />
                      <b className={styles.proceed}>Get quote</b>
                    </div>
                  </div>
                  <div className={styles.rectangleGroup}>
                    <img
                      className={styles.frameChild}
                      alt=""
                      src="/rectangle-12135@2x.png"
                    />
                    <div className={styles.dysonElectricalsParent}>
                      <b className={styles.dysonElectricals}>
                        Dyson electricals
                      </b>
                      <div className={styles.groupParent}>
                        <img
                          className={styles.frameItem}
                          alt=""
                          src="/group-66649.svg"
                        />
                        <div className={styles.div4}>4.0(20)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputParent1}>
            <div className={styles.input4}>
              <div className={styles.email}>
                <div className={styles.emailChild} />
                <div className={styles.jackDorsey1}>Jack Dorsey</div>
              </div>
              <div className={styles.selectOption}>Location</div>
            </div>
            <div className={styles.inputParent2}>
              <div className={styles.input6}>
                <div className={styles.email}>
                  <div className={styles.emailChild} />
                  <div className={styles.jackDorsey2}>Jack Dorsey</div>
                </div>
                <div className={styles.selectOption}>Expiry Time</div>
                <img
                  className={styles.calendarIcon}
                  alt=""
                  src="/calendar2.svg"
                />
              </div>
              <div className={styles.input6}>
                <div className={styles.email}>
                  <div className={styles.emailChild} />
                  <div className={styles.jackDorsey2}>Jack Dorsey</div>
                </div>
                <div className={styles.selectOption}>Expiry Date</div>
                <img className={styles.calendarIcon} alt="" src="/clock2.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.payment}>
          <div className={styles.button2}>
            <div className={styles.iconsParent}>
              <img className={styles.icons} alt="" src="/icons4.svg" />
              <b className={styles.proceed}>Proceed to pay</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePromo;
