import { TextField } from "@mui/material";
import styles from "./AddPaymetCard.module.css";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import axios from 'axios';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51OKRh6Dk2CQnAQVzdZ1sazYAyHpXmWJPXZwnZ0AYNdiXUO2nKH8OdQy3UjK74s4Yu6ln2qXbBMQVyNaBtKjAziGP00RTMN49iC');
const AddPaymetCard = ({ onClose }) => {
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [payId, setPayId] = useState("");
  const [name, setName]=useState('')
  const [email, setEmail]=useState('')
  const [dob, setDob]=useState('')
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    db.collection('users').doc(auth.currentUser.uid).get().then(function (snap){
      setName(snap.data().name)
      setEmail(snap.data().email||auth.currentUser.email)
      setDob(snap.data().dob)
      setPayId(snap.data().Strip_payId)
    })
  }, [])
  
 /* const handleSaveCard = async () => {
    // Check if any of the variables are empty
    if (!nameOnCard || !cardNumber || !expiryDate || !cvv) {
      // Handle the case where any of the fields are empty
      console.log("Please fill in all the fields.");
      return;
    }
    setLoading(true)
    const expiryDatef = expiryDate.replace(/\//g, ''); // Remove all forward slashes
    try {
      if (payId==null) {
        // Step 1: Send user data to the backend for user creation
       const responseuid = await axios.post("https://loggworks-c15c9e56f169.herokuapp.com/api/createUser", {
        firstName:name,
        lastName:'loggworks',
        email,
        dob
      });

      const userId = responseuid.data.userId;
      db.collection('users').doc(auth.currentUser.uid).update({
        payId:userId
      })
        const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/mangopay/cardregistration', {
        userId,
        cardNumber,
        expiryDatef,
        cvv, // Pass the user's MangoPay ID
      });
      // Handle the card registration response, which should include the CardId
      const cardId = response.data.cardId;
      console.log(cardId)
      
    // Reference to the "cards" subcollection
    const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");

    // Create a new card document
    const newCard = {
      nameOnCard,
      cardNumber,
      expiryDatef,
      cvv,
      cardId
    };

    // Add the card data to Firestore
    cardsCollection
      .add(newCard)
      .then((docRef) => {
        console.log("Card added with ID: ", docRef.id);
        onClose();
      })
      .catch((error) => {
        console.error("Error adding card: ", error);
        setLoading(false)
      });
      console.log('Card registration successful. CardId:', cardId);
    
      }
      else{
        const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/mangopay/cardregistration', {
          userId:payId,
          cardNumber,
          expiryDatef,
          cvv,// Pass the user's MangoPay ID
      });
      // Handle the card registration response, which should include the CardId
      const cardId = response.data.cardId;
      console.log(cardId)
      
    // Reference to the "cards" subcollection
    const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");

    // Create a new card document
    const newCard = {
      nameOnCard,
      cardNumber,
      expiryDatef,
      cvv,
      cardId
    };

    // Add the card data to Firestore
    cardsCollection
      .add(newCard)
      .then((docRef) => {
        console.log("Card added with ID: ", docRef.id);
        onClose();
      })
      .catch((error) => {
        console.error("Error adding card: ", error);
        setLoading(false)
      });
      console.log('Card registration successful. CardId:', cardId);
   
      }
      
    } catch (error) {
      // Handle errors, e.g., show an error message to the user
      console.error('Error:', error);
      setLoading(false)
    }

  };*/
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe) {
      return;
    }
    setLoading(true)
    try {
      // Create a token using the card details
      const { token, error } = await stripe.createToken('card', {
        number: cardNumber,
        exp_month: expiryDate.slice(0, 2),
        exp_year: expiryDate.slice(3, 5),
        cvc: cvv,
      });

      if (error) {
        setError(error.message);
      } else {
        const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");

        // Create a new card document
        const newCard = {
          nameOnCard,
          cardNumber,
          expiryDate,
          cvv,
          cardId:token
        };
    
        // Add the card data to Firestore
        cardsCollection
          .add(newCard)
          .then((docRef) => {
            console.log("Card added with ID: ", docRef.id);
            onClose();
          })
          .catch((error) => {
            console.error("Error adding card: ", error);
            setLoading(false)
          });
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false)
    }
  };
  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  return (
    <Elements stripe={stripePromise}>
      <MyComponentWithStripeHook onClose={onClose}/>
    </Elements>
  
  );
};

export default AddPaymetCard;

const MyComponentWithStripeHook = ({onClose}) => {
  const [nameOnCard, setNameOnCard] = useState("");
const [cardNumber, setCardNumber] = useState("");
const [expiryDate, setExpiryDate] = useState("");
const [cvv, setCvv] = useState("");
const [payId, setPayId] = useState("");
const [name, setName]=useState('')
const [email, setEmail]=useState('')
const [dob, setDob]=useState('')
const [loading,setLoading]=useState(false)
const stripe = useStripe();
const elements = useElements();

useEffect(() => {
  db.collection('users').doc(auth.currentUser.uid).get().then(function (snap){
    setName(snap.data().name)
    setEmail(snap.data().email||auth.currentUser.email)
    setDob(snap.data().dob)
    setPayId(snap.data().Strip_payId)
  })
}, [])


const handleSubmit = async (e) => {
  e.preventDefault();

  if (!stripe || !elements) {
    return;
  }

  setLoading(true);

  const cardNumberElement = elements.getElement(CardNumberElement);
  const cardExpiryElement = elements.getElement(CardExpiryElement);
  const cardCvcElement = elements.getElement(CardCvcElement);

  console.log(cardNumberElement)
  try {
    // Create a payment method using the card details
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement
    });

    if (error) {
      console.log(error.message);
    } else {
      const { last4, exp_month, exp_year, cvc } = paymentMethod.card;
      const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");

      // Create a new card document
      const newCard = {
        nameOnCard, // Assuming nameOnCard is defined somewhere in your code
        cardNumber: last4, // Extract last 4 digits of the card number
        expiryDate: `${exp_month}/${exp_year}`, // Format expiration date
        cvv: '',
        cardId: paymentMethod.id,
      };
    
      // Add the card data to Firestore
      await cardsCollection.add(newCard);
      console.log("Card added successfully");
      onClose();
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setLoading(false);
  }
};


const expriy_format = (value) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(/\//g, "").substring(0, 2) +
    (expdate.length > 2 ? "/" : "") +
    expdate.replace(/\//g, "").substring(2, 4);

  return expDateFormatter;
};

return (
  <div className={styles.addpaymetcard}>
  <div className={styles.addANewCardParent}>
    <div className={styles.addANew}>Add a new card</div>
    <img className={styles.iconsclose} alt="" src="/iconsclose1.svg" onClick={onClose} />
  </div>
      <div className={styles.frameParent}>
    <div className={styles.inputParent}>
      <div className={styles.input}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>Name on Card</div>
        </div>
        <input
         className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
          fullWidth
          color="primary"
          variant="outlined"
          type="text"
          placeholder="Name of Card"
          size="medium"
          margin="none"
          value={nameOnCard}
          onChange={(e)=>setNameOnCard(e.target.value)}
        />
       
        <div className={styles.helperTextContainer}>
          <div className={styles.thisIsA}>This is a helper text</div>
          <div className={styles.div}>1/100</div>
        </div>
      </div>
      <div className={styles.input}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>Card Number</div>
        </div>
        
         {/* 
         <TextField
          className={styles.baseField}
          fullWidth
          color="primary"
          variant="outlined"
          type="text"
          placeholder="0000 0000 0000 0000"
          size="medium"
          margin="none"
          value={cardNumber}
          onChange={(e)=>setCardNumber(e.target.value)}
        />
/>*/}
        <CardNumberElement
        className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
         options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#32325d',
              '::placeholder': {
                color: '#aab7c4',
              },
              border: '1px solid #ccc', // Add this line for the border
            },
            invalid: {
              color: '#fa755a',
            },
          },
        }}
        />
        <div className={styles.helperTextContainer}>
          <div className={styles.thisIsA}>This is a helper text</div>
          <div className={styles.div}>1/100</div>
        </div>
      </div>
      <div className={styles.inputGroup}>
        <div className={styles.input2}>
          <div className={styles.labelContainer}>
            <div className={styles.label}>Expiry date</div>
          </div>
          
           {/* 
           <TextField
            className={styles.baseField}
            fullWidth
            color="primary"
            variant="outlined"
            type="text"
            placeholder="MM/YY"
            size="medium"
            margin="none"
            value={expriy_format(expiryDate)}
          onChange={(e)=>setExpiryDate(e.target.value)}
          />
/>*/}
          <CardExpiryElement
          className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
           options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                  color: '#aab7c4',
                },
                border: '1px solid #000', 
              },
              invalid: {
                color: '#fa755a',
              },
            },
          }}
          />
          <div className={styles.helperTextContainer}>
            <div className={styles.thisIsA}>This is a helper text</div>
            <div className={styles.div}>1/100</div>
          </div>
        </div>
        <div className={styles.input3}>
          <div className={styles.labelContainer}>
            <div className={styles.label}>CVV</div>
          </div>
         {/* <TextField
            className={styles.baseField}
            fullWidth
            color="primary"
            variant="outlined"
            type="text"
            placeholder="CVV"
            size="medium"
            margin="none"
            value={cvv}
          onChange={(e)=>setCvv(e.target.value)}
/>*/}
          <CardCvcElement  
          className="appearance-none w-full text-gray-700 border border-red-500 rounded p-4 my-2 leading-tight focus:outline-none focus:bg-white bg-lightWhite border border-grey80"
         options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#32325d',
              '::placeholder': {
                color: '#aab7c4',
              },
              border: '1px solid #ccc', // Add this line for the border
            },
            invalid: {
              color: '#fa755a',
            },
          },
        }}
/>
          <div className={styles.helperTextContainer}>
            <div className={styles.thisIsA}>This is a helper text</div>
            <div className={styles.div}>1/100</div>
          </div>
        </div>
      </div>
    </div>
    <button disabled={loading} className={styles.largeButtons} onClick={handleSubmit}>
      <img className={styles.iconsadd} alt="" src="/iconsadd1.svg" />
      <b className={styles.label4}>Save Card</b>
      <img className={styles.iconsadd} alt="" src="/iconsadd2.svg" />
    </button>
  </div>
</div>
);
};