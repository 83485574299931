import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PaymentSuccessful.module.css";

const PaymentSuccessful = ({onClose}) => {
  return (
    <div className={styles.paymentsuccessful}>
      <div className={styles.modalTitle}>
        <div className={styles.jobDetails}>Job Details</div>
        <img className={styles.iconsclose} alt="" src="/iconsclose.svg" />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.illustrationsParent}>
          <img
            className={styles.illustrationsIcon}
            alt=""
            src="./illustrations.svg"
          />
          <div className={styles.paymentSuccessfulParent}>
            <div className={styles.paymentSuccessful}>Payment successful</div>
            <div className={styles.yourPaymentHas}>
              Your payment has been received
            </div>
          </div>
        </div>
        <button className={styles.largeButtons} onClick={onClose}>
          <img
            className={styles.icons20pxplaceholder}
            alt=""
            src="/icons20pxplaceholder2.svg"
          />
          <b className={styles.label}>Close</b>
          <img
            className={styles.icons20pxplaceholder}
            alt=""
            src="/icons20pxplaceholder2.svg"
          />
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
