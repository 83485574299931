import { useState, useCallback, useEffect } from "react";
import WorkModal from "../components/WorkModal";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./WorkProof.module.css";
import { auth, db } from "../firebase";
import { Fab } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
const WorkProof = () => {
  const [isWorkModalPopupOpen, setWorkModalPopupOpen] = useState(false);
  const navigate = useNavigate();

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const openWorkModalPopup = useCallback(() => {
    setWorkModalPopupOpen(true);
  }, []);

  const closeWorkModalPopup = useCallback(() => {
    setWorkModalPopupOpen(false);
  }, []);
  const [uploadedData, setUploadedData] = useState([]);
  useEffect(() => {
    const verificationCollection = db.collection("users").doc(auth.currentUser.uid).collection("work");

    // Fetch the uploaded data from Firestore
    const unsubscribe = verificationCollection.onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      // Update the component's state with the retrieved data
      setUploadedData(data);
    });

    // Return a cleanup function to unsubscribe from the listener when the component unmounts
    return () => unsubscribe();
  }, []); 

  return (
    <>
      <div className={styles.workproof}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665221.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome3.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account2.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back}  onClick={()=>navigate(-1)}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.notifications}>Work Experience</div>
          </div>
          <div className={styles.component102}>
            <div className={styles.smallButtons} onClick={openWorkModalPopup}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
                <div className={styles.button}>Add Item</div>
              </div>
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar1.svg" />
            <b className={styles.licenseAndCertification}>Work Experience</b>
            <div className={styles.icon} style={{cursor:'pointer'}}
            onClick={()=>navigate(-1)}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
          </div>
          {
            uploadedData==0?
            <div className={styles.groupParent}>
            <img className={styles.groupIcon} alt="" src="/group.svg" />
            <div className={styles.tapThePlus}>
              Tap the plus icon to upload a license or certificate
            </div>
          </div>
          :
          <div className={styles.verificationRequirementsWrapper}>
            {uploadedData.map((item, index) => (
            <div className={styles.verificationRequirements}>
            <div className={styles.frameParent}>
              <div className={styles.frameGroup}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="./work_done.svg"
                />
                <div className={styles.titleParent}>
                  <div className={styles.title}>
                  {item.schoolName}
                  </div>
                  <div className={styles.subTitle}>{item.degree}</div>
                  <div className={styles.subTitle}>
                  {item.date} to {item.end}
                  </div>
                </div>
              </div>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright1.svg"
              />
            </div>
          </div>
          ))}
          
          </div>
          }
         
          
        </div>
        <Fab size="medium" color="primary" aria-label="add" 
      style={{background:'green', position:'absolute',right:'5%',top:'80%'}}
      className={styles.Fab}
      onClick={()=>{
        openWorkModalPopup()}}
      >
        <AddIcon />
      </Fab>
      </div>
      {isWorkModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeWorkModalPopup}
        >
          <WorkModal onClose={closeWorkModalPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default WorkProof;
