import { useCallback, useEffect, useState } from "react";
import container from "./SignIn.module.css";
import { useNavigate } from "react-router-dom";
import styles from "./PersonalInfo.module.css";
import { Alert, MenuItem, Paper, Snackbar, TextField } from "@mui/material";
import { auth, db } from "../firebase";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
}  from "react-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import posthog from 'posthog-js'

const PersonalInfo = () => {
  const navigate = useNavigate();
  const [ name, setName] = useState('')
  const [ email, setEmail] = useState('')
  const [city, setCity] = useState('')
  const [reg, setReg] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState('')
  const [ dob, setDob] = useState('')
  const [tab,setTab]=useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    checkAuthenticationMethod()
  }, [])
  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const selectedResult = results[0];
      const { formatted_address, address_components, geometry } = selectedResult;

      const city = getAddressComponentValue(address_components, 'locality');
      const state = getAddressComponentValue(address_components, 'administrative_area_level_1');
      const country = getAddressComponentValue(address_components, 'country');
      const zip = getAddressComponentValue(address_components, 'postal_code');

      setCity(city);
      setReg(state);
      setCountry(country);
      setZip(zip);
      console.log(city)
    } catch (error) {
      console.error(error);
    }
  };
  const checkAuthenticationMethod = () => {
    if (auth.currentUser) {
      const providerData = auth.currentUser.providerData;
      providerData.forEach((userInfo) => {
        if (userInfo.providerId === 'phone'|| userInfo.providerId === 'phone.com') {
          setTab(0)
        } else if (userInfo.providerId === 'password') {
          setTab(1)
        }else{
          setTab(1)
        }
      });
    }
  };
  const proceed = () => {
    // Check if any of the input fields are empty
    if (name.trim() === '' || email.trim() === '' || dob === ''||address==='') {
      setAlertMessage('Please fill in all the required fields.');
      setAlertOpen(true);
      return;
    }
    const age = calculateAge(dob);

    // Check if the user is at least 18 years old
    if (age < 18) {
      // Display an error message or prevent further action
      setAlertMessage('You must be at least 18 years old to continue.');
      setDob(''); // Clear the date of birth field
      setAlertOpen(true);
      return;
    }
    if (tab === 0) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.match(emailRegex)) {
      setAlertMessage("Invalid email format.");
      setAlertOpen(true);
      return;
    }
    }
    if (tab === 0) {
      posthog.capture('Signup By phone', { phone:true,email:false})
      db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
         posthog.capture('Signup Started', {name: name,
        email: email,
        phone:snap.data().phone,
        dob: dob})
      })
     
    }else{
      posthog.capture('Signup By email', { phone:true,email:false})
      db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
        posthog.capture('Signup Started', {  name: name,
        phone: email,
        email: snap.data().email,
        dob: dob})
      }) 
    }
    if (tab === 0) {
     
      let data = {
        name: name,
        email: email,
        dob: dob,
        online:true,
        address,
        city,
        zip,
        reg,country,creationDate: new Date().toISOString()
      };
      db.collection('users')
        .doc(auth.currentUser.uid)
        .update(data)
        .then(() => {
          navigate('/compinfo');
        })
        .catch((error) => {
          console.error('Error updating user data:', error);
        });
    } else {
      if (email === "" || email.length < 10) {
        alert('input a valid phone number')
        return
      };
      let data = {
        name: name,
        phone: email,
        dob: dob,
        address,
        city,
        zip,
        reg,
        online:true,
        country,
        creationDate: new Date().toISOString()
      };
      db.collection('users')
        .doc(auth.currentUser.uid)
        .set(data)
        .then(() => {
          navigate('/compinfo');
        })
        .catch((error) => {
          console.error('Error updating user data:', error);
        });
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const age = calculateAge(selectedDate);
setDob(selectedDate);
  };

  return (
    <div className={styles.personalinfo}>
      <div className={container.img}>
        <img className={container.logoIcon} alt="" src="/logo.svg" />
        <div className={container.testimonial}>
          <div className={container.greatSessionDaniWasSuperParent}>
            <div className={container.greatSessionDani}>
              "Great session! Dani was super helpful. She shared some practical
              advice on how can we go about refining our service offerings."
            </div>
            <div className={container.frameParent}>
              <div className={container.ellipseParent}>
                <img
                  className={container.frameChild}
                  alt=""
                  src="/ellipse-249@2x.png"
                />
                <div className={container.cameronWilliamson}>
                  Cameron Williamson
                </div>
              </div>
              <div className={container.starRating}>
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={container.icons16pxstar}
                  alt=""
                  src="/icons16pxstar11.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.personalinfoInner}>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.personalInformationParent}>
              <b className={styles.personalInformation}>Personal Information</b>
              <div className={styles.tellUsAbout}>Tell us about yourself</div>
            </div>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progressBarChild} />
            <div className={styles.progressBarItem} />
            <div className={styles.progressBarItem} />
          </div>
          <div className={styles.frameGroup}>
          <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>
            <div className={styles.largeInputsParent}>
              <div className={styles.largeInputs}>
                <div className={styles.label}>Fullname</div>
                <div style={{width:'100%'}}>
                  <TextField
                    fullWidth
                    onChange={(e)=>setName(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.largeInputs}>
                {
                  tab==0?
                  <>
                  <div className={styles.label}>Email address</div>
                    <div style={{width:'100%'}}>
                        <TextField
                          fullWidth
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </div>
                  </>
                  :
                  <>
                   <div className={styles.label}>Phone Number</div>
                    <div style={{width:'100%'}}>
                    <PhoneInput
            country={'gb'}
            onChange={e => setEmail(e)}
            inputStyle={{
              width:'100%',
              height:'3rem'
            }}
          />   
                      </div>
                  </>
                }
               
              </div>
              <div className={styles.largeInputs}>
                <div className={styles.label}>Address (Make sure Postcode is included)</div>
                <div style={{width:'100%'}}>
                <PlacesAutocomplete className={styles.baseField} value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{width:'100%'}}>
          <TextField
            {...getInputProps({
              placeholder: 'Postcode',
              className: 'form-control',
            })}
            
            fullWidth
          />
          <Paper className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <MenuItem key={index} {...getSuggestionItemProps(suggestion, { className })}>
                  {suggestion.description}
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
                </div>
              </div>
              <div className={styles.largeInputs2} style={{width:'100%'}}>
                <div className={styles.label2}>Date of Birth</div>
                <div style={{width:'100%'}}>
                   <input
                type='date'
                className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                value={dob}
                onChange={handleDateChange}
                style={{height:'3.5rem'}}
              />
                </div>
                
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.byClickingContinueYouAgWrapper}>
                <div className={styles.byClickingContinueContainer}>
                  <span>{`By proceeding, you agree to our `}</span>
                  <a
                    className={styles.terms}
                    href="https://www.loggworks.co.uk/careers"
                    target="_blank"
                  >
                    <span className={styles.terms1}>Terms</span>
                  </a>
                  <span>{` and acknowledge our `}</span>
                  <a
                    className={styles.terms}
                    href="https://www.loggworks.co.uk/privacy"
                    target="_blank"
                  >
                    <span className={styles.terms1}>Privacy policy</span>
                  </a>
                </div>
              </div>
            </div>
            <button
              className={styles.largeButtons}
              onClick={proceed}
            >
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder61.svg"
              />
              <b className={styles.label3}>Enter Company Information</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder61.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
