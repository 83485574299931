import styles from "./QoutePrev.module.css";
import { Avatar, Box, Modal } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import app, { db } from "../firebase";
import html2canvas from 'html2canvas'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
};
const QoutePrev = ({send_mod, tools, setTab,date,
qkey,due,onClosesend}) => {
  const [user, setUser] = useState({})
  const percTools = tools.filter((item) => item.disc_type === 'perc');
  const flatTools = tools.filter((item) => item.disc_type != 'perc');
useEffect(() => {
    app.auth().onAuthStateChanged(user => {
      if(user)
      {
        db.collection('users').doc(user.uid).get().then(function(snapshot) {
            if(snapshot.data())
            {
              setUser(snapshot.data())
            }
          });
      }
    })
  }, [])
  const onSendWhats=()=>{
  try{
      html2canvas(document.querySelector(`#one`), {scrollY: -window.scrollY, scale: '2'}).then(canvas => {
          let dataURL = canvas.toDataURL('image/png');

window.open("whatsapp://send?text=View my qoute: "+dataURL)
             });
      
  }
  catch {
  }
  }
   const onSendEmail=()=>{
 try{
      html2canvas(document.querySelector(`#one`), {scrollY: -window.scrollY, scale: '2'}).then(canvas => {
          let dataURL = canvas.toDataURL('image/png');
window.open("mailto:max.mustermann@example.com?body= View my quote: "+ dataURL)
             });
      
  }
  catch {
  }
  }
   const onSendCopy=()=>{
 try{
      html2canvas(document.querySelector(`#one`), {scrollY: -window.scrollY, scale: '2'}).then(canvas => {
          let dataURL = canvas.toDataURL('image/png');
 if ('clipboard' in navigator) {
       navigator.clipboard.writeText(dataURL);
       alert('successful!!')
    } else {
    }
             });
      
  }
  catch {
  }
  }
  return (
    <div className={styles.qouteprev}>
       <div className="invoice" style={{width:'100%'}}>
                <div className="header flex items-center justify-between my-7">
                  <div className="flex items-start">
                  <Avatar
        alt=""
        src={user.profileImage}
      />
                    <div className="">
                      <h1 className="text-[14px] font-bold">
                      {user.org_name}
                      </h1>
                      <div className="text-[10px]">
                        <p>{user.addy1}</p>
                        <p>{user.phone}</p>
                        <p>{user.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="text-[10px] text-right">
                      <p className="font-bold">Date: {date}</p>
                      <p>Invoice No</p>
                      <p className="font-bold">{qkey}</p>
                      <p>Due Date</p>
                      <p className="font-bold">{due}</p>
                    </div>
                  </div>
                </div>

                <div className="invoiceData">
                  <table className="w-full text-[10px]">
                    <thead>
                      <tr className="border-y-2 text-right">
                        <th className="text-left  py-2 ">DESCRIPTION</th>
                        <th className=" py-2">RATE</th>
                        <th className=" py-2">QTY</th>
                        <th className=" py-2">DISCOUNT</th>
                        <th className=" py-2">TAX</th>
                        <th className=" py-2">AMOUNT</th>
                      </tr>
                    </thead>

                    <tbody className="text-right">
                    {tools.map((data,index) => (
                       <tr className="border-b "  key={index}>
                        <td className="text-left py-4">
                          {data.desc}  
                        <br/>
                    <span>{data.detail}</span></td>
                        <td>£{Number(data.unit).toFixed(2)}</td>
                        <td>{data.quant}</td>
                        <td className="">
                          <span>-£{
                  data.disc_type=='perc'?Number((data.quant*data.unit)*data.disc/100).toFixed(2): Number(data.disc).toFixed(2)}</span> <br />
                          <span>{Number(data.disc).toFixed(2)}{data.disc_type=='perc'?'%': ''}</span>
                        </td>

                        <td className="">
                          <span>£{Number((data.quant*data.unit)*data.tax/100).toFixed(2)}</span> <br />
                          <span>{Number(data.tax).toFixed(2)}%</span>
                        </td>
                        <td>£{Number(data.total).toFixed(2)}</td>
                      </tr>
                    ))}
                     
                     
                      <tr className="text-light">
                        <td className="text-left py-3 mt-10"></td>
                        <td className="text-left font-bold relative left-[65px]">
                          SUBTOTAL
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">£{tools.reduce((a,v ) =>  a = Number(a) + Number(v.total),0 ).toFixed(2)}</td>
                      </tr>
                      <tr className="text-light ">
                        <td className="text-left py-3s"></td>
                        <td className="font-bold text-left relative left-[65px]">
                          DISCOUNT
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold"> £
                        {Number(
                        ((percTools.reduce((a ,v ) =>  a = Number(a) + Number(v.quant),0 )*percTools.reduce((a ,v ) =>  a = Number(a) + Number(v.unit),0 ))*percTools.reduce((a ,v ) =>  a = Number(a) + Number(v.disc) ,0)/100)
                        +
                        (flatTools.reduce((a ,v ) =>  a = Number(a) + Number(v.disc) ,0))).toFixed(2)
                        }</td>
                      </tr>
                      <tr className="text-light ">
                        <td className="text-left py-3"></td>
                        <td className="text-left font-bold relative left-[65px]">
                          TAX
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">£{Number((tools.reduce((a ,v ) =>  a = Number(a) + Number(v.quant),0 )*tools.reduce((a ,v ) =>  a = Number(a) + Number(v.unit),0 ))*tools.reduce((a ,v ) =>  a = Number(a) + Number(v.tax) ,0)/100).toFixed(2)}</td>
                      </tr>

                      <tr className="">
                        <td className="text-left"></td>
                        <td className="text-left relative left-[65px] text-green font-bold">
                          TOTAL
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-green font-bold">£{tools.reduce((a ,v ) =>  a = Number(a) + Number(v.total),0 ).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                 <br></br>
              </div>
    </div>
  );
};

export default QoutePrev;
