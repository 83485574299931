import { useCallback } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import styles from "./InstagramModal.module.css";

const InstagramModal = ({onClose}) => {
  const onIconsCloseClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onLargeButtonsContainerClick = useCallback(() => {
    onClose()
  }, []);

  return (
    <div className={styles.instagrammodal}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.connectInstagramAccountParent}>
            <div className={styles.connectInstagramAccount}>
              Connect Instagram Account
            </div>
            <img
              className={styles.iconsclose}
              alt=""
              src="/iconsclose1.svg"
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.frameWrapper}>
              <div className={styles.input}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Instagram Handle</div>
                </div>
                <TextField
                  className={styles.baseField}
                  color="primary"
                  fullWidth={true}
                  variant="outlined"
                />
                <div className={styles.helperTextContainer}>
                  <div className={styles.thisIsA}>This is a helper text</div>
                  <div className={styles.div}>1/100</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.largeButtons}
            onClick={onLargeButtonsContainerClick}
          >
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder11.svg"
            />
            <b className={styles.label1}>Connect Instagram</b>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder11.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstagramModal;
