import { useCallback, useEffect, useState } from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import styles from "./BookingFee.module.css";
const BookingFeeMic = ({   tool_desc,
  unit_cost,
  quant,
  rate,
  tool_disc_t,
  tool_disc,
  tool_tax_t,
  tool_tax,
  tool_detail,
  onTool_desc,
  onUnit_cost,
  onQuant,
  onTool_disc_t,
  onTool_disc,
  onTool_tax_t,
  onTool_tax,
  onTool_detail,
  onServices,
  onRate,
    onOitem,
    tools, onClose }) => {
      const [total, setTotal]=useState(0)

      useEffect(() => {
          let tot = unit_cost*quant
          setTotal(tot);
        if (tool_disc_t=='perc') {
            if(tool_disc!=0)
            {
             let perc= tool_disc/100
             let disc= tot*perc
             tot = tot-disc
            setTotal(tot);
            }
          }
          else{
             if(tool_disc!=0)
            {
              tot= tot-tool_disc
            setTotal(tot);
            }
          }
          if(tool_tax_t=='exclusive')
          {
             if(tool_tax!=0)
            {
              let perc = tool_tax/100
              let tax= perc*tot
              tot=tot+tax
            setTotal(tot+tax);
            }
            
          }
      
      }, [onUnit_cost,onTool_tax])
      
      const onTotal=()=>{
        let tot = unit_cost*quant
        setTotal(tot);
      if (tool_disc_t=='perc') {
          if(tool_disc!=0)
          {
           let perc= tool_disc/100
           let disc= tot*perc
           tot = tot-disc
          setTotal(tot);
          }
        }
        else{
           if(tool_disc!=0)
          {
            tot= tot-tool_disc
          setTotal(tot);
          }
        }
        if(tool_tax_t=='exclusive')
        {
           if(tool_tax!=0)
          {
            let perc = tool_tax/100
            let tax= perc*tot
            tot=tot+tax
          setTotal(tot+tax);
          }
          
        }
         onServices(tot);
      }

  return (
    <div className={styles.bookingfee}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>Miscellaneous</div>
            <img className={styles.iconsclose} alt="" src="/iconsclose.svg" style={{cursor:'pointer'}}
             onClick={onClose}/>
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.inputParent}>
              <div className={styles.input}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Description</div>
                </div>
                <div style={{width:'100%'}}>
                 <TextField 
                 style={{width:'100%'}}
                 value={tool_desc}
                 onChange={(e)=>{
                  onTool_desc(e.target.value);
                  }}
                 />
                </div>
              </div>
              <div className={styles.inputGroup} style={{width:'100%'}}>
                <div style={{width:'100%'}}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Cost(£)</div>
                  </div>
                  <div style={{width:'100%'}}>
                 <TextField 
                 style={{width:'100%'}}
                 value={unit_cost}
                 type='number'
                 onChange={(e)=>{
                  onQuant(1)
                  onUnit_cost(e.target.value);
                  }}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
                 />
                  </div>
                </div>
               
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.input1}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Tax</div>
                  </div>
                  <div style={{width:'100%'}}>
                 <FormControl fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
     value={tool_tax_t}
    onChange={(e)=>{onTool_tax_t(e.target.value);  }}
  >
    <MenuItem value={'none'}>None</MenuItem>
    <MenuItem value={'inclusive'}>Inclusive</MenuItem>
    <MenuItem value={'exclusive'}>Exclusive</MenuItem>
  </Select>
</FormControl>
                </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Tax rate(%)</div>
                  </div>
                  <div style={{width:'100%'}}>
                 <TextField 
                 style={{width:'100%'}}
                 value={tool_tax}
                 type='number'
                 onChange={(e)=>{
                  onTool_tax(e.target.value);
                  }}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
                 />
                </div>
                </div>
              </div>
              <div className={styles.quoteCards}>
                <div className={styles.quoteNumber}>Total:</div>
                <div className={styles.qn0001}>£{total.toFixed(2)}</div>
                <div className={styles.transactionPills}>
                  <div className={styles.success}>DRAFTS</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.largeButtons}
            onClick={()=>{
              onTotal();
              onClose()
              }}
          >
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder31.svg"
            />
            <b className={styles.label7}>Save Micellaneous Fee</b>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder31.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingFeeMic;
