import { useCallback, useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
} from "@mui/material";
import styles from "./AddTool.module.css";
const AddTool = ({ 
  tool_desc,
unit_cost,
quant,
tool_disc_t,
tool_disc,
tool_tax_t,
tool_tax,
tool_detail,
tools,
onTool_desc,
onUnit_cost,
onQuant,
onTool_disc_t,
onTool_disc,
onTool_tax_t,
onTool_tax,
onTool_detail,
onTools,
onClose
 }) => {
  const [total, setTotal]=useState(0)
  useEffect(() => {
    let tot = unit_cost*quant
    setTotal(tot);
  if (tool_disc_t=='perc') {
      if(tool_disc!=0)
      {
       let perc= tool_disc/100
       let disc= tot*perc
       tot = tot-disc
      setTotal(tot);
      }
    }
    else{
       if(tool_disc!=0)
      {
        tot= tot-tool_disc
      setTotal(tot);
      }
    }
    if(tool_tax_t=='exclusive')
    {
       if(tool_tax!=0)
      {
        let perc = tool_tax/100
        let tax= perc*tot
        tot = tot+tax
      setTotal(tot+tax);
      }
      
    }
}, [onUnit_cost,onTool_tax,onTool_disc])
  const onTotal=()=>{
    let tot = unit_cost*quant
    setTotal(tot);
  if (tool_disc_t=='perc') {
      if(tool_disc!=0)
      {
       let perc= tool_disc/100
       let disc= tot*perc
       tot = tot-disc
      setTotal(tot);
      }
    }
    else{
       if(tool_disc!=0)
      {
        tot= tot-tool_disc
      setTotal(tot);
      }
    }
    if(tool_tax_t=='exclusive')
    {
       if(tool_tax!=0)
      {
        let perc = tool_tax/100
        let tax= perc*tot
        tot=tot+tax
      setTotal(tot+tax);
      }
      
    }
     onTools(tot);
     onClose()
  }

  return (
    <div className={styles.addtool}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <div className={styles.addANewCardParent}>
            <div className={styles.addANew}>Add tool/material</div>
            <img
              className={styles.iconsclose}
              alt=""
              src="/iconsclose.svg"
              style={{cursor:'pointer'}}
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.inputParent}>
              <div className={styles.input}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Description</div>
                </div>
                <div style={{width:'100%'}}>
                 <TextField 
                 style={{width:'100%'}}
                 value={tool_desc}
                 onChange={(e)=>{
                  onTool_desc(e.target.value);
                  }}
                 />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.input1}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Unit cost(£)</div>
                  </div>
                  <TextField 
                 style={{width:'100%'}}
                 type='number'
                 value={unit_cost}
                 onChange={(e)=>{
                  onUnit_cost(e.target.value);
                   
                  }}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
                 />
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Quantity</div>
                  </div>
                  <TextField 
                 style={{width:'100%'}}
                 type='number'
                 value={quant}
                 onChange={(e)=>{
                  onQuant(e.target.value);
                   
                  }}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
                 />
                  <div className={styles.helperTextContainer}>
                    <div className={styles.thisIsA}>This is a helper text</div>
                    <div className={styles.div}>1/100</div>
                  </div>
                </div>
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.input1}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Discount</div>
                  </div>
                  <div style={{width:'100%'}}>
                    <FormControl fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={tool_disc_t}
    onChange={(e)=>{onTool_disc_t(e.target.value);  }}
  >
    <MenuItem value={'perc'}>percentage(%)</MenuItem>
    <MenuItem value={'flat'}>flat</MenuItem>
  </Select>
</FormControl>
                  </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Discount percentage</div>
                  </div>
                  <TextField 
                 style={{width:'100%'}}
                 type='number'
                  value={tool_disc}
    onChange={(e)=>{
      onTool_disc(e.target.value);
                   
                  }}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
                 />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.input1}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Tax</div>
                  </div>
                  <div style={{width:'100%'}}>
                   <FormControl fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
     value={tool_tax_t}
    onChange={(e)=>{onTool_tax_t(e.target.value);  }}
  >
    <MenuItem value={'none'}>None</MenuItem>
    <MenuItem value={'inclusive'}>Inclusive</MenuItem>
    <MenuItem value={'exclusive'}>Exclusive</MenuItem>
  </Select>
</FormControl>
                 </div>
                </div>
                <div className={styles.input2}>
                  <div className={styles.labelContainer}>
                    <div className={styles.label}>Tax rate(%)</div>
                  </div>
                  <div style={{width:'100%'}}>
                     <TextField 
                 style={{width:'100%'}}
                 type='number'
                  value={tool_tax}
    onChange={(e)=>{
      onTool_tax(e.target.value);
                   
                  }}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
                 />
                  </div>
                </div>
              </div>
              <div className={styles.input7}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>Additional details</div>
                </div>
                <div  style={{width:'100%'}}>
                  <TextField 
                  multiline
                  rows={3}
                 style={{width:'100%'}}
                  value={tool_detail}
    onChange={(e)=>{
      onTool_detail(e.target.value);
                   
                  }}
                 />
                </div>
              </div>
              <div className={styles.quoteCards}>
                <div className={styles.quoteNumber}>Total:</div>
                <div className={styles.qn0001}>£{total.toFixed(2)}</div>
                <div className={styles.transactionPills}>
                  <div className={styles.success}>DRAFTS</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.largeButtons}
             onClick={()=>{
              onTotal();
              }}>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder111.svg"
            />
            <b className={styles.label8}>Save Tool/Material</b>
            <img
              className={styles.icons20pxplaceholder}
              alt=""
              src="/icons20pxplaceholder111.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTool;
