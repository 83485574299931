import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import Promotion from "./pages/Promotion";
import NavPrototype from "./pages/Profile";
import Wallet from "./pages/Wallet";
import Activity from "./pages/Activity";
import Jobs1 from "./pages/Jobs1";
import Chat from "./pages/Chat";
import Message from "./pages/Message";
import OfferPromo from "./pages/OfferPromo";
import ProfilePromo from "./pages/ProfilePromo";
import CreateQuote from "./pages/CreateQuote";
import HomeprofList from "./pages/HomeprofList";
import Home from "./pages/Home";
import JobView from "./components/JobView";
import GroupComponent from "./pages/GroupComponent";
import ResetPassword from "./pages/ResetPassword";
import GladHere from "./pages/GladHere";
import JobCat from "./pages/JobCat";
import CompInfo from "./pages/CompInfo";
import PersonalInfo from "./pages/PersonalInfo";
import Verify from "./pages/Verify";
import SignUp1 from "./pages/SignUp";
import QoutePrev from "./pages/QoutePrev";
import Tools from "./pages/Tools";
import Items from "./pages/Items";
import MyQuote from "./pages/MyQuote";
import ConfirmReset from "./pages/ConfirmReset";
import { useEffect, useState } from "react";
import app, { auth, db } from "./firebase";
import Settings from "./pages/Settings";
import Plans from "./components/Plans";
import CurandPastPromo from "./pages/CurandPastPromo";
import EditProfile from "./pages/EditProfile";
import Security from "./pages/Security";
import NotificationSettings from "./pages/NotificationSettings";
import PandP from "./pages/PandP";
import MyPersonalInfo from "./pages/MyPersonalInfo";
import Help from "./pages/Help";
import FAQ from "./pages/FAQ";
import PastPromo from "./pages/PastPromo";
import WorkProof from "./pages/WorkProof";
import EducationProof from "./pages/EducationProof";
import LicenseProof from "./pages/LicenseProof";
import Idproof from "./pages/Idproof";
import PaymentMethod from "./pages/PaymentMethod";
import PayoutPref from "./pages/PayoutPref";
import Add from "./pages/Add";
import Reference from "./pages/Reference";
import MyNetwork from "./pages/MyNetwork";
import './tailcss.css'
import Reviews from "./pages/Reviews";
import ConfirmEmail from "./pages/ConfirmEmail";
import EmailVery from "./pages/EmailVery";
import ResetPasswordPhone from "./pages/ResetPasswordPhone";
import PasswordChangedPh from "./pages/reset/password-change-phone.tsx";
import ResetFormChangePh from "./pages/reset/formchange-phone.tsx";
import PasswordChanged from "./pages/reset/password-change.tsx";
import ResetFormChange from "./pages/reset/formchange.tsx";
import OneSignal from 'react-onesignal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ViewProf from "./pages/ViewProf";
import posthog from 'posthog-js'
import Verify_email from "./pages/Verify_email.js";
import axios from "axios";

const stripePromise = loadStripe('pk_live_51OKRh6Dk2CQnAQVzdZ1sazYAyHpXmWJPXZwnZ0AYNdiXUO2nKH8OdQy3UjK74s4Yu6ln2qXbBMQVyNaBtKjAziGP00RTMN49iC');



function App() {
  const action = useNavigationType();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const location = useLocation(); // Use useLocation hook
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname.includes('message')||pathname.includes('chat')) {
      db.collection('users').doc(auth.currentUser?.uid).collection('noti_m').get().then((querySnapshot) => {
       if(!querySnapshot.empty){
         querySnapshot.forEach((doc) => {
           doc.ref.delete();
         });
        }
     })
     }
     if (pathname.includes('activity')) {
       db.collection('users').doc(auth.currentUser?.uid).collection('noti_act').get().then((querySnapshot) => {
         if(!querySnapshot.empty){
           querySnapshot.forEach((doc) => {
             doc.ref.delete();
           });
          }
       })
      }
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [location.pathname])
  useEffect(() => {
    // Hide the widget and prevent it from loading when leaving the specific page
    return () => {
        const tawkScript = document.querySelector("script[src*='tawk.to']");
        if (tawkScript) {
            // Hide the widget
            Tawk_API.hideWidget();
            // Remove the tawk.to script from the document
            document.head.removeChild(tawkScript);
        }
    };
}, [location.pathname]);
  //add subs to mobile profile
  //link help page

useEffect(() => {
  
  if (pathname.includes('email-verified')) {
    setLoading(false)
    return;
  }
  if (pathname.includes('confirm-email')) {
    setLoading(false)
    return;
  }
  if (pathname.includes('confirm-password')) {
    setLoading(false)
    return;
  }
  if (pathname.includes('password-change')) {
    setLoading(false)
    return;
  }
  if (pathname.includes('reset')) {
    setLoading(false)
    return;
  }
  if (pathname.includes('sign-up')) {
    setLoading(false)
    return;
  }
  if (pathname.includes('logg_user')) {
    setLoading(false)
    return;
  }

    (async () => {
      app.auth().onAuthStateChanged(user => {
        if (user) {
          db.collection('users').doc(user.uid).get().then(snapshot => {
            if (snapshot.exists) {
              posthog.identify(snapshot.data()?.email)
              db.collection('otp').doc(snapshot.data()?.email).get().then(function (snap) {
                if(snap.exists){
                  if(snap.data()?.otp!='verified'){
                    navigate("/confirm-email/" + snapshot.data()?.email)
                  }
                }
              })
              if(!snapshot.data()?.name){
                setLoading(false);
                navigate('/personalinfo');// Navigate to personalinfo if user data doesn't exist
              }
              if(!snapshot.data()?.email){
                db.collection('users').doc(user.uid).update({
                  email: user.email
                })
              }
               new Promise(resolve => setTimeout(resolve, 3000));
              setLoading(false);
            } else {
              setLoading(false);
              navigate('/personalinfo');// Navigate to personalinfo if user data doesn't exist
            }
          });
        } else {
          setLoading(false);
          navigate('/'); // Navigate to sign-up if user is not authenticated
        }
      });
    })();
  }, [pathname]);

  const notification=async()=>
  {
    try {
      const collectionRef = db.collection('users').doc(auth.currentUser.uid).collection('noti_m');
      const collectionRef2 = db.collection('users').doc(auth.currentUser.uid).collection('noti_act');
      const updatedList = [];
      const actList = [];
    
      // Subscribe to real-time updates for noti_m collection
      collectionRef.onSnapshot(async (snapshot) => {
        // Handle the snapshot changes
        snapshot.forEach((doc) => {
          const docData = doc.data();
          updatedList.push(docData);
        });
    
        // Send a notification using OneSignal
        try {
          const playerID = await OneSignal.getUserId();
          const response = await fetch('https://loggworks-c15c9e56f169.herokuapp.com/notify', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'userid': 'YOUR_USER_ID', // Replace with your actual user ID
            },
            body: JSON.stringify({ playerID }),
          });
    
          const data = await response.json();
          console.log('Notification sent successfully:', data);
        } catch (notificationError) {
          console.error('Error sending notification:', notificationError);
        }
      });
    
      // Subscribe to real-time updates for noti_act collection
      collectionRef2.onSnapshot((snapshot) => {
        // Handle the snapshot changes
        snapshot.forEach((doc) => {
          const docData = doc.data();
          actList.push(docData);
        });
      });
    } catch (error) {
      console.error('Error:', error);
    }
    
  }
  
  return (
    <Elements stripe={stripePromise}>
    {
      !loading?
      <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/promotion" element={<Promotion />} />
      <Route path="/profile" element={<NavPrototype />} />
      <Route path="/logg_user/:uid" element={<ViewProf />} />
      <Route path="/edit_profile" element={<EditProfile />} />
      <Route path="/security" element={<Security />} />
      <Route path="/notificationsetting" element={<NotificationSettings />} />
      <Route path="/payment_payout" element={<PandP />} />
      <Route path="/paymentmethod" element={<PaymentMethod />} />
      <Route path="/payoutpref" element={<PayoutPref />} />
      <Route path="/mypersonal" element={<MyPersonalInfo />} />
      <Route path="/work_proof" element={<WorkProof />} />
      <Route path="/reference_proof" element={<Reference />} />
      <Route path="/network_proof" element={<MyNetwork />} />
      <Route path="/education_proof" element={<EducationProof />} />
      <Route path="/license_proof" element={<LicenseProof />} />
      <Route path="/id_proof" element={<Idproof />} />
      <Route path="/help" element={<Help />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/my_past_promo" element={<PastPromo />} />
      <Route path="/activities" element={<Activity />} />
      <Route path="/jobs" element={<Jobs1 />} />
      <Route path="/chat/:id" element={<Chat />} />
      <Route path="/messages" element={<Message />} />
      <Route path="/offerpromo" element={<OfferPromo />} />
      <Route path="/profilepromo" element={<ProfilePromo />} />
      <Route path="/settings" element={<Settings/>} />
      <Route path="/createquote/:id" element={<CreateQuote />} />
      <Route path="/my_current_promo" element={<CurandPastPromo />} />
      <Route path="/homeproflist" element={<HomeprofList />} />
      <Route path="/home" element={<Home />} />
      <Route path="/jobview/:id" element={<JobView />} />
      <Route path="/group-66949" element={<GroupComponent />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/resetpassword-phone" element={<ResetPasswordPhone />} />
      <Route path="/confirm-password-phone" element={<ResetFormChangePh />} />
      <Route path="/confirm-password/:id" element={<ResetFormChange />} />
      <Route path="/password-change-phone" element={<PasswordChangedPh />} />
      <Route path="/password-change" element={<PasswordChanged />} />
      <Route path="/gladhere" element={<GladHere />} />
      <Route path="/job-category" element={<JobCat />} />
      <Route path="/compinfo" element={<CompInfo />} />
      <Route path="/personalinfo" element={<PersonalInfo />} />
      <Route path="/otp" element={<Verify />} />
      <Route path="/sign-up" element={<SignUp1 />} />
      <Route path="/confirm-email/:email" element={<Verify_email />} />
      <Route path="/email-verified/" element={<EmailVery />} />
      <Route path="/qouteprev" element={<QoutePrev />} />
      <Route path="/tools" element={<Tools />} />
      <Route path="/items" element={<Items />} />
      <Route path="/myquote" element={<MyQuote />} />
      <Route path="/confirmreset" element={<ConfirmReset />} />
      <Route path="/add" element={<Add />} />
      <Route path="/reviews" element={<Reviews />} />
    </Routes>
    :
    null
    }
      
    </Elements>
   
  );
}
export default App;
