import { useCallback, useEffect, useState } from "react";
import styles from "./JobView.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import moment from "moment/moment";
import { serverTimestamp } from "firebase/firestore";
import { Avatar } from "@mui/material";
import PortalPopup from "./PortalPopup";
import Jobdetailscomp from "./Jobdetailscomp";

const ProfileImage=({id})=>{
  const [profile,setProfile]=useState('')
  useEffect(() => {
    db.collection('jobs/').doc(id).get().then(snapshot=> {
      db.collection('customers').doc(snapshot.data().uid).get().then(function (snap) {
       setProfile(snap.data()?.profileImage)
      })
     })
  }, [])
  
  return(
    <Avatar
    src={profile||""}
    />
  )
}
const JobView = () => {
  const navigate = useNavigate()
     const [name, setName] = useState('');
    const [ago, setAgo] = useState('');
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');
    const [image4, setImage4] = useState('');
    const [type, setType] = useState('');
    const [desc, setDesc] = useState('');
    const [to, setTo] = useState('');
    const [from, setFrom] = useState('');
    const [addy, setAddy] = useState('');
    const [uid, setUid] = useState('');
    const [tab, setTab] = useState(0);
    const [total, setTot] = useState(1)
    const [view, setView] = useState(false)
    const [list,setList] = useState([])
    let {id} = useParams()
   useEffect(() => {
    if(auth.currentUser)
    {
        db.collection('users').doc(auth.currentUser.uid).get().then(function(snapshot) {
            if(snapshot.data())
            {
              const cate = snapshot.data()?.category
              let city = snapshot.data().city
            
             
              db.collection('jobs/').doc(id).get().then(snapshot=> {
                setName(snapshot.data().name)
                 setDesc(snapshot.data().desc)
                setTo(snapshot.data().to)
                setFrom(snapshot.data().from)
                setAddy(snapshot.data().addy1)
                setUid(snapshot.data().uid)
                setImage1(snapshot.data().image1)
                setImage2(snapshot.data().image2)
                setImage3(snapshot.data().image3)
                setType(snapshot.data().cate)
                setImage4(snapshot.data().image4)
                setAgo(moment(snapshot.data().unix).fromNow())
                if(snapshot.data().image1)
                {
                  setTot(0)
                }
                if(snapshot.data().image2)
                {
                  setTot(1)
                }
                if(snapshot.data().image3)
                {
                  setTot(2)
                }
                if(snapshot.data().image4)
                {
                  setTot(3)
                }
                 
            })
            const lat = snapshot.data().lat
              const lng = snapshot.data().lng
              const distance = snapshot.data().distance
              fetchJob(cate,lat,lng,distance);
            }
          });
    }
      const fetchJob = (cate,lat,lng,distance) => {
       const unsubscribe = 
       db
      .collection('jobs/')
      .where('cate','==',cate.toLowerCase())
      .onSnapshot((snapshot) => {
        const jobsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Filter jobs within the selected distance
        const filteredList = jobsData.filter((job) => {
          const jobLatitude = job.lat;
          const jobLongitude = job.lng;

          const jobDistance = calculateDistance(lat, lng, jobLatitude, jobLongitude);

          return jobDistance <= distance;
        });
        console.log(filteredList)
        setList(filteredList);
      });

    return () => {
      unsubscribe();
    };
    };
  
  }, [id])
   const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(lat1)) *
        Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return milesToKilometers(distance);
  };

  const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const milesToKilometers = (miles) => {
    return miles * 1.60934;
  };

  const onCloseIconClick = useCallback(() => {
    navigate('/home')
  }, []);

  const onFrameIconClick = useCallback(() => {
    // Please sync "Logg Jobs" to the project
  }, []);

  return (
    <div className={styles.jobview}>
      <div className={styles.signup}>
      <div
  className={styles.component27Child}
  style={{
    backgroundImage: `url('${
      tab === 0 ? image1 : tab === 1 ? image2 || image1 : tab === 2 ? image3 || image2 : image4 || image3
    }')`,
  }}
>
<div className={styles.component27}
        onClick={()=>{
          if(tab!=total)
          {
            setTab(tab+1)
          }
          else
          {
            setTab(0)
          }
        }}>
    
      
          
          <div className={styles.component27Item}>
          <div className={styles.frameParent} onClick={()=>setView(true)}>
            <div className={styles.jobDetailParent} >
              <b className={styles.jobDetail}>Job Detail</b>
              <div className={styles.imInNeed}>
                 {desc}
              </div>
            </div>
            <div className={styles.button}
             onClick={()=>{
              //navigate('/log-cash-transactions17/'+id)
                    var date = new Date();
           var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
              const key = db.collection("tmp").doc().id
               db.collection('customers/').doc(uid).get().then(snap=> {
                 db.collection('contacts/'+auth.currentUser.uid+'/my/').where('uid','==',uid).get().then(snapshot=>{
                  if(snapshot.empty)
                  {
                     db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(key).set({
                uid:uid,
                last: '',
                unix: Date.now(),
                timestamp: serverTimestamp(),
                name: snap.data().fname+' '+snap.data().lname,
                key: key,
                desc: desc
              }) 
              
                  }
                  else
                  {
                    snapshot.forEach((prev)=>{
                      db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(prev.data().key).update({
                unix: Date.now(),
                timestamp: serverTimestamp(),
                name: snap.data().fname+' '+snap.data().lname,
                desc: desc
              }) 
                    })
                      
                  }
                 })
                   
                })
                localStorage.setItem('title', type);
                localStorage.setItem('desc', desc);
              navigate('/chat/'+uid)
              }}
            >
              <div className={styles.iconsParent}>
                <img className={styles.icons} alt="" src="/icons41.svg" />
                <b className={styles.jobDetail}>Send Quote</b>
              </div>
            </div>
          </div>
          </div>
          <div className={styles.component27Inner} >
              <div className={styles.rectangleParent}>
            {image1!=''?<div className={tab==0?styles.frameItem:styles.frameInner} />:null}
                      {image2!=''?<div className={tab==1?styles.frameItem:styles.frameInner} />:null}
                     {image3!=''?<div className={tab==2?styles.frameItem:styles.frameInner} />:null}
                     {image4!=''?<div className={tab==3?styles.frameItem:styles.frameInner} />:null}
          </div>
          <div className={styles.name}>
            <div className={styles.ellipseParent}>
               <div className={styles.frameChild}>
                        <ProfileImage id={id}/>
                      </div>
              <div className={styles.frameWrapper}>
                <div className={styles.crosbyOdennehyParent}>
                  <div className={styles.crosbyOdennehy}>{name}</div>
                  <div className={styles.mins}>{ago}</div>
                </div>
              </div>
            </div>
            <img
            className={styles.evacloseOutlineIcon}
            alt=""
            src="/evacloseoutline1.svg"
             onClick={onCloseIconClick}
          />
          </div>
          </div>
        
        </div>
</div>

        
      </div>
      <div className={styles.jobview1}>
        <div className={styles.loggJobsSidebar}>
          <div className={styles.loggJobs}>Logg Jobs</div>
          <div className={styles.loggJobbsProfileParent}>
           {
        list.map(doc => {
            return(
           <div
              className={styles.loggJobbsProfile1}
             style={id==doc.id?{background:'#ccc'}:{}}
             onClick={()=>{navigate('/jobview/'+doc.id)}}
            >
              <div className={styles.avatarParent}>
              <div className={styles.avatarIcon}>
                        <ProfileImage id={id}/>
                      </div>
                <div className={styles.annetteBlackParent}>
                  <div className={styles.annetteBlack}>{doc.name}</div>
                  <div className={styles.m}>{moment(doc.unix).fromNow()}</div>
                </div>
              </div>
            </div>
            )
        })
      }
          </div>
          <img
            className={styles.closeIcon}
            alt=""
            src="/close.svg"
            onClick={onCloseIconClick}
          />
        </div>
        <div className={styles.jobviewInner}>
          <div className={styles.jobStatusParent}>
            {
              tab!=0&&
              <img
              className={styles.frameIcon}
              style={{transform: 'scaleX(-1)'}}
              alt=""
              src="/frame-67764.svg"
              onClick={()=>{
                if (tab!=0) {
                  setTab(tab-1)
                }
                
              }}
            />
            }
          
            <div
              className={styles.jobStatus}
               style={tab==0?{backgroundImage: `url(${image1})`}:
      tab==1?{backgroundImage: `url(${image2||image1})`}
      :tab==2?{backgroundImage: `url(${image3||image2})`}:
      {backgroundImage: `url(${image4||image3})`}}
      onClick={()=>{
        if(tab!=total)
        {
          setTab(tab+1)
        }
        else
        {
          setTab(0)
        }
      }}
            >
              <div className={styles.topSection}>
                <div className={styles.topSectionChild} />
                <div className={styles.topSection1}>
                  <div className={styles.progressBarWrapper}>
                    <div className={styles.progressBar}>
                      {image1!=''?<div className={tab==0?styles.progressBarChild:styles.progressBarItem} />:null}
                      {image2!=''?<div className={tab==1?styles.progressBarChild:styles.progressBarItem} />:null}
                     {image3!=''?<div className={tab==2?styles.progressBarChild:styles.progressBarItem} />:null}
                     {image4!=''?<div className={tab==3?styles.progressBarChild:styles.progressBarItem} />:null}
                    </div>
                  </div>
                  <div className={styles.topSectionInner}>
                    <div className={styles.avatarParent5}>
                      <div className={styles.avatarIcon}>
                        <ProfileImage id={id}/>
                      </div>
                      <div className={styles.annetteBlackParent}>
                        <div className={styles.annetteBlack}>{name}</div>
                        <div className={styles.m8}>{ago}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              tab!=total&&
              <img
              className={styles.frameIcon}
              style={{transform: 'scaleX(-1)'}}
              alt=""
              src="/frame-67764.svg"
              onClick={()=>{
                if(tab!=total)
                {
                  setTab(tab+1)
                }
                else
                {
                  setTab(0)
                }
              }}
            />
            }
            <div className={styles.loggJobsDescription}>
              <div className={styles.frameGroup}>
                <div className={styles.requestForElectricianParent}>
                  <div className={styles.requestForElectrician}>
                    Request for {type}
                  </div>
                  <div className={styles.imInNeed1}>
                    {desc}
                  </div>
                  <div className={styles.frameContainer}>
                    <div className={styles.frameParent1}>
                      <div className={styles.iconsmoneyParent}>
                        <img
                          className={styles.iconsmoney}
                          alt=""
                          src="/iconsmoney.svg"
                        />
                        <div className={styles.citiessummary}>Budjet</div>
                      </div>
                      <div className={styles.imInNeed1}>£{from} - £{to}</div>
                    </div>
                    <div className={styles.frameParent1}>
                      <div className={styles.iconsmoneyParent}>
                        <img
                          className={styles.iconsmoney}
                          alt=""
                          src="/iconslocation.svg"
                        />
                        <div className={styles.citiessummary2}>Location</div>
                      </div>
                      <div className={styles.imInNeed1}>
                        {addy}
                      </div>
                    </div>
                  </div>
                </div>
                <button className={styles.largeButtons}
                onClick={()=>{
                  //navigate('/log-cash-transactions17/'+id)
                        var date = new Date();
               var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
                  const key = db.collection("tmp").doc().id
                  db.collection('customers/').doc(uid).get().then(snap=> {
                    db.collection('contacts/'+auth.currentUser.uid+'/my/').where('uid','==',uid).get().then(snapshot=>{
                     if(snapshot.empty)
                     {
                        db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(key).set({
                   uid:uid,
                   last: '',
                   unix: Date.now(),
                   timestamp: serverTimestamp(),
                   name: snap.data().fname+' '+snap.data().lname,
                   key: key,
                   desc: desc
                 }) 
                 
                     }
                     else
                     {
                       snapshot.forEach((prev)=>{
                         db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(prev.data().key).update({
                   unix: Date.now(),
                   timestamp: serverTimestamp(),
                   name: snap.data().fname+' '+snap.data().lname,
                   desc: desc
                 }) 
                       })
                         
                     }
                    })
                      
                   })
                   localStorage.setItem('title', type);
                   localStorage.setItem('desc', desc);
                  navigate('/chat/'+uid)
                  }}>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                  <b className={styles.label}>Message {name}</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {view && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setView(false)}
        >
          <Jobdetailscomp onClose={()=>setView(false)} 
          name={name} desc={desc} title={type} 
          to={to} from={from} addy={addy}
          uid={uid}
          />
        </PortalPopup>
      )}
    </div>
  );
};

export default JobView;

