import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ConfirmReset.module.css";
const ConfirmReset = () => {
  const navigate = useNavigate();

  const onBackToLoginClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.confirmreset}>
      <div className={styles.logo}>
        <img className={styles.logoChild} alt="" src="/group-66524.svg" />
        <img className={styles.logoItem} alt="" src="/group-66525.svg" />
      </div>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <img className={styles.frameIcon} alt="" src="/frame1111.svg" />
          <div className={styles.frameContainer}>
            <div className={styles.passwordResetEmailSentParent}>
              <div className={styles.passwordResetEmail}>
                Password reset email sent.
              </div>
              <div className={styles.aResetLink}>
                A reset link has been sent to your mailbox. Check and click the
                link to reset password.
              </div>
            </div>
            <div className={styles.stillHaveIssuesContainer}>
              <span>{`Still have issues resetting your password? `}</span>
              <span className={styles.contactSupport}>Contact Support</span>
            </div>
          </div>
        </div>
        <div className={styles.backToLogin} onClick={onBackToLoginClick}>
          Back to Login
        </div>
      </div>
      <div className={styles.privacyPolicy}
      style={{cursor:'pointer'}}
      onClick={()=>{window.open('http://www.loggworks.co.uk/privacy', '_blank');}}
      >Privacy policy</div>
      
    </div>
  );
};

export default ConfirmReset;
