import { useState, useCallback, useEffect } from "react";
import ChangePlanMonth from "./ChangePlanMonth";
import PortalPopup from "./PortalPopup";
import ChangePlanPsgl from "./ChangePlanPsgl";
import { useNavigate } from "react-router-dom";
import styles from "./Plans.module.css";
import { auth, db } from "../firebase";
import MonthlyPay from "./MonthlyPay";
const Plans = () => {
  const [isChangePlanMonthPopupOpen, setChangePlanMonthPopupOpen] =
    useState(false);
  const [isChangePlanPsglPopupOpen, setChangePlanPsglPopupOpen] =
    useState(false);
    
  const navigate = useNavigate();
  const [payg, setPayg]=useState(0)
  const [monthly, setMonthly]=useState(0)
 
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const openChangePlanMonthPopup = useCallback(() => {
    setChangePlanMonthPopupOpen(true);
  }, []);

  const closeChangePlanMonthPopup = useCallback(() => {
    setChangePlanMonthPopupOpen(false);
  }, []);

  const openChangePlanPsglPopup = useCallback(() => {
    setChangePlanPsglPopupOpen(true);
  }, []);

  const closeChangePlanPsglPopup = useCallback(() => {
    setChangePlanPsglPopupOpen(false);
  }, []);
  const [ subType, setSubType] = useState(0)
  useEffect(() => {
    if(auth.currentUser)
    {
    db.collection('users').doc(auth.currentUser.uid).get().then((snapshot)=>{
      if(snapshot.data().subType!=null)
      {
        setSubType(snapshot.data().subType)
      }
      else{
        setSubType(0)
      }
     })
    }
    db.collection('pricing').doc('payg').get().then(function (snap) {
      setPayg(snap.data()?.value)
    })
    db.collection('pricing').doc('monthly').get().then(function (snap) {
      setMonthly(snap.data()?.value)
    })
  }, [])
  const reload=()=>{
    if(auth.currentUser)
    {
    db.collection('users').doc(auth.currentUser.uid).get().then((snapshot)=>{
      if(snapshot.data().subType!=null)
      {
        setSubType(snapshot.data().subType)
      }
      else{
        setSubType(0)
      }
     })
    }
  }

  return (
    <>
      <div className={styles.security}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-66522.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account51.svg"
                  />
                  <div className={styles.changePlan}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
          <div className={styles.titles}>
            <div className={styles.back} onClick={()=>{navigate(-1)}}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.subscriptions}>Subscriptions</div>
          </div>
          <div className={styles.topNavigation}>
            <div className={styles.navBar}>
              <div className={styles.smallButtons}>
                <div className={styles.icons16pxaddParent}>
                  <img
                    className={styles.icons16pxadd}
                    alt=""
                    src="/icons16pxadd2.svg"
                  />
                  <div className={styles.button}>Post a job</div>
                </div>
              </div>
              <img
                className={styles.navBarChild}
                alt=""
                src="/ellipse-252@2x.png"
              />
              <div className={styles.component98}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconscalendar.svg"
                />
                <div className={styles.home}>Bookmarks</div>
              </div>
              <div className={styles.searchBar}>
                <div className={styles.icons20pxsearchParent}>
                  <img
                    className={styles.icons20pxsearch}
                    alt=""
                    src="/icons20pxsearch2.svg"
                  />
                  <div className={styles.trySearchingCarpenter}>
                    Try searching carpenter...
                  </div>
                </div>
                <div className={styles.searchBarChild} />
                <div className={styles.iconslocationParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslocation1.svg"
                  />
                  <div className={styles.trySearchingCarpenter}>Location</div>
                </div>
              </div>
            </div>
            <div className={styles.pageTitle}>
              <div className={styles.merge}>
                <div className={styles.icon}  onClick={()=>{navigate(-1)}}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconschevronright.svg"
                  />
                </div>
                <b className={styles.subscriptions1}>Subscriptions</b>
                <div className={styles.smallButtons1}>
                  <div className={styles.icons16pxaddParent}>
                    <img
                      className={styles.icons16pxadd}
                      alt=""
                      src="/icons16pxadd2.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.border} />
            </div>
          </div>
           {
          subType==0?
          <div className={styles.frameParent}>
            <div className={styles.frameWrapper}>
              <div className={styles.frameGroup}>
                <div className={styles.frameContainer}>
                  <div className={styles.payAsYouGoParent}>
                    <div className={styles.payAsYou}>Pay as you go</div>
                    <div className={styles.commissionJob}>
                      <span>
                        <span className={styles.span}>{payg}%</span>
                      </span>
                      <span className={styles.commissionJob1}>
                        <span>{` `}</span>
                        <span>Commission /Job</span>
                      </span>
                    </div>
                  </div>
                  <div className={styles.frameDiv}>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>
                            Everything in Monthly subscription
                          </div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Get all the perks in our monthly subscription plan
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>Promotion</div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Send offers to service consumers directly to improve
                            chances of getting jobs.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>Logg Jobs</div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Get jobs directly on your dashboard at no extra
                            cost.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.largeButtonsWrapper}>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.iconsmessage1}
                      alt=""
                      src="/iconsmessage5.svg"
                    />
                    <b className={styles.label}>Current Plan</b>
                    <img
                      className={styles.iconsmessage1}
                      alt=""
                      src="/iconsmessage6.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.changePlanParent}>
                <div className={styles.changePlan}>Change plan</div>
                <div className={styles.chooseFromMonthly}>
                  Choose from monthly or pay as you go.
                </div>
              </div>
              <div className={styles.plans}>
                <div className={styles.subscriptionListWrapper1}>
                  <div className={styles.subscriptionList3}>
                    <div className={styles.payg}>PAYG</div>
                    <div className={styles.parent}>
                      <div className={styles.div}>{payg}%</div>
                      <div className={styles.commissionjob}>Commission/Job</div>
                    </div>
                  </div>
                </div>
                <div className={styles.subscriptionListWrapper2}>
                  <div className={styles.subscriptionList3}>
                    <div className={styles.payg}>Monthly</div>
                    <div className={styles.parent}>
                      <div className={styles.div}>£{monthly}</div>
                      <div className={styles.monthlyprofessional}>
                        Monthly/Professional
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.largeButtons1}
                onClick={openChangePlanMonthPopup}
              >
                <img
                  className={styles.iconsmessage1}
                  alt=""
                  src="/iconsmessage7.svg"
                />
                <b className={styles.label}>Change plan</b>
                <img
                  className={styles.iconsmessage1}
                  alt=""
                  src="/iconsmessage8.svg"
                />
              </div>
            </div>
          </div>
          :
          <div className={styles.frameParent2}>
            <div className={styles.frameWrapper1}>
              <div className={styles.frameParent3}>
                <div className={styles.frameParent4}>
                  <div className={styles.changePlanParent}>
                    <div className={styles.monthly1}>Monthly</div>
                    <div className={styles.commissionJob}>
                      <b>{`£${monthly}`}</b>
                      <span className={styles.commissionJob1}>
                        Monthly/Professional
                      </span>
                    </div>
                  </div>
                  <div className={styles.frameParent5}>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>
                            Job Management
                          </div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Automatically manage your jobs with our job
                            management feature.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>Quote</div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Send a quote to receive payment swiftly.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>
                            Social Share
                          </div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Share your business on any platform and get booked.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.subscriptionListWrapper}>
                      <div className={styles.subscriptionList}>
                        <div className={styles.iconsscrewParent}>
                          <img
                            className={styles.iconsscrew}
                            alt=""
                            src="/iconsscrew2.svg"
                          />
                          <div className={styles.jobManagement}>Vetting</div>
                        </div>
                        <div
                          className={styles.automaticallyManageYourJobsWrapper}
                        >
                          <div className={styles.automaticallyManageYour}>
                            Get vetted for free to improve your business
                            reputation and increase visibility.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.largeButtonsWrapper}>
                  <div className={styles.largeButtons}>
                    <img
                      className={styles.iconsmessage1}
                      alt=""
                      src="/iconsmessage5.svg"
                    />
                    <b className={styles.label}>Current plan</b>
                    <img
                      className={styles.iconsmessage1}
                      alt=""
                      src="/iconsmessage6.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.changePlanParent}>
                <div className={styles.changePlan}>Change plan</div>
                <div className={styles.chooseFromMonthly}>
                  Choose from monthly or pay as you go.
                </div>
              </div>
              <div className={styles.plans}>
                <div className={styles.subscriptionListWrapper2}>
                  <div className={styles.subscriptionList3}>
                    <div className={styles.payg1}>PAYG</div>
                    <div className={styles.parent}>
                      <div className={styles.div2}>{payg}%</div>
                      <div className={styles.commissionjob1}>
                        Commission/Job
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.subscriptionListWrapper1}>
                  <div className={styles.subscriptionList3}>
                    <div className={styles.payg1}>Monthly</div>
                    <div className={styles.parent}>
                      <div className={styles.div2}>£{monthly}</div>
                      <div className={styles.monthlyprofessional3}>
                        Monthly/Professional
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.largeButtons1}
                onClick={openChangePlanPsglPopup}
              >
                <img
                  className={styles.iconsmessage1}
                  alt=""
                  src="/iconsmessage7.svg"
                />
                <b className={styles.label}>Change plan</b>
                <img
                  className={styles.iconsmessage1}
                  alt=""
                  src="/iconsmessage8.svg"
                />
              </div>
            </div>
          </div>
           }
        </div>
      </div>
      {isChangePlanMonthPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeChangePlanMonthPopup}
        >
          <ChangePlanMonth onClose={closeChangePlanMonthPopup} monthly={monthly} reload={reload}/>
        </PortalPopup>
      )}
      {isChangePlanPsglPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeChangePlanPsglPopup}
        >
          <ChangePlanPsgl onClose={closeChangePlanPsglPopup} payg={payg} reload={reload}/>
        </PortalPopup>
      )}
       
    </>
  );
};

export default Plans;
