import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Jobs1.module.css";
import { AppBar, Avatar, Badge, Menu, MenuItem, styled } from "@mui/material";
import { auth, db } from "../firebase";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import PortalPopup from "../components/PortalPopup";
import RescheduleJobContainer from "../components/RescheduleJobContainer";
import CancelBooking from "../components/CancelBooking";
import { serverTimestamp } from "firebase/firestore";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const Jobs1 = () => {
  const navigate = useNavigate();
  const [tab, setTab]= useState(0)
   const [pastData, setPastData] = useState([]);
  const [futureData, setFutureData] = useState([]);
  const [presentData, setPresentData] = useState([]);
  const [selected, setSelected]=useState('')
  const [cid, setCid]=useState('')
  const[cancel,setCancel]=useState(false)
  const[resche,setResche]=useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const [msg, setMsg]= useState([])
  const [activ, setActivity]= useState([])
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function formatCurrentDate() {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour < 12 ? 'am' : 'pm';
    const formattedTime = `${hour % 12 || 12}:${minute.toString().padStart(2, '0')}${period}`;
  
    return formattedDate;
  }
  
  const formattedCurrentDate = formatCurrentDate();
  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = '12';
    const minutes = '00';
    const seconds = '00';
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
  
  function getThreeDays() {
    const currentDate = new Date();
    const threeDays = [];
  
    for (let i = 0; i < 3; i++) {
      const newDate = new Date(currentDate);
      newDate.setDate(currentDate.getDate() + i);
      threeDays.push(getFormattedDate(newDate));
    }
  
    return threeDays;
  }
  
  const threeDaysStartingFromNow = getThreeDays();
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for accurate date comparison
  
    const unsubscribe = db.collection('prof_booking/' + auth.currentUser?.uid + '/list')
    .orderBy('stamp','desc').onSnapshot((querySnapshot) => {
      const pastData = [];
      const futureData = [];
      const presentData = [];
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const providedDate = new Date(data.qoute.jdate);
  
        // Set hours, minutes, seconds, and milliseconds to 0 for accurate date comparison
        providedDate.setHours(0, 0, 0, 0);
  
        data.id = doc.id;
        if (providedDate < currentDate || data.status === 'complete'|| data.status === 'cancel') {
          pastData.push(data);
        } else if (providedDate > currentDate) {
          futureData.push(data);
        } else {
          presentData.push(data);
        }
      });
  
      // Set state arrays after categorizing all data
      setPastData(pastData);
      setFutureData(futureData);
      setPresentData(presentData);
    });
    const collectionRef = db.collection('users').doc(auth.currentUser.uid).collection('noti_m');
    const collectionRef2 = db.collection('users').doc(auth.currentUser.uid).collection('noti_act');
    const updatedList = [];
    const actList = [];
// Subscribe to real-time updates using onSnapshot
const unsubscribe1 = collectionRef.onSnapshot((snapshot) => {
// Handle the snapshot changes
snapshot.forEach((doc) => {
  const docData = doc.data();
  updatedList.push(docData);
});
setMsg(updatedList)
});
const unsubscribe2 = collectionRef2.onSnapshot((snapshot) => {
// Handle the snapshot changes
snapshot.forEach((doc) => {
  const docData = doc.data();
  actList.push(docData);
});
setActivity(actList)
});

return () => {
  unsubscribe();
unsubscribe1();
unsubscribe2();
};
  }, []);
  

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);
  const onDefaultContainer5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onAllContainerClick = useCallback(() => {
    setTab(0)
  }, [navigate]);

  const onAllContainer1Click = useCallback(() => {
    setTab(1)
  }, [navigate]);

  const onAllContainer2Click = useCallback(() => {
    setTab(2)
  }, [navigate]);

  const onIconsMoreVerticalClick = useCallback(() => {
    // Please sync "Jobs" to the project
  }, []);

  return (
    <div className={styles.jobs}>
      <div className={styles.jobs1}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome5.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div className={styles.default1}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs1.svg"
                  />
                  <div className={styles.peoplename}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                <StyledBadge badgeContent={activ.length} color="primary" >
                   <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                </StyledBadge>
                 
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                <StyledBadge badgeContent={msg.length} color="primary" >
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                </StyledBadge>
                  
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}
              style={{cursor:'pointer'}}
              onClick={onDefaultContainer5Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.titlesParent}>
        <div className={styles.titles1}>
          <div className={styles.activities}>Jobs</div>
          <div className={styles.groupParent}>
          <div className={styles.bellParent} onClick={()=>navigate('/activities')}>
            <StyledBadge badgeContent={activ.length} color="primary" >
                <img
                src="/iconsactivity.svg"
                />
            </StyledBadge>
            </div>
            <div className={styles.messageCircleParent} onClick={()=>navigate('/messages')}>
            <StyledBadge badgeContent={msg.length} color="primary" >
              <img
                src="/iconsmessage.svg"
                />
            </StyledBadge>
            </div>
          </div>
        </div>
        <div className={styles.component102}>
              <b className={styles.licenseAndCertification}>Jobs</b>
            </div>
          <div className={styles.component102Parent}>
           
            <div className={styles.jobPrototypesWrapper}>
              <div className={styles.jobPrototypes}>
                <div className={styles.tabSwitch}>
                  <div className={styles.tabSwitchChild} />
                  <div className={styles.all} style={tab==0?{borderBottom: '2px solid green',color:'green'}:{}} onClick={onAllContainerClick}>
                <div className={tab==0?styles.booking:styles.booking1}>Current</div>
              </div>
              <div className={styles.all1} style={tab==1?{borderBottom: '2px solid green',color:'green'}:{}} onClick={onAllContainer1Click}>
                <div className={tab==1?styles.booking:styles.booking1}>Upcoming</div>
              </div>
              <div className={styles.all2} style={tab==2?{borderBottom: '2px solid green',color:'green'}:{}}onClick={onAllContainer2Click}>
                <div className={tab==2?styles.booking:styles.booking1}>Past</div>
              </div>
                </div>
              </div>
            </div>
            {
      tab==0?
      presentData.map((data)=>{
        return(
           <div className={styles.jobs2}>
        <div className={styles.jobs3}>
          <div className={styles.profileParent}>
            <div className={styles.profile}>
              <Avatar
              className={styles.avatarIcon}
              src={data.profile||''}
              />
              <div className={styles.merge}>
                <div className={styles.peoplename}>{data.qoute.cname}</div>
                <div className={styles.dateTime}>
                  <div className={styles.date}>{data.qoute.jdate}</div>
                  <div className={styles.dateTimeChild} />
                  <div className={styles.date}>{data.qoute.jtime}</div>
                </div>
              </div>
            </div>
            <img
                   className={styles.iconsmoreVertical}
                   alt=""
                   src="../iconsmore-vertical.svg"
                  style={{cursor:'pointer'}}
                  id={data.id}
                  aria-controls={open2 ? data.id : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.id)
                    setCid(data.cid)
                    handleClick2(e)
                  }}
                />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconscalendar.svg"
                />
                <div className={styles.date}>{data.qoute.jdate}</div>
              </div>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconsclock.svg"
                />
                <div className={styles.date}>{data.qoute.jtime}</div>
              </div>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconsduration1.svg"
                />
                <div className={styles.date}>{data.qoute.jdura} days</div>
              </div>
            </div>
            <div className={styles.iconslocationParent}>
              <img
                className={styles.iconsmoreVertical}
                alt=""
                src="../iconslocation.svg"
              />
              <div className={styles.citiessummary3}>
                {data.qoute.jloc}
              </div>
            </div>
          </div>
          <div className={styles.electricianRequiredTo}>
           {data.qoute.jdesc}
          </div>
        </div>
      </div>
        );
      })
      :tab==1?
      futureData.map((data)=>{
        return(
            <div className={styles.jobs2}>
        <div className={styles.jobs3}>
          <div className={styles.profileParent}>
            <div className={styles.profile}>
            <Avatar
              className={styles.avatarIcon}
              src={data.profile||''}
              />
              <div className={styles.merge}>
                <div className={styles.peoplename}>{data.qoute.cname}</div>
                <div className={styles.dateTime}>
                  <div className={styles.date}>{data.qoute.jdate}</div>
                  <div className={styles.dateTimeChild} />
                  <div className={styles.date}>{data.qoute.jtime}</div>
                </div>
              </div>
            </div>
            <img
              className={styles.iconsmoreVertical}
              alt=""
              src="../iconsmore-vertical.svg"
              style={{cursor:'pointer'}}
              id={data.id}
              aria-controls={open ? data.id : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(e)=>{
                setSelected(data.id)
                setCid(data.cid)
                handleClick(e)
              }}
            />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconscalendar.svg"
                />
                <div className={styles.date}>{data.qoute.jdate}</div>
              </div>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconsclock.svg"
                />
                <div className={styles.date}>{data.qoute.jtime}</div>
              </div>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconsduration1.svg"
                />
                <div className={styles.date}>{data.qoute.jdura} days</div>
              </div>
            </div>
            <div className={styles.iconslocationParent}>
              <img
                className={styles.iconsmoreVertical}
                alt=""
                src="../iconslocation.svg"
              />
              <div className={styles.citiessummary3}>
                {data.qoute.jloc}
              </div>
            </div>
          </div>
          <div className={styles.electricianRequiredTo}>
           {data.qoute.jdesc}
          </div>
        </div>
      </div>
        );
      })
      :
      pastData.map((data)=>{
        return(
         <div className={styles.jobs2}>
        <div className={styles.jobs3}>
          <div className={styles.profileParent}>
            <div className={styles.profile}>
            <Avatar
              className={styles.avatarIcon}
              src={data.profile||''}
              />
              <div className={styles.merge}>
                <div className={styles.peoplename}>{data.qoute.cname}</div>
                <div className={styles.dateTime}>
                  <div className={styles.date}>{data.qoute.jdate}</div>
                  <div className={styles.dateTimeChild} />
                  <div className={styles.date}>{data.qoute.jtime}</div>
                </div>
              </div>
            </div>
           
          </div>
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconscalendar.svg"
                />
                <div className={styles.date}>{data.qoute.jdate}</div>
              </div>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconsclock.svg"
                />
                <div className={styles.date}>{data.qoute.jtime}</div>
              </div>
              <div className={styles.iconscalendarParent}>
                <img
                  className={styles.iconsmoreVertical}
                  alt=""
                  src="../iconsduration1.svg"
                />
                <div className={styles.date}>{data.qoute.jdura} days</div>
              </div>
            </div>
            <div className={styles.iconslocationParent}>
              <img
                className={styles.iconsmoreVertical}
                alt=""
                src="../iconslocation.svg"
              />
              <div className={styles.citiessummary3}>
                {data.qoute.jloc}
              </div>
            </div>
          </div>
          <div className={styles.electricianRequiredTo}>
           {data.qoute.jdesc}
          </div>
          {data.status === "complete"&& (
              <div className={styles.rating}>
              <div className={styles.transactionPills}>
                <div className={styles.success}>COMPLETED</div>
              </div>
              <div className={styles.iconsstarParent}>
              {Array.from({ length: data.stars }, (_, i) => (
  <img className={styles.iconsstar} alt="" src="../filledStar.svg" />
))}     
              </div>
            </div>
            )}
             {data.status === "cancel"&& (
              <div className={styles.rating}>
              <div className={styles.transactionPills2}>
                <div className={styles.success}>CANCELLED</div>
              </div>
              <div className={styles.iconsstarParent}>
              {Array.from({ length: data.stars }, (_, i) => (
  <img className={styles.iconsstar} alt="" src="../filledStar.svg" />
))}     
              </div>
            </div>
            )}
        
        </div>
      </div>
        );
      })
    }
           <br></br> 
         <br></br> 
          </div>
          <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
             <div className={styles.bottomNav}>
            <div className={styles.home6} onClick={()=>navigate('/home')}>
              <div className={styles.home7}>Home</div>
              <img
                className={styles.brandLogoIcon}
                alt=""
                src="/brand-logo31.svg"
              />
            </div>
            <div className={styles.jobs8} onClick={()=>navigate('/jobs')}>
              <b className={styles.jobs9}>Jobs</b>
              <img
                className={styles.bibriefcaseFillIcon}
                alt=""
                src="/iconsjobs1.svg"
              />
            </div>
            <div className={styles.wallet} onClick={()=>navigate('/wallet')}>
              <div className={styles.wallet1}>Wallet</div>
              <img
                className={styles.zondiconswallet}
                alt=""
                src="/iconslog-cash.svg"
              />
            </div>
            <div className={styles.account} onClick={()=>navigate('/profile')}>
              <div className={styles.home7}>Account</div>
              <img
                className={styles.mdiaccountIcon}
                alt=""
                src="/account4.svg"
              />
            </div>
          </div>
          </AppBar>
         
        </div>
      </div>
      <div className={styles.currentJobs}>
        <div className={styles.titles1}>
          <div className={styles.activities}>Jobs</div>
          <div className={styles.groupParent}>
          <div className={styles.bellParent} onClick={()=>navigate('/activities')}>
            <StyledBadge badgeContent={activ.length} color="primary" >
                 <img
               src="/iconsactivity.svg"
                />
            </StyledBadge>
            </div>
            <div className={styles.messageCircleParent} onClick={()=>navigate('/messages')}>
            <StyledBadge badgeContent={msg.length} color="primary" >
               <img
                src='/iconsmessage.svg'
                />
            </StyledBadge>
            </div>
          
          </div>
        </div>
        <div className={styles.topBar}>
          {/*<div className={styles.input}>
            <div className={styles.rectangleParent}>
              <div className={styles.emailChild} />
              <div className={styles.ddMm}>DD - MM - YYYY</div>
              <div className={styles.jackDorsey}>Jack Dorsey</div>
            </div>
            <img className={styles.frameIcon} alt="" src="/frame3.svg" />
            <div className={styles.search}>Search</div>
  </div>*/}
          <div className={styles.jobTabs}>
            <div className={styles.jobTabsChild} />
            <div className={styles.titles2}>
              <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{setTab(0)}}>
              <div className={styles.current} style={tab==0?{color:`var(--logg-green)`}:{}}>Current</div>
              <div className={styles.titlesChild} 
              style={tab==0?{backgroundColor:`var(--logg-green)`}:{}}/>
              </div>
              <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{setTab(1)}}>
              <div className={styles.upcoming} style={tab==1?{color:`var(--logg-green)`}:{}}>Upcoming</div>
              <div className={styles.titlesChild} 
              style={tab==1?{backgroundColor:`var(--logg-green)`}:{}}/>
              </div>
              <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{setTab(2)}}>
              <div className={styles.past} style={tab==2?{color:`var(--logg-green)`}:{}}>Past</div>
              <div className={styles.titlesChild} 
              style={tab==2?{backgroundColor:`var(--logg-green)`}:{}}/>
              </div>
              <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{setTab(3)}}>
              <div className={styles.pending} style={tab==3?{color:`var(--logg-green)`}:{}}>Pending</div>
              <div className={styles.titlesChild} 
              style={tab==3?{backgroundColor:`var(--logg-green)`}:{}}/>
              </div>
              <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{setTab(4)}}>
              <div className={styles.all3} style={tab==4?{color:`var(--logg-green)`}:{}}>All</div>
              <div className={styles.titlesChild} 
              style={tab==4?{backgroundColor:`var(--logg-green)`}:{}}/>
              </div>
              
            </div>
            <div className={styles.jobTabsItem} />
          </div>
        </div>
        <div className={styles.instanceParent}>
        {
      tab==0?
      presentData.map((data)=>{
        return(
          <div className={styles.merge}>
            <div className={styles.frameParent3}>
              <div className={styles.frameParent4}>
                <div className={styles.frameParent4}>
                  <div className={styles.merge}>
                    <div className={styles.iconshomeParent}>
                    <Avatar
              className={styles.frameChild}
              src={data.profile||''}
              />
                      <div className={styles.merge}>
                        <div className={styles.peoplenameParent}>
                          <div className={styles.peoplename3}>
                          {data.qoute.cname}
                          </div>
                          <img
                            className={styles.accountIcon}
                            alt=""
                            src="/group-66511.svg"
                            style={{cursor:'pointer'}}
                            id={data.id}
                            aria-controls={open2 ? data.id : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? 'true' : undefined}
                            onClick={(e)=>{
                              setSelected(data.id)
                              setCid(data.cid)
                              handleClick2(e)
                            }}
                          />
                        </div>
                        <div
                          className={styles.citiessummary12}
                        >{data.qoute.jdate}</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameParent7}>
                    <div className={styles.iconsParent}>
                      <img className={styles.icons} alt="" src="/icons6.svg" />
                      <div className={styles.citiessummary13}>
                      {data.qoute.jloc}
                      </div>
                    </div>
                    <div className={styles.frameParent8}>
                      <div className={styles.iconsParent}>
                        <img
                          className={styles.icons1}
                          alt=""
                          src="/icons7.svg"
                        />
                        <div
                          className={styles.citiessummary14}
                        >{data.qoute.jdate}</div>
                      </div>
                      <div className={styles.iconsParent}>
                        <img
                          className={styles.icons1}
                          alt=""
                          src="/icons8.svg"
                        />
                        <div className={styles.citiessummary14}>{data.qoute.jtime}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.iAmEnjoying}
                  >{data.qoute.jdesc}</div>
                </div>
                <div className={styles.status}>
                  <div className={styles.frameParent9}>
                    {
                      data.status=='complete'?
                         <div className={styles.completedWrapper}>
                      <b className={styles.completed}>completed</b>
                    </div>:null
                    }
                 {
                  data.status=='cancel'?
                  <div className={styles.cancelledWrapper}>
                      <b className={styles.completed}>cancelled</b>
                    </div>:null
                 }
                    
                  </div>
                  <div className={styles.iconsstarParent}>
              {Array.from({ length: data.stars }, (_, i) => (
  <img className={styles.iconsstar} alt="" src="../filledStar.svg" />
))}     
              </div>
                </div>
              </div>
              <div className={styles.frameInner} />
            </div>
          </div>
        );
      })
      :tab==1?
      futureData.map((data)=>{
        return(
          <div className={styles.merge}>
          <div className={styles.frameParent3}>
            <div className={styles.frameParent4}>
              <div className={styles.frameParent4}>
                <div className={styles.merge}>
                  <div className={styles.iconshomeParent}>
                  <Avatar
              className={styles.frameChild}
              src={data.profile||''}
              />
                    <div className={styles.merge}>
                      <div className={styles.peoplenameParent}>
                        <div className={styles.peoplename3}>
                        {data.qoute.cname}
                        </div>
                        <img
                          className={styles.accountIcon}
                          alt=""
                          src="/group-66511.svg"
                          style={{cursor:'pointer'}}
                          id={data.id}
                          aria-controls={open ? data.id : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e)=>{
                            setSelected(data.id)
                            setCid(data.cid)
                            handleClick(e)
                          }}
                        />
                      </div>
                      <div
                        className={styles.citiessummary12}
                      >{data.qoute.jdate}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.frameParent7}>
                  <div className={styles.iconsParent}>
                    <img className={styles.icons} alt="" src="/icons6.svg" />
                    <div className={styles.citiessummary13}>
                    {data.qoute.jloc}
                    </div>
                  </div>
                  <div className={styles.frameParent8}>
                    <div className={styles.iconsParent}>
                      <img
                        className={styles.icons1}
                        alt=""
                        src="/icons7.svg"
                      />
                      <div
                        className={styles.citiessummary14}
                      >{data.qoute.jdate}</div>
                    </div>
                    <div className={styles.iconsParent}>
                      <img
                        className={styles.icons1}
                        alt=""
                        src="/icons8.svg"
                      />
                      <div className={styles.citiessummary14}>{data.qoute.jtime}</div>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.iAmEnjoying}
                >{data.qoute.jdesc}</div>
              </div>
              <div className={styles.status}>
                  <div className={styles.frameParent9}>
                    {
                      data.status=='complete'?
                         <div className={styles.completedWrapper}>
                      <b className={styles.completed}>completed</b>
                    </div>:null
                    }
                 {
                  data.status=='cancel'?
                  <div className={styles.cancelledWrapper}>
                      <b className={styles.completed}>cancelled</b>
                    </div>:null
                 }
                    
                  </div>
                  <div className={styles.iconsstarParent}>
              {Array.from({ length: data.stars }, (_, i) => (
  <img className={styles.iconsstar} alt="" src="../filledStar.svg" />
))}     
              </div>
                </div>
            </div>
            <div className={styles.frameInner} />
          </div>
        </div>
        );
      })
      :
      pastData.map((data)=>{
        return(
          <div className={styles.merge}>
            <div className={styles.frameParent3}>
              <div className={styles.frameParent4}>
                <div className={styles.frameParent4}>
                  <div className={styles.merge}>
                    <div className={styles.iconshomeParent}>
                    <Avatar
              className={styles.frameChild}
              src={data.profile||''}
              />
                      <div className={styles.merge}>
                        <div className={styles.peoplenameParent}>
                          <div className={styles.peoplename3}>
                          {data.qoute.cname}
                          </div>
                          
                        </div>
                        <div
                          className={styles.citiessummary12}
                        >{data.qoute.jdate}</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.frameParent7}>
                    <div className={styles.iconsParent}>
                      <img className={styles.icons} alt="" src="/icons6.svg" />
                      <div className={styles.citiessummary13}>
                      {data.qoute.jloc}
                      </div>
                    </div>
                    <div className={styles.frameParent8}>
                      <div className={styles.iconsParent}>
                        <img
                          className={styles.icons1}
                          alt=""
                          src="/icons7.svg"
                        />
                        <div
                          className={styles.citiessummary14}
                        >{data.qoute.jdate}</div>
                      </div>
                      <div className={styles.iconsParent}>
                        <img
                          className={styles.icons1}
                          alt=""
                          src="/icons8.svg"
                        />
                        <div className={styles.citiessummary14}>{data.qoute.jtime}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.iAmEnjoying}
                  >{data.qoute.jdesc}</div>
                </div>
                <div className={styles.status}>
                  <div className={styles.frameParent9}>
                    {
                      data.status=='complete'?
                         <div className={styles.completedWrapper}>
                      <b className={styles.completed}>completed</b>
                    </div>:null
                    }
                 {
                  data.status=='cancel'?
                  <div className={styles.cancelledWrapper}>
                      <b className={styles.completed}>cancelled</b>
                    </div>:null
                 }
                    
                  </div>
                  <div className={styles.iconsstarParent}>
              {Array.from({ length: data.stars }, (_, i) => (
  <img className={styles.iconsstar} alt="" src="../filledStar.svg" />
))}     
              </div>
                </div>
              </div>
              <div className={styles.frameInner} />
            </div>
          </div>
        );
      })
    }
         <br></br> 
         <br></br> 
        </div>
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <div className={styles.bottomNav1}>
          <div className={styles.home6} onClick={()=>navigate('/home')}>
            <div className={styles.home7}>Home</div>
            <img
              className={styles.brandLogoIcon}
              alt=""
              src="/brand-logo31.svg"
            />
          </div>
          <div className={styles.jobs8} onClick={()=>navigate('/jobs')}>
            <b className={styles.jobs9}>Jobs</b>
            <img
              className={styles.bibriefcaseFillIcon}
              alt=""
              src="/iconsjobs1.svg"
            />
          </div>
          <div className={styles.wallet} onClick={()=>navigate('/wallet')}>
            <div className={styles.wallet1}>Wallet</div>
            <img
              className={styles.zondiconswallet}
              alt=""
              src="/iconslog-cash.svg"
            />
          </div>
          <div className={styles.account} onClick={()=>navigate('/profile')}>
            <div className={styles.home7}>Account</div>
            <img
              className={styles.mdiaccountIcon}
              alt=""
              src="/account4.svg"
            />
          </div>
        </div>
        </AppBar>
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={()=>{
          setResche(true)
          handleClose()
          }}>Reschedule Booking</MenuItem>
        <MenuItem style={{color:'red'}} onClick={()=>{
          setCancel(true)
          handleClose()
          }}>Cancel Booking</MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={()=>{
          db.collection('cust_booking/'+cid+'/list').doc(selected).update({
            status:'pend'
          })
          db.collection('prof_booking/'+auth.currentUser?.uid+'/list').doc(selected).update({
            status:'pend'
          })
          db.collection('bookings/').doc(selected).update({
            status:'pend'
          })
          db.collection('users/').doc(auth.currentUser?.uid).get().then(function (snap) {
            db.collection('activity/cust/'+cid).add({
              uid:cid,
              type:'booking',
              msg:'Your booking was marked as complete by',
              date: formattedCurrentDate,
              name: snap.data().name,
              unix: Date.now(),
              stamp:serverTimestamp()
            })
            db.collection('users').doc(auth.currentUser?.uid).get().then(function (snap) {
              db.collection('bookings/').doc(selected).get().then(function (data) {
        fetch('https://loggworks-c15c9e56f169.herokuapp.com/prof_booking_comp', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                to: snap.data().email,
                name: snap.data().name,
                quote: data.data().qid,
                location:data.data().qoute.jloc,
                scheduled:data.data().qoute.jdate,
                service:snap.data().category
              }),
            })
            db.collection('customers/').doc(cid).get().then(function (cust) {
              db.collection('customers').doc(cid).get().then(function (customer) {
                if (customer.data().emailNotifications) {
                  fetch('https://loggworks-c15c9e56f169.herokuapp.com/cust_booking_comp_confirm', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      to: cust.data().email,
                      name: cust.data().fname,
                      name2: snap.data().name,
                      quote: data.data().qid,
                      location:data.data().qoute.jloc,
                      scheduled:data.data().qoute.jdate,
                      service:snap.data().category
                    }),
                  })
                }
              })
              
                    })
              })

            })
          })
        
          db.collection('customers').doc(cid).get().then(function (customer) {
            if (customer.data().pushNotifications) {
              db.collection('customers').doc(cid).collection('noti_act').add({
            last: 'Your booking has been marked as complete!!',
            name: '',
           })
            }
            if (customer.data().emailNotifications) {
                fetch('https://loggworks-c15c9e56f169.herokuapp.com/schedulecomp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
             schedule_date: threeDaysStartingFromNow,
             pid:auth.currentUser.uid,
             cid,
             selected
            }),
          })
            }
          })
          
          
          handleClose2()
          }}>Mark as complete</MenuItem>
      </Menu>
      </div>
      {resche && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setResche(false)}
        >
          <RescheduleJobContainer onClose={()=>setResche(false)} cid={cid} selected={selected}/>
        </PortalPopup>
      )}
       {cancel && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setCancel(false)}
        >
          <CancelBooking onClose={()=>setCancel(false)} cid={cid} selected={selected}/>
        </PortalPopup>
      )}
    </div>
  );
};

export default Jobs1;
