import { useState, useCallback } from "react";
import CertificateModal from "./CertificateModal";
import PortalPopup from "./PortalPopup";
import LicenseModal from "./LicenseModal";
import OtherLandCModal from "./OtherLandCModal";
import { useNavigate } from "react-router-dom";
import styles from "./LandCModal.module.css";
const LandCModal = ({ onClose }) => {
  const [isCertificateModalPopupOpen, setCertificateModalPopupOpen] =
    useState(false);
  const [isLicenseModalPopupOpen, setLicenseModalPopupOpen] = useState(false);
  const [isOtherLandCModalPopupOpen, setOtherLandCModalPopupOpen] =
    useState(false);
  const navigate = useNavigate();

  const openCertificateModalPopup = useCallback(() => {
    setCertificateModalPopupOpen(true);
  }, []);

  const closeCertificateModalPopup = useCallback(() => {
    setCertificateModalPopupOpen(false);
  }, []);

  const openLicenseModalPopup = useCallback(() => {
    setLicenseModalPopupOpen(true);
  }, []);

  const closeLicenseModalPopup = useCallback(() => {
    setLicenseModalPopupOpen(false);
  }, []);

  const openOtherLandCModalPopup = useCallback(() => {
    setOtherLandCModalPopupOpen(true);
  }, []);

  const closeOtherLandCModalPopup = useCallback(() => {
    setOtherLandCModalPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.landcmodal}>
        <div className={styles.modalTitleParent}>
          <div className={styles.modalTitle}>
            <div className={styles.addANewCardParent}>
              <div className={styles.addANew}>Add Item</div>
              <img
                className={styles.iconsclose}
                alt=""
                src="/iconsclose1.svg"
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.listParent}>
            <div className={styles.list} onClick={openCertificateModalPopup}>
              <div className={styles.content}>
                <div className={styles.left}>
                  <img
                    className={styles.iconWithBg}
                    alt=""
                    src="/icon-with-bg.svg"
                  />
                  <img
                    className={styles.iconsplaceholder}
                    alt=""
                    src="/iconsplaceholder6.svg"
                  />
                  <div className={styles.list1}>Certificate</div>
                </div>
                <div className={styles.right}>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevronright11.svg"
                  />
                  <div className={styles.toggle}>
                    <div className={styles.toggleChild} />
                    <div className={styles.toggleItem} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.list} onClick={openLicenseModalPopup}>
              <div className={styles.content}>
                <div className={styles.left}>
                  <img
                    className={styles.iconWithBg}
                    alt=""
                    src="/icon-with-bg3.svg"
                  />
                  <img
                    className={styles.iconsplaceholder}
                    alt=""
                    src="/iconsplaceholder6.svg"
                  />
                  <div className={styles.list1}>License</div>
                </div>
                <div className={styles.right}>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevronright11.svg"
                  />
                  <div className={styles.toggle}>
                    <div className={styles.toggleChild} />
                    <div className={styles.toggleItem} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.list4} onClick={openOtherLandCModalPopup}>
              <div className={styles.content}>
                <div className={styles.left}>
                  <img
                    className={styles.iconWithBg}
                    alt=""
                    src="/icon-with-bg7.svg"
                  />
                  <img
                    className={styles.iconsplaceholder}
                    alt=""
                    src="/iconsplaceholder6.svg"
                  />
                  <div className={styles.list1}>Other</div>
                </div>
                <div className={styles.right}>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevronright11.svg"
                  />
                  <div className={styles.toggle}>
                    <div className={styles.toggleChild} />
                    <div className={styles.toggleItem} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCertificateModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCertificateModalPopup}
        >
          <CertificateModal onClose={closeCertificateModalPopup} />
        </PortalPopup>
      )}
      {isLicenseModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLicenseModalPopup}
        >
          <LicenseModal onClose={closeLicenseModalPopup} />
        </PortalPopup>
      )}
      {isOtherLandCModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOtherLandCModalPopup}
        >
          <OtherLandCModal onClose={closeOtherLandCModalPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default LandCModal;
