import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../firebase'
import { Helmet } from 'react-helmet'

function ViewProf() {
    let {uid} = useParams()
    const [user, setUser]=useState('')
    const [profileImage, setProfileImage]=useState('')
    const [imagePreview, setImagePreview]=useState('')
    useEffect(() => {
        const openProfileWithDelay = () => {
          setTimeout(() => {
            window.location.href = 'https://www.consumer.loggworks.co.uk/profile/' + uid;
          }, 3000); // 3000 milliseconds = 3 seconds
        };
        db.collection('users').where('org_name','==',uid).get().then(function (snap) {

            let uid = snap.docs[0].id
            db.collection('users').doc(uid).get().then(function(snapshot) {
                if(snapshot.data())
                {
                  setUser(snapshot.data()?.name)
                  setProfileImage(snapshot.data()?.profileImage)
                }
              });
              db.collection('users')
        .doc(uid)
        .collection('gallery').onSnapshot((snapshot) => {
                const images = [];
                snapshot.forEach((doc) => {
                  images.push(doc.data().imageUrl);
                });
                 // Set the first image as imagePreview if available
                 if (images.length > 0) {
                  setImagePreview(images[0]);
                }
              });
        }).then(()=>{
         
          openProfileWithDelay();
        })
      }, [uid]);
    
  return (
    <div>
      <Helmet>
  <title>{uid}</title>
  <meta name="description" content={user.serviceDescription} />
  <meta name="keywords" content="loggworks" />
  <meta name="author" content={uid} />

  {/* Open Graph / Facebook */}
  <meta property="og:title" content={uid} />
  <meta property="og:description" content={user.serviceDescription} />
  <meta property="og:image" content={profileImage} />

  {/* Twitter */}
  <meta name="twitter:title" content={uid} />
  <meta name="twitter:description" content={user.serviceDescription} />
  <meta name="twitter:image" content={profileImage} />
  <meta name="twitter:card" content={profileImage} />

  {/* WhatsApp */}
  <meta property="og:site_name" content="Loggworks" />
  <meta property="og:title" content={uid} />
  <meta property="og:description" content={user.serviceDescription} />
  <meta property="og:image" content={profileImage} />
</Helmet>

        Redirecting.....
        </div>
  )
}

export default ViewProf