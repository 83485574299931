import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Alert, Link, Snackbar } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../firebase";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";


interface AuthFormProp {
  title: string;
  newPassword?: boolean;
  sub?: string;
}
interface FormData {
  email?: string;
  newPassword?: string;
  confirmPassword?: string;
}
const ResetFormChange: React.FC<AuthFormProp> = ({ title, newPassword, sub }) => {
  const [numberReactInput, setNumberReactInput] = useState<any>(null);

  const [formData, setFormData] = useState<FormData>({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate =useNavigate()
  const [passwordVisibity, setPasswordVisibity] = useState<boolean>(false);
  const {id}= useParams()


  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleOnChange = (event: any): void => {
    setFormData((prevData: FormData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const validatePassword = (password:any) => {
    const minLength = 8; // Minimum password length
    const hasUpperCase = /[A-Z]/.test(password); // Check for uppercase letter
    const hasLowerCase = /[a-z]/.test(password); // Check for lowercase letter
    const hasDigit = /[0-9]/.test(password); // Check for digit
  
    if (
      password.length < minLength ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasDigit
    ) {
      return false;
    }
  
    return true;
  };
  const handleSubmit = async(e: any)  => {
    e.preventDefault();
    if (formData.newPassword!=formData.confirmPassword) {
      setAlertMessage('Password must match')
      setAlertOpen(true);
      return
    }
    if (!validatePassword(formData.newPassword)) {
      setAlertMessage(
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a digit."
      );
      setAlertOpen(true);
      return
    }
    handleResetPassword(id,formData.newPassword)
  };
  const handleResetPassword = async (id:any, newPassword:any) => {
    try {
      await auth.confirmPasswordReset(id, newPassword);
      console.log('Password reset successful.');
      navigate('/password-change')
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };
  console.log(numberReactInput);
  return (
    <div className="authForm text-center h-screen">
      <div className="right flex flex-col formInput px-5 md:w-3/12 md:mx-auto md:my-auto ">
        <div className="logo mx-auto my-10">
          <img
            src={"/assets/images/Logo-green.png"}
            width={197}
            height={32}
            alt=""
          />
        </div>
        <h1 className="max-sm:mt-12 max-sm:text-center form_header max-sm:text-left text-2xl font-bold text-greyDark md:my-5">
          {title}
        </h1>
        <p className="text-center text-greyDark">{sub}</p>
        <br></br>
        <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>
        <div className="max-sm:mt-10">
          <form action="" onSubmit={handleSubmit}>
          <div className="md:mb-4">
          <div className="password mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1 text-left"
                    id="email"
                  >
                    <span>New Password</span>
                  </label>
                  <div className="relative">
                    <input
                      className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                      id="email"
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleOnChange}
                      type={passwordVisibity ? "text" : "password"}
                    />
                     <div
                    className="absolute top-[13px] right-2"
                    onClick={() => setPasswordVisibity(!passwordVisibity)}
                  >
                    {passwordVisibity ? (
                      <EyeSlashIcon width={35} height={19} />
                    ) : (
                      <EyeIcon width={35} height={19} />
                    )}
                  </div>
                  </div>
                </div>
                <div className="password mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-1 text-left"
                    id="email"
                  >
                    <span>Confirm Password</span>
                  </label>
                  <div className="relative">
                    <input
                      className="border border-grey rounded w-full py-2 px-3 text-gray-700 h-[48px]"
                      id="email"
                      type={passwordVisibity ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleOnChange}
                    />
                     <div
                    className="absolute top-[13px] right-2"
                    onClick={() => setPasswordVisibity(!passwordVisibity)}
                  >
                    {passwordVisibity ? (
                      <EyeSlashIcon width={35} height={19} />
                    ) : (
                      <EyeIcon width={35} height={19} />
                    )}
                  </div>
                  </div>
                </div>
                <button className="mb-10 w-full h-[48px] bg-green  font-bold py-2 px-4 rounded">
                Reset password
                </button>
                <Link
                  href={"/"}
                  className="text-center underline text-green"
                >
                  Back to login
                </Link>
              </div>
          </form>
        </div>

        <p className="absolute bottom-5 left-1/2 transform -translate-x-1/2"
         style={{cursor:'pointer'}}
         onClick={()=>{window.open('http://www.loggworks.co.uk/privacy', '_blank');}}>
          Privacy policy
        </p>
      </div>
    </div>
  );
};

export default ResetFormChange;
