import { useCallback, useState } from "react";
import Container from "../components/Container";
import { useNavigate } from "react-router-dom";
import styles from "./CompInfo.module.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert, Snackbar, TextField } from "@mui/material";
import { auth, db } from "../firebase";

const CompInfo = () => {
 const [type, setType] = useState('');
   const [org_name, setOrg] = useState('');
   const [num, setNum] = useState(0);
   const navigate = useNavigate()
   const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
   const proceed = () => {
    // Check if any of the input fields are empty
    if (type.trim() === '' || org_name.trim() === '' || num === 0) {
      setAlertMessage('Please fill in all the required fields.');
      setAlertOpen(true);
      return;
    }
    const usersCollection = db.collection('users');
    const querySnapshot = usersCollection.where('org_name', '==', org_name).get();
    querySnapshot.then(function (snap) {
      if (!snap.empty) {
        setAlertMessage('The Organization Name you provided is used. please provide a unique one');
        setAlertOpen(true);
        return;
      }
    })
    let data = {
      type: type,
      org_name: org_name,
      num: num,
    };
  
    db.collection('users')
      .doc(auth.currentUser.uid)
      .update(data)
      .then(() => {
        navigate('/job-category');
      })
      .catch((error) => {
        console.error('Error updating user data:', error);
      });
  };
  
  const handleChange = (event) => {
    setType(event.target.value);
    if(event.target.value=='Sole Proprietorship'){
      setNum(1)
    }
  };

  const onLargeInputsContainerClick = useCallback(() => {
    // Please sync "Onboarding 7" to the project
  }, []);

  const onLargeButtonsClick = useCallback(() => {
    navigate("/job-category");
  }, [navigate]);

  return (
    <div className={styles.compinfo}>
      <Container
        logoHeight="795px"
        logoBackgroundImage={`url("/img11@3x.png")`}
        frame66982AlignSelf="unset"
      />
      <div className={styles.compinfoInner}>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
          <Snackbar
  open={alertOpen}
  autoHideDuration={6000}
  
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set anchorOrigin to top center
>
<Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
{alertMessage}
  </Alert>
</Snackbar>
            <div className={styles.companyInformationParent}>
              <b className={styles.companyInformation}>Company Information</b>
              <div className={styles.tellUsAbout}>Tell us about yourself</div>
            </div>
          </div>
          <div className={styles.progressBar}>
            <div className={styles.progressBarChild} />
            <div className={styles.progressBarItem} />
            <div className={styles.progressBarChild} />
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.largeInputsParent}>
              <div
                className={styles.largeInputs}
              >
                <div className={styles.label}>Organization Type</div>
                 <div style={{width:'100%'}}>
              <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={handleChange}
        >
          <MenuItem value={'Limited Company'}>Limited Company</MenuItem>
          <MenuItem value={'Sole Proprietorship'}>Sole Proprietorship</MenuItem>
        </Select>
      </FormControl>
            </div>
              </div>
              <div className={styles.largeInputs1}>
                <div className={styles.label}>Organization Name</div>
                <div style={{width:'100%'}}>
              <TextField
                fullWidth
                onChange={(e)=>setOrg(e.target.value)}
              />
            </div>
              </div>
              <div className={styles.largeInputs1}>
                <div className={styles.label}>Number of Employees</div>
                 <div style={{width:'100%'}}>
             <TextField
                fullWidth
                type={'number'}
                value={num}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onKeyPress: (e) => {
                    const isNumber = /[0-9]/.test(e.key);
                    if (!isNumber) {
                      e.preventDefault();
                    }
                  },
                }}
                onChange={(e)=>{
                  setNum(e.target.value)
                }}
              />
            </div>
              </div>
            </div>
            <button
              className={styles.largeButtons}
              onClick={proceed}
            >
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder71.svg"
              />
              <b className={styles.label3}>Enter Job Category</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder71.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompInfo;
