import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Help.module.css";
import { Alert, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import useHubSpotScript from "../components/useHubSpotScript";
import LiveChat from "../components/LiveChat";
const Help = () => {
  const navigate = useNavigate();
  const [sugg, setSugg] = useState('')
  const [success, setSuccess] = useState(false)
  const [chat,setChat]=useState(false)

  const onSend =()=>{
    if (sugg==''){
      return
    }
    setSugg('')
    setSuccess(true)
  }
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onLargeButtonsContainerClick = useCallback(() => {
    navigate("/faq");
  }, [navigate]);

  const openMail = () => {
    const emailAddress = 'loggworks@gmail.com';
    const subject = 'Subject of the email';
    const body = 'Content of the email';

    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoUrl;
  };
  const tweetUrl = 'https://x.com/loggworks?s=20';

  const handleTweetClick = () => {
    window.open(`https://twitter.com/intent/tweet?text=Check out this profile: ${tweetUrl}`);
  };
  const phoneNumber = '+447769240184'; // Replace with your desired phone number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleChatButtonClick = () => {
  setChat(!chat)
  };
  return (
    <div className={styles.help}>
     {chat&&
      <LiveChat/>
      }
      <Snackbar
                    open={Boolean(success)}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    autoHideDuration={8000} 
                    onClose={() => setSuccess(false)}
                >
                    <Alert
                        onClose={() => setSuccess(false)} 
                        severity="success"
                        variant='filled'
                    >
                        Thank you for the review 
                    </Alert>
                </Snackbar>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-665221.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconshome3.svg"
                />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home}>Jobs</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home}>Activities</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home}>Wallet</div>
              </div>
            </div>
            <div className={styles.default} onClick={onDefaultContainer4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home}>Message</div>
              </div>
            </div>
            <div className={styles.default5}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.accountIcon}
                  alt=""
                  src="/account2.svg"
                />
                <div className={styles.home5}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.topNavigationParent}>
        <div className={styles.topNavigation}>
          <div className={styles.pageTitle}>
            <div className={styles.merge}>
              <div className={styles.icon} style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconschevronright.svg"
                />
              </div>
              <b className={styles.subscriptions}>Help</b>
            </div>
            <div className={styles.border} />
          </div>
        </div>
        <div className={styles.titles}>
          <div className={styles.back}  onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.notifications}>Help</div>
        </div>
        <div className={styles.help1}>
       
          <div className={styles.helpInner}>
            <img className={styles.frameChild} alt="" src="./group-66931.svg" />
          </div>
          <div className={styles.form}>
            <div className={styles.frequentlyAskedQuestionsParent}>
              <div className={styles.frequentlyAskedQuestions}>
                Frequently Asked Questions
              </div>
              <div
                className={styles.largeButtons}
                onClick={onLargeButtonsContainerClick}
              >
                <img
                  className={styles.iconsmessage1}
                  alt=""
                  src="/iconsmessage3.svg"
                />
                <b className={styles.label}>Visit FAQ Page</b>
                <img
                  className={styles.iconsadd}
                  alt=""
                  src="/iconsmessage2.svg"
                />
              </div>
            </div>
            <div className={styles.frequentlyAskedQuestionsParent}>
              <div className={styles.contactUsParent}>
                <div className={styles.frequentlyAskedQuestions}>
                  Contact Us
                </div>
                <div className={styles.cantFindYour}>
                  Can’t find your answers in FAQ’S
                </div>
              </div>
              <div className={styles.largeButtonsParent}>
                <div className={styles.largeButtons1} style={{cursor:'pointer'}} onClick={handleChatButtonClick}>
                  <img
                    className={styles.iconsmessage1}
                    alt=""
                    src="/iconsmessage3.svg"
                  />
                  <b className={styles.label}>Chat Us</b>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsmessage4.svg"
                  />
                </div>
                <div className={styles.largeButtons2} style={{cursor:'pointer'}} onClick={openMail}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd4.svg"
                  />
                  <b className={styles.label}>Send a mail</b>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd5.svg"
                  />
                </div>
                <div className={styles.largeButtons2}  style={{cursor:'pointer'}} onClick={handleTweetClick}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd1.svg"
                  />
                  <b className={styles.label}>Tweet Us</b>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd2.svg"
                  />
                </div>
                <div className={styles.largeButtons2} style={{cursor:'pointer'}} onClick={handleCallClick}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd1.svg"
                  />
                  <b className={styles.label}>Call Us</b>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd2.svg"
                  />
                </div>
              </div>
            </div>
            <div className={styles.frequentlyAskedQuestionsParent}>
              <div className={styles.contactUsParent}>
                <div className={styles.home5}>Suggestions</div>
                <div className={styles.helpUsImprove}>
                  Help us improve our app
                </div>
              </div>
              <div className={styles.inputParent}>
                <div className={styles.input}>
                <TextField
                    color="primary"
                    variant="outlined"
                    multiline
                    rows={4}
                    margin="none"
                    fullWidth
                    value={sugg}
                    onChange={(e)=>{setSugg(e.target.value)}}
                  />
                </div>
                <div className={styles.largeButtons5} 
                style={{cursor:'pointer'}}
                onClick={onSend}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsmessage5.svg"
                  />
                  <b className={styles.label}>Send</b>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsmessage6.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
