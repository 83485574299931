import { useCallback, useEffect, useState } from "react";
import { FormControlLabel, MenuItem, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./PayoutMethod.module.css";
import { auth, db } from "../firebase";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
}  from "react-places-autocomplete";
import axios from "axios";
const PayoutMethod = ({ onClose }) => {
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState("savings"); // Default to "Savings"
  const [accountName, setAccountName] = useState("");
  const [bankName, setBankName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("")
  const [addy1, setAddy1] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [city, setCity] = useState('')
  const [reg, setReg] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState('')

  const usersCollection = db.collection("users");
  const payoutCollection = usersCollection.doc(auth.currentUser.uid).collection("payout");

  const handleRadioChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleSelect = async (selectedAddress) => {
    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const selectedResult = results[0];
      const { formatted_address, address_components, geometry } = selectedResult;

      const city = getAddressComponentValue(address_components, 'locality');
      const state = getAddressComponentValue(address_components, 'administrative_area_level_1');
      const country = getAddressComponentValue(address_components, 'country');
      const zip = getAddressComponentValue(address_components, 'postal_code');

      setAddy1(formatted_address);
      setCity(city);
      setReg(state);
      setCountry(country);
      setZip(zip);
      console.log(city)
    } catch (error) {
      console.error(error);
    }
  };
  function getAddressComponentValue(addressComponents, type) {
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
  
    return component ? component.long_name : '';
  }
  const [payId, setPayId] = useState("");
  useEffect(() => {
   db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
    setPayId(snap.data().connectId)
    setName(snap.data().name)
    setEmail(snap.data().email||auth.currentUser.email)
    setPhone('+'+snap.data().phone)
   })
  }, [])
  
  const handleSaveChanges = async () => {
    if (!accountName || !bankName || !sortCode || !accountNumber) {
      alert("Please fill in all the fields.");
      return;
    }
    setLoading(true)
   /* const responseW = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/createPayout', 
    {userId:payId,name:accountName,accountNumber,sortCode}, { timeout: 10000 });
    if(!responseW.data.bankAccountId){
      setLoading(false)
      return
    }*/
    const userData={
      sortCode,
      accountNumber,
      email,
      address:address,
      city:city,
      reg:reg,
      zip:zip,
      country:country,
      name,
      phone, payId
    };
   
   
    axios.post('https://loggworks-c15c9e56f169.herokuapp.com/create-connected-account', userData)
    .then((response) => {
      const connectId = response.data.connectId;
      const payoutData = {
        accountName,
        bankName,
        sortCode,
        accountNumber,
        bankId:connectId
      };
    payoutCollection
      .add(payoutData)
      .then(() => {
        console.log("Payout data added successfully.");
        onClose();
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error adding payout data: ", error);
        setLoading(false)
      });
    })
    
  };

  return (
    <div className={styles.payoutmethod}>
      <div className={styles.editPayoutMethodParent}>
        <div className={styles.editPayoutMethod}>Edit Payout Method</div>
        <img
          className={styles.iconsclose}
          alt=""
          src="/iconsclose1.svg"
          onClick={onClose}
        />
      </div>
      {/*<div className={styles.accountTypeParent}>
        <div className={styles.accountType}>Account Type</div>
        <div className={styles.frameParent}>
      <RadioGroup
        aria-label="accountType"
        name="accountType"
        value={accountType}
        onChange={handleRadioChange}
        style={{width:'100%'}}
      >
        <div className={styles.checkingParent}>
          <div className={styles.checking}>Checking</div>
          <FormControlLabel
            className={styles.radioBtn}
            label=""
            value="checking"
            control={<Radio color="success" size="medium" />}
          />
        </div>
        <div className={styles.savingsParent}>
          <div className={styles.checking}>Savings</div>
          <FormControlLabel
            className={styles.radioBtn}
            label=""
            value="savings"
            control={<Radio color="success" size="medium" />}
          />
        </div>
      </RadioGroup>
        </div>
      </div>*/}
      <div className={styles.accountTypeParent}>
        <div className={styles.accountType}>Add Account Info</div>
        <div className={styles.frameGroup}>
          <div className={styles.inputParent}>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Account Name</div>
              </div>
              <TextField
                className={styles.baseField}
                fullWidth
                color="primary"
                variant="outlined"
                type="text"
                size="medium"
                margin="none"
                value={accountName}
                onChange={(e)=>setAccountName(e.target.value)}
              />
              <div className={styles.helperTextContainer}>
                <div className={styles.thisIsA}>This is a helper text</div>
                <div className={styles.div}>1/100</div>
              </div>
            </div>
            {/*<div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Account Address</div>
              </div>
               <PlacesAutocomplete className={styles.baseField} value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{width:'100%'}}>
          <TextField
            {...getInputProps({
              placeholder: 'Address',
              className: 'form-control',
            })}
            
            fullWidth
          />
          <Paper className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <MenuItem key={index} {...getSuggestionItemProps(suggestion, { className })}>
                  {suggestion.description}
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
              <div className={styles.helperTextContainer}>
                <div className={styles.thisIsA}>This is a helper text</div>
                <div className={styles.div}>1/100</div>
              </div>
            </div>*/}
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Bank Name</div>
              </div>
              <TextField
                className={styles.baseField}
                fullWidth
                color="primary"
                variant="outlined"
                type="text"
                size="medium"
                margin="none"
                value={bankName}
                onChange={(e)=>setBankName(e.target.value)}
              />
              <div className={styles.helperTextContainer}>
                <div className={styles.thisIsA}>This is a helper text</div>
                <div className={styles.div}>1/100</div>
              </div>
            </div>
            <div className={styles.input2}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Sort Code</div>
              </div>
              <TextField
                className={styles.baseField}
                fullWidth
                color="primary"
                variant="outlined"
                type="number"
                size="medium"
                margin="none"
                value={sortCode}
                onChange={(e)=>setSortCode(e.target.value)}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onKeyPress: (e) => {
                    const isNumber = /[0-9]/.test(e.key);
                    if (!isNumber) {
                      e.preventDefault();
                    }
                  },
                }}
              />
              <div className={styles.helperTextContainer}>
                <div className={styles.thisIsA}>This is a helper text</div>
                <div className={styles.div}>1/100</div>
              </div>
            </div>
            <div className={styles.input}>
              <div className={styles.labelContainer}>
                <div className={styles.label}>Account Number</div>
              </div>
              <TextField
                className={styles.baseField}
                fullWidth
                color="primary"
                variant="outlined"
                type="number"
                size="medium"
                margin="none"
                value={accountNumber}
                onChange={(e)=>setAccountNumber(e.target.value)}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onKeyPress: (e) => {
                    const isNumber = /[0-9]/.test(e.key);
                    if (!isNumber) {
                      e.preventDefault();
                    }
                  },
                }}
              />
              <div className={styles.helperTextContainer}>
                <div className={styles.thisIsA}>This is a helper text</div>
                <div className={styles.div}>1/100</div>
              </div>
            </div>
          </div>
          <button disabled={loading} className={styles.largeButtons} onClick={handleSaveChanges}>
            <img className={styles.iconsadd} alt="" src="/iconsadd1.svg" />
            <b className={styles.label4}>Save Changes</b>
            <img className={styles.iconsadd} alt="" src="/iconsadd2.svg" />
          </button>
        </div>
      </div>

    
    </div>
  );
};

export default PayoutMethod;
