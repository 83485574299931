import { TextField } from "@mui/material";
import styles from "./AddSkill.module.css";
import { auth, db } from "../firebase";
import { useEffect, useState } from "react";
const AddSkill = ({ onClose }) => {
  const [skill, setSkill] = useState("");
  const [price, setPrice] = useState("");

  const handleSkillChange = (event) => {
    setSkill(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (skill.trim() === '' || price.trim() === '') {
      // You can display an error message to the user here if desired
      return;
    }
  
    // Reference to the user's skills collection
    const skillsCollection = db.collection(`users/${auth.currentUser.uid}/skills`);
  
    // Add the skill and price tag to Firestore
    try {
      await skillsCollection.add({
        skill: skill,
        price: price,
      });
      // Clear the input fields after successful submission
      setSkill("");
      setPrice("");
      onClose()
    } catch (error) {
      console.error("Error adding skill to Firestore:", error);
    }
  };
  

  return (
    <div className={styles.addskill}>
      <div className={styles.addASkillOrPriceTagParent}>
        <div className={styles.addASkill}>Add a Skill or Price Tag</div>
        <img className={styles.iconsclose} alt="" src="/iconsclose1.svg" onClick={onClose}/>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.inputParent}>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>{`Skill `}</div>
            </div>
            <TextField
              className={styles.baseField}
              fullWidth
              color="primary"
              variant="outlined"
              type="text"
              placeholder="Skill"
              size="medium"
              margin="none"
              value={skill}
              onChange={handleSkillChange}
            />
          </div>
          <div className={styles.input}>
            <div className={styles.labelContainer}>
              <div className={styles.label}>Price per hour</div>
            </div>
            <TextField
              className={styles.baseField}
              color="primary"
              variant="outlined"
              type="number"
              placeholder="0"
              size="medium"
              margin="none"
              value={price}
              onChange={handlePriceChange}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                onKeyPress: (e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                },
              }}
            />
          </div>
        </div>
        <button className={styles.largeButtons} style={{cursor:'pointer'}} onClick={handleSubmit}>
          <img className={styles.iconsadd} alt="" src="/iconsadd1.svg" />
          <b className={styles.label2}>Save</b>
          <img className={styles.iconsadd} alt="" src="/iconsadd2.svg" />
        </button>
      </div>
    </div>
  );
};

export default AddSkill;
