import { useCallback, useEffect, useState } from "react";
import styles from "./Reviews.module.css";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Avatar, Rating, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { format } from 'date-fns';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#2FA500' : '#308fe8',
  },
}));


const Reviews = () => {
  const navigate = useNavigate()
  const [rating,setRating]=useState(0)
  const [review,setReview]=useState(0)
  const [allrating,setAllReview]=useState({
    five:0,
    four:0,
    three:0,
    two:0,
    one:0
  })
  const [total,setTotal]=useState(0)
  const [list_reviews,setList]=useState([])

  useEffect(() => {
    db.collection('reviews/').where('uid','==',auth.currentUser.uid).orderBy('timestamp','desc').get().then(function (snap) {
      setReview(snap.size)
      const reviewData = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setList(reviewData);
    })
    db.collection("reviews")
  .where("uid", "==", auth.currentUser.uid)
  .get()
  .then((querySnapshot) => {
    let totalRating = 0;
    let numberOfReviews = querySnapshot.size;
    let counts = {
      five: 0,
      four: 0,
      three: 0,
      two: 0,
      one: 0,
    };
   

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      // Assuming 'stars' is the field containing the star rating in each document
      const rating = data.stars;

      // Increment the count for the corresponding rating
      if (rating === 5) {
        counts.five += 1;
      } else if (rating === 4) {
        counts.four += 1;
      } else if (rating === 3) {
        counts.three += 1;
      } else if (rating === 2) {
        counts.two += 1;
      } else if (rating === 1) {
        counts.one += 1;
      }
      totalRating += rating;
    });
    setAllReview(counts)
    if (numberOfReviews > 0) {
      console.log(totalRating)
      setTotal(totalRating)
      const avgRating = totalRating / numberOfReviews;
      setRating(avgRating.toFixed(1));
    }
  })
  .catch((error) => {
    console.error("Error getting reviews: ", error);
  });
 
  }, [])
  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, []);

  const onDefault1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefault2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefault3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefault4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDefault5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp.toDate());
  
    // Format the date part (e.g., "09 Jun 2021")
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Format the time part (e.g., "9:30 AM")
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
  
    // Combine the date and time with "|"
    return `${formattedDate} | ${formattedTime}`;
  }
 
  return (
    <div className={styles.reviews}>
      <div className={styles.navPrototype}>
        <div className={styles.nav}>
          <img className={styles.navChild} alt="" src="/frame-66522.svg" />
          <div className={styles.defaultParent}>
            <div className={styles.default} onClick={onDefaultContainerClick}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconshome.svg" />
                <div className={styles.home}>Home</div>
              </div>
            </div>
            <div className={styles.default1} style={{cursor:'pointer'}} onClick={onDefault1Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.iconshome} alt="" src="/iconsjobs.svg" />
                <div className={styles.home1}>Jobs</div>
              </div>
            </div>
            <div className={styles.default2} onClick={onDefault2Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsactivity.svg"
                />
                <div className={styles.home1}>Activities</div>
              </div>
            </div>
            <div className={styles.default1} style={{cursor:'pointer'}} onClick={onDefault3Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconslog-cash.svg"
                />
                <div className={styles.home1}>Wallet</div>
              </div>
            </div>
            <div className={styles.default2} style={{cursor:'pointer'}} onClick={onDefault4Click}>
              <div className={styles.iconshomeParent}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconsmessage.svg"
                />
                <div className={styles.home1}>Message</div>
              </div>
            </div>
            <div className={styles.default1} style={{cursor:'pointer'}} onClick={onDefault5Click}>
              <div className={styles.iconshomeParent}>
                <img className={styles.accountIcon} alt="" src="/account.svg" />
                <div className={styles.home1}>Profile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.component102Parent}>
        <div className={styles.component102}>
          <div className={styles.smallButtons}>
            <div className={styles.iconsaddParent}>
              <img className={styles.iconsadd} alt="" src="/iconsadd.svg" />
              <div className={styles.button}>Add Item</div>
            </div>
          </div>
          <img className={styles.avatarIcon} alt="" src="/ellipse-252@2x.svg" />
          <b className={styles.licenseAndCertification}>Reviews</b>
          <div className={styles.icon} onClick={()=>navigate(-1)}>
            <img
              className={styles.iconshome}
              alt=""
              src="/iconschevronright.svg"
              
            />
          </div>
        </div>
        <div className={styles.titles}>
          <div className={styles.back} onClick={()=>navigate(-1)}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.activities}>Reviews</div>
        </div>
        <div className={styles.frameParent}>
          <div className={styles.groupParent}>
            <div className={styles.groupContainer}>
              <div className={styles.parent}>
                <div className={styles.div}>
                  <span>{` `}{rating}</span>
                  <span className={styles.span}>{` `}</span>
                </div>
                <div className={styles.reviews1}>({total} Reviews)</div>
              </div>
              
              <Rating name="read-only" value={rating} readOnly style={{color:'#2FA500'}}/>
            </div>
            <div className={styles.reviewBarParent}>
              <div className={styles.reviewBar}>
                <div className={styles.stars}>5 Stars</div>
                <div className={styles.wrapper}>
                <BorderLinearProgress variant="determinate" value={allrating.five} max={review}/>
                </div>
                <div className={styles.div2}>({allrating.five})</div>
              </div>
              <div className={styles.reviewBar}>
                <div className={styles.stars}>4 Stars</div>
                <div className={styles.wrapper}>
                <BorderLinearProgress variant="determinate" value={allrating.four} />
                </div>
                <div className={styles.div2}>({allrating.four})</div>
              </div>
              <div className={styles.reviewBar}>
                <div className={styles.stars}>3 Stars</div>
                <div className={styles.wrapper}>
                <BorderLinearProgress variant="determinate" value={allrating.three} />
                </div>
                <div className={styles.div2}>({allrating.three})</div>
              </div>
              <div className={styles.reviewBar}>
                <div className={styles.stars}>2 Stars</div>
                <div className={styles.wrapper}>
                <BorderLinearProgress variant="determinate" value={allrating.two} />
                </div>
                <div className={styles.div2}>({allrating.two})</div>
              </div>
              <div className={styles.reviewBar}>
                <div className={styles.stars}>1 Star</div>
                <div className={styles.wrapper}>
                <BorderLinearProgress variant="determinate" value={allrating.one} />
                </div>
                <div className={styles.div2}>({allrating.one})</div>
              </div>
            </div>
          </div>
          <div className={styles.frameWrapper}>
            {
                list_reviews.map(data=>{
                  return(
                  <div className={styles.reviewsParent}>
              <div className={styles.reviews2}>
                <div className={styles.frameGroup}>
                 <UserInfo
                 cid={data.cid}
                 timestamp={formatTimestamp(data.timestamp)}
                 />
                  <div className={styles.starRatingWrapper}>
                    <div className={styles.starRating}>
                     <Rating name="read-only" value={data.stars}  readOnly style={{color:'#2FA500'}}/>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.iAmEnjoying}
                >{data.textReview}</div>
              </div>
              <div className={styles.frameChild} />
            </div>
                  )
                })
            }
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;

const UserInfo = ({ cid, timestamp }) => {
  const [customer, setCustomer] = useState({ fname: "", lname: "", profileImage: "" });

  useEffect(() => {
    db.collection('customers').doc(cid).get().then(function (snap) {
      const data = snap.data();
      setCustomer(data);
    });
  }, [cid]);

  return (
    <div className={styles.avatarParent}>
      <Avatar className={styles.avatarIcon1} alt="" src={customer?.profileImage || ""} />
      <div className={styles.peoplenameParent}>
        <div className={styles.peoplename}>{`${customer?.fname} ${customer?.lname}`}</div>
        <div className={styles.jun2021}>{timestamp}</div>
      </div>
    </div>
  );
};