import { useState, useCallback, useEffect } from "react";
import IDproofModal from "./IDproofModal";
import PortalPopup from "./PortalPopup";
import AddressproofModal from "./AddressproofModal";
import { useNavigate } from "react-router-dom";
import styles from "./IdModal.module.css";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { auth, db } from "../firebase";
import { useStripe } from "@stripe/react-stripe-js";
import posthog from 'posthog-js'

 

const stripePromise = loadStripe('pk_live_51OKRh6Dk2CQnAQVzdZ1sazYAyHpXmWJPXZwnZ0AYNdiXUO2nKH8OdQy3UjK74s4Yu6ln2qXbBMQVyNaBtKjAziGP00RTMN49iC');
const IdModal = ({ onClose }) => {
  const [isIDproofModalPopupOpen, setIDproofModalPopupOpen] = useState(false);
  const [isAddressproofModalPopupOpen, setAddressproofModalPopupOpen] =
    useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [city, setCity] = useState('')
  const [reg, setReg] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState('')
  const [dob, setDob] = useState('')
  const [payId, setPayId] = useState("");
  const [loading, setLoading]=useState(false)
  const stripe = useStripe()
  useEffect(() => {
   db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
    setPayId(snap.data().connectId)
    setName(snap.data().name)
    setEmail(snap.data().email||auth.currentUser.email)
    setPhone('+'+snap.data().phone)
    setCity(snap.data().city)
    setReg(snap.data().reg)
    setZip(snap.data().zip)
    setCountry(snap.data().country)
    setAddress(snap.data().address)
    setDob(snap.data().dob)
   })
  }, [])
  function divideDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = date.getDate();
  
    return { year, month, day };
  }
 
  const handleSubmit = async () =>
  {
    
    if (!stripePromise) {
      // Stripe.js hasn't loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }
    if(loading){
      return
    }
setLoading(true)
    const dateParts = divideDate(dob);
    const userData={
      email,
      address:address,
      city:city,
      reg:reg,
      zip:zip,
      country:country,
      name,
      phone,
      mm:dateParts.month,
      dd: dateParts.day,
      yyy:dateParts.year,
      payId
    };
    axios.post('https://loggworks-c15c9e56f169.herokuapp.com/verify-connected-account', userData)
    .then((response) => {
      const connectId = response.data.connectId;
      const client= response.data.clientSecret
      db.collection('users').doc(auth.currentUser.uid).update({
        connectId
      })
      try{
  const { error} = stripe.verifyIdentity(client);

    if (error) {
      console.log('[error]', error);
      setLoading(false)
    } else {
      setLoading(false)
    const response = fetch('https://loggworks-c15c9e56f169.herokuapp.com/webhook', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
   
  });
  if (!response.ok) {
    // Check if the response status is not OK (e.g., 400 Bad Request)
    console.error('Webhook request failed:', response.status);
    return;
  }
  const result = response.json();

// Check if the result is true or false
if (result === true) {
  const usersCollection = db.collection("users");
  
  // Get a reference to the 'verification' subcollection within the user's document
  const verificationCollection = usersCollection.doc(auth.currentUser.uid).collection("proofID");

  // Define the data you want to add
  const certificateData = {
    certificationName: 'ID proof verified',
    expirationDate: '',
  };
  verificationCollection.add(certificateData)
  .then((docRef) => {
    verificationCollection.get().then((querySnapshot) => {
      if (querySnapshot.size <= 1) {
        usersCollection.doc(auth.currentUser.uid).update({
          verification: firebase.firestore.FieldValue.increment(15)})
      } 
    });
   
    posthog.capture('Verification of ID completed', { IDProof:true})
    onClose()
  })
  .catch((error) => {
    console.error("Error adding certificate: ", error);
  });
} 
    }
      }
      catch(e){

      }
      
    })
  }

  const openIDproofModalPopup = useCallback(() => {
    setIDproofModalPopupOpen(true);
  }, []);

  const closeIDproofModalPopup = useCallback(() => {
    setIDproofModalPopupOpen(false);
  }, []);

  const openAddressproofModalPopup = useCallback(() => {
    setAddressproofModalPopupOpen(true);
  }, []);

  const closeAddressproofModalPopup = useCallback(() => {
    setAddressproofModalPopupOpen(false);
  }, []);

  return (
    <>
      <div className={styles.idmodal}>
        <div className={styles.modalTitleParent}>
          <div className={styles.modalTitle}>
            <div className={styles.addANewCardParent}>
              <div className={styles.addANew}>Add Item</div>
              <img
                className={styles.iconsclose}
                alt=""
                src="/iconsclose1.svg"
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.listParent}>
            <div className={styles.list} onClick={handleSubmit}>
              <div className={styles.content}>
                <div className={styles.left}>
                  <img
                    className={styles.iconWithBg}
                    alt=""
                    src="/icon-with-bg.svg"
                  />
                  <img
                    className={styles.iconsplaceholder}
                    alt=""
                    src="/iconsplaceholder6.svg"
                  />
                  <div className={styles.list1}>ID Proof</div>
                </div>
                <div className={styles.right}>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevronright11.svg"
                  />
                  <div className={styles.toggle}>
                    <div className={styles.toggleChild} />
                    <div className={styles.toggleItem} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.list2} onClick={openAddressproofModalPopup}>
              <div className={styles.content}>
                <div className={styles.left}>
                  <img
                    className={styles.iconWithBg}
                    alt=""
                    src="/icon-with-bg1.svg"
                  />
                  <img
                    className={styles.iconsplaceholder}
                    alt=""
                    src="/iconsplaceholder6.svg"
                  />
                  <div className={styles.list1}>Address Proof</div>
                </div>
                <div className={styles.right}>
                  <img
                    className={styles.iconschevronRight}
                    alt=""
                    src="/iconschevronright11.svg"
                  />
                  <div className={styles.toggle}>
                    <div className={styles.toggleChild} />
                    <div className={styles.toggleItem} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isIDproofModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeIDproofModalPopup}
        >
          <IDproofModal onClose={closeIDproofModalPopup} />
        </PortalPopup>
      )}
      {isAddressproofModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAddressproofModalPopup}
        >
          <AddressproofModal onClose={closeAddressproofModalPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default IdModal;
