import { useState, useCallback, useEffect } from "react";
import AddPaymetCard from "../components/AddPaymetCard";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./PaymentMethod.module.css";
import { Radio } from "@mui/material";
import { auth, db } from "../firebase";
import { Menu, MenuItem } from "@mui/material";

const PaymentMethod = () => {
  const [isAddPaymetCardPopupOpen, setAddPaymetCardPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [cards, setCards] = useState([]); 
  const [defaultCard, setDefaultCard] = useState(null);
  const [selected, setSelected]=useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");
  const updateDefaultCardInFirestore = (cardId) => {
    const userRef = db.collection("users").doc(auth.currentUser.uid);
    userRef.update({
      defaultCardId: cardId,
    });
  };

  // Function to fetch the default card ID from Firestore
  const fetchDefaultCardFromFirestore = async () => {
    const userRef = db.collection("users").doc(auth.currentUser.uid);
    const userDoc = await userRef.get();
    if (userDoc.exists) {
      const userData = userDoc.data();
      const defaultCardId = userData.defaultCardId;
      setDefaultCard(defaultCardId);
    }
  };

  useEffect(() => {
    fetchDefaultCardFromFirestore()
    // Use onSnapshot to listen for real-time changes in the "cards" subcollection
    const unsubscribe = cardsCollection.onSnapshot((querySnapshot) => {
      const cardsData = [];
      querySnapshot.forEach((doc) => {
        cardsData.push({ id: doc.id, ...doc.data() });
      });
      setCards(cardsData);
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const onDefaultContainerClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultContainer1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefaultContainer2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefaultContainer3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefaultContainer4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDropdownListContainerClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const openAddPaymetCardPopup = useCallback(() => {
    setAddPaymetCardPopupOpen(true);
  }, []);

  const closeAddPaymetCardPopup = useCallback(() => {
    setAddPaymetCardPopupOpen(false);
  }, []);

  function trimCardNumberToLast4Digits(cardNumber) {
    if (typeof cardNumber !== "string") {
      console.error("Invalid card number format. Expected a string.");
      return cardNumber; // Return the input as is if it's not a string
    }
  
    // Use string slicing to get the last 4 digits of the card number
    const last4Digits = cardNumber.slice(-4);
    return last4Digits;
  }
  const handleRadioChange = (event) => {
    const cardId = event.target.value;
    setDefaultCard(cardId);
    updateDefaultCardInFirestore(cardId); // Update default card in Firestore
  };

  return (
    <>
      <div className={styles.paymentmethod}>
        <div className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665221.svg" />
            <div className={styles.defaultParent}>
              <div className={styles.default} onClick={onDefaultContainerClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome3.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer1Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home}>Jobs</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer2Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home}>Activities</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer3Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home}>Wallet</div>
                </div>
              </div>
              <div
                className={styles.default}
                onClick={onDefaultContainer4Click}
              >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home}>Message</div>
                </div>
              </div>
              <div className={styles.default5}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account2.svg"
                  />
                  <div className={styles.home5}>Profile</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dropdown}>
          <div
            className={styles.dropdownList}
            onClick={onDropdownListContainerClick}
          >
            <div className={styles.listParent}>
              <div className={styles.list}>Edit details</div>
            </div>
          </div>
          <div className={styles.dropdownList1}>
            <div className={styles.listParent}>
              <div className={styles.list}>Remove Card</div>
            </div>
          </div>
        </div>
        <div className={styles.component102Parent}>
          <div className={styles.component102}>
            <div className={styles.smallButtons}>
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
                <div className={styles.button}>Add Item</div>
              </div>
            </div>
            <b className={styles.licenseAndCertification}>Payments methods</b>
            <div className={styles.icon} style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
          </div>
          <div className={styles.titles}>
            <div className={styles.back} onClick={()=>navigate(-1)}>
              <div className={styles.back1}>Back</div>
              <img className={styles.frameIcon} alt="" src="/frame.svg" />
            </div>
            <div className={styles.notifications}>Payment methods</div>
          </div>
          <div className={styles.title}>
            <div className={styles.savedCards}>Saved Cards</div>
          </div>
          <div
            className={styles.savedCardsParent}>
              {cards.length > 0 && (
        <div>
          {cards.map((card) => (
            <>
             <div className={styles.savedCards1} onClick={()=>{}}>
             <div className={styles.crosbyOdennehyParent}>
               <div className={styles.home}>{card.nameOnCard}</div>
               <div className={styles.transactionPills}>
                 <div className={styles.success}>{defaultCard === card.id?'DEFAULT':''}</div>
               </div>
             </div>
             <img
               className={styles.iconsmoreVertical}
               alt=""
               src="/iconsmore-vertical1.svg"
               style={{cursor:'pointer'}}
                id={card.id}
                aria-controls={open ? card.id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e)=>{
                  setSelected(card.id)
                  handleClick(e)
                }}
             />
             <div className={styles.div}>**** **** **** {trimCardNumberToLast4Digits(card.cardNumber)}</div>
             <div className={styles.mastercard1Parent}>
               <img
                 className={styles.mastercard1Icon}
                 alt=""
                 src="/mastercard-1.svg"
               />
               <Radio
                value={card.id}
                checked={defaultCard === card.id}
                onChange={handleRadioChange}
               />
             </div>
           </div>
           <br></br>
            </>
            ))}
        </div>
      )}
            
            <div
              className={styles.largeButtons}
              onClick={openAddPaymetCardPopup}
            >
              <img className={styles.iconsadd1} alt="" src="/iconsadd1.svg" />
              <b className={styles.label}>Add Another Card</b>
              <img className={styles.iconsadd2} alt="" src="/iconsadd2.svg" />
            </div>
          </div>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem style={{color:'red'}} onClick={()=>{
          db.collection('users').doc(auth.currentUser.uid).collection('cards').doc(selected).delete()
          handleClose()
          }}>Delete</MenuItem>
      </Menu>
      {isAddPaymetCardPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAddPaymetCardPopup}
        >
          <AddPaymetCard onClose={closeAddPaymetCardPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentMethod;
