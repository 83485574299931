import { useCallback } from "react";
import { Avatar, Menu, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import styles from "./MyQuote.module.css";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import PortalPopup from "../components/PortalPopup";
import SendQuote from "../components/SendQuote";
const MyQuote = () => {
  const navigate = useNavigate();
  const [draft_list, setDraft]=useState([]);
  const [acc_list, setAcc]=useState([]);
  const [rej_list, setRej]=useState([]);
  const [pend_list, setPend]=useState([]);
  const [all_list, setAll]=useState([]);
  const [tab, setTab] = useState(0)
  const [isSendQuotePopupOpen, setSendQuotePopupOpen] = useState(false);
  const [selected, setSelected]=useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
   getQoute()
  }, [])
  const openSendQuotePopup = useCallback(() => {
    setSendQuotePopupOpen(true);
  }, []);

  const closeSendQuotePopup = useCallback(() => {
    setSendQuotePopupOpen(false);
  }, []);
 const getQoute = () => {
  if (auth.currentUser) {
    // Reference to the collection
    const draftRef = db.collection('my_draft/' + auth.currentUser.uid + '/list/');

    // Function to get data, filter by status and order by date
    const fetchDataByStatus = (status, setState) => {
      draftRef
        .where('status', '==', status)
        .onSnapshot(snapshot => {
          const cald = snapshot.docs.map(doc => doc.data());
          // Sort the array by date in descending order
          const sortedData = cald.sort((a, b) => new Date(b.due) - new Date(a.due));
          setState(sortedData);
        });
    };

    // Fetch data for each status
    fetchDataByStatus('acc', setAcc);
    fetchDataByStatus('decl', setRej);
    fetchDataByStatus('pend', setPend);
    fetchDataByStatus('draft', setDraft);

    // Fetch all data without filtering by status
    draftRef.onSnapshot(snapshot => {
      const cald = snapshot.docs.map(doc => doc.data());
      // Sort the array by date in descending order
      const sortedData = cald.sort((a, b) => new Date(b.due) - new Date(a.due));
      setAll(sortedData);
    });
  }
};

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    if (term=='') {
      getQoute()
    }
    else{
// Filter quotes based on the search term
const filtered = all_list.filter((quote) => {
  return (
    quote.cname.toLowerCase().includes(term) || // Search by name
    quote.qkey.toLowerCase().includes(term) // Search by qkey
  );
});

setAll(filtered);
setDraft(filtered);
setAcc(filtered);
setRej(filtered);
setPend(filtered);
    }
  };
  const onDefaultClick = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefault2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefault3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefault4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDefault5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onSmallButtonsClick = useCallback(() => {
   navigate('/createquote/new')
  }, []);

  const onIconClick = useCallback(() => {
    navigate('/home');
  }, [navigate]);
  

  return (
    <div className={styles.myquote}>
      <div className={styles.wallet}>
        <div className={styles.titles}>
          <div className={styles.back} onClick={onIconClick}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.activities}>Quote</div>
        </div>
        <div className={styles.topBar}>
          <div className={styles.input} style={{width:'100%',margin:'0px 1rem'}}>
            <TextField
                className={styles.input1}
                fullWidth
                color="primary"
                variant="outlined"
                type="text"
                placeholder="Search by name or quote number"
                size="small"
                margin="none"
                onChange={handleSearch}
              />
          </div>
          <div className={styles.quotesBottomTab}>
            <div className={styles.titles1}>
              <div className={tab==0?styles.draftParent:styles.accepted} onClick={()=>{setTab(0)}}>
                <div className={styles.accepted}>Drafts</div>
                <div className={tab==0?styles.frameChild:null} style={tab==0?{width:'100%'}:{}}/>
              </div>
              <div className={tab==1?styles.draftParent:styles.accepted} onClick={()=>{setTab(1)}}>
                <div className={styles.accepted}>Accepted</div>
                <div className={tab==1?styles.frameChild:null} style={tab==1?{width:'100%'}:{}}/>
              </div>
              <div className={tab==2?styles.draftParent:styles.accepted} onClick={()=>{setTab(2)}}>
                <div className={styles.accepted}>Rejected</div>
                <div className={tab==2?styles.frameChild:null} style={tab==2?{width:'100%'}:{}}/>
              </div>
              <div className={tab==3?styles.draftParent:styles.accepted} onClick={()=>{setTab(3)}}>
                <div className={styles.accepted}>Pending</div>
                <div className={tab==3?styles.frameChild:null} style={tab==3?{width:'100%'}:{}}/>
              </div>
              <div className={tab==4?styles.draftParent:styles.accepted} onClick={()=>{setTab(4)}}>
                <div className={styles.all}>All</div>
                <div className={tab==4?styles.frameChild:null} style={tab==4?{width:'100%'}:{}}/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.quoteParent}>
           {
        tab==0?
        <>
          {draft_list
              .map((data) => {
                return (
                 <div className={styles.quote}>
            <div className={styles.frameParent}>
              <div className={styles.quoteNoQn001Parent}>
                <div className={styles.quoteNoQn001Container}>
                  <span className={styles.quoteNoQn001Container1}>
                    <span className={styles.quoteNo}>{`Quote No: `}</span>
                    <span className={styles.qn001}>{data.qkey}</span>
                  </span>
                </div>
                <img
                  className={styles.moreVerticalIcon}
                  alt=""
                  src="/morevertical.svg"
                  style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                />
              </div>
              <div className={styles.ellipseParent}>
                <Avatar
                src={data.profile||''}
                />
                <div className={styles.frameGroup}>
                  <div className={styles.peoplenameParent}>
                    <b className={styles.peoplename}>{data.cname}</b>
                    <div className={styles.statusParent}>
                      <div className={styles.status}>
                        <div className={styles.success}>
                          <div className={styles.paidWrapper}>
                            <b className={styles.paid}>PAID</b>
                          </div>
                        </div>
                      </div>
                      <div className={styles.success1}>
                        <div className={styles.cancelledWrapper}>
                          <b className={styles.paid}>CANCELLED</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.peoplenameGroup}>
                    <div className={styles.peoplename1}>
                     {data.jdate} | {data.jtime}
                    </div>
                    <b className={styles.peoplename2}>£{data.total}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteChild} />
          </div>
                );
              })}
        </>
        :tab==1?
        <>
  {acc_list
              .map((data) => {
                return (
                <div className={styles.quote}>
            <div className={styles.frameParent}>
              <div className={styles.quoteNoQn001Parent}>
                <div className={styles.quoteNoQn001Container}>
                  <span className={styles.quoteNoQn001Container1}>
                    <span className={styles.quoteNo}>{`Quote No: `}</span>
                    <span className={styles.qn001}>{data.qkey}</span>
                  </span>
                </div>
                <img
                  className={styles.moreVerticalIcon}
                  alt=""
                  src="/morevertical.svg"
                  style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                />
              </div>
              <div className={styles.ellipseParent}>
              <Avatar
                src={data.profile||''}
                />
                <div className={styles.frameGroup}>
                  <div className={styles.peoplenameParent}>
                    <b className={styles.peoplename}>{data.cname}</b>
                    <div className={styles.statusParent}>
                      <div className={styles.status}>
                        <div className={styles.success}>
                          <div className={styles.paidWrapper}>
                            <b className={styles.paid}>PAID</b>
                          </div>
                        </div>
                      </div>
                      <div className={styles.success1}>
                        <div className={styles.cancelledWrapper}>
                          <b className={styles.paid}>CANCELLED</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.peoplenameGroup}>
                    <div className={styles.peoplename1}>
                     {data.jdate} | {data.jtime}
                    </div>
                    <b className={styles.peoplename2}>£{data.total}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteChild} />
          </div>
                );
              })}
        </>
        :tab==2?
        <>
  {rej_list
              .map((data) => {
                return (
                  <div className={styles.quote}>
            <div className={styles.frameParent}>
              <div className={styles.quoteNoQn001Parent}>
                <div className={styles.quoteNoQn001Container}>
                  <span className={styles.quoteNoQn001Container1}>
                    <span className={styles.quoteNo}>{`Quote No: `}</span>
                    <span className={styles.qn001}>{data.qkey}</span>
                  </span>
                </div>
                <img
                  className={styles.moreVerticalIcon}
                  alt=""
                  src="/morevertical.svg"
                  style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                />
              </div>
              <div className={styles.ellipseParent}>
              <Avatar
                src={data.profile||''}
                />
                <div className={styles.frameGroup}>
                  <div className={styles.peoplenameParent}>
                    <b className={styles.peoplename}>{data.cname}</b>
                    <div className={styles.statusParent}>
                      <div className={styles.status}>
                        <div className={styles.success}>
                          <div className={styles.paidWrapper}>
                            <b className={styles.paid}>PAID</b>
                          </div>
                        </div>
                      </div>
                      <div className={styles.success1}>
                        <div className={styles.cancelledWrapper}>
                          <b className={styles.paid}>CANCELLED</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.peoplenameGroup}>
                    <div className={styles.peoplename1}>
                     {data.jdate} | {data.jtime}
                    </div>
                    <b className={styles.peoplename2}>£{data.total}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteChild} />
          </div>
                );
              })}
        </>
        :tab==3?
        <>
  {pend_list
              .map((data) => {
                return (
                <div className={styles.quote}>
            <div className={styles.frameParent}>
              <div className={styles.quoteNoQn001Parent}>
                <div className={styles.quoteNoQn001Container}>
                  <span className={styles.quoteNoQn001Container1}>
                    <span className={styles.quoteNo}>{`Quote No: `}</span>
                    <span className={styles.qn001}>{data.qkey}</span>
                  </span>
                </div>
                <img
                  className={styles.moreVerticalIcon}
                  alt=""
                  src="/morevertical.svg"
                  style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                />
              </div>
              <div className={styles.ellipseParent}>
              <Avatar
                src={data.profile||''}
                />
                <div className={styles.frameGroup}>
                  <div className={styles.peoplenameParent}>
                    <b className={styles.peoplename}>{data.cname}</b>
                    <div className={styles.statusParent}>
                      <div className={styles.status}>
                        <div className={styles.success}>
                          <div className={styles.paidWrapper}>
                            <b className={styles.paid}>PAID</b>
                          </div>
                        </div>
                      </div>
                      <div className={styles.success1}>
                        <div className={styles.cancelledWrapper}>
                          <b className={styles.paid}>CANCELLED</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.peoplenameGroup}>
                    <div className={styles.peoplename1}>
                     {data.jdate} | {data.jtime}
                    </div>
                    <b className={styles.peoplename2}>£{data.total}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteChild} />
          </div>
                );
              })}
        </>
        :
        <>
  {all_list
              .map((data) => {
                return (
                <div className={styles.quote}>
            <div className={styles.frameParent}>
              <div className={styles.quoteNoQn001Parent}>
                <div className={styles.quoteNoQn001Container}>
                  <span className={styles.quoteNoQn001Container1}>
                    <span className={styles.quoteNo}>{`Quote No: `}</span>
                    <span className={styles.qn001}>{data.qkey}</span>
                  </span>
                </div>
                <img
                  className={styles.moreVerticalIcon}
                  alt=""
                  src="/morevertical.svg"
                  style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                />
                  
              </div>
              <div className={styles.ellipseParent}>
              <Avatar
                src={data.profile||''}
                />
                <div className={styles.frameGroup}>
                  <div className={styles.peoplenameParent}>
                    <b className={styles.peoplename}>{data.cname}</b>
                    <div className={styles.statusParent}>
                      <div className={styles.status}>
                        <div className={styles.success}>
                          <div className={styles.paidWrapper}>
                            <b className={styles.paid}>PAID</b>
                          </div>
                        </div>
                      </div>
                      <div className={styles.success1}>
                        <div className={styles.cancelledWrapper}>
                          <b className={styles.paid}>CANCELLED</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.peoplenameGroup}>
                    <div className={styles.peoplename1}>
                     {data.jdate} | {data.jtime}
                    </div>
                    <b className={styles.peoplename2}>£{data.total}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.quoteChild} />
          </div>
                );
              })}
        </>
      }
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={()=>{
           navigate('/createquote/'+selected)
          handleClose()
          }}>Edit Quote</MenuItem>
        <MenuItem onClick={()=>{
           openSendQuotePopup()
          handleClose()
          }}>Send Quote</MenuItem>
        <MenuItem style={{color:'red'}} onClick={()=>{
          db.collection('my_draft/'+auth.currentUser.uid+'/list/').doc(selected).delete()
          handleClose()
          }}>Delete Quote</MenuItem>
      </Menu>
        </div>
      </div>
      <div className={styles.myquotes}>
        <nav className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <button className={styles.default} >
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.moreVerticalIcon}
                    alt=""
                    src="/iconshome1.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefaultClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.moreVerticalIcon}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home1}>Jobs</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault2Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.moreVerticalIcon}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home1}>Activities</div>
                </div>
              </button>
              <button className={styles.default1} onClick={()=>{
                navigate('/wallet')
              }}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.moreVerticalIcon}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home1}>Wallet</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault4Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.moreVerticalIcon}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home1}>Message</div>
                </div>
              </button>
              <button className={styles.default1} onClick={()=>{
                navigate('/profile')
              }}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home1}>Profile</div>
                </div>
              </button>
            </div>
          </div>
        </nav>
        <div className={styles.myquotesInner}>
          <div className={styles.component102Parent}>
            <div className={styles.component102}>
              <button
                className={styles.smallButtons}
                onClick={onSmallButtonsClick}
              >
                <div className={styles.iconsaddParent}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd11.svg"
                  />
                  <div className={styles.button}>Create Quote</div>
                </div>
              </button>
              <img className={styles.avatarIcon} alt="" src="/avatar60.svg" />
              <b className={styles.licenseAndCertification}>Quotes</b>
              <button className={styles.icon} onClick={onIconClick}>
                <img
                  className={styles.moreVerticalIcon}
                  alt=""
                  src="/iconschevronright.svg"
                />
              </button>
            </div>
            <div className={styles.inputParent}>
              <TextField
                className={styles.input1}
                sx={{ width: 558 }}
                color="primary"
                variant="outlined"
                type="text"
                placeholder="Search by name or quote number"
                size="medium"
                margin="none"
                onChange={handleSearch}
              />
              <div className={styles.frameGroup}>
                <div className={styles.tabsSubSwitch}>
                  <div className={styles.allParent}>
                    <div className={tab==0?styles.all1:styles.payment} onClick={()=>{setTab(0)}}>
                      <div className={tab==0?styles.booking:styles.booking2}>Drafted</div>
                    </div>
                    <div
                     className={tab==1?styles.all1:styles.payment} onClick={()=>{setTab(1)}}
                    >
                      <div className={tab==1?styles.booking:styles.booking2}>Accepted</div>
                    </div>
                    <div
                     className={tab==2?styles.all1:styles.payment} onClick={()=>{setTab(2)}}
                    >
                      <div className={tab==2?styles.booking:styles.booking2}>Rejected</div>
                    </div>
                    <div
                      className={tab==3?styles.all1:styles.payment} onClick={()=>{setTab(3)}}
                    >
                      <div className={tab==3?styles.booking:styles.booking2}>Pending</div>
                    </div>
                    <div
                      className={tab==4?styles.all1:styles.payment} onClick={()=>{setTab(4)}}
                    >
                      <div className={tab==4?styles.booking:styles.booking2}>All</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.quoteWrapper}>
              {
        tab==0?
        <>
          {draft_list
              .map((data) => {
                return (
               <div className={styles.quote2}>
                  <div className={styles.jobs}>
                    <div className={styles.mergeParent}>
                      <div className={styles.merge}>
                        <div className={styles.citiessummary}>
                          <span>{`Quote No: `}</span>
                          <span className={styles.span}>{data.qkey}</span>
                        </div>
                        <div className={styles.transactionPills}>
                          <div className={styles.success4}>SUCCESS</div>
                        </div>
                      </div>
                      <img
                        className={styles.moreVerticalIcon}
                        alt=""
                        src="/iconsmore-vertical1.svg"
                        style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                      />
                    </div>
                    <div className={styles.container}>
                      <div className={styles.profileWrapper}>
                        <div className={styles.profile}>
                        <Avatar
                src={data.profile||''}
                />
                          <div className={styles.merge1}>
                            <div className={styles.peoplename6}>
                             {data.cname}
                            </div>
                            <div className={styles.dateTime}>
                              <div className={styles.date}>{data.jdate}</div>
                              <div className={styles.dateTimeChild} />
                              <div className={styles.date}>{data.jtime}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.div}>£{data.total}</div>
                    </div>
                  </div>
                </div>
                );
              })}
        </>
        :tab==1?
        <>
  {acc_list
              .map((data) => {
                return (
                <div className={styles.quote2}>
                  <div className={styles.jobs}>
                    <div className={styles.mergeParent}>
                      <div className={styles.merge}>
                        <div className={styles.citiessummary}>
                          <span>{`Quote No: `}</span>
                          <span className={styles.span}>{data.qkey}</span>
                        </div>
                        <div className={styles.transactionPills}>
                          <div className={styles.success4}>SUCCESS</div>
                        </div>
                      </div>
                      <img
                        className={styles.moreVerticalIcon}
                        alt=""
                        src="/iconsmore-vertical1.svg"
                        style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                      />
                    </div>
                    <div className={styles.container}>
                      <div className={styles.profileWrapper}>
                        <div className={styles.profile}>
                        <Avatar
                src={data.profile||''}
                />
                          <div className={styles.merge1}>
                            <div className={styles.peoplename6}>
                             {data.cname}
                            </div>
                            <div className={styles.dateTime}>
                              <div className={styles.date}>{data.jdate}</div>
                              <div className={styles.dateTimeChild} />
                              <div className={styles.date}>{data.jtime}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.div}>£{data.total}</div>
                    </div>
                  </div>
                </div>
                );
              })}
        </>
        :tab==2?
        <>
  {rej_list
              .map((data) => {
                return (
               <div className={styles.quote2}>
                  <div className={styles.jobs}>
                    <div className={styles.mergeParent}>
                      <div className={styles.merge}>
                        <div className={styles.citiessummary}>
                          <span>{`Quote No: `}</span>
                          <span className={styles.span}>{data.qkey}</span>
                        </div>
                        <div className={styles.transactionPills}>
                          <div className={styles.success4}>SUCCESS</div>
                        </div>
                      </div>
                      <img
                        className={styles.moreVerticalIcon}
                        alt=""
                        src="/iconsmore-vertical1.svg"
                        style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                      />
                    </div>
                    <div className={styles.container}>
                      <div className={styles.profileWrapper}>
                        <div className={styles.profile}>
                        <Avatar
                src={data.profile||''}
                />
                          <div className={styles.merge1}>
                            <div className={styles.peoplename6}>
                             {data.cname}
                            </div>
                            <div className={styles.dateTime}>
                              <div className={styles.date}>{data.jdate}</div>
                              <div className={styles.dateTimeChild} />
                              <div className={styles.date}>{data.jtime}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.div}>£{data.total}</div>
                    </div>
                  </div>
                </div>
                );
              })}
        </>
        :tab==3?
        <>
  {pend_list
              .map((data) => {
                return (
              <div className={styles.quote2}>
                  <div className={styles.jobs}>
                    <div className={styles.mergeParent}>
                      <div className={styles.merge}>
                        <div className={styles.citiessummary}>
                          <span>{`Quote No: `}</span>
                          <span className={styles.span}>{data.qkey}</span>
                        </div>
                        <div className={styles.transactionPills}>
                          <div className={styles.success4}>SUCCESS</div>
                        </div>
                      </div>
                      <img
                        className={styles.moreVerticalIcon}
                        alt=""
                        src="/iconsmore-vertical1.svg"
                        style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                      />
                    </div>
                    <div className={styles.container}>
                      <div className={styles.profileWrapper}>
                        <div className={styles.profile}>
                        <Avatar
                src={data.profile||''}
                />
                          <div className={styles.merge1}>
                            <div className={styles.peoplename6}>
                             {data.cname}
                            </div>
                            <div className={styles.dateTime}>
                              <div className={styles.date}>{data.jdate}</div>
                              <div className={styles.dateTimeChild} />
                              <div className={styles.date}>{data.jtime}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.div}>£{data.total}</div>
                    </div>
                  </div>
                </div>
                );
              })}
        </>
        :
        <>
  {all_list
              .map((data) => {
                return (
              <div className={styles.quote2}>
                  <div className={styles.jobs}>
                    <div className={styles.mergeParent}>
                      <div className={styles.merge}>
                        <div className={styles.citiessummary}>
                          <span>{`Quote No: `}</span>
                          <span className={styles.span}>{data.qkey}</span>
                        </div>
                        <div className={styles.transactionPills}>
                          <div className={styles.success4}>SUCCESS</div>
                        </div>
                      </div>
                      <img
                        className={styles.moreVerticalIcon}
                        alt=""
                        src="/iconsmore-vertical1.svg"
                        style={{cursor:'pointer'}}
                  id={data.qkey}
                  aria-controls={open ? data.qkey : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e)=>{
                    setSelected(data.qkey)
                    handleClick(e)
                  }}
                      />
                    </div>
                    <div className={styles.container}>
                      <div className={styles.profileWrapper}>
                        <div className={styles.profile}>
                        <Avatar
                src={data.profile||''}
                />
                          <div className={styles.merge1}>
                            <div className={styles.peoplename6}>
                             {data.cname}
                            </div>
                            <div className={styles.dateTime}>
                              <div className={styles.date}>{data.jdate}</div>
                              <div className={styles.dateTimeChild} />
                              <div className={styles.date}>{data.jtime}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.div}>£{data.total}</div>
                    </div>
                  </div>
                </div>
                );
              })}
        </>
      }
               
              </div>
            </div>
          </div>
        </div>
      </div>
       <Fab size="medium" color="primary" aria-label="add" 
      style={{background:'green', position:'absolute',right:'5%',top:'80%'}}
      className={styles.Fab}
      onClick={onSmallButtonsClick}
      >
        <AddIcon />
      </Fab>
      {isSendQuotePopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSendQuotePopup}
        >
          <SendQuote onClose={closeSendQuotePopup} qkey={selected}/>
        </PortalPopup>
      )}
    </div>
  );
};

export default MyQuote;
