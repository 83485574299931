import { useCallback, useState } from "react";
import styles from "./Items.module.css";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../components/PortalPopup";
import AddTool from "../components/AddTool";
import cardstyle from "./CreateQuote.module.css";
const Items = ({
    tool_desc,
unit_cost,
quant,
tool_disc_t,
tool_disc,
tool_tax_t,
tool_tax,
tool_detail,
tools,
onTool_desc,
onUnit_cost,
onQuant,
onTool_disc_t,
onTool_disc,
onTool_tax_t,
onTool_tax,
onTool_detail,
onTools,
onBack
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const mateTools = tools.filter((item) => item.type == 'tool');

  const onDefaultClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onDefault1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefault2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefault3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefault4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDefault5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onQuoteCardsContainer2Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onSmallButtonsContainerClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <div className={styles.items}>
      <div className={styles.items1}>
        <nav className={styles.navPrototype}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <button className={styles.default} onClick={onDefaultClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome1.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault1Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home1}>Jobs</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault2Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home1}>Activities</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault3Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home1}>Wallet</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault4Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home1}>Message</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault5Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home1}>Profile</div>
                </div>
              </button>
            </div>
          </div>
        </nav>
        <div className={styles.component102Parent}>
          <div className={styles.component102}>
            <div
              className={styles.smallButtons}
              onClick={()=>setModal(true)}
            >
              <div className={styles.iconsaddParent}>
                <img className={styles.iconsadd} alt="" src="/iconsadd11.svg" />
                <div className={styles.button}>Tools/material</div>
              </div>
            </div>
            <img className={styles.avatarIcon} alt="" src="/avatar12111.svg" />
            <b className={styles.licenseAndCertification}>
              Tools and materials
            </b>
            <div className={styles.icon}  onClick={onBack}>
              <img
                className={styles.iconshome}
                alt=""
                src="/iconschevronright.svg"
              />
            </div>
          </div>
          {
      mateTools.length>0?
      <div style={{width:'558px'}}>
      {mateTools.map((data) => (
              <div className={cardstyle.customerInfo}>
                         <div className={cardstyle.quoteNumberParent}>
                           <div className={cardstyle.quoteNumber4}>
                            {data.desc}
                           </div>
                           <div className={cardstyle.x10}>
                             <span className={cardstyle.span}>{data.quant}</span>
                             <span className={cardstyle.x}>X</span>
                             <span className={cardstyle.span}> £{data.unit}</span>
                           </div>
                         </div>
                         <div className={cardstyle.frameGroup}>
                           <div className={cardstyle.kvPinInsulatorsParent}>
                             <div className={cardstyle.kvPinInsulators}>
                               {data.detail}
                             </div>
                             <div className={cardstyle.div}>£{data.total}</div>
                           </div>
                           <div className={cardstyle.kvPinInsulatorsParent}>
                             <div className={cardstyle.kvPinInsulators}>
                             {
                                data.disc_type=='perc'?
                               <a>Discount({data.disc}%)</a> 
                                :
                                <a>Flat({data.disc})</a> 
                              }
                              
                             </div>
                             <div className={cardstyle.div1}>-£{
                               data.disc_type=='perc'?
                               <a>{(data.quant*data.unit)*data.disc/100}</a> 
                                :
                                <a>{data.disc}</a>
                            }</div>
                           </div>
                         </div>
                       </div>
            
           ))}
     </div>
      :
      <div className={styles.medicalCaseLineParent}>
      <img
        className={styles.medicalCaseLine}
        alt=""
        src="/medical-case-line.svg"
      />
      <div className={styles.clickTheButton}>
        Click the button above to add tools and materials
      </div>
    </div>
      }
         
        </div>
      </div>
      <div className={styles.signup}>
        <div className={styles.titles}>
          <div className={styles.activities}>Tools and materials</div>
          <div className={styles.back}  onClick={onBack}>
            <div className={styles.back1}>Back</div>
            <img className={styles.frameIcon} alt="" src="/frame.svg" />
          </div>
          <div className={styles.button1}>
            <div className={styles.iconsaddParent}>
              <img className={styles.edit2Icon} alt="" src="/edit2.svg" />
              <div className={styles.edit}>Edit</div>
            </div>
          </div>
        </div>
        {
      mateTools.length>0?
     <>
     {mateTools.map((data) => (
             <div className={cardstyle.customerInfo}>
                        <div className={cardstyle.quoteNumberParent}>
                          <div className={cardstyle.quoteNumber4}>
                           {data.desc}
                          </div>
                          <div className={cardstyle.x10}>
                            <span className={cardstyle.span}>{data.quant}</span>
                            <span className={cardstyle.x}>X</span>
                            <span className={cardstyle.span}> £{data.unit}</span>
                          </div>
                        </div>
                        <div className={cardstyle.frameGroup}>
                          <div className={cardstyle.kvPinInsulatorsParent}>
                            <div className={cardstyle.kvPinInsulators}>
                              {data.detail}
                            </div>
                            <div className={cardstyle.div}>£{data.total}</div>
                          </div>
                          <div className={cardstyle.kvPinInsulatorsParent}>
                            <div className={cardstyle.kvPinInsulators}>
                            {
                                data.disc_type=='perc'?
                               <a>Discount({data.disc}%)</a> 
                                :
                                <a>Flat({data.disc})</a> 
                              }
                              
                             </div>
                             <div className={cardstyle.div1}>-£{
                               data.disc_type=='perc'?
                               <a>{(data.quant*data.unit)*data.disc/100}</a> 
                                :
                                <a>{data.disc}</a>
                            }</div>
                            </div>
                        </div>
                      </div>
           
          ))}
    </>
      :
       <div className={styles.medicalCaseLineGroup}>
          <img
            className={styles.medicalCaseLine1}
            alt=""
            src="/medical-case-line1.svg"
          />
          <div className={styles.clickTheButton1}>
            Click the button below to add tools and materials
          </div>
        </div>
      }
         {modal && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setModal(false)}
        >
          <AddTool onClose={()=>setModal(false)} 
           tool_desc={tool_desc}
           unit_cost={unit_cost}
           quant={quant}
           tool_disc_t={tool_disc_t}
           tool_disc={tool_disc}
           tool_tax_t={tool_tax_t}
           tool_tax={tool_tax}
           tool_detail={tool_detail}
           tools={tools}
           onTool_desc={onTool_desc}
           onUnit_cost={onUnit_cost}
           onQuant={onQuant}
           onTool_disc_t={onTool_disc_t}
           onTool_disc={onTool_disc}
           onTool_tax_t={onTool_tax_t}
           onTool_tax={onTool_tax}
           onTool_detail={onTool_detail}
           onTools={onTools}/>
        </PortalPopup>
      )}
        <button className={styles.button2}  onClick={()=>setModal(true)}>
          <div className={styles.iconsParent}>
            <img className={styles.icons} alt="" src="/icons311.svg" />
            <b className={styles.promoteProfile}>Add tool/material</b>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Items;
