import { useState, useCallback, useEffect } from "react";
import LocationModal from "../components/LocationModal";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./HomeprofList.module.css";
import { auth, db } from "../firebase";
import { AppBar, Avatar, Box, FormControlLabel, Modal, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import { DashboardCustomize } from "@mui/icons-material";
import { serverTimestamp } from "firebase/firestore";
import CloseIcon from '@mui/icons-material/Close';
import MobileHeader from "../components/MobileHeader";
import verify_styles from "./GladHere.module.css";
import moment from "moment";
import posthog from 'posthog-js'

 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const HomeprofList = () => {
  const [isLocationModalPopupOpen, setLocationModalPopupOpen] = useState(false);
 const navigate = useNavigate();
 const [sub, setSub] = useState(1)
 const [address, setAddress] = useState('');
 const [ avail, setAvail]= useState(false)
 const [ verified, setVerified]= useState(false)
 const [views,setViews]=useState([])
const [loading, setLoading] = useState(true);
  const [list, setList] = useState([])
 useEffect(() => {
 const fetchData = async () => {
   if (auth.currentUser) {
    posthog.capture('Job listing view: List',)
     try {
       const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
       if (snapshot.exists) {
         if(snapshot.data().verification>=35)
         {
          if (snapshot.data()?.online) {
            setVerified(true)
         }else{
           setVerified(false)
         }
         }
         else{
           setVerified(false)
         }
         setAddress(snapshot.data().addy1);
         setSub(snapshot.data().subType);
         const cate = snapshot.data()?.category;
         const lat = snapshot.data().lat;
         const lng = snapshot.data().lng;
         const distance = snapshot.data().distance;
         const sub_cate = snapshot.data()?.sub_cate;
         const service_tags = snapshot.data()?.service_tags;
       
         fetchJob(cate, lat, lng, distance,sub_cate,service_tags);
         setLoading(false)
       } else {
         navigate('/personalinfo');
       }
     } catch (error) {
       console.log('Error fetching user data:', error);
     }
   }
 };
 const fetchJob = (cate, lat, lng, distance,sub_cate,service_tags) => {
  const unsubscribe = db
    .collection('jobs/').onSnapshot((snapshot) => {
      const jobsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Filter jobs within the selected distance
      const filteredList = jobsData.filter((job) => {
        if(job.cate.toLowerCase()==cate.toLowerCase()&&
        job.subCate.toLowerCase()==sub_cate.toLowerCase()|| 
        job.subCate.toLowerCase()=='others'&&job.inactive!=true){
        const jobLatitude = job.lat;
        const jobLongitude = job.lng;

        const jobDistance = calculateDistance(lat, lng, jobLatitude, jobLongitude);

        return jobDistance <= distance;
      }
      else{
        service_tags?.map((x)=>{
        if(job.cate.toLowerCase()==cate.toLowerCase()&&
        job.subCate.toLowerCase()==x.toLowerCase()&&job.inactive!=true){
          const jobLatitude = job.lat;
          const jobLongitude = job.lng;

          const jobDistance = calculateDistance(lat, lng, jobLatitude, jobLongitude);

          return jobDistance <= distance;
        }
      })
      }
      
      });

      setList(filteredList);
    });

  return () => {
    unsubscribe();
  };
};
 db.collection('profileViews').doc(auth.currentUser.uid).collection('list').orderBy('stamp','desc').onSnapshot((snapshot) => {
  const view = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  setViews(view)
})
 fetchData();
}, []);
 const handleAvailCh =async (e) => {
      try {
       const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
       if (snapshot.exists) {
         if(snapshot.data().verification>=35)
         {
           setVerified(!verified)
           db.collection('users').doc(auth.currentUser.uid).update({
            online:!verified
          })
         }
         else{
           setAvail(true)
           setVerified(false)
         }
       } 
     } catch (error) {
       console.log('Error fetching user data:', error);
     }
 };
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(lat1)) *
        Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return milesToKilometers(distance);
  };

  const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const milesToKilometers = (miles) => {
    return miles * 1.60934;
  };

  const onFrameButtonClick = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onDefaultClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onDefault1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefault2Click = useCallback(() => {
    // Please sync "Notifications" to the project
  }, []);

  const onDefault4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const openLocationModalPopup = useCallback(() => {
    setLocationModalPopupOpen(true);
  }, []);

  const closeLocationModalPopup = useCallback(() => {
    setLocationModalPopupOpen(false);
  }, []);

  const onFrameButton2Click = useCallback(() => {
    navigate("/home");
  }, [navigate]);

  const onFrameContainer41Click = useCallback(() => {
    navigate("/jobview");
  }, [navigate]);

  return (
    <>
     {
      loading?
      null
      :
      <div className={styles.homeproflist}>
        <div className={styles.homemob}>
        <MobileHeader/>
          <div className={styles.location}>
            <div className={styles.mapPinParent}>
              <img className={styles.mapPinIcon} alt="" src="/mappin.svg" />
              <div className={styles.frame1}>
                <img
                  className={styles.chevronUpIcon}
                  alt=""
                  src="/chevronup.svg"
                />
                <div className={styles.manchesterEngland}>
                  {address}
                </div>
              </div>
            </div>
            <div className={styles.locationChild} />
            <div className={styles.toggleToGoOnlineOrOfflineParent}>
              <div className={styles.toggleToGo}>
                Toggle to go online or offline
              </div>
              <FormControlLabel
             onChange={handleAvailCh}
        control={<IOSSwitch sx={{ m: 1 }} checked={verified}/>}
      />
            </div>
          </div>
          <div className={styles.loggJobsWrapper}>
            <div className={styles.loggJobs}>
              <div className={styles.loggJobsParent}>
                <b className={styles.loggJobs1}>Logg jobs</b>
                <button
                  className={styles.tablercarouselHorizontalParent}
                  onClick={onFrameButtonClick}
                >
                  <img
                    className={styles.tablercarouselHorizontalIcon}
                    alt=""
                    src="/tablercarouselhorizontal.svg"
                  />
                  <div className={styles.citiessummary}>Change View</div>
                </button>
              </div>
                 {
          list.length>0?
      list.map(doc => {
            return(
         <div className={styles.frameContainer}
         >
                <div className={styles.frameDiv}>
                  <div style={{position:'relative'}}>
                    <img
                           className={styles.rectangleWrapper}
                            alt=""
                            src={doc.image1}
                            onClick={()=>{
                              posthog.capture('job viewing',)
                              navigate("/jobview/"+doc.id)}}
                          />
                           <div className={styles.frameChild}>
                  <ProfileImage id={doc.id}/>
                 </div>
                  </div>

                  {
                            doc.image2?
                             <div className={styles.frameParent1}>
                          <div  className={styles.rectangleParent}>
                          {doc.image2?<img src={doc.image2} className={styles.frameItem} />:''}
                          {doc.image3?<img src={doc.image3} className={styles.frameInner} />:''}
                          {doc.image4?<img src={doc.image4} className={styles.frameInner} />:''}
                          </div>
                          <div className={styles.citiessummary1}></div>
                        </div>
                        :
                        null
                          }
                </div>
                <div className={styles.requestForElectricianParent}>
                  <div className={styles.requestForElectricianContainer}>
                    <span className={styles.requestForElectricianContainer1}>
                      <p className={styles.p}>{` `}</p>
                      <p className={styles.p}>Request for {doc.cate}</p>
                    </span>
                  </div>
                  <div className={styles.fluenttextDescriptionLtr20Parent}>
                    <img
                      className={styles.fluenttextDescriptionLtr20Icon}
                      alt=""
                      src="/fluenttextdescriptionltr20filled.svg"
                    />
                    <div className={styles.imInNeed}>
                     {doc.desc}
                    </div>
                  </div>
                  <div className={styles.fluenttextDescriptionLtr20Parent}>
                    <img
                      className={styles.uilmoneyBillIcon}
                      alt=""
                      src="/uilmoneybill.svg"
                    />
                    <div className={styles.citiessummary2}>£{doc.from} - £{doc.to}</div>
                  </div>
                  <div className={styles.fluenttextDescriptionLtr20Parent}>
                    <img
                      className={styles.fluenttextDescriptionLtr20Icon}
                      alt=""
                      src="/akariconslocation.svg"
                    />
                    <div className={styles.citiessummary3}>
                      {doc.addy1}
                    </div>
                  </div>
                  <div className={styles.frameWrapper}
                  onClick={()=>{
                    db.collection('post_by_cate/'+doc.cate+'/list').doc(doc.id).get().then(snapshot=> {
                      let uid = snapshot.data().uid
                      var date = new Date();
                      var hours = date.getHours();
                 var minutes = date.getMinutes();
                 var ampm = hours >= 12 ? 'pm' : 'am';
                 hours = hours % 12;
                 hours = hours ? hours : 12; // the hour '0' should be '12'
                 minutes = minutes < 10 ? '0'+minutes : minutes;
                 var strTime = hours + ':' + minutes + ' ' + ampm;
                         const key = db.collection("tmp").doc().id
                          db.collection('customers/').doc(uid).get().then(snap=> {
                            db.collection('contacts/'+auth.currentUser.uid+'/my/').where('uid','==',uid).get().then(snapshot=>{
                             if(snapshot.empty)
                             {
                               console.log('here')
                                db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(key).set({
                           uid:uid,
                           last: '',
                           unix: Date.now(),
                           timestamp: serverTimestamp(),
                           name: snap.data().fname+' '+snap.data().lname,
                           key: key,
                           desc: doc.desc
                         }) 
                             }
                             else
                             {
                               snapshot.forEach((prev)=>{
                                 db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(prev.data().key).update({
                           unix: Date.now(),
                           timestamp: serverTimestamp(),
                           name: snap.data().fname+' '+snap.data().lname,
                           desc: doc.desc
                         }) 
                               })
                              
                             }
                            })
                              
                           })
                           localStorage.setItem('title', doc.cate);
                           localStorage.setItem('desc', doc.desc);
                         navigate('/chat/'+uid)
                  })
                         
                    }}>
                    <div className={styles.citiessummaryWrapper}>
                      <div className={styles.citiessummary4}>
                        Message {doc.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        )
        })
        :
        <b style={{fontSize:'17px',color:'grey'}}>No jobs at your location</b>
    }
              
            </div>
          </div>
           <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
             <div className={styles.bottomNav}>
            <div className={styles.home} onClick={()=>navigate('/home')}>
              <b className={styles.home1}>Home</b>
              <img
                className={styles.brandLogoIcon1}
                alt=""
                src="/brand-logo2.svg"
              />
            </div>
            <div className={styles.jobs} onClick={()=>navigate('/jobs')}>
              <div className={styles.jobs1}>Jobs</div>
              <img
                className={styles.bibriefcaseFillIcon}
                alt=""
                src="/bibriefcasefill2.svg"
              />
            </div>
            <div className={styles.wallet} onClick={()=>navigate('/wallet')}>
              <div className={styles.wallet1}>Wallet</div>
              <img
                className={styles.zondiconswallet}
                alt=""
                src="/iconslog-cash.svg"
              />
            </div>
            <div className={styles.account} onClick={()=>navigate('/profile')}>
              <div className={styles.account1}>Account</div>
              <img
                className={styles.mdiaccountIcon}
                alt=""
                src="/mdiaccount2.svg"
              />
            </div>
          </div>
           </AppBar>
         
        </div>
        <div className={styles.homeproflist1}>
          <nav className={styles.navPrototype}>
            <div className={styles.nav}>
              <img className={styles.navChild} alt="" src="/frame-665222.svg" />
              <div className={styles.defaultParent}>
                <button className={styles.default} onClick={onDefaultClick}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconshome1.svg"
                    />
                    <div className={styles.home2}>Home</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault1Click}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconsjobs.svg"
                    />
                    <div className={styles.home3}>Jobs</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault2Click}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconsactivity.svg"
                    />
                    <div className={styles.home3}>Activities</div>
                  </div>
                </button>
                <button className={styles.default1}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconslog-cash.svg"
                    />
                    <div className={styles.home3}>Wallet</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault4Click}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconsmessage.svg"
                    />
                    <div className={styles.home3}>Message</div>
                  </div>
                </button>
                <button className={styles.default1}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.accountIcon}
                      alt=""
                      src="/account4.svg"
                    />
                    <div className={styles.home3}>Profile</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
          <div className={styles.headerParent}>
           <MobileHeader/>
            <div className={styles.location1}>
              <div className={styles.mapPinParent}>
                <img className={styles.mapPinIcon} alt="" src="/mappin1.svg" />
                <div className={styles.frame1}>
                  <img
                    className={styles.chevronUpIcon}
                    alt=""
                    src="/chevronup1.svg"
                  />
                  <div className={styles.manchesterEngland}>
                    {address}
                  </div>
                </div>
              </div>
              <div className={styles.locationItem} />
              <div className={styles.toggleToGoOnlineOrOfflineParent}>
                <div className={styles.toggleToGo}>
                  Toggle to go online or offline
                </div>
                <FormControlLabel
             onChange={handleAvailCh}
        control={<IOSSwitch sx={{ m: 1 }} checked={verified}/>}
      />
              </div>
            </div>
            <div className={styles.titleBarsParent}>
              <div className={styles.titleBars}>
                
                <div className={styles.frameParent4}>
                  <button
                    className={styles.iconslocationParent}
                    onClick={openLocationModalPopup}
                  >
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconslocation2.svg"
                    />
                    <div className={styles.manchesterEngland2}>
                      {address}
                    </div>
                    <img
                      className={styles.tablercarouselHorizontalIcon}
                      alt=""
                      src="/iconschevrondown1.svg"
                    />
                  </button>
                  <div className={styles.lineDiv} />
                  <div className={styles.toggleToGoOnlineOrOfflineContainer}>
                    <div className={styles.toggleToGo2}>
                      Toggle to go online or offline
                    </div>
                    <FormControlLabel
             onChange={handleAvailCh}
        control={<IOSSwitch sx={{ m: 1 }} checked={verified}/>}
      />
                  </div>
                </div>
              </div>
              <div className={styles.frameParent5}>
                <div className={styles.loggJobsGroup}>
                  <div className={styles.loggJobs}>
                    <div className={styles.loggJobsParent}>
                      <div className={styles.loggJobs1}>Logg jobs</div>
                      <button
                        className={styles.tablercarouselHorizontalParent}
                        onClick={onFrameButton2Click}
                      >
                        <img
                          className={styles.tablercarouselHorizontalIcon}
                          alt=""
                          src="/tablercarouselhorizontal1.svg"
                        />
                        <div className={styles.citiessummary}>Change View</div>
                      </button>
                    </div>
                    <div style={{width:'100%'}}>
                          {
          list.length>0?
      list.map(doc => {
            return(
         <div className={styles.frameParent6}>
                      <div
                        className={styles.frameParent7}
                         onClick={()=>{
                          posthog.capture('job viewing',)
                          navigate("/jobview/"+doc.id)}}
                      >
                          <img
                           className={styles.rectangleContainer}
                            alt=""
                            src={doc.image1}
                            onClick={()=>{
                              posthog.capture('job viewing',)
                              navigate("/jobview/"+doc.id)}}
                          />
                            <div className={styles.statusInner}>
                  <ProfileImage id={doc.id}/>
                 </div>
                          {
                            doc.image2?
                             <div className={styles.frameParent8}>
                          <div  className={styles.rectangleParent}>
                          {doc.image2?<img src={doc.image2} className={styles.frameItem} />:''}
                          {doc.image3?<img src={doc.image3} className={styles.frameInner} />:''}
                          {doc.image4?<img src={doc.image4} className={styles.frameInner} />:''}
                          </div>
                          <div className={styles.citiessummary1}></div>
                        </div>
                        :
                        null
                          }
                       
                      </div>
                      <div className={styles.requestForElectricianGroup}>
                        <div className={styles.requestForElectricianContainer2}>
                          <span
                            className={styles.requestForElectricianContainer1}
                          >
                            <p className={styles.p}>{` `}</p>
                            <p className={styles.p}>Request for {doc.cate}</p>
                          </span>
                        </div>
                        <div className={styles.fluenttextDescriptionLtr20Group}>
                          <img
                            className={styles.fluenttextDescriptionLtr20Icon1}
                            alt=""
                            src="/fluenttextdescriptionltr20filled1.svg"
                          />
                          <div className={styles.imInNeed1}>
                           {doc.desc}
                          </div>
                        </div>
                        <div className={styles.fluenttextDescriptionLtr20Group}>
                          <img
                            className={styles.uilmoneyBillIcon1}
                            alt=""
                            src="/uilmoneybill11.svg"
                          />
                          <div className={styles.citiessummary7}>
                            £{doc.to} - £{doc.from}
                          </div>
                        </div>
                        <div className={styles.fluenttextDescriptionLtr20Group}>
                          <img
                            className={styles.fluenttextDescriptionLtr20Icon1}
                            alt=""
                            src="/akariconslocation1.svg"
                          />
                          <div className={styles.imInNeed1}>
                            {doc.addy1}
                          </div>
                        </div>
                        <div className={styles.frameWrapper}
                         onClick={()=>{
                          db.collection('post_by_cate/'+doc.cate+'/list').doc(doc.id).get().then(snapshot=> {
                            let uid = snapshot.data().uid
                            var date = new Date();
                            var hours = date.getHours();
                       var minutes = date.getMinutes();
                       var ampm = hours >= 12 ? 'pm' : 'am';
                       hours = hours % 12;
                       hours = hours ? hours : 12; // the hour '0' should be '12'
                       minutes = minutes < 10 ? '0'+minutes : minutes;
                       var strTime = hours + ':' + minutes + ' ' + ampm;
                               const key = db.collection("tmp").doc().id
                                db.collection('customers/').doc(uid).get().then(snap=> {
                                  db.collection('contacts/'+auth.currentUser.uid+'/my/').where('uid','==',uid).get().then(snapshot=>{
                                   if(snapshot.empty)
                                   {
                                     console.log('here')
                                      db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(key).set({
                                 uid:uid,
                                 last: '',
                                 unix: Date.now(),
                                 timestamp: serverTimestamp(),
                                 name: snap.data().fname+' '+snap.data().lname,
                                 key: key,
                                 desc: doc.desc
                               }) 
                                  
                                   }
                                   else
                                   {
                                     snapshot.forEach((prev)=>{
                                       db.collection('contacts/'+auth.currentUser.uid+'/my/').doc(prev.data().key).update({
                                 unix: Date.now(),
                                 timestamp: serverTimestamp(),
                                 name: snap.data().fname+' '+snap.data().lname,
                                 desc: doc.desc
                               }) 
                                     })
                                   
                                   }
                                  })
                                    
                                 })
                                 localStorage.setItem('title', doc.cate);
                                 localStorage.setItem('desc', doc.desc);
                               navigate('/chat/'+uid)
                        })}}>
                          <div className={styles.citiessummaryContainer}>
                            <div className={styles.citiessummary4}>
                              Message {doc.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
        )
        })
        :
        <b style={{fontSize:'17px',color:'grey'}}>No jobs at your location</b>
    }
                      
                    </div>
                    
                  </div>
                  <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                      <div className={styles.bottomNav1}>
                      <div className={styles.home} onClick={()=>navigate('/home')}>
                        <b className={styles.home1}>Home</b>
                        <img
                          className={styles.brandLogoIcon1}
                          alt=""
                          src="/brand-logo21.svg"
                        />
                      </div>
                      <div className={styles.jobs} onClick={()=>navigate('/jobs')}>
                        <div className={styles.jobs1}>Jobs</div>
                        <img
                          className={styles.bibriefcaseFillIcon}
                          alt=""
                          src="/iconsjobs.svg"
                        />
                      </div>
                      <div className={styles.wallet} onClick={()=>navigate('/wallet')}>
                        <div className={styles.wallet1}>Wallet</div>
                        <img
                          className={styles.zondiconswallet}
                          alt=""
                          src="/iconslog-cash.svg"
                        />
                      </div>
                      <div className={styles.account} onClick={()=>navigate('/profile')}>
                        <div className={styles.account1}>Account</div>
                        <img
                          className={styles.mdiaccountIcon}
                          alt=""
                          src="/account4.svg"
                        />
                      </div>
                    </div>
                     </AppBar>
                  
                </div>
                <div className={styles.frameParent9}>
                  <div className={styles.seeWhoViewedYourProfileWrapper}>
                    <div className={styles.loggJobs1}>
                      See who viewed your profile
                    </div>
                  </div>
                  <div className={styles.profileViewsWrapper}>
                    <div className={styles.profileViews}>
                    {
                        views.map(data=>{
                          return(
                          <SeeWho
                          data={data}
                          type={'desk'}
                          />
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
}
<Modal
        open={avail}
        onClose={()=>setAvail(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{width:'100%', display:'flex', justifyContent:'right'}} onClick={()=>setAvail(false)}>
             <CloseIcon/>
            </div>
        <img className={verify_styles.hiFiveIcon} alt="" src="/hifive.svg" />
          <div className={verify_styles.largeButtonsParent}>
            <button className={verify_styles.largeButtons} onClick={()=>{navigate('/profile')}}>
              <b className={verify_styles.label}>Verify Profile Now</b>
            </button>
          </div>
        </Box>
      </Modal>
      {isLocationModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLocationModalPopup}
        >
          <LocationModal onClose={closeLocationModalPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default HomeprofList;

const ProfileImage=({id})=>{
  const [profile,setProfile]=useState('')
  useEffect(() => {
    db.collection('jobs/').doc(id).get().then(snapshot=> {
   db.collection('customers').doc(snapshot.data().uid).get().then(function (snap) {
    setProfile(snap.data()?.profileImage)
   })
  })
  }, [])
  
  return(
    <Avatar
    src={profile||""}
    style={{width:'35px',height:'32px'}}
    />
  )
}

const SeeWho=({data,type})=>{
  const [profile,setProfile]=useState('')
  const [name,setName]=useState('')
  const [addy,setAddress]=useState('')
  useEffect(() => {
    db.collection('customers').doc(data.uid).get().then(function (snap) {
      setProfile(snap.data()?.profileImage)
      setName(snap.data()?.lname+' '+snap.data()?.fname)
      setAddress(snap.data()?.address)
     })
  }, [])
  
  return(
    <>
    {
      type=='desk'?
       <div className={styles.seeWhoViewedMyProfile}>
    <div className={styles.pm10}>{moment(data.unix).fromNow(true)}</div>
    <div className={styles.avatarParent}>
      <Avatar
      className={styles.avatarIcon}
      src={profile||""}
      style={{width:'35px',height:'32px'}}
      />
      <div className={styles.overallSatisfaction60Group}>
        <div className={styles.jamesBorden2}>
          {name}
        </div>
        <div className={styles.ontarioUsa2}>
          {addy}
        </div>
      </div>
    </div>
  </div>
  : type=='mob'?
  <div className={styles.whoViewedMyProfile}>
  <div className={styles.whoViewedMyProfileChild} />
  <Avatar
      className={styles.whoViewedMyProfileItem}
      src={profile||""}
      style={{width:'35px',height:'32px'}}
      />
  <div className={styles.jamesBorden}>{name}</div>
  <div className={styles.ontarioUsa}>{addy}</div>
  <div className={styles.pm}>{moment(data.unix).fromNow(true)}</div>
</div>:
  <div className={styles.whoViewedMyProfile}>
  <div className={styles.whoViewedMyProfileChild} />
  <Avatar
      className={styles.whoViewedMyProfileItem}
      src={profile||""}
      style={{width:'35px',height:'32px'}}
      />
  <div className={styles.jamesBorden}>{name}</div>
  <div className={styles.ontarioUsa}>{addy}</div>
  <div className={styles.pm}>{moment(data.unix).fromNow(true)}</div>
</div>
    }
   
    </>
    
  )
}