import { useState, useCallback, useEffect } from "react";
import RecentChat from "../components/RecentChat";
import PortalPopup from "../components/PortalPopup";
import AddItem from "../components/AddItem";
import SendQuote from "../components/SendQuote";
import styles from "./CreateQuote.module.css";
import { Alert, MenuItem, Paper, Snackbar, TextField, TextareaAutosize } from "@mui/material";
import { auth, db } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import Items from "./Items";
import QoutePrev from "./QoutePrev";
import BookingFee from "../components/BookingFee";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import BookingFeeMic from "../components/BookingFeeMel";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import posthog from 'posthog-js'

 

const CreateQuote = () => {
  const [isRecentChatPopupOpen, setRecentChatPopupOpen] = useState(false);
  const [isAddItemPopupOpen, setAddItemPopupOpen] = useState(false);
  const [isSendQuotePopupOpen, setSendQuotePopupOpen] = useState(false);
     const navigate = useNavigate();
  const generatePassword = (len) => {
    let result = [];
    const characters = '0123456789';
    const charLength = characters.length;
    for (let i = 0; i < len; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charLength)));
    }
    return result.join('');
}

var tempDate = new Date();
var year = tempDate.getFullYear();
var month = (tempDate.getMonth() + 1).toString().padStart(2, '0');
var day = tempDate.getDate().toString().padStart(2, '0');
var date = month + '/' + day + '/' + year;
  const [open, setOpen] = useState()
  const [oitem, setOtime] = useState(false)
  const [oservice, setOservice] = useState(false)
  const [omic, setOmic] = useState(false)
  const [tab, setTab] = useState(0)
  const [due, setDue] = useState('')
  const [cname, setCname] = useState('')
  const [caddy, setCaddy] = useState('')
  const [jdate, setJdate] = useState('')
  const [jtime, setJtime] = useState('')
  const [jdura, setJdura] = useState('')
  const [jloc, setJloc] = useState('')
  const [jdesc, setJdesc] = useState('')
  const [qkey, setQkey] = useState('')
  const [tool_desc, setTdesc] = useState('')
  var [advance, setAdvance] = useState(0)
  var [unit_cost, setUcost] = useState(0)
  var [quant, setQuant] = useState(0)
  const [tool_disc_t, setTdisc_t] = useState('perc')
  var [tool_disc, setTdisc] = useState(0)
  const [tool_tax_t, setTtax_t] = useState('none')
  var [tool_tax, setTtax] = useState(0)
  const [tool_detail, setTdetail] = useState('')
  const [tools, setTool] = useState([])
  const [tools_adv, setTool_adv] = useState([])
  let {id} = useParams()
  const [rate, setRate] = useState('hr')
  const [above, setAbove] = useState(false)
  const [adv, setAdv] = useState('')
  const [send_mod, setSmodal] = useState(false)
  const [error, setError] = useState('')
  const [result, setResult] = useState('');
  const percTools = tools.filter((item) => item.disc_type === 'perc');
  const flatTools = tools.filter((item) => item.disc_type != 'perc');
  useEffect(() => {
    if(auth.currentUser)
    {
      if (id!='new') {
        setQkey(id)
        db.collection('my_draft/'+auth.currentUser.uid+'/list/').doc(id).get().then(snapshot=>{
          setTool(snapshot.data().tools)
          setDue(snapshot.data().due)
          setCname(snapshot.data().cname)
          setCaddy(snapshot.data().caddy)
          setJdate(snapshot.data().jdate)
          setJtime(snapshot.data().jtime)
          setJdura(snapshot.data().jdura)
          setJloc(snapshot.data().jloc)
          setJdesc(snapshot.data().jdesc)
        })
      }
      else
      {
        setQkey(generatePassword(6))
      }
      
       
    }
  
  }, [])
  useEffect(() => {
   onMax()
  }, [tools])
  
   const onAdv=(e)=>{
     const other_fee = tools.filter(obj => {
    return obj.desc !== 'Service Fee';
  });
  console.log(other_fee)
    if(e.target.value>other_fee.reduce((a,v) =>  a = a + v.total , 0 ))
    {
      setAbove(true)
    }
    else{
      setAbove(false)
      const existingServiceFee = tools.find((item) => item.desc === 'adv');

      if (existingServiceFee) {
        // If "Advance Fee" exists, update its properties
        const updatedItem = {
          ...existingServiceFee,
          total: e.target.value,
        };
    
        // Update the existing "Advance Fee" item
        setTool((prevTools) =>
          prevTools.map((item) =>
            item.desc === 'adv' ? updatedItem : item
          )
        );
      }
      else{
      const newItem = {
        id: tools.length + 1,
        desc: 'adv',
        total: e.target.value,
      };
     
      setTool([...tools, newItem]);
      }
    }
  }
  const onMax=()=>{
    const other_fee = tools.filter(obj => {
    return obj.desc !== 'Service Fee';
  });
 const total_other_fee = other_fee.reduce((a, v) => a + v.total, 0);
   setResult(`${total_other_fee}`);
  }
  const onCname=(e)=>{
    setCname(e)
  }
  const onCaddy=(e)=>{
    setCaddy(e)
    console.log(e)
  }
  const onJloc=(e)=>{
    setJloc(e)
  }
  const onJdesc=(e)=>{
    setJdesc(e)
  }
   const onJdate=(e)=>{
    if(auth.currentUser){
    db.collection('my_draft/'+auth.currentUser.uid+'/list')
    .where('jtime','==',jtime).get().then(function(snapshot){
      if(!snapshot.empty)
      {
        db.collection('my_draft/'+auth.currentUser.uid+'/list')
    .where('jdate','==',e).get().then(function(snapshot){
      if(!snapshot.empty)
      {
          setError('You have a Qoute on the date and time you selected')
      }
      else{
             setJdate(e)
      }
    })
      }
      else{
        console.log('here')
           setJdate(e)
      }
    })
    }
  }
  const handleSelect = async (selectedAddress) => {
    setJloc(selectedAddress);
  
  };
   const onJtime=(e)=>{
  if(auth.currentUser){
    db.collection('my_draft/'+auth.currentUser.uid+'/list')
    .where('jdate','==',jdate).get().then(function(snapshot){
      if(!snapshot.empty)
      {
        db.collection('my_draft/'+auth.currentUser.uid+'/list')
    .where('jtime','==',e).get().then(function(snapshot){
      if(!snapshot.empty)
      {
         console.log('here')
          setError('You have a Qoute on the date and time you selected')
      }
      else{
            setJtime(e)
      }
    })
      }
      else{
        console.log('here')
          setJtime(e)
      }
    })
    }
  }
   const onJdura=(e)=>{
    setJdura(e)
  }
  const onRate=(e)=>{
    setRate(e)
  }
  const onOitem=()=>{
    setOtime(!oitem)
  }
  const onOservice=()=>{
    setOservice(true)
  }
  const onOmic=()=>{
    setOmic(true)
  }
 const onTool_desc=(e)=>{
  setTdesc(e)
 }
const onUnit_cost=(e)=>{
setUcost(e)
}
const onQuant=(e)=>{
setQuant(e)
}
const onTool_disc_t=(e)=>{
setTdisc_t(e)
}
const onTool_disc=(e)=>{
setTdisc(e)
}
const onTool_tax_t=(e)=>{
setTtax_t(e)
}
const onTool_tax=(e)=>{
setTtax(e)
}
const onTool_detail=(e)=>{
setTdetail(e)
}
const onTools=(e)=>{
  if (
    tool_desc === null || tool_desc === "" ||
    unit_cost === null || unit_cost === "" ||
    quant === null || quant === "" ||
    tool_disc_t === null || tool_disc_t === "" ||
    tool_disc === null || tool_disc === "" ||
    tool_tax_t === null || tool_tax_t === "" ||
    tool_tax === null || tool_tax === "" ||
    rate === null || rate === ""
  )  {
    // Display an alert if any of the variables are null
    alert("Additional details is the only optional.");
    return;
  }
   const newItem = {
      id: tools.length + 1,
      desc: tool_desc,
      detail: tool_detail,
      unit: unit_cost,
      quant: quant,
      disc_type: tool_disc_t,
      disc: tool_disc,
      tax_type: tool_tax_t,
      tax: tool_tax,
      total: e,
      type:'tool'
    };
    setTool([...tools, newItem]);
    setTdesc('')
    setTdetail('')
    setUcost(0)
    setQuant(0)
    setTdisc(0)
    setTtax(0)
    
}
const onServices = (e) => {
  // Check if any of the variables are null or undefined
  if (
    unit_cost === null || unit_cost === "" ||unit_cost === 0 ||
    quant === null || quant === "" || quant === 0 ||
    tool_disc_t === null || tool_disc_t === "" ||
    tool_disc === null || tool_disc === "" ||
    tool_tax_t === null || tool_tax_t === "" ||
    tool_tax === null || tool_tax === "" ||
    rate === null || rate === ""
  ) {
    // Display an alert if any of the variables are null
    alert("Discount and Tax are the only optionals");
    return;
  }

  // Check if an item with "desc" equal to "Service Fee" already exists
  const existingServiceFee = tools.find((item) => item.desc === 'Service Fee');

  if (existingServiceFee) {
    // If "Service Fee" exists, update its properties
    const updatedItem = {
      ...existingServiceFee,
      detail: tool_detail,
      unit: unit_cost,
      quant: quant,
      disc_type: tool_disc_t,
      disc: tool_disc,
      tax_type: tool_tax_t,
      tax: tool_tax,
      total: e,
      rate: rate,
    };

    // Update the existing "Service Fee" item
    setTool((prevTools) =>
      prevTools.map((item) =>
        item.desc === 'Service Fee' ? updatedItem : item
      )
    );
  } else {
    // If "Service Fee" doesn't exist, add a new item
    const newItem = {
      id: tools.length + 1,
      desc: 'Service Fee',
      detail: tool_detail,
      unit: unit_cost,
      quant: quant,
      disc_type: tool_disc_t,
      disc: tool_disc,
      tax_type: tool_tax_t,
      tax: tool_tax,
      total: e,
      rate: rate,
    };
   
    setTool([...tools, newItem]);
  }
  setTdesc('')
  setTdetail('')
  setUcost(0)
  setQuant(0)
  setTdisc(0)
  setTtax(0)
  setOservice(false)
};

const onMic = (e) => {
  // Check if any of the variables are null or undefined
  if (
    tool_desc === null || tool_desc === "" ||
    unit_cost === null || unit_cost === "" ||unit_cost === 0 ||
    tool_tax_t === null || tool_tax_t === "" ||
    tool_tax === null || tool_tax === "" 
  ) {
    // Display an alert if any of the variables are null
    alert("Tax is the only optional");
    return;
  }

  // Check if an item with the same "desc" already exists
  const existingItem = tools.find((item) => item.desc === tool_desc);

  if (existingItem) {
    // If an item with the same "desc" exists, update its properties
    const updatedItem = {
      ...existingItem,
      detail: tool_detail,
      unit: unit_cost,
      quant: quant,
      disc_type: tool_disc_t,
      disc: tool_disc,
      tax_type: tool_tax_t,
      tax: tool_tax,
      total: e,
      rate: rate,
    };

    // Update the existing item
    setTool((prevTools) =>
      prevTools.map((item) => (item.desc === tool_desc ? updatedItem : item))
    );
  } else {
    // If no item with the same "desc" exists, add a new item
    const newItem = {
      id: tools.length + 1,
      desc: tool_desc,
      detail: tool_detail,
      unit: unit_cost,
      quant: quant,
      disc_type: tool_disc_t,
      disc: tool_disc,
      tax_type: tool_tax_t,
      tax: tool_tax,
      total: e,
      rate: rate,
    };

    setTool([...tools, newItem]);
  }
  setTdesc('')
  setTdetail('')
  setUcost(0)
  setQuant(0)
  setTdisc(0)
  setTtax(0)
  setOmic(false)
};

const onDaft=(e)=>{
 const serv_fee = tools.find(obj => {
    return obj.desc === 'Service Fee';
  });
  posthog.capture('Quote: Drafted',)
  if (serv_fee!=null) {
    db.collection('my_draft/'+auth.currentUser.uid+'/list/').doc(qkey).set({
  tools: tools,
  qkey: qkey,
  due: due,
cname: cname,
caddy: caddy,
jdate: jdate,
jtime: jtime,
jdura: jdura,
jloc: jloc,
jdesc: jdesc,
status:'draft',
total: tools.reduce((a,v) =>  a = Number(a) + Number(v.total),0 )
 })
 navigate('/myquote')
  }
  else{
    setError('You have not included your service fee')
  }
}
const onSend=(e)=>{
  if(due=== null||due=== ""||
    cname=== null||cname=== ''||
    jdate=== null||jdate=== ""||
    jtime=== null||jtime=== ""||
    jdura=== null||jdura=== ""||
    jloc=== null||jloc=== ""||
    jdesc=== null|| jdesc=== "")
    {
      setError('Please provide value for all the input fields')
      return
    }
  const serv_fee = tools.find(obj => {
     return obj.desc === 'Service Fee';
   });
   if (serv_fee!=null) {
     db.collection('my_draft/'+auth.currentUser.uid+'/list/').doc(qkey).set({
   tools: tools,
   qkey: qkey,
   due: due,
 cname: cname,
 caddy: caddy,
 jdate: jdate,
 jtime: jtime,
 jdura: jdura,
 jloc: jloc,
 jdesc: jdesc,
 status:'draft',
 total: tools.reduce((a,v) =>  a = Number(a) + Number(v.total),0 )
  })
  setSmodal(true);
  openSendQuotePopup()
   }
   else{
     setError('You have not included your service fee')
   }
}
  const onDefaultClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onDefault1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefault2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefault3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefault4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDefault5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onIconContainerClick = useCallback(() => {
    navigate(-1)
  }, []);

  const onQuoteCardsContainer2Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const openRecentChatPopup = useCallback(() => {
    setRecentChatPopupOpen(true);
  }, []);

  const closeRecentChatPopup = useCallback(() => {
    setRecentChatPopupOpen(false);
  }, []);

  const openAddItemPopup = useCallback(() => {
    setAddItemPopupOpen(true);
  }, []);

  const closeAddItemPopup = useCallback(() => {
    setAddItemPopupOpen(false);
  }, []);

  const openSendQuotePopup = useCallback(() => {
    setSendQuotePopupOpen(true);
  }, []);

  const closeSendQuotePopup = useCallback(() => {
    setSendQuotePopupOpen(false);
  }, []);

  return (
   <>
    {
      !oitem?
    <>
      <div className={styles.createquote}>
        <div className={styles.createquote1}>
          <div className={styles.nav}>
            <img className={styles.navChild} alt="" src="/frame-665222.svg" />
            <div className={styles.defaultParent}>
              <button className={styles.default} onClick={onDefaultClick}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconshome1.svg"
                  />
                  <div className={styles.home}>Home</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault1Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsjobs.svg"
                  />
                  <div className={styles.home1}>Jobs</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault2Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsactivity.svg"
                  />
                  <div className={styles.home1}>Activities</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault3Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconslog-cash.svg"
                  />
                  <div className={styles.home1}>Wallet</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault4Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.iconshome}
                    alt=""
                    src="/iconsmessage.svg"
                  />
                  <div className={styles.home1}>Message</div>
                </div>
              </button>
              <button className={styles.default1} onClick={onDefault5Click}>
                <div className={styles.iconshomeParent}>
                  <img
                    className={styles.accountIcon}
                    alt=""
                    src="/account4.svg"
                  />
                  <div className={styles.home1}>Profile</div>
                </div>
              </button>
            </div>
          </div>
          <div className={styles.titlesParent}>
            <div className={styles.titles}>
              <div className={styles.back} onClick={onIconContainerClick}>
                <div className={styles.back1}>Back</div>
                <img className={styles.frameIcon} alt="" src="/frame.svg" />
              </div>
              <div className={styles.activities}>Create quote</div>
              <div className={styles.button}>
                <div className={styles.edit2Parent}>
                  <img className={styles.edit2Icon} alt="" src="/edit2.svg" />
                  <div className={styles.edit}>Edit</div>
                </div>
              </div>
            </div>
            <div className={styles.component102}>
              <div className={styles.smallButtons}>
                <div className={styles.edit2Parent}>
                  <img
                    className={styles.iconsadd}
                    alt=""
                    src="/iconsadd11.svg"
                  />
                  <div className={styles.button1}>Add Item</div>
                </div>
              </div>
              <img className={styles.avatarIcon} alt="" src="/avatar.svg" />
              <b className={styles.licenseAndCertification}>Create Quote</b>
              <div className={styles.icon} onClick={onIconContainerClick}>
                <img
                  className={styles.iconshome}
                  alt=""
                  src="/iconschevronright.svg"
                />
              </div>
            </div>
            
            <div className={styles.quoteParent}>
              <div className={styles.quote}>
                <div className={styles.quoteChild} />
                <div className={styles.tab}>
                  <div className={styles.tabChild} />
                  <div className={styles.tabItem} />
                  <div className={styles.edit1}>Edit</div>
                  <div className={styles.preview}>Preview</div>
                </div>
              </div>
             <div className={styles.twoTabSwitchWrapper}>
                <div className={styles.twoTabSwitch}>
                  <div className={styles.tab1Wrapper}
                  style={tab==0?{cursor:'pointer',background:'white',color:'green'}:{cursor:'pointer',color:'#5e5e5e'}} 
            onClick={()=>{setTab(0)}}>
                    <div className={styles.tab1}>Edit</div>
                  </div>
                  <div className={styles.tab2Wrapper}
                  style={tab==1?{cursor:'pointer',background:'white',color:'green'}:{cursor:'pointer',color:'#5e5e5e'}} 
            onClick={()=>{setTab(1)}}>
                    <div className={styles.tab1}>Preview</div>
                  </div>
                </div>
              </div>
               {
      tab == 0?
    <>
       <div className={styles.quoteForm}>
                <div className={styles.quoteInformationParent}>
                  <div className={styles.quoteInformation}>
                    <div className={styles.quoteCards}>
                      <div className={styles.quoteNumber}>Quote number:</div>
                      <div className={styles.qn0001}>{qkey}</div>
                    </div>
                    <div className={styles.quoteCards}>
                      <div className={styles.quoteNumber}>Date:</div>
                      <div className={styles.qn0001}>{date}</div>
                    </div>
                    <div
                      className={styles.quoteCards2}
                      onClick={onQuoteCardsContainer2Click}
                    >
                      <div className={styles.quoteNumber}>Due Date:</div>
                      <div className={styles.qn00012}>
                       <input
                type='date'
                min={new Date().toISOString().split('T')[0]}
                style={{color:'black'}}
               value={due}
               onChange={(e)=>{setDue(e.target.value)}}
                />
                
                      </div>
                    </div>
                    <div className={styles.quoteCards}>
                      <div className={styles.quoteNumber}>Status:</div>
                      <div className={styles.transactionPills}>
                        <div className={styles.success}>DRAFTS</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.customerInformation}>
                    <div className={styles.customerInfo}>
                      <div className={styles.quoteCards4}>
                        <div className={styles.customerInformation1}>
                          Customer Information
                        </div>
                      </div>
                      <div className={styles.labels}>
                        <div className={styles.quoteInputs1}>
                          <div className={styles.labelParent}>
                            <div className={styles.quoteNumber}>Name:</div>
                            <div className={styles.input}>
                              <TextField fullWidth 
              variant="standard"
              value={cname}
              onChange={(e)=>onCname(e.target.value)}
              InputProps={{
                      disableUnderline: true,
                    }}
              placeholder="Full name" 
             />
                            </div>
                          </div>
                        </div>
                        <div className={styles.quoteInputs1}>
                          <div className={styles.labelParent}>
                            <div className={styles.quoteNumber}>Address:</div>
                            <div className={styles.input}>
                            <TextField fullWidth  
              variant="standard"
              value={caddy}
              onChange={(e)=>onCaddy(e.target.value)}
              autoComplete="off"
              InputProps={{
                      disableUnderline: true,
                    }} placeholder="Input an address"/>
                            </div>
                          </div>
                          <img
                            className={styles.iconsplaceholder}
                            alt=""
                            src="/iconsplaceholder31.svg"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className={styles.largeButtons}
                      onClick={openRecentChatPopup}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                      <b className={styles.label2}>Add Contacts</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                    </button>
                  </div>
                  <div className={styles.customerInfo}>
                    <div className={styles.quoteCards4}>
                      <div className={styles.customerInformation1}>
                        Job Information
                      </div>
                    </div>
                    <div className={styles.labels}>
                      <div className={styles.quoteInputs2}>
                        <div className={styles.iconshomeParent}>
                          <div className={styles.quoteNumber}>Date:</div>
                          <div style={{width:'100%'}}>
                             <input
                type='date'
                min={new Date().toISOString().split('T')[0]}
                style={{color:'black',width:'100%'}}
            value={jdate}
            onChange={(e)=>onJdate(e.target.value)}/>
                            </div>
                        </div>
                      </div>
                      <div className={styles.quoteInputs2}>
                        <div className={styles.iconshomeParent}>
                          <div className={styles.quoteNumber}>Time:</div>
                           <div style={{width:'100%'}}>
            <input
                type='time'
                min={new Date().toISOString().split('T')[0]}
                style={{color:'black',width:'100%'}}
                  value={jtime}
                  onChange={(e)=>{
                    onJtime(e.target.value)
                    }}/></div>
                        </div>
                      </div>
                      <div className={styles.quoteInputs4}>
                        <div className={styles.iconshomeParent}>
                          <div style={{width:'11rem',display:'flex',justifyContent:'left'}}>
                            Job Duration:
                          </div>
                          <div style={{width:'100%',display:'flex',justifyContent:'left'}}>
                            <TextField placeholder="0 Days" type={'number'}
                          fullWidth
                  variant="standard"
                  value={jdura}
                  onChange={(e)=>onJdura(e.target.value)}
                 inputProps={{
    inputMode: 'numeric',
    pattern: '[0-9.]*', // Allow numbers and period
    onKeyPress: (e) => {
      const isNumberOrPeriod = /[0-9.]/.test(e.key);
      if (!isNumberOrPeriod) {
        e.preventDefault();
      }
    },
  }}
              InputProps={{
                      disableUnderline: true,
                    }}/>
                    </div>
                        </div>
                      </div>
                      <div className={styles.quoteInputs4}>
                        <div className={styles.iconshomeParent}>
                          <div className={styles.quoteNumber}>Location:</div>
                          <div className={styles.input}>
                          <PlacesAutocomplete 
                          value={jloc}
                          onChange={setJloc} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            {...getInputProps({
              placeholder: 'Input Job address',
              className: 'form-control',
            })}
            fullWidth
            variant="standard"
            autoComplete="off"
          InputProps={{
                  disableUnderline: true,
                }}
          />
          <Paper style={{width:'80%'}} className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <MenuItem style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',maxWidth:'100%'}} key={index} {...getSuggestionItemProps(suggestion, { className })}>
                  {suggestion.description}
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      )}
    </PlacesAutocomplete>
                           
                          </div>
                        </div>
                      </div>
                      <div className={styles.quoteInputs6}>
                        <div className={styles.quoteNumber}>Description:</div>
                        <div className={styles.giveDetailsAbout}>
                          <TextareaAutosize
            placeholder="Input Job description"
             variant="standard"
             value={jdesc}
                  onChange={(e)=>onJdesc(e.target.value)}
              InputProps={{
                      disableUnderline: true,
                    }}
            style={{width:'100%',color:'black'}}
             minRows={3}
          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.customerInformation}>
                    <div className={styles.quoteCards4}>
                      <div className={styles.customerInformation1}>
                        Add Item
                      </div>
                    </div>
                       {
      tools.length>0?
       < >
         {tools.map((data) => (
             <div className={styles.customerInfo}>
             <div className="relative flex items-center justify-center"
                        style={{ top: '50%', left: '50%' }}>
    <div
      onClick={() => {
        setTool((prevTools) => prevTools.filter((item) => item.id !== data.id));
      }}
      className="absolute bg-[#ccc] text-sm flex items-center px-3 py-1 rounded-md text-white cursor-pointer"
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <div>
        <DeleteIcon />
      </div>
    </div>
  </div>
                        <div className={styles.quoteNumberParent}>
                          <div className={styles.quoteNumber4}>
                           {data.desc}
                          </div>
                         
                          <div className={styles.x10}>
                            <span className={styles.span}>{data.quant}</span>
                            <span className={styles.x}>X</span>
                            <span className={styles.span}> £{parseFloat(data.unit).toFixed(2)}</span>
                          </div>
                        </div>
                     
                        <div className={styles.frameGroup}>
                          <div className={styles.kvPinInsulatorsParent}>
                            <div className={styles.kvPinInsulators}>
                              {data.detail}
                            </div>
                            <div className={styles.div}>£{parseFloat(data.total).toFixed(2)}</div>
                          </div>
                          <div className={styles.kvPinInsulatorsParent}>
                            <div className={styles.kvPinInsulators}>
                              {
                                data.disc_type=='perc'?
                               <a>Discount({parseFloat(data.disc).toFixed(2)}%)</a> 
                                :
                                <a>Flat({data.disc})</a> 
                              }
                              
                            </div>
                            <div className={styles.div1}>-£{
                               data.disc_type=='perc'?
                               <a>{((data.quant*data.unit)*data.disc/100).toFixed(2)}</a> 
                                :
                                <a>{parseFloat(data.disc).toFixed(2)}</a>
                            }</div>
                          </div>
                        </div>
                       
                      </div>
           
          ))}
         
        </>
      :
     null
    }
                 
                    <button
                      className={styles.largeButtons}
                      onClick={openAddItemPopup}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                      <b className={styles.label2}>Add Item</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder7.svg"
                      />
                    </button>
                  </div>
                  <div className={styles.quoteInformationParent}>
                    <div className={styles.quoteCards4}>
                      <div className={styles.customerInformation1}>
                        Quote Total
                      </div>
                    </div>
                    <div className={styles.quoteInformation}>
                      <div className={styles.quoteCards}>
                        <div className={styles.tab1}>Discount:</div>
                        <div className={styles.qn00013}>£
                        {
                      (  ((percTools.reduce((a,v) =>  a = Number(a) + Number(v.quant),0 )*percTools.reduce((a,v) =>  a = Number(a) + Number(v.unit),0 ))*percTools.reduce((a,v) =>  a = Number(a) + Number(v.disc) ,0)/100)
                        +
                        (flatTools.reduce((a,v) =>  a = Number(a) + Number(v.disc) ,0))).toFixed(2)
                        }</div>
                          
                      </div>
                      <div className={styles.quoteCards}>
                        <div className={styles.tab1}>Tax:</div>
                        <div className={styles.qn00013}>£{((tools.reduce((a,v) =>  a = Number(a) + Number(v.quant),0 )*tools.reduce((a,v) =>  a = Number(a) + Number(v.unit),0 ))*tools.reduce((a,v) =>  a = Number(a) + Number(v.tax) ,0)/100).toFixed(2)}</div>
                      </div>
                      <div className={styles.quoteCards}>
                        <div className={styles.tab1}>Total:</div>
                        <div className={styles.qn00016}>£{tools.reduce((a,v) =>  a = Number(a) + Number(v.total),0 ).toFixed(2)}</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.advancedPayment}>
                    <div className={styles.quoteCards4}>
                      <div className={styles.customerInformation1}>
                        Advance Payment
                      </div>
                    </div>
                     {
                above?
                <div className={styles.inAppWarning}>
                        <div className={styles.youCanOnly}>
                          You can only request an advance payment from the total
                          sum of tools/materials and miscellaneous
                        </div>
                      </div>
                :
                null
              }
                    <div className={styles.quoteCards}>
                      <div className={styles.tab1}>Amount:</div>
                      <div className={styles.qn0001Parent}>
                        <div className={styles.tab1}>£<TextField 
                onChange={onAdv}
                type={'number'} style={{height:'2rem'}}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onKeyPress: (e) => {
                    const isNumber = /[0-9]/.test(e.key);
                    if (!isNumber) {
                      e.preventDefault();
                    }
                  },
                }}/></div>
                        <div className={styles.tab2}>Max: £{result}</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.quoteInformationParent}>
                    <div className={styles.quoteCards4}>
                      <div className={styles.customerInformation1}>
                        Add Note
                      </div>
                    </div>
                    <div className={styles.quoteInputs6}>
                      <div className={styles.quoteNumber}>Label:</div>
                      <div className={styles.giveDetailsAbout}>Input</div>
                    </div>
                  </div>
                </div>
                  <Snackbar
                    open={Boolean(error)}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    autoHideDuration={8000} 
                    onClose={() => setError(null)}
                >
                    <Alert
                        onClose={() => setError(null)} 
                        severity="error"
                        variant="filled"
                    >
                        {error}
                    </Alert>
                </Snackbar>
                <div className={styles.largeButtonsParent}>
                  <button
                    className={styles.largeButtons2}
                     onClick={onSend}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder111.svg"
                    />
                    <b className={styles.label10}>Send Quote</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder111.svg"
                    />
                  </button>
                  <button className={styles.largeButtons}
                   onClick={onDaft}
                  >
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder21.svg"
                    />
                    <b className={styles.label2}>Save to drafts</b>
                    <img
                      className={styles.icons20pxplaceholder}
                      alt=""
                      src="/icons20pxplaceholder21.svg"
                    />
                  </button>
                </div>
              </div>
    </>
    :
     <div className={styles.quoteForm}>
                <div className={styles.frameParent1}>
                  <div className={styles.quoteInformationParent}>
 <QoutePrev
       tools={tools}
        send_mod={send_mod}
        setTab={()=>{setTab(0)}}
        date={date}
        qkey={qkey}
        due={due}
        onClosesend={()=>setSmodal(false)}
    />
                  </div>
                  
                  <Snackbar
                    open={Boolean(error)}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    autoHideDuration={8000} 
                    onClose={() => setError(null)}
                >
                    <Alert
                        onClose={() => setError(null)} 
                        severity="error"
                        variant="filled"
                    >
                        {error}
                    </Alert>
                </Snackbar>
                  <div className={styles.largeButtonsParent}>
                    <button className={styles.largeButtons2}
                    onClick={onSend}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder111.svg"
                      />
                      <b className={styles.label10}>Send Quote</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder111.svg"
                      />
                    </button>
                    <button className={styles.largeButtons}
                    onClick={onDaft}
                    >
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder21.svg"
                      />
                      <b className={styles.label2}>Save to drafts</b>
                      <img
                        className={styles.icons20pxplaceholder}
                        alt=""
                        src="/icons20pxplaceholder21.svg"
                      />
                    </button>
                  </div>
                </div>
              </div>
     }
            </div>
          </div>
        </div>
      </div>
      {isRecentChatPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeRecentChatPopup}
        >
          <RecentChat 
          onClose={closeRecentChatPopup} 
            onCname={onCname}
            onCaddy={onCaddy}
          />
        </PortalPopup>
      )}
      {isAddItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAddItemPopup}
        >
          <AddItem onClose={closeAddItemPopup} 
          onOitem={onOitem}
          onOservice={onOservice}
          onOmic={onOmic}
          tool_desc={tool_desc}
          unit_cost={unit_cost}
          quant={quant}
          tool_disc_t={tool_disc_t}
          tool_disc={tool_disc}
          tool_tax_t={tool_tax_t}
          tool_tax={tool_tax}
          tool_detail={tool_detail}
          tools={tools}
          rate={rate}
          onTool_desc={onTool_desc}
          onUnit_cost={onUnit_cost}
          onQuant={onQuant}
          onTool_disc_t={onTool_disc_t}
          onTool_disc={onTool_disc}
          onTool_tax_t={onTool_tax_t}
          onTool_tax={onTool_tax}
          onTool_detail={onTool_detail}
          onServices={onServices}
          onRate={onRate}
          onTools={onTools}
          onServices2={onMic}
         onBack= {()=>{setOtime(false)}}
          />
        </PortalPopup>
      )}
       {oservice && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setOservice(false)}
        >
          <BookingFee onClose={()=>setOservice(false)} 
         tool_desc={tool_desc}
         unit_cost={unit_cost}
         quant={quant}
         tool_disc_t={tool_disc_t}
         tool_disc={tool_disc}
         tool_tax_t={tool_tax_t}
         tool_tax={tool_tax}
         tool_detail={tool_detail}
         tools={tools}
         rate={rate}
         onTool_desc={onTool_desc}
         onUnit_cost={onUnit_cost}
         onQuant={onQuant}
         onTool_disc_t={onTool_disc_t}
         onTool_disc={onTool_disc}
         onTool_tax_t={onTool_tax_t}
         onTool_tax={onTool_tax}
         onTool_detail={onTool_detail}
         onServices={onServices}
         onRate={onRate}/>
        </PortalPopup>
      )}
       {omic && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={()=>setOmic(false)}
        >
          <BookingFeeMic onClose={()=>setOmic(false)} 
         tool_desc={tool_desc}
         unit_cost={unit_cost}
         quant={quant}
         tool_disc_t={tool_disc_t}
         tool_disc={tool_disc}
         tool_tax_t={tool_tax_t}
         tool_tax={tool_tax}
         tool_detail={tool_detail}
         tools={tools}
         rate={rate}
         onTool_desc={onTool_desc}
         onUnit_cost={onUnit_cost}
         onQuant={onQuant}
         onTool_disc_t={onTool_disc_t}
         onTool_disc={onTool_disc}
         onTool_tax_t={onTool_tax_t}
         onTool_tax={onTool_tax}
         onTool_detail={onTool_detail}
         onServices={onMic}
         onRate={onRate}/>
        </PortalPopup>
      )}
      {isSendQuotePopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSendQuotePopup}
        >
          <SendQuote onClose={closeSendQuotePopup} qkey={qkey} onDaft={onDaft}/>
        </PortalPopup>
      )}
     </>
    :
    <Items
      tool_desc={tool_desc}
unit_cost={unit_cost}
quant={quant}
tool_disc_t={tool_disc_t}
tool_disc={tool_disc}
tool_tax_t={tool_tax_t}
tool_tax={tool_tax}
tool_detail={tool_detail}
tools={tools}
onTool_desc={onTool_desc}
onUnit_cost={onUnit_cost}
onQuant={onQuant}
onTool_disc_t={onTool_disc_t}
onTool_disc={onTool_disc}
onTool_tax_t={onTool_tax_t}
onTool_tax={onTool_tax}
onTool_detail={onTool_detail}
onTools={onTools}
onBack= {()=>{setOtime(false)}}
    />
    }
    </>
  );
};

export default CreateQuote;
