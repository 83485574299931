import styles from "./FrameComponent.module.css";
const FrameComponent = ({ onQuote }) => {
  return (
    <div className={styles.rectangleParent}>
      <div className={styles.frameChild} />
      <div className={styles.groupParent}>
        <img className={styles.groupChild} alt="" src="/group-66558.svg" />
        <div className={styles.document}>Document</div>
      </div>
      <div className={styles.cameraParent}>
        <div className={styles.camera}>Camera</div>
        <img className={styles.groupItem} alt="" src="/group-66560.svg" />
      </div>
      <div className={styles.galleryParent}>
        <div className={styles.gallery}>Gallery</div>
        <img className={styles.groupItem} alt="" src="/group-66561.svg" />
      </div>
      <div className={styles.locationParent}>
        <div className={styles.document}>Location</div>
        <img className={styles.groupIcon} alt="" src="/group-66562.svg" />
      </div>
      <div className={styles.offerParent}>
        <div className={styles.offer}>Offer</div>
        <img className={styles.groupItem} alt="" src="/group-66563.svg" />
      </div>
      <div className={styles.quoteParent} onClick={onQuote}>
        <div className={styles.quote}>Quote</div>
        <img className={styles.groupItem} alt="" src="/group-66565.svg" />
      </div>
    </div>
  );
};

export default FrameComponent;
