import { useCallback, useState } from "react";
import styles from "./ChangePlanMonth.module.css";
import { auth, db } from "../firebase";
import PortalPopup from "./PortalPopup";
import MonthlyPay from "./MonthlyPay";
import posthog from 'posthog-js'

const ChangePlanMonth = ({ onClose,reload,monthly }) => {
  const [PayPopupOpen, setPayPopupOpen] =
  useState(0);
  const closePopup = useCallback(() => {
    setPayPopupOpen(0);
  }, []);
  const handleSubmit=()=>{
    posthog.capture('Subcription: PAYE')
    db.collection('users').doc(auth.currentUser.uid).update({
      subType:1
     })
     reload()
     onClose()
  }
  return (
    <div className={PayPopupOpen==0?styles.changeplanmonth:null}>
      {
        PayPopupOpen==0?
         <div className={styles.merge}>
        <div className={styles.merge1}>
          <div className={styles.title}>
            <div className={styles.monthly}>Monthly</div>
            <div className={styles.monthlyprofessional}>
              <b>{`£${monthly} `}</b>
              <span className={styles.monthlyprofessional1}>
                Monthly/Professional
              </span>
            </div>
          </div>
          <div className={styles.points}>
            <div className={styles.div}>
              <div className={styles.subscriptionList}>
                <div className={styles.iconsscrewParent}>
                  <img
                    className={styles.iconsscrew}
                    alt=""
                    src="/iconsscrew1.svg"
                  />
                  <div className={styles.jobManagement}>Job Management</div>
                </div>
                <div className={styles.automaticallyManageYourJobsWrapper}>
                  <div className={styles.automaticallyManageYour}>
                    Automatically manage your jobs with our job management
                    feature.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.div}>
              <div className={styles.subscriptionList}>
                <div className={styles.iconsscrewParent}>
                  <img
                    className={styles.iconsscrew}
                    alt=""
                    src="/iconsscrew1.svg"
                  />
                  <div className={styles.jobManagement}>Quote</div>
                </div>
                <div className={styles.automaticallyManageYourJobsWrapper}>
                  <div className={styles.automaticallyManageYour}>
                    Send a quote to receive payment swiftly.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.div}>
              <div className={styles.subscriptionList}>
                <div className={styles.iconsscrewParent}>
                  <img
                    className={styles.iconsscrew}
                    alt=""
                    src="/iconsscrew1.svg"
                  />
                  <div className={styles.jobManagement}>Social Share</div>
                </div>
                <div className={styles.automaticallyManageYourJobsWrapper}>
                  <div className={styles.automaticallyManageYour}>
                    Share your business on any platform and get booked.
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.div}>
              <div className={styles.subscriptionList}>
                <div className={styles.iconsscrewParent}>
                  <img
                    className={styles.iconsscrew}
                    alt=""
                    src="/iconsscrew1.svg"
                  />
                  <div className={styles.jobManagement}>Vetting</div>
                </div>
                <div className={styles.automaticallyManageYourJobsWrapper}>
                  <div className={styles.automaticallyManageYour}>
                    Get vetted for free to improve your business reputation and
                    increase visibility.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.largeButtons}
            style={{cursor:'pointer'}}
               onClick={()=>{
                setPayPopupOpen(1)
              }}
          >
            <img
              className={styles.iconsmessage}
              alt=""
              src="/iconsmessage3.svg"
            />
            <b className={styles.label}>Change Plan</b>
            <img
              className={styles.iconsmessage}
              alt=""
              src="/iconsmessage4.svg"
            />
          </div>
        </div>
      </div>
      :<MonthlyPay onClose={closePopup} handleSubmit={handleSubmit}/>
      }
     
      
    </div>
  );
};

export default ChangePlanMonth;
