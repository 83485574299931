import AlertBanner2 from "./alert2.tsx";

const PasswordChangedPh = () => {
  return (
    <AlertBanner2
      title="Password changed"
      image={"/assets/images/passwordchange.svg"}
      sub="Your password has been updated successfully. you will be automatically redirected to the homepage..."
      emailSent={false}
    />
  );
};

export default PasswordChangedPh;
