import { useCallback, useEffect, useState } from "react";
import styles from "./PromoPayModal.module.css";
import { auth, db } from "../firebase";
import { Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useStripe } from "@stripe/react-stripe-js";

const MonthlyPay = ({ onClose, handleSubmit}) => {
  const navigate =useNavigate()
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardId, setCardId] = useState("");
  const [userId, setUserId]=useState('')
  const [email, setEmail]=useState('')
  const [loading, setLoading]=useState(false)
  const stripe=useStripe()

  /*const onLargeButtonsContainerClick = async () => {
    // Create a payment object with the card information
    const paymentData = {
      cardNumber,
      expirationDate,
      cvv,
      amount: 50, 
      userId,
      cardId
    };
setLoading(true)
    try {
      // Send a POST request to your server to initiate the payment process
      const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/mangopay/monthlypayment', paymentData);

      // Handle the response from your server, which may include the payment status or redirect URL
      // For example, you can redirect the user to the payment provider's page for card authentication
      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else {
        // Handle the success or error case
        console.log('Payment response:', response.data);
      }
      handleSubmit()
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
      // Handle the error case
      setLoading(false)
    }
  };*/
  const payMonth = async () => {
    const [exp_month, exp_year] =expirationDate.split('/');
    // Create a payment object with the card information
    const paymentData = {
      amount: 50, 
      userId: auth.currentUser.uid,
      custid: userId||'null',
      email,
      cardId
    };
setLoading(true)
    try {
      // Send a POST request to your server to initiate the payment process
    const response= await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/mangopay/monthlypayment', paymentData);
     const responseData = response.data;

    // Check if an action is required (3D Secure authentication)
    if (responseData.clientSecret) {
      const { setupIntent, error } = await stripe.confirmCardPayment(responseData.clientSecret, {
        payment_method: cardId, // Replace with the actual payment method ID
      });
  
      if (error) {
        console.error('Error confirming card payment:', error.message);
        setLoading(false)
      return
      } else {
        // Payment was confirmed successfully
        console.log('Payment confirmed:', setupIntent);
        handleSubmit()
      }
    } else {
      // Subscription creation was successful or encountered an error
      console.log(responseData.message, responseData.subscription);
      setLoading(false)
      return
    }
     handleSubmit()
     setLoading(false)
    } catch (error) {
      console.error('Error:', error);
      // Handle the error case
      setLoading(false)
    }
  };
  
 
  const [cards, setCards] = useState([]); 
  const cardsCollection = db.collection("users").doc(auth.currentUser.uid).collection("cards");

  useEffect(() => {
    db.collection('users').doc(auth.currentUser.uid).get().then(function (snap){
      setUserId(snap.data().Strip_payId)
      setEmail(snap.data().email)
    })
    db.collection('users').doc(auth.currentUser?.uid).get().then(function(snapshot) {
      if(snapshot.data())
      {
   // Use onSnapshot to listen for real-time changes in the "cards" subcollection
   const unsubscribe = cardsCollection.onSnapshot((querySnapshot) => {
    const cardsData = [];
    querySnapshot.forEach((doc) => {
      if(doc.id==snapshot.data()?.defaultCardId){
        cardsData.push({ id: doc.id, ...doc.data() });
        if (cardsData.length>0) {
          setCardNumber(cardsData[0].cardNumber)
       setExpirationDate(cardsData[0].expiryDatef)
       setCvv(cardsData[0].cvv)
       setCardId(cardsData[0].cardId)
       }
      }
    });
    setCards(cardsData);
  });

  // Cleanup the listener when the component unmounts
  return () => unsubscribe();
      }
    });
  }, []);
  const handleCardAction = async (clientSecret) => {
    const { paymentIntent, error } = await stripe?.handleCardAction(clientSecret);
  
    if (error) {
        // Handle errors
        console.error('Error handling card action:', error.message);
        setLoading(false)
    } else if (paymentIntent.status === 'succeeded') {
        // The payment succeeded after 3D Secure
        handleSubmit()
      setLoading(false)
        // Perform additional actions on success
    } else {
        // Handle other paymentIntent statuses after 3D Secure
        console.log('Unexpected PaymentIntent status after 3D Secure:', paymentIntent.status);
        setLoading(false)
    }
  };

  function trimCardNumberToLast4Digits(cardNumber) {
    if (typeof cardNumber !== "string") {
      console.error("Invalid card number format. Expected a string.");
      return cardNumber; // Return the input as is if it's not a string
    }
  
    // Use string slicing to get the last 4 digits of the card number
    const last4Digits = cardNumber.slice(-4);
    return last4Digits;
  }

  return (
    <div className={styles.promopaymodal}>
      <div className={styles.modalTitle}>
        <div className={styles.addANewCardParent}>
          <div className={styles.addANew}>Payment</div>
          <img className={styles.iconsclose} alt="" src="/iconsclose1.svg" onClick={onClose}/>
        </div>
      </div>
      <div className={styles.savedCardsParent}>
      {cards.length > 0 && (
        <div style={{width:'100%'}}>
          {cards.map((card) => (
             <div className={styles.savedCards} onClick={()=>{
              setCardNumber(card.cardNumber)
              setExpirationDate(card.expiryDate)
              setCvv(card.cvv)
              setCardId(card.cardId)
             }}>
             <div className={styles.frameParent}>
               <div className={styles.crosbyOdennehyParent}>
                 <div className={styles.crosbyOdennehy}>{card.nameOnCard}</div>
                 <div className={styles.transactionPills}>
                   <div className={styles.success}>DEFAULT</div>
                 </div>
               </div>
               <div className={styles.div}>**** **** **** {trimCardNumberToLast4Digits(card.cardNumber)}</div>
             </div>
             <div className={styles.iconsplaceholderParent}>
               <img
                 className={styles.iconsplaceholder}
                 alt=""
                 src="/iconsplaceholder1.svg"
               />
               <div className={styles.mastercard1Parent}>
                 <img
                   className={styles.mastercard1Icon}
                   alt=""
                   src="/mastercard-1.svg"
                 />
                <Radio
                  
                  />
               </div>
             </div>
           </div>
            ))}
        </div>
      )}
       
        <div className={styles.iconsaddParent} style={{cursor:'pointer'}} onClick={()=>navigate('/paymentmethod')}>
          <img className={styles.iconsadd} alt="" src="/iconsadd.svg" />
          <b className={styles.addAnotherCard}>Add another card</b>
        </div>
      </div>
      <button
        className={styles.largeButtons}
        disabled={loading}
        onClick={payMonth}
      >
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder31.svg"
        />
        <b className={styles.addAnotherCard}>Make payment</b>
        <img
          className={styles.icons20pxplaceholder}
          alt=""
          src="/icons20pxplaceholder31.svg"
        />
      </button>
    </div>
  );
};

export default MonthlyPay;
