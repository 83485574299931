import { useState, useCallback, useEffect } from "react";
import LocationModal from "../components/LocationModal";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import { auth, db } from "../firebase";
import { AppBar, Avatar, Box, FormControlLabel, Rating, Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import verify_styles from "./GladHere.module.css";
import CloseIcon from '@mui/icons-material/Close';
import MobileHeader from "../components/MobileHeader";
import Item from "../components/map/Item";
import { format } from 'date-fns';
import moment from "moment";
import { Badge } from "@mui/material";
import posthog from 'posthog-js'

 

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding:'1rem'
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const Home = () => {
  const [isLocationModalPopupOpen, setLocationModalPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [sub, setSub] = useState(1)
  const [address, setAddress] = useState('');
  const [ avail, setAvail]= useState(false)
  const [ verified, setVerified]= useState(false)
 const [loading, setLoading] = useState(true);
   const [list, setList] = useState([])
   const [job, setJob] = useState(1)
   const [jobc, setJobC] = useState(1)
   const [jobp, setJobP] = useState(1)
   const [survey, setSurvey] = useState('')
   const [currentDate, setCurrentDate] = useState('')
   const [review,setReview]=useState(0)
   const [unreview,setUnReview]=useState(0)
   const [rating,setRating]=useState(0)
   const [views,setViews]=useState([])
   const [msg, setMsg]= useState([])
  const [activ, setActivity]= useState([])
   const [allrating,setAllReview]=useState({
     five:0,
     four:0,
     three:0,
     two:0,
     one:0
   })
  useEffect(() => {
  const fetchData = async () => {
    if (auth.currentUser) {
      posthog.capture('Job listing view: Grid',)
      try {
        const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
        if (snapshot.exists) {
          if(snapshot.data()?.verification>=35)
          {
            if (snapshot.data()?.online) {
               setVerified(true)
            }else{
              setVerified(false)
            }
          }
          else{
            setVerified(false)
          }
          setAddress(snapshot.data()?.addy1);
          setSub(snapshot.data()?.subType);
          const cate = snapshot.data()?.category;
          const sub_cate = snapshot.data()?.sub_cate;
          const service_tags = snapshot.data()?.service_tags;
          const lat = snapshot.data()?.lat;
          const lng = snapshot.data()?.lng;
          const distance = snapshot.data()?.distance;
           let category=cate
           db.collection('prof_booking/' + auth.currentUser.uid + '/list').get().then(function (snap) {
            setJob(snap?.size||0)
          })
          db.collection('prof_booking/' + auth.currentUser.uid + '/list').where('status','==','complete').get().then(function (snap) {
            setJobC(snap?.size||0)
          })
          db.collection('prof_booking/' + auth.currentUser.uid + '/list').where('status','!=','complete').get().then(function (snap) {
            setJobP(snap?.size||0)
          })
          db.collection('reviews/').where('uid','==',auth.currentUser.uid).where('status','==','unread').get().then(function (snap) {
            setUnReview(snap?.size||0)
          })
          db.collection('reviews/').where('uid','==',auth.currentUser.uid).get().then(function (snap) {
            setReview(snap?.size||0)
          })
          db.collection("reviews")
    .where("uid", "==", auth.currentUser.uid)
    .get()
    .then((querySnapshot) => {
      let totalRating = 0;
      let numberOfReviews = querySnapshot.size;
      let counts = {
        five: 0,
        four: 0,
        three: 0,
        two: 0,
        one: 0,
      };
     

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Assuming 'stars' is the field containing the star rating in each document
        const rating = data.stars;

        // Increment the count for the corresponding rating
        if (rating === 5) {
          counts.five += 1;
        } else if (rating === 4) {
          counts.four += 1;
        } else if (rating === 3) {
          counts.three += 1;
        } else if (rating === 2) {
          counts.two += 1;
        } else if (rating === 1) {
          counts.one += 1;
        }
        totalRating += rating;
      });
      setAllReview(counts)
      if (numberOfReviews > 0) {
        console.log(totalRating)
        const avgRating = totalRating / numberOfReviews;
        setRating(Math.round(avgRating));
      }
    })
    db.collection('reviews/')
  .where('uid', '==', auth.currentUser.uid)
  .orderBy('timestamp', 'asc') // Order by timestamp in ascending order to get the oldest first
  .limit(1) // Limit the result to 1 to get the oldest review
  .get()
  .then((querySnapshot) => {
    if (!querySnapshot.empty) {
      // Get the oldest review data
      const oldestReviewData = querySnapshot.docs[0].data()
      const timestamp = oldestReviewData.timestamp.toDate();
      const formattedDate = format(timestamp, 'MM/dd/yy');
      const currentdate=format(new Date(),'MM/dd/yy')
      setCurrentDate(currentdate)
      setSurvey(formattedDate)
    } else {
      // Handle the case where there are no reviews
      console.log('No reviews found.');
    }
  })
  db.collection('profileViews').doc(auth.currentUser.uid).collection('list').orderBy('stamp','desc').onSnapshot((snapshot) => {
    const view = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setViews(view)
  })
          fetchJob(category, lat, lng, distance,sub_cate,service_tags);
          setLoading(false)
        } else {
          navigate('/personalinfo');
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    }
  };
  const fetchJob = (cate, lat, lng, distance,sub_cate,service_tags) => {
    console.log(job)
    const unsubscribe = db
      .collection('jobs/').onSnapshot((snapshot) => {
        const jobsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Filter jobs within the selected distance
        const filteredList = jobsData.filter((job) => {
          if(job.cate.toLowerCase()==cate.toLowerCase()&&
          job.subCate.toLowerCase()==sub_cate.toLowerCase()|| 
          job.subCate.toLowerCase()=='others'&&job.inactive!=true){
          const jobLatitude = job.lat;
          const jobLongitude = job.lng;

          const jobDistance = calculateDistance(lat, lng, jobLatitude, jobLongitude);

          return jobDistance <= distance;
        }
        else{
          service_tags?.map((x)=>{
          if(job.cate.toLowerCase()==cate.toLowerCase()&&
          job.subCate.toLowerCase()==x.toLowerCase()&&job.inactive!=true){
            const jobLatitude = job.lat;
            const jobLongitude = job.lng;
  
            const jobDistance = calculateDistance(lat, lng, jobLatitude, jobLongitude);
  
            return jobDistance <= distance;
          }
        })
        }
        
        });

        setList(filteredList);
      });

    return () => {
      unsubscribe();
    };
  };
  const collectionRef = db.collection('users').doc(auth.currentUser.uid).collection('noti_m');
  const collectionRef2 = db.collection('users').doc(auth.currentUser.uid).collection('noti_act');
  const updatedList = [];
  const actList = [];
  const unsubscribe1 = collectionRef.onSnapshot((snapshot) => {
    // Handle the snapshot changes
    snapshot.forEach((doc) => {
      const docData = doc.data();
      updatedList.push(docData);
    });
    setMsg(updatedList)
    });
    const unsubscribe2 = collectionRef2.onSnapshot((snapshot) => {
    // Handle the snapshot changes
    snapshot.forEach((doc) => {
      const docData = doc.data();
      actList.push(docData);
    });
    setActivity(actList)
    });
  fetchData();
  return () => {
    unsubscribe1();
    unsubscribe2();
    };
}, []);
  const handleAvailCh =async (e) => {
       try {
        const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
        if (snapshot.exists) {
          if(snapshot.data().verification>=35)
          {
            setVerified(!verified)
            db.collection('users').doc(auth.currentUser.uid).update({
              online:!verified
            })
          }
          else{
            setAvail(true)
            setVerified(false)
          }
        } 
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(lat1)) *
        Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers

    return milesToKilometers(distance);
  };

  const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const milesToKilometers = (miles) => {
    return miles * 1.60934;
  };

  const onFrameButtonClick = useCallback(async() => {
    const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
    if (snapshot.exists) {
      console.log('hrtt') 
      if(snapshot.data().verification>=35)
      {
        console.log('hrtt')
        navigate("/homeproflist");
      }
      else{
        setAvail(true)
      }
    } 
  }, [navigate]);

  const onDefaultClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  const onDefault1Click = useCallback(() => {
    navigate("/jobs");
  }, [navigate]);

  const onDefault2Click = useCallback(() => {
    navigate("/activities");
  }, [navigate]);

  const onDefault3Click = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  const onDefault4Click = useCallback(() => {
    navigate("/messages");
  }, [navigate]);

  const onDefault5Click = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const openLocationModalPopup = useCallback(async() => {
    const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
    if (snapshot.exists) {
      console.log('hrtt') 
      if(snapshot.data().verification>=35)
      {
        console.log('hrtt')
        setLocationModalPopupOpen(true);
      }
      else{
        setAvail(true)
      }
    } 
  }, []);

  const closeLocationModalPopup = useCallback(() => {
    setLocationModalPopupOpen(false);
  }, []);

  const onFrameButton2Click = useCallback(async() => {
    const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
    if (snapshot.exists) {
      console.log('hrtt') 
      if(snapshot.data().verification>=35)
      {
        console.log('hrtt')
        navigate("/homeproflist");
      }
      else{
        setAvail(true)
      }
    } 
  }, [navigate]);

  const onLoggJobsClick = useCallback(() => {
    navigate("/jobview");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (

    <>
    {
      loading?
      null
      :
       <div className={styles.home}>
        <div className={styles.homemob}>
         <MobileHeader/>
          <div className={styles.location}>
            <div className={styles.mapPinParent}
 onClick={openLocationModalPopup}
            >
              <img className={styles.mapPinIcon} alt="" src="/mappin.svg" />
              <div className={styles.frame1}>
                <img
                  className={styles.chevronUpIcon}
                  alt=""
                  src="/chevronup.svg"
                />
                <div className={styles.manchesterEngland}>
                  {address}
                </div>
              </div>
            </div>
            <div className={styles.locationChild} />
            <div className={styles.toggleToGoOnlineOrOfflineParent}>
              <div className={styles.toggleToGo}>
                Toggle to go online or offline
              </div>
              <FormControlLabel
             onChange={handleAvailCh}
        control={<IOSSwitch sx={{ m: 1 }} checked={verified}/>}
      />
            </div>
          </div>
          {
                    sub==0?
                   <div className={styles.loggJobs}>
            <div className={styles.loggJobsParent}>
              <b className={styles.loggJobs1}>Logg Jobs</b>
              <button
                className={styles.fa6SolidlistParent}
                onClick={onFrameButtonClick}
              >
                <img
                  className={styles.fa6SolidlistIcon}
                  alt=""
                  src="/fa6solidlist.svg"
                />
                <div className={styles.listView}>List view</div>
              </button>
            </div>
          
            <div className={styles.statusParent}>
             {
          list.length>0?
      list.map(doc => {
            return(
            <div className={styles.status}
            onClick={()=>{
              if (verified) {
                posthog.capture('job viewing')
                navigate("/jobview/"+doc.id)
              }
              else{
                setAvail(true)
              }
              }}>
                <img
                  className={styles.statusChild}
                    src={doc.image1}
                   
                />
                <div className={styles.statusItem} />
                <div className={styles.statusInner}>
                  <ProfileImage id={doc.id}/>
                 </div>
                
              </div>
        )
        })
        :
        <b style={{fontSize:'17px',color:'grey'}}>No jobs at your location</b>
    }
           
        
            </div>
          </div>
          :
          null
            }
         
          <div className={styles.userReviews}>
            <div className={styles.frame2}>
              <b className={styles.quote}>Quote</b>
              <div className={styles.createJobQuote}>
                Create job quote for client jobs
              </div>
              <button className={styles.button} onClick={()=>{
                 db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
                  if(snap.data().verification>=35){
                    navigate("/myquote")
                  }
                  else{
                    setAvail(true)
                  }
                 })
              }}>
                <div className={styles.iconsParent}>
                  <img className={styles.icons} alt="" src="/icons11.svg" />
                  <b className={styles.proceed}>Create quote</b>
                </div>
              </button>
            </div>
          </div>
          <div className={styles.userReviews1}>
            <div className={styles.frame3}>
              <b className={styles.userReviews2}>User Reviews</b>
            </div>
            <div className={styles.frame4}>
              <div className={styles.parent}>
                <b className={styles.b}>{review}</b>
                <div className={styles.inTheLast}>In the last {survey?moment(survey).fromNow(true):''}</div>
              </div>
            </div>
            <div className={styles.frame5}>
              <div className={styles.group}>
                <b className={styles.b1}>{unreview}</b>
                <div className={styles.unread}>Unread</div>
              </div>
            </div>
            <div className={styles.reviews} onClick={()=>navigate('/reviews')}>
              <div className={styles.toggleToGo}>See reviews</div>
              <div className={styles.reviewsChild} />
            </div>
          </div>
          <div className={styles.overallCompletedJobs}>
            <b className={styles.quote}>Overall Completed Jobs</b>
            <div className={styles.frameContainer}>
              <div className={styles.totalBookingsParent}>
                <div className={styles.totalBookings}>Total Bookings</div>
                 <Item
                  data={[1,1,1,1,job]}
                  subTitle1={job!=0?job:'0'}
                  subTitle2={''}
                  colors={["#2FA500", "#2FA500", "#2FA500", "#2FA500", "#2FA500"]}
                  radius={40}
                  hole={34}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
              </div>
              <div className={styles.completedParent}>
                <div className={styles.totalBookings}>Completed</div>
                <div className={styles.ellipseParent}>
                <Item
                  data={[1,1,1,1,jobc]}
                  subTitle1={jobc!=0?jobc:'0'}
                  subTitle2={''}
                  colors={["#FEAA3B", "#FEAA3B", "#FEAA3B", "#FEAA3B", "#FEAA3B"]}
                  radius={40}
                  hole={34}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
                </div>
              </div>
              <div className={styles.percentageParent}>
                <div className={styles.totalBookings}>Percentage</div>
                <div className={styles.container}>
                <Item
                   data={[1,1,1,job,jobc]}
                   subTitle1={`${jobc!=0?((jobc/job)*100).toFixed(1):0}%`}
                  subTitle2={''}
                  colors={(jobc/job)*100!=100?["#2FA500", "#2FA500", "#2FA500", "#FEAA3B", "#2FA500"]:["#2FA500", "#2FA500", "#2FA500", "#2FA500", "#2FA500"]}
                  radius={40}
                  hole={34}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.customerSatisfactionMeasure}>
            <b className={styles.quote}>Customer Satisfaction Measure</b>
            <div className={styles.frameDiv}>
              <div className={styles.parent1}>
              <Item
                  data={[1,1,1,1,review]}
                  subTitle1={`${allrating.five + allrating.four + allrating.three + allrating.two + allrating.one}`}
                  subTitle2={''}
                  colors={["#2FA500", "#2FA500", "#2FA500", "#2FA500", "#2FA500"]}
                  radius={40}
                  hole={34}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
              </div>
              <div className={styles.overallSatisfaction60Parent}>
                <div className={styles.overallSatisfaction60Container}>
                  <span className={styles.overallSatisfaction}>
                    Overall Satisfaction:
                  </span>
                  <span className={styles.span}>
                    <span className={styles.span1}>{` `}</span>
                    <span>{(((allrating.five + allrating.four + allrating.three) / (allrating.five + allrating.four + allrating.three + allrating.two + allrating.one)) * 100).toFixed(2)}%</span>
                  </span>
                </div>
                <div className={styles.responses140}>Responses: {allrating.five + allrating.four + allrating.three + allrating.two + allrating.one}</div>
                <div className={styles.surveyDate}>Survey Date</div>
                <div className={styles.toggleToGo}>{survey} - {currentDate}</div>
              </div>
            </div>
          </div>
          <div className={styles.customerRatings}>
            <b className={styles.quote}>Customer Ratings</b>
            <div className={styles.frameParent1}>
              <div className={styles.parent2}>
                <div className={styles.toggleToGo}>{survey} - {currentDate}</div>
                <div className={styles.reviews1}>{allrating.five + allrating.four + allrating.three + allrating.two + allrating.one} Reviews</div>
              </div>
              <div className={styles.groupParent}>
              <Rating name="read-only" value={rating} readOnly style={{color:'#2FA500'}}/>
                <div className={styles.toggle}>
                  <span className={styles.span2}>{rating}</span>
                  <span className={styles.span3}>{` `}</span>
                  <span className={styles.outOf50}>out of 5.0</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.seeWhoViewedYourProfile}>
            <div className={styles.seeWhoViewed}>
              See who viewed your profile
            </div>
            <div className={styles.whoViewedMyProfileParent}>
              {
                        views.map(data=>{
                          return(
                          <SeeWho
                          data={data}
                          type={'mob2'}
                          />
                          )
                        })
                      }
            </div>
          </div>
           <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
<div className={styles.bottomNav}>
            <div className={styles.home1} onClick={()=>navigate('/home')}>
              <b className={styles.home2}>Home</b>
              <img
                className={styles.brandLogoIcon1}
                alt=""
                src="/brand-logo32.svg"
              />
            </div>
            <div className={styles.jobs} onClick={()=>navigate('/jobs')}>
              <div className={styles.jobs1}>Jobs</div>
              <img
                className={styles.bibriefcaseFillIcon}
                alt=""
                src="/iconsjobs.svg"
              />
            </div>
            <div className={styles.wallet} onClick={()=>navigate('/wallet')}>
              <div className={styles.wallet1}>Wallet</div>
              <img
                className={styles.zondiconswallet}
                alt=""
                src="/iconslog-cash.svg"
              />
            </div>
            <div className={styles.account} onClick={()=>navigate('/profile')}>
              <div className={styles.account1}>Account</div>
              <img
                className={styles.mdiaccountIcon}
                alt=""
                src="/account4.svg"
              />
            </div>
          </div>
           </AppBar>
          
        </div>
        <div className={styles.homeprof}>
          <nav className={styles.navPrototype}>
            <div className={styles.nav}>
              <img className={styles.navChild} alt="" src="/frame-665222.svg" />
              <div className={styles.defaultParent}>
                <button className={styles.default} onClick={onDefaultClick}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconshome1.svg"
                    />
                    <div className={styles.home3}>Home</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault1Click}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconsjobs.svg"
                    />
                    <div className={styles.home4}>Jobs</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault2Click}>
                  <div className={styles.iconshomeParent}>
                  <StyledBadge badgeContent={activ.length} color="primary" >
                     <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconsactivity.svg"
                    />
                  </StyledBadge>
                   
                    <div className={styles.home4}>Activities</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault3Click}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconslog-cash.svg"
                    />
                    <div className={styles.home4}>Wallet</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault4Click}>
                  <div className={styles.iconshomeParent}>
                  <StyledBadge badgeContent={msg.length} color="primary" >
                     <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconsmessage.svg"
                    />
                  </StyledBadge>
                   
                    <div className={styles.home4}>Message</div>
                  </div>
                </button>
                <button className={styles.default1} onClick={onDefault5Click}>
                  <div className={styles.iconshomeParent}>
                    <img
                      className={styles.accountIcon}
                      alt=""
                      src="/account4.svg"
                    />
                    <div className={styles.home4}>Profile</div>
                  </div>
                </button>
              </div>
            </div>
          </nav>
          <div className={styles.headerParent}>
            <MobileHeader/>
            <div className={styles.location1}>
              <div className={styles.mapPinParent}
               onClick={openLocationModalPopup}>
                <img className={styles.mapPinIcon} alt="" src="/mappin1.svg" />
                <div className={styles.frame1}>
                  <img
                    className={styles.chevronUpIcon}
                    alt=""
                    src="/chevronup1.svg"
                  />
                  <div className={styles.manchesterEngland}>
                    {address}
                  </div>
                </div>
              </div>
              <div className={styles.locationItem} />
              <div className={styles.toggleToGoOnlineOrOfflineParent}>
                <div className={styles.toggleToGo}>
                  Toggle to go online or offline
                </div>
                <FormControlLabel
             disabled={avail}
        control={<IOSSwitch sx={{ m: 1 }}   checked={verified}/>}
      />
              </div>
            </div>
             <div className={styles.titleBars}>
                <div className={styles.frameParent4}>
                  <button
                    className={styles.iconslocationParent}
                    onClick={openLocationModalPopup}
                  >
                    <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconslocation2.svg"
                    />
                    <div className={styles.manchesterEngland2}>
                     {address}
                    </div>
                    <img
                      className={styles.iconshome}
                      alt=""
                      src="/iconschevrondown1.svg"
                    />
                  </button>
                  <div className={styles.lineDiv} />
                  <div className={styles.toggleToGoOnlineOrOfflineContainer}>
                    <div className={styles.toggleToGo2}>
                      Toggle to go online or offline
                    </div>
                    <FormControlLabel
             onChange={handleAvailCh}
        control={<IOSSwitch sx={{ m: 1 }}   checked={verified}/>}
      />
                  </div>
                </div>
              </div>
            <div className={styles.titleBarsParent}>
              <div className={styles.frameParent5}>
                <div className={styles.frameWrapper}>
                  <div className={styles.loggJobsGroup}>
                  {
                    sub==0?
                    <div className={styles.loggJobs2}>
                      <div className={styles.loggJobsParent}>
                        <div className={styles.loggJobs3}>Logg jobs</div>
                        <button
                          className={styles.fa6SolidlistParent}
                          onClick={onFrameButton2Click}
                        >
                          <img
                            className={styles.fa6SolidlistIcon}
                            alt=""
                            src="/fa6solidlist1.svg"
                          />
                          <div className={styles.listView}>List view</div>
                        </button>
                      </div>
                      <div className={styles.loggJobsParent1}>
                       {
          list.length>0?
      list.map(doc => {
            return(
              <div className={styles.status}>
                 <img
          className={styles.loggJobsIcon}
          alt=""
          src={doc.image1}
          onClick={()=>{
            if (verified) {
              posthog.capture('job viewing',)
              navigate("/jobview/"+doc.id)
            }
            else{
              setAvail(true)
            }
            }}
        />
        <div className={styles.statusInner}>
                  <ProfileImage id={doc.id}/>
                 </div>
              </div>
        
        )
        })
        :
        <b style={{fontSize:'17px',color:'grey'}}>No jobs at your location</b>
    }
                      </div>
                    </div>
                    :
                    null
                  }
                    <div className={styles.quote1}>
                      <div className={styles.quoteParent}>
                        <div className={styles.quote2}>Quote</div>
                        <div className={styles.createJobQuote1}>
                          Create job quote for client jobs
                        </div>
                      </div>
                      <button
                        className={styles.largeButtons}
                        onClick={()=>{
                          db.collection('users').doc(auth.currentUser.uid).get().then(function (snap) {
                            if(snap.data().verification>=35){
                              navigate("/myquote")
                            }
                            else{
                              setAvail(true)
                            }
                           })
                          }}
                      >
                        <img
                          className={styles.icons}
                          alt=""
                          src="/iconsadd111.svg"
                        />
                        <b className={styles.label}>Create Quote</b>
                        <img
                          className={styles.iconsadd1}
                          alt=""
                          src="/iconsadd21.svg"
                        />
                      </button>
                    </div>
                    <div className={styles.overallCompletedJob}>
                      <div className={styles.userReviewsWrapper}>
                        <div className={styles.loggJobs3}>User Reviews</div>
                      </div>
                      <div className={styles.frameParent6}>
                        <div className={styles.frameWrapper1}>
                          <div className={styles.parent3}>
                            <div className={styles.div10}>{review}</div>
                            <div className={styles.inTheLast1}>
                              In the last {survey?moment(survey).fromNow(true):''}
                            </div>
                          </div>
                        </div>
                        <div className={styles.frameWrapper2}>
                          <div className={styles.parent4}>
                            <div className={styles.loggJobs3}>{unreview}</div>
                            <div className={styles.unread1}>Unread</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.reviews} style={{cursor:'pointer',color:'green'}} 
                    onClick={()=>navigate('/reviews')}>
              <div className={styles.toggleToGo}>See reviews</div>
              <div className={styles.reviewsChild} />
            </div>
                    <div className={styles.frameParent7}>
                      <div className={styles.userReviewsWrapper}>
                        <div className={styles.loggJobs3}>
                          Overall completed Job
                        </div>
                      </div>
                      <div className={styles.frameParent8}>
                        <div className={styles.frameParent9}>
                        <Item
                  data={[1,1,1,1,job]}
                  subTitle1={job!=0?job:'0'}
                  subTitle2={''}
                  colors={["#2FA500", "#2FA500", "#2FA500", "#2FA500", "#2FA500"]}
                  radius={53}
                  hole={45}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
                          <div className={styles.totalBookings1}>
                            Total Bookings
                          </div>
                        </div>
                        <div className={styles.frameParent10}>
                        <Item
                  data={[1,1,1,1,jobc]}
                  subTitle1={jobc!=0?jobc:'0'}
                  subTitle2={''}
                  colors={["#FEAA3B", "#FEAA3B", "#FEAA3B", "#FEAA3B", "#FEAA3B"]}
                  radius={53}
                  hole={45}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
                          <div className={styles.totalBookings1}>Completed</div>
                        </div>
                        <div className={styles.frameParent11}>
                        <Item
                  data={[1,1,1,job,jobc]}
                  subTitle1={`${jobc!=0?((jobc/job)*100).toFixed(1):0}%`}
                  subTitle2={''}
                  colors={(jobc/job)*100!=100?["#2FA500", "#2FA500", "#2FA500", "#FEAA3B", "#2FA500"]:["#2FA500", "#2FA500", "#2FA500", "#2FA500", "#2FA500"]}
                  radius={53}
                  hole={45}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
                          <div className={styles.percentage1}>Percentage</div>
                        </div>
                      </div>
                    </div>
                  
                    <div className={styles.customerSatisfaction}>
                      <div className={styles.quote2}>
                        Customer Satisfaction Measure
                      </div>
                      <div className={styles.frameParent12}>
                        <div className={styles.frameWrapper3}>
                        <Item
                  data={[1,1,1,1,review]}
                  subTitle1={`${allrating.five + allrating.four + allrating.three + allrating.two + allrating.one}`}
                  subTitle2={''}
                  colors={["#2FA500", "#2FA500", "#2FA500", "#2FA500", "#2FA500"]}
                  radius={53}
                  hole={45}
                  stroke={1}
                  strokeWidth={10}
                  showLabel={false}
                  currency={''}
                />
                        </div>
                        <div className={styles.frameParent13}>
                          <div className={styles.overallSatisfaction60Group}>
                            <div
                              className={styles.overallSatisfaction60Container1}
                            >
                              <span className={styles.overallSatisfaction}>
                                <span>Overall Satisfaction:</span>
                                <span className={styles.span1}>{` `}</span>
                              </span>
                              <span className={styles.span}>{(((allrating.five + allrating.four + allrating.three) / (allrating.five + allrating.four + allrating.three + allrating.two + allrating.one)) * 100).toFixed(2)}%</span>
                            </div>
                            <div className={styles.responses1401}>
                              Responses: {allrating.five + allrating.four + allrating.three + allrating.two + allrating.one}
                            </div>
                          </div>
                          <div className={styles.surveyDateParent}>
                            <div
                              className={styles.overallSatisfaction60Container1}
                            >
                              Survey Date
                            </div>
                            <div className={styles.div16}>
                            {survey} - {currentDate}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.customerRatingsParent}>
                      <div className={styles.quote2}>Customer Ratings</div>
                      <div className={styles.frameParent14}>
                        <div className={styles.parent6}>
                          <div className={styles.div17}>
                          {survey} - {currentDate}
                          </div>
                          <div className={styles.starRatingParent}>
                          <Rating name="read-only" value={rating} readOnly style={{color:'#2FA500'}}/>
                          </div>
                        </div>
                        <div className={styles.loggJobsParent}>
                          <div className={styles.div17}>{allrating.five + allrating.four + allrating.three + allrating.two + allrating.one} Reviews</div>
                          <div className={styles.outOf50Container1}>
                            <b>{rating}</b>
                            <span className={styles.outOf501}> out of 5.0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.seeWhoViewedYourProfile1}>
                      <div className={styles.seeWhoViewed}>
                        See who viewed your profile
                      </div>
                      <div className={styles.whoViewedMyProfileParent}>
                        {
                        views.map(data=>{
                          return(
                          <SeeWho
                          data={data}
                          type={'mob'}
                          />
                          )
                        })
                      }
                      </div>
                    </div>
                     <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                      <div className={styles.bottomNav1}>
                      <div className={styles.home1} onClick={()=>navigate('/home')}>
                        <b className={styles.home2}>Home</b>
                        <img
                          className={styles.brandLogoIcon1}
                          alt=""
                          src="/brand-logo21.svg"
                        />
                      </div>
                      <div className={styles.jobs} onClick={()=>navigate('/jobs')}>
                        <div className={styles.jobs1}>Jobs</div>
                        <img
                          className={styles.bibriefcaseFillIcon}
                          alt=""
                          src="/iconsjobs.svg"
                        />
                      </div>
                      <div className={styles.wallet} onClick={()=>navigate('/wallet')}>
                        <div className={styles.wallet1}>Wallet</div>
                        <img
                          className={styles.zondiconswallet}
                          alt=""
                          src="/iconslog-cash.svg"
                        />
                      </div>
                      <div className={styles.account} onClick={()=>navigate('/profile')}>
                        <div className={styles.account1}>Account</div>
                        <img
                          className={styles.mdiaccountIcon}
                          alt=""
                          src="/account4.svg"
                        />
                      </div>
                    </div>
                     </AppBar>
                    
                  </div>
                </div>
                <div className={styles.frameParent15}>
                  <div className={styles.seeWhoViewedYourProfileWrapper}>
                    <div className={styles.loggJobs3}>
                      See who viewed your profile
                    </div>
                  </div>
                  <div className={styles.profileViewsWrapper}>
                    <div className={styles.profileViews}>
                      {
                        views.map(data=>{
                          return(
                          <SeeWho
                          data={data}
                          type={'desk'}
                          />
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
   <Modal
        open={avail}
        onClose={()=>setAvail(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className={verify_styles.gladheremodal}>
          <div>
            <div style={{ display:'flex', justifyContent:'right',marginRight:'1rem'}} onClick={()=>setAvail(false)}>
             <CloseIcon/>
            </div>
            <div className={verify_styles.frameWrapper}>
            <div className={verify_styles.weAreGladYoureHereParent}>
              <b className={verify_styles.weAreGlad}>We are glad you’re here.</b>
              <div className={verify_styles.youWillNeedContainer}>
                <p className={verify_styles.youWillNeed}>
                  You will need to upload some documents
                </p>
                <p className={verify_styles.youWillNeed}>
                  to verify you as a Service provider?
                </p>
              </div>
            </div>
          </div>
          <div style={{width:'100%', display:'flex', justifyContent:'center'}} onClick={()=>setAvail(false)}>
          <img className={verify_styles.hiFiveIcon} alt="" src="/hifive.svg" />
            </div>
          <div className={verify_styles.largeButtonsParent}>
            <button className={verify_styles.largeButtons} onClick={()=>{navigate('/profile')}}>
              <b className={verify_styles.label}>Verify Profile Now</b>
            </button>
          </div>
          </div>
        </div>
         
        </Box>
      </Modal>
     {isLocationModalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLocationModalPopup}
        >
          <LocationModal onClose={closeLocationModalPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default Home;

const ProfileImage=({id})=>{
  const [profile,setProfile]=useState('')
  useEffect(() => {
    db.collection('jobs/').doc(id).get().then(snapshot=> {
   db.collection('customers').doc(snapshot.data().uid).get().then(function (snap) {
    setProfile(snap.data()?.profileImage||'')
   })
  })
  }, [])
  
  return(
    <Avatar
    src={profile||""}
    style={{width:'35px',height:'32px'}}
    />
  )
}

const SeeWho=({data,type})=>{
  const [profile,setProfile]=useState('')
  const [name,setName]=useState('')
  const [addy,setAddress]=useState('')
  useEffect(() => {
    db.collection('customers').doc(data.uid).get().then(function (snap) {
      setProfile(snap.data()?.profileImage)
      setName(snap.data()?.lname+' '+snap.data()?.fname)
      setAddress(snap.data()?.address)
     })
  }, [])
  
  return(
    <>
    {
      type=='desk'?
       <div className={styles.seeWhoViewedMyProfile}>
    <div className={styles.pm10}>{moment(data.unix).fromNow(true)}</div>
    <div className={styles.avatarParent}>
      <Avatar
      className={styles.avatarIcon}
      src={profile||""}
      />
      <div className={styles.overallSatisfaction60Group}>
        <div className={styles.jamesBorden2}>
          {name}
        </div>
        <div className={styles.ontarioUsa2}>
          {addy}
        </div>
      </div>
    </div>
  </div>
  : type=='mob'?
  <div className={styles.whoViewedMyProfile}>
  <div className={styles.whoViewedMyProfileChild} />
  <Avatar
      className={styles.whoViewedMyProfileItem}
      src={profile||""}
      />
  <div className={styles.jamesBorden}>{name}</div>
  <div className={styles.ontarioUsa}>{addy}</div>
  <div className={styles.pm}>{moment(data.unix).fromNow(true)}</div>
</div>:
  <div className={styles.whoViewedMyProfile}>
  <div className={styles.whoViewedMyProfileChild} />
  <Avatar
      className={styles.whoViewedMyProfileItem}
      src={profile||""}
      />
  <div className={styles.jamesBorden}>{name}</div>
  <div className={styles.ontarioUsa}>{addy}</div>
  <div className={styles.pm}>{moment(data.unix).fromNow(true)}</div>
</div>
    }
   
    </>
    
  )
}