import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CancelBooking.module.css";
import { auth, db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import { useEffect } from "react";
import axios from "axios";
import posthog from 'posthog-js'

 


const CancelBooking = ({onClose,selected,cid}) => {
  const navigate = useNavigate();
  const [userId, setUserId]=useState('')
  const [profId, setProfId]=useState('')
  const [wallet, setWallet]=useState('')
  const [mywallet, setMyWallet]=useState('')
  useEffect(() => {
    db.collection('users').doc(auth.currentUser?.uid).get().then(function(snapshot) {
      if(snapshot.data())
      {
        setUserId(snapshot.data()?.payId)
        setMyWallet(snapshot.data()?.wallet)
      }
    });
    db.collection('customers').doc(cid).get().then(function(snapshot) {
      if(snapshot.data())
      {
        setWallet(snapshot.data()?.wallet)
      }
    });
  }, [])
  
  function formatCurrentDate() {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    
    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour < 12 ? 'am' : 'pm';
    const formattedTime = `${hour % 12 || 12}:${minute.toString().padStart(2, '0')}${period}`;
  
    return formattedDate;
  }
  
  const formattedCurrentDate = formatCurrentDate();
  
  const handleClick=()=>{
    db.collection('prof_booking/'+auth.currentUser?.uid+'/list').doc(selected).update({
      status:'cancel'
    })
    db.collection('cust_booking/'+cid+'/list').doc(selected).update({
      status:'cancel'
    })
    db.collection('bookings/').doc(selected).update({
      status:'cancel'
    })
    db.collection('wallet/'+auth.currentUser.uid+'/balance').doc(selected).update({
      status:'cancel'
    })
    db.collection('wallet/'+auth.currentUser.uid+'/balance').doc(selected).get().then(function (snap) {
    if(snap.data()){
      const transferData={
        userId: userId,
        sourceWalletId: mywallet,
        destinationWalletId: wallet, 
        amount: snap.data().pend, 
        fee:0
      }
      Refund(transferData)
      db.collection('activity/cust/'+cid).add({
        uid:cid,
        type:'payment',
        msg:`${snap.data().pend} GBP was refunded to your wallet`,
        date: formattedCurrentDate,
        name: '',
        unix: Date.now(),
        stamp: serverTimestamp()
      })
        db.collection('wallet_cust/'+cid+'/balance').add({
        type: 'Job Refund',
        pend: snap.data().pend,
        stamp: serverTimestamp(),
        status:'dep',
        date: formattedCurrentDate
      })
      }
    })
   
    db.collection('users/').doc(auth.currentUser?.uid).get().then(function (snap) {
      db.collection('activity/cust/'+cid).add({
        uid:cid,
        type:'booking',
        msg:'Unfortunately, Your booking was cancelled by',
        date: formattedCurrentDate,
        name: snap.data().name,
        unix: Date.now(),
        stamp: serverTimestamp()
      })
    })
    db.collection('customers').doc(cid).get().then(function (snap) {
      db.collection('bookings/').doc(selected).get().then(function (data) {
        db.collection('customers').doc(cid).get().then(function (customer) {
          posthog.capture(`Job Cancelled`,
          {'customers name': snap.data().name, 
          Service: snap.data().category, location: data.data().qoute.jloc, 'job date': data.data().jdate,
           QuoteNum:data.data().qid, 'job desc':data.data().jdesc})
          if (customer.data().emailNotifications) {
            fetch('https://loggworks-c15c9e56f169.herokuapp.com/prof_booking_cancel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: snap.data().email,
        name: snap.data().name,
        quote: data.data().qid,
        location:data.data().qoute.jloc,
        schedule:data.data().qoute.jdate,
        service:snap.data().category
      }),
    })
          }
        })

      })
    })
    db.collection('customers').doc(cid).get().then(function (customer) {
      if (customer.data().pushNotifications) {
        db.collection('customers').doc(cid).collection('noti_act').add({
      last: 'Your booking was cancelled',
      name: '',
     })
      }
    })
   
    onClose()
  }
  const Refund=async(transferData)=>
  {
    try{
      await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/mangopay/transfer', transferData);
    }
    catch(e){

    }
  }
  return (
    <div className={styles.cancelBooking}>
      <div className={styles.modalTitleParent}>
        <div className={styles.modalTitle}>
          <img className={styles.iconsclose} alt="" src="/iconsclose.svg"
           style={{cursor:'pointer'}} onClick={onClose} />
        </div>
        <div className={styles.frameParent}>
          <div className={styles.frameParent}>
            <img
              className={styles.trashLineIcon}
              alt=""
              src="/trash-line.svg"
            />
            <div className={styles.cancelBookingParent}>
              <div className={styles.cancelBooking1}>Cancel Booking</div>
              <div className={styles.areYouSure}>
                Are you sure you want to cancel booking?
              </div>
            </div>
          </div>
          <div className={styles.input} >
            <div className={styles.labelContainer}>
              <div className={styles.label}>Enter a reason for cancelling</div>
            </div>
            <input className={styles.baseField} type="text" />
            <div className={styles.helperTextContainer}>
              <div className={styles.thisIsA}>This is a helper text</div>
              <div className={styles.div}>1/100</div>
            </div>
          </div>
          <div className={styles.largeButtonsParent}>
            <div className={styles.largeButtons} style={{cursor:'pointer'}} onClick={onClose}>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder.svg"
              />
              <b className={styles.label1}>Exit</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder.svg"
              />
            </div>
            <div className={styles.largeButtons1} style={{cursor:'pointer'}} onClick={handleClick}>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder1.svg"
              />
              <b className={styles.label1}>Cancel Booking</b>
              <img
                className={styles.icons20pxplaceholder}
                alt=""
                src="/icons20pxplaceholder1.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelBooking;
