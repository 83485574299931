import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import app, { firebase, auth, signInWithGoogle, db, signInWithFacebook } from '../firebase';
import { TextField, IconButton, InputAdornment } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Verify from "./Verify";
import { Alert, AlertTitle } from "@mui/material";
import axios from "axios";


const SignUp1 = () => {
 const [phone,setPhone] = useState("")
  const [password,setPassword] = useState('')
  const [show, setShow] = useState(false);
  const [final, setfinal] = useState('');
  const [sent, setSent] = useState(false);
  const [tabs,setTab]=useState(0)
  const [emailError, setEmailError] = useState("");
  const recaptchaRef = useRef(null);
  const [passwordError, setPasswordError] = useState("");
  const [loading,setLoading]=useState(false)


  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  
    // Sent OTP
    const signin = () => {
      if(tabs==0){
        if (phone === "" || phone.length < 10) return;
        console.log(phone)
        let phoneNo='+'+phone
        setLoading(true)
        app.auth().signInWithPhoneNumber(phoneNo, recaptchaRef.current).then((result) => {
            setfinal(result);
            setSent(true)
            setLoading(false)
        })
            .catch((err) => {
                console.log(err.message)
                setEmailError('Unable to send OTP please try again later')
                setLoading(false)
            });
          }
          else{
            signInWithEmailAndPassword()
          }
    }
    const isValidEmail = (email) => {
      // Regular expression pattern for a basic email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    
    const validatePassword = (password) => {
      const minLength = 8; // Minimum password length
      const hasUpperCase = /[A-Z]/.test(password); // Check for uppercase letter
      const hasLowerCase = /[a-z]/.test(password); // Check for lowercase letter
      const hasDigit = /[0-9]/.test(password); // Check for digit
    
      if (
        password.length < minLength ||
        !hasUpperCase ||
        !hasLowerCase ||
        !hasDigit
      ) {
        return false;
      }
    
      return true;
    };
    
// Function to sign up a user with email and password
async function signInWithEmailAndPassword() {
  try {
    if (!isValidEmail(phone)) {
      setEmailError("Invalid email format");
      return;
    }
    setLoading(true)

    const userCredential = await auth.createUserWithEmailAndPassword( phone, password);
    const user = userCredential.user;
       
    if(user.uid)
    {
    db.collection('users').doc(user.uid).get().then(function(snapshot) {
      if(snapshot.data()?.name)
            {
              db.collection('users').doc(auth.currentUser.uid).update({
                email:phone
              })
              navigate('/home')
            }
            else
            {
              db.collection('users').doc(auth.currentUser.uid).set({
                email:phone
              })
              if (user.emailVerified) {
                // If user.emailVerified is true, navigate to the '/personalinfo' page
                navigate('/personalinfo');
                return
              }
              (async () => {
                try {
                  let email = phone;
                  //const customUrl = `https://www.professional.loggworks.co.uk/email-verified`;
                  const response = await axios.post('https://loggworks-c15c9e56f169.herokuapp.com/firecreateUser');
                  console.log(response.data.code)
                  await fetch('https://loggworks-c15c9e56f169.herokuapp.com/verifyuser', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      to: email,
                      name: 'user',
                      code: response.data.code,
                    }),
                  }).then(()=>{
                    console.log(response.data.code)
                    db.collection('otp').doc(email).set({
                      otp: `${response.data.code}`
                    })
                  // After both axios and fetch are completed, navigate to the confirmation page
                  navigate("/confirm-email/" + email);
                  });
                 
                } catch (error) {
                  setEmailError('Email already in use.');
                  // Handle any errors that might occur during the asynchronous operations
                  console.error('Error:', error);
                }
              })();
              
            }
            setLoading(false)
    });
    }
  
  } catch (error) {
    console.error("Error signing up:", error.message);
    if (error.code === 'auth/email-already-in-use') {
      // Handle the case where the email (or phone in your case) is already in use
      setEmailError('Email already in use.');
      // You might want to redirect the user to a login page or display an error message
    }
    setLoading(false)
  }
}
 const navigate = useNavigate()
  useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
      // You can provide optional parameters here
      size: "invisible", // "normal" or "compact"
      callback: () => {
        // This callback will be triggered when the reCAPTCHA verification is successful
      },
      "expired-callback": () => {
        // This callback will be triggered when the reCAPTCHA verification expires
      },
    });
    // Assign the RecaptchaVerifier instance to the ref
    recaptchaRef.current = recaptchaVerifier;

  }, [])

  const onSignUpContainerClick = useCallback(() => {
    navigate("/otp");
  }, [navigate]);

  const onLargeButtonsClick = useCallback(() => {
    // Please sync "Onboarding 2" to the project
  }, []);

  const onAlreadyHaveAnClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div>
    {
      !sent?
      <div className={styles.signUp} >
      <div className={styles.img}>
        <img className={styles.logoIcon} alt="" src="/logo1.svg" />
        <div className={styles.testimonial}>
          <div className={styles.greatSessionDaniWasSuperParent}>
            <div className={styles.greatSessionDani}>
              "Great session! Dani was super helpful. She shared some practical
              advice on how can we go about refining our service offerings."
            </div>
            <div className={styles.frameParent}>
              <div className={styles.ellipseParent}>
                <img
                  className={styles.frameChild}
                  alt=""
                  src="/ellipse-249@2x.png"
                />
                <div className={styles.cameronWilliamson}>
                  Cameron Williamson
                </div>
              </div>
              <div className={styles.starRating}>
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar2.svg"
                />
                <img
                  className={styles.icons16pxstar}
                  alt=""
                  src="/icons16pxstar11.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.signUpInner}>
        <div className={styles.joinLoggworksForFreeParent}>
          <b className={styles.joinLoggworksFor}>Join Loggworks for free</b>
          {emailError && (
  <Alert severity="error">
    {emailError}
  </Alert>
)}
          <div className={styles.login}>
            <div className={styles.greatSessionDaniWasSuperParent}>
              <div className={styles.verifyForm}>
                <div className={styles.inputs}>
                  <div className={styles.largeInputs}>
                    <div className={styles.label}><a>{tabs==0?'Phone number':'Email Address'}</a></div>
                    <div className={styles.field}>
                      {
                        tabs==0?
                        <PhoneInput
                        country={'gb'}
                        value={phone}
                        onChange={e => setPhone(e)}
                        inputStyle={{
                          width:'100%',
                          height:'3rem'
                        }}
                      />
                      :
                      <TextField
                      fullWidth
                      value={phone}
                      onChange={(e)=>{setPhone(e.target.value)}}
                      />
                      }
                     
                    </div>
                    
                  </div>
                  <div className={styles.largeInputs1}>
                    <>
                     <div className={styles.label1}>Password</div>
                    <div style={{width:'100%'}}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        type={show ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (!validatePassword(e.target.value)) {
                            setPasswordError(
                              "Your password doesn’t meet the requirements."
                            );
                          } else {
                            setPasswordError("");
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                  >
                                      {show ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                              </InputAdornment>
                          )
                      }}
                    /> 
                    {passwordError && (
  <div style={{ color: "red",fontSize:'14px' }}>{passwordError}</div>
)}
{passwordError && (
  <img
  src="./assets/failed.svg"
  />
)}
 {!passwordError && (
  <img
  src="./assets/success.svg"
  />
)}
                    </div>
                    </>
                    <div id="recaptcha-container" /> 
                  </div>
                </div>
                {
                  tabs==0?
                  <button
                  className={styles.largeButtons}
                  onClick={signin}
                  disabled={loading}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                  <b className={styles.label2}>Verify phone number</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                </button>

                  :
                  <button
                  className={styles.largeButtons}
                  onClick={signin}
                  disabled={loading}
                >
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                  <b className={styles.label2}>Register With Email Address</b>
                  <img
                    className={styles.icons20pxplaceholder}
                    alt=""
                    src="/icons20pxplaceholder5.svg"
                  />
                </button>
                }
              </div>
              <div className={styles.demarcation}>
                <div className={styles.orWrapper}>
                  <div className={styles.or}>OR</div>
                </div>
                <div className={styles.demarcationChild} />
              </div>
              <div className={styles.socialLogin}>
                <button className={styles.largeButtons1} onClick={signInWithFacebook}>
                  <img
                    className={styles.iconsfacebook}
                    alt=""
                    src="/iconsfacebook1.svg"
                  />
                  <b className={styles.label2}>Continue with Facebook</b>
                  <img
                    className={styles.iconsfacebook1}
                    alt=""
                    src="/iconsfacebook1.svg"
                  />
                </button>
                <button className={styles.largeButtons2} onClick={signInWithGoogle}>
                  <img
                    className={styles.iconsfacebook}
                    alt=""
                    src="/iconsgoogle1.svg"
                  />
                  <b className={styles.label4}>Continue with Google</b>
                  <img
                    className={styles.iconsfacebook1}
                    alt=""
                    src="/iconsgoogle1.svg"
                  />
                </button>
                {
            tabs==1?
            <button onClick={()=>setTab(0)} className="w-full h-[48px] text-[#000000] font-bold py-2 px-4 border border-[#000000] rounded flex justify-center items-center mb-4">
            <div className="icon">
            <img
                src={"/assets/icons/tabler_phone.svg"}
                height={20}
                width={20}
                alt="icon"
                className="mr-2"
              />
            </div>
            <span>Continue with Phone number</span>
          </button>
          :
          <button onClick={()=>setTab(1)}  className="w-full h-[48px] text-[#000000] font-bold py-2 px-4 border border-[#000000] rounded flex justify-center items-center mb-4">
          <div className="icon">
          <img
              src={"/assets/icons/Icons_mail2.svg"}
              height={20}
              width={20}
              alt="icon"
              className="mr-2"
            />
          </div>
          <span>Continue with Email</span>
        </button>
          }
              </div>
            </div>
            <div
              className={styles.alreadyHaveAnContainer}
              onClick={onAlreadyHaveAnClick}
            >
              <span
                className={styles.alreadyHaveAn}
              >{`Already have an account? `}</span>
              <span className={styles.logIn}>Log in</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    :
    <Verify final={final} phone={phone} check={false} password={password} recaptchaRef={recaptchaRef}/>
    }
      
    </div>
    
  );
};

export default SignUp1;
