import { useState, useCallback, useEffect } from "react";
import {
  TextField,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
  Switch,
  FormControlLabel,
} from "@mui/material";
import LocationModal from "./LocationModal";
import PortalPopup from "./PortalPopup";
import styles from "./AvailModal.module.css";
import { styled } from '@mui/material/styles';
import { auth, db } from "../firebase";
import axios from 'axios';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const AvailModal = ({ onClose, onLocation }) => {
  const [isLocationModalPopupOpen, setLocationModalPopupOpen] = useState(false);
  const [ verified, setVerified]= useState(false)
  const [ verifiedshc, setVerifiedsch]= useState(false)
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [selectedDays, setSelectedDays] = useState(['none']);

  useEffect(() => {
 const fetchData =async() =>{
  const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
  if (snapshot.exists) {
    setToTime(snapshot.data().toTime)
    setFromTime(snapshot.data().fromTime)
    setSelectedDays(snapshot.data().selectedDays)
    setVerifiedsch(snapshot.data().schedule)
    if(snapshot.data()?.verification>=35)
    {
      if (snapshot.data()?.online) {
         setVerified(true)
      }else{
        setVerified(false)
      }
    }
    else{
      setVerified(false)
    }
  }
 }
 fetchData()

  }, [])
  
  const handleAvailCh =async (e) => {
    try {
     const snapshot = await db.collection('users').doc(auth.currentUser.uid).get();
     if (snapshot.exists) {
      setVerified(!verified)
      db.collection('users').doc(auth.currentUser.uid).update({
        online:!verified
      })
     } 
   } catch (error) {
     console.log('Error fetching user data:', error);
   }
};
const handleSchAv =async (e) => {
  setVerifiedsch(!verifiedshc)
};

  const onToggle1Click = useCallback(() => {
    // Please sync "Log cash (Transactions)" to the project
  }, []);

  return (
    <>
      <div className={styles.availmodal}>
      <div className={styles.modalTitle}>
            <div className={styles.addANewCardParent}>
              <div className={styles.addANew}>{`Location & Availabilty`}</div>
              <img
                className={styles.iconsclose}
                alt=""
                src="/iconsclose1.svg"
                onClick={onClose}
              />
            </div>
          </div>
        <br/>
        <div className={styles.modalTitleParent}>
          <div className={styles.frameParent}>
            <div className={styles.twoTabSwitchWrapper}>
              <div className={styles.twoTabSwitch}>
                <button
                  className={styles.tab1Wrapper}
                  onClick={onLocation}
                >
                  <div className={styles.tab1}>Location</div>
                </button>
                <div className={styles.tab2Wrapper}>
                  <div className={styles.tab2}>Availability</div>
                </div>
              </div>
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.toggleToGoOnlineOrOfflineParent}>
                  <div className={styles.toggleToGo}>
                    Toggle to go online or offline
                  </div>
                  <FormControlLabel
             onChange={handleAvailCh}
        control={<IOSSwitch sx={{ m: 1 }} checked={verified}/>}
      />
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.frameParent1}>
                    <div className={styles.createAScheduleParent}>
                      <div className={styles.addANew}>Create a schedule</div>
                      <FormControlLabel
             onChange={handleSchAv}
        control={<IOSSwitch sx={{ m: 1 }}  checked={verifiedshc}/>}
      />
                    </div>
                    <div className={styles.useThisTo}>
                      Use this to automatically create availability periods
                    </div>
                  </div>
                  <div className={styles.timeParent}>
                    <div className={styles.addANew}>Time</div>
                    <div className={styles.inputParent}>
                    <div className={styles.input1}>
                        <div className={styles.labelContainer}>
                          <div className={styles.label}>From</div>
                        </div>
                        <TextField
                          className={styles.baseField}
                          fullWidth
                          color="primary"
                          variant="outlined"
                          type="time"
                          placeholder="Placeholder"
                          size="medium"
                          margin="none"
                          value={fromTime} onChange={(e) => {
                            setFromTime(e.target.value)
                          }}
                        />
                      </div>
                      <div className={styles.input1}>
                        <div className={styles.labelContainer}>
                          <div className={styles.label}>To</div>
                        </div>
                        <TextField
                          className={styles.baseField}
                          fullWidth
                          color="primary"
                          variant="outlined"
                          type="time"
                          placeholder="Placeholder"
                          size="medium"
                          margin="none"
                          value={toTime} onChange={(e) => {
                            setToTime(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.timeParent}>
                    <div className={styles.addANew}>Days</div>
                    <div className={styles.frameParent2}>
                      <div className={styles.frameParent3}>
                        <div className={styles.mondayParent}>
                          <div className={styles.monday}>Monday</div>
                          <MuiFormControlLabel
                            className={styles.toggle}
                            label=""
                            value="Monday"
                            checked={ selectedDays?.includes('Monday')}
                            onChange={() =>
                              setSelectedDays((prevDays) =>
                                 prevDays && prevDays.includes('Monday')
                                  ?  prevDays?.filter((day) => day !== 'Monday')
                                  : [...(prevDays || []), 'Monday']
                              )
                            }
                            control={
                              <MuiCheckbox color="success" size="medium" 
                              />
                            }
                          />
                        </div>
                        <div className={styles.mondayParent}>
                          <div className={styles.monday}>Tuesday</div>
                          <MuiFormControlLabel
                            className={styles.toggle}
                            label=""
                            value="Tuesday"
              checked={ selectedDays?.includes('Tuesday')}
              onChange={() =>
                setSelectedDays((prevDays) =>
                   prevDays && prevDays.includes('Tuesday')
                    ?  prevDays?.filter((day) => day !== 'Tuesday')
                    : [...(prevDays || []), 'Tuesday']
                )
              }
                            control={
                              <MuiCheckbox color="success" size="medium" />
                            }
                          />
                        </div>
                      </div>
                      <div className={styles.frameParent3}>
                        <div className={styles.mondayParent}>
                          <div className={styles.monday}>Wednesday</div>
                          <MuiFormControlLabel
                            className={styles.toggle}
                            label=""
                            value="Wednesday"
              checked={ selectedDays?.includes('Wednesday')}
              onChange={() =>
                setSelectedDays((prevDays) =>
                   prevDays && prevDays.includes('Wednesday')
                    ?  prevDays?.filter((day) => day !== 'Wednesday')
                    : [...(prevDays || []), 'Wednesday']
                )
              }
                            control={
                              <MuiCheckbox color="success" size="medium" />
                            }
                          />
                        </div>
                        <div className={styles.mondayParent}>
                          <div className={styles.monday}>Thursday</div>
                          <MuiFormControlLabel
                            className={styles.toggle}
                            label=""
                            value="Thursday"
              checked={ selectedDays?.includes('Thursday')}
              onChange={() =>
                setSelectedDays((prevDays) =>
                   prevDays && prevDays.includes('Thursday')
                    ?  prevDays?.filter((day) => day !== 'Thursday')
                    : [...(prevDays || []), 'Thursday']
                )
              }
                            control={
                              <MuiCheckbox color="success" size="medium" />
                            }
                          />
                        </div>
                      </div>
                      <div className={styles.frameParent3}>
                        <div className={styles.mondayParent}>
                          <div className={styles.monday}>Friday</div>
                          <MuiFormControlLabel
                            className={styles.toggle}
                            label=""
                            value="Friday"
              checked={ selectedDays?.includes('Friday')}
              onChange={() =>
                setSelectedDays((prevDays) =>
                   prevDays && prevDays.includes('Friday')
                    ?  prevDays?.filter((day) => day !== 'Friday')
                    : [...(prevDays || []), 'Friday']
                )
              }
                            control={
                              <MuiCheckbox color="success" size="medium" />
                            }
                          />
                        </div>
                        <div className={styles.mondayParent}>
                          <div className={styles.monday}>Saturday</div>
                          <MuiFormControlLabel
                            className={styles.toggle}
                            label=""
                            value="Saturday"
              checked={ selectedDays?.includes('Saturday')}
              onChange={() =>
                setSelectedDays((prevDays) =>
                   prevDays && prevDays.includes('Saturday')
                    ? prevDays?.filter((day) => day !== 'Saturday')
                    : [...(prevDays || []), 'Saturday']
                )
              }
                            control={
                              <MuiCheckbox color="success" size="medium" />
                            }
                          />
                        </div>
                      </div>
                      <div className={styles.sundayParent}>
                        <div className={styles.monday}>Sunday</div>
                        <MuiFormControlLabel
                          className={styles.toggle}
                          label=""
                          value="Sunday"
              checked={ selectedDays?.includes('Sunday')}
              onChange={() =>{
                setSelectedDays((prevDays) =>
                   prevDays && prevDays.includes('Sunday')
                    ?  prevDays?.filter((day) => day !== 'Sunday')
                    : [...(prevDays || []), 'Sunday']
                )}
              }
                          control={
                            <MuiCheckbox color="success" size="medium" />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className={styles.largeButtons}
              onClick={()=>{
                db.collection('users').doc(auth.currentUser.uid).update({
                  fromTime,
                  toTime,
                  selectedDays,
                  schedule:verifiedshc
                })
                const response = axios.post('https://loggworks-c15c9e56f169.herokuapp.com/api/schedule', {
                  fromTime,
                  toTime,
                  selectedDays,
                  uid:auth.currentUser.uid,
                  schedule:verifiedshc
                }).then(()=>{
                  console.log(response.data);
                  window.location.reload()
                });
              }}>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder111.svg"
                />
                <b className={styles.label1}>Save</b>
                <img
                  className={styles.icons20pxplaceholder}
                  alt=""
                  src="/icons20pxplaceholder111.svg"
                />
              </button>
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailModal;
